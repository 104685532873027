import img1 from "../assets/pages/international/blog/ninja-van-phillippines/ninja-van-philippines.png";
import img2 from "../assets/pages/international/blog/seur-shop.jpeg";
import img3 from "../assets/pages/international/blog/rock-fall.jpeg";
import img4 from "../assets/pages/international/blog/epicery.jpeg";
import img5 from "../assets/pages/international/blog/greencityhub.png";
import img6 from "../assets/pages/international/blog/poland-measure-air-quality.png";
import img7 from "../assets/pages/international/blog/ninja-direct-procurement.png";
import img8 from "../assets/pages/international/blog/all-electric.png";
import img9 from "../assets/pages/international/blog/rail-project.png";
import img10 from "../assets/pages/international/blog/viva-tech.png";
import img11 from "../assets/pages/international/blog/brt-italy.png";
import img12 from "../assets/pages/international/blog/forestry-funding.jpeg";
import img13 from "../assets/pages/international/blog/sustainability-report.png";
import img14 from "../assets/pages/international/blog/dpd-ireland.jpeg";
import img15 from "../assets/pages/international/blog/delivery-robots.jpeg";
import img16 from "../assets/pages/international/blog/asendia-10.jpeg";
import img17 from "../assets/pages/international/blog/climate-change.png";
import img18 from "../assets/pages/international/blog/dpd-lockers.png";
import img20 from "../assets/pages/international/blog/ninja-van.png";
import img21 from "../assets/pages/international/blog/80-seur.png";
import img22 from "../assets/pages/international/blog/self-driving-estonia.png";
import img23 from "../assets/pages/international/blog/climate-group.png";
import img24 from "../assets/pages/international/blog/seagrass.png";
import img25 from "../assets/pages/international/blog/ninja-van-packet.png";
import img26 from "../assets/pages/international/blog/first-bus.png";
import img27 from "../assets/pages/international/blog/renewable-HGVs.png";
import img28 from "../assets/pages/international/blog/International-Trade-Awards-2023.png";
import img29 from "../assets/pages/international/blog/france-award.png";
import img30 from "../assets/pages/international/blog/seur-eletric-vans.png";
import img31 from "../assets/pages/international/blog/polis-conference.png";
import img32 from "../assets/pages/international/blog/france-pickup.png";
import img33 from "../assets/pages/international/blog/switzerland-air-quality.jpg";
import img34 from "../assets/pages/international/blog/SIRHA-Lyon.jpg";
import img35 from "../assets/pages/international/blog/poland-van.jpg";
import img36 from "../assets/pages/international/blog/eShopper-trends.png";
import img37 from "../assets/pages/international/blog/trade-awards.png";
import img38 from "../assets/pages/international/blog/geopost-empowers-businesses.png";
import img39 from "../assets/pages/international/blog/syria-aid.jpeg";
import img40 from "../assets/pages/international/blog/ninja-van-singapore.jpg";
import img42 from "../assets/pages/international/blog/sustained-operation.jpg";
import img43 from "../assets/pages/international/blog/geopost-logo.png";
import img44 from "../assets/pages/international/blog/RSPB-Langford-Lowlands_feature.jpg";
import img45 from "../assets/pages/international/blog/Asendia_feature.jpg";
import img46 from "../assets/pages/international/blog/Stuart_feature-scaled.jpg";
import img47 from "../assets/pages/international/blog/naujas.png";
import img48 from "../assets/pages/international/blog/dpd-direct-lite.png";
import img49 from "../assets/pages/international/blog/dpd-poland-award.png";
import img50 from "../assets/pages/international/blog/medicine-beauty.jpeg";
import img51 from "../assets/pages/international/blog/Delivery-personnel-calling-customer-scaled.jpeg";
import img52 from "../assets/pages/international/blog/Carbon-neutral-shipping_025_554x388px.png";
import img53 from "../assets/pages/international/blog/Visuel2.jpg";
import img54 from "../assets/pages/international/blog/seur-award.png";
import img55 from "../assets/pages/international/blog/geopost-sustainability.png";
import img57 from "../assets/pages/international/blog/cma-gcm-logo.png";
import img58 from "../assets/pages/international/blog/WorldToDoList-DPD-VAN-OXFORD.jpg";
import img59 from "../assets/pages/international/blog/ireland-changes.jpg";
import img60 from "../assets/pages/international/blog/mobile-charging-solution.jpg";
import img61 from "../assets/pages/international/blog/sustainability-report-2023.jpg";
import img62 from "../assets/pages/international/blog/robot-deliveries.jpg";
import img63 from "../assets/pages/international/blog/dpd-forest-green.jpg";
import img64 from "../assets/pages/international/blog/asendia-returns.jpg";
import img65 from "../assets/pages/international/blog/dpd-germany.jpg";
import img66 from "../assets/pages/international/blog/dpd-croatia.jpg";
import img67 from "../assets/pages/international/blog/asendia-organisation.jpg";
import img68 from "../assets/pages/international/blog/article68.jpeg";
import img69 from "../assets/pages/international/blog/article69.png";
import img70 from "../assets/pages/international/blog/article70.jpeg";
import img71 from "../assets/pages/international/blog/article71.jpeg";
import img72 from "../assets/pages/international/blog/article72.jpeg";
import img73 from "../assets/pages/international/blog/article73.png";
import img74 from "../assets/pages/international/blog/article74.png";
import img75 from "../assets/pages/international/blog/article75.png";
import img76 from "../assets/pages/international/blog/article76.png";
import img77 from "../assets/pages/international/blog/article77.jpeg";
import img78 from "../assets/pages/international/blog/article78.jpeg";
import img79 from "../assets/pages/international/blog/article79.jpeg";
import img80 from "../assets/pages/international/blog/article80.jpeg";
import img81 from "../assets/pages/international/blog/article81.jpeg";
import img82 from "../assets/pages/international/blog/article82.jpeg";
import img83 from "../assets/pages/international/blog/article83.jpeg";
import img85 from "../assets/pages/international/blog/article85.jpeg";
import img86 from "../assets/pages/international/blog/article86.jpeg";
import img87 from "../assets/pages/international/blog/article87.jpeg";
import img88 from "../assets/pages/international/blog/article88.jpeg";
import img89 from "../assets/pages/international/blog/article89.jpeg";
import img90 from "../assets/pages/international/blog/article90.png";
import img91 from "../assets/pages/international/blog/article91.png";
import img92 from "../assets/pages/international/blog/article92.png";
import img93 from "../assets/pages/international/blog/article93.png";
import img94 from "../assets/pages/international/blog/article94.png";
import img95 from "../assets/pages/international/blog/article95.png";
import img96 from "../assets/pages/international/blog/article96.png";
import img97 from "../assets/pages/international/blog/article97.png";
import img98 from "../assets/pages/international/blog/article98.jpg";
import img99 from "../assets/pages/international/blog/article99.png";
import img100 from "../assets/pages/international/blog/article100.png";
import img101 from "../assets/pages/international/blog/article101.png";
import img102 from "../assets/pages/international/blog/article102.png";
import img103 from "../assets/pages/international/blog/article103.png";
import img104 from "../assets/pages/international/blog/article104.png";
import img105 from "../assets/pages/international/blog/article105.png";
import img106 from "../assets/pages/international/blog/article106.png";
import img107 from "../assets/pages/international/blog/article107.png";

import img90Header from "../assets/pages/international/blog/article90Header.png";
import img91Header from "../assets/pages/international/blog/article91Header.png";
import img92Header from "../assets/pages/international/blog/article92Header.png";
import img93Header from "../assets/pages/international/blog/article93Header.png";

import chineseNewYear from "../assets/pages/international/blog/chineseNewYear.png";
import moneySavingExpert from "../assets/pages/international/blog/money-saving-expert.jpeg";
import economistsSustainabilityWeek from "../assets/pages/international/blog/economistsSustainabilityWeek.jpeg";
import logisticsSummit24 from "../assets/pages/international/blog/logisticsSummit24.png";
import solarPoweredLockers from "../assets/pages/international/blog/solarPoweredLockers.png";
import lifeScienceLogistics from "../assets/pages/international/blog/lifeScienceLogistics.png";
import croatiaFleet from "../assets/pages/international/blog/croatiaFleet.jpeg";
import outOfHomeDeliveries from "../assets/pages/international/blog/outOfHomeDeliveries.jpeg";
import sustainabilityReport from "../assets/pages/international/blog/sustainabilityReport.jpeg";
import internationalTrade from "../assets/pages/international/blog/internationalTrade.png";
import sustainabilityAward2024 from "../assets/pages/international/blog/sustainabilityAward2024.jpg";
import tipsa from "../assets/pages/international/blog/TIPSA.jpg";
import chronopost from "../assets/pages/international/blog/chronopost.jpg";

import carbonScreenshot from "../assets/pages/international/blog/DPD_Carbon-Dashboard_Screenshot_final.png";

import navBg from "../assets/pages/international/blog/ninja-van-phillippines/background.png";
import { CallToActionBtn } from "../components/CallToActionBtn/CallToActionBtn";
import { Body } from "../components/Typography/Body";
import { Bold } from "../components/Typography/BlogArticle/Bold";
import { BulletPoints } from "../components/Typography/BlogArticle/BulletPoints";
import { Header2 } from "../components/Typography/BlogArticle/Header2";
import { Header3 } from "../components/Typography/BlogArticle/Header3";
import { Normal } from "../components/Typography/BlogArticle/Normal";
import { Quotation } from "../components/Typography/BlogArticle/Quotation";
import { Quotee } from "../components/Typography/BlogArticle/Quotee";
import { SmallPrint } from "../components/Typography/BlogArticle/SmallPrint";
import { Underline } from "../components/Typography/BlogArticle/Underline";
import variables from "../styles/colors.scss";
import { AnchorLink } from "../components/AnchorLink/AnchorLink";
import { Heading4 } from "../components/Typography/Heading4";
import { Heading5 } from "../components/Typography/Heading5";
import { DownloadBtn } from "../components/DownloadBtn/DownloadBtn";
import { AccentRed } from "../components/Typography/AccentRed";
import { AccentBlue } from "../components/Typography/AccentBlue";
import { AccentYellow } from "../components/Typography/AccentYellow";
import ReactPlayer from "react-player";

export const DPD_INTERNATIONAL_UPDATES_COUNTRY_LIST = [
  "Bahrain",
  "Canada",
  "Egypt",
  "France",
  "India",
  "Italy",
  "Jordan",
  "Kuwait",
  "Philippines",
  "Oman",
  "Qatar",
  "Russia",
  "Saudi Arabia",
  "Singapore",
  "South Korea",
  "Sri Lanka",
  "Taiwan",
  "Turkey",
  "UAE",
  "Ukraine",
  "USA",
  "Germany",
  "Australia",
  "Finland",
  "Belgium",
  "New Zealand",
].sort();

export const DPD_NEWS_FEED_COUNTRY_LIST = [
  "Germany",
  "Spain",
  "Singapore",
  "Switzerland",
  "Poland",
  "United Kingdom",
  "France",
  "Italy",
  "Ireland",
  "Thailand",
  "Estonia",
  "India",
  "Australia",
  "Europe",
  "Croatia",
  "China",
].sort();

export const DPD_NEWS_FEED_CATEGORY_LIST = {
  Blog: "#fffd8d",
  "e-Commerce": "#f7b2c2",
  Fulfilment: "#f2716c",
  Sustainability: "#83c06f",
  Technology: "#d3d3d3",
  "International Trade": "#0096FF",
};

// Insight & info / Newsroom / DPDgroup News Feed.
export const blogArticles = [
  {
    id: 107,
    date: "March 2025",
    dateTitle: "06 March 2025",
    category: "Blog",
    country: "USA",
    title: "New US Tariffs: What You Need to Know",
    cardTitle: "New US Tariffs: What You Need to Know",
    imgs: [img107],
    navImg: [""],
    body: (
      <>
        <Heading4>New US Tariffs: What You Need to Know</Heading4>
        <Body>
          <Bold>
            Important changes to US import duties are now in effect for goods
            from China, Canada, and Mexico.
          </Bold>
        </Body>
        <Body>
          Businesses and individuals shipping to the US should be aware of newly
          implemented tariffs on goods originating from China, Canada, and
          Mexico. These tariffs apply to goods exceeding a value of $800 and
          vary depending on the country of origin:
        </Body>
        <BulletPoints>
          <li>
            <Body>
              <Bold>China:</Bold>
              20% duty
            </Body>
          </li>
          <li>
            <Body>
              <Bold>Canada and Mexico:</Bold>
              25% duty
            </Body>
          </li>
        </BulletPoints>
        <Body>
          <Bold>What is the de minimis threshold?</Bold>
        </Body>
        <Body>
          Currently, goods valued under $800 are exempt from duty under the de
          minimis rule. However, this threshold is under review and may be
          subject to change. This means that in the future, duties could apply
          to a wider range of imported goods.
        </Body>
        <Body>
          <Bold>What does this mean for you?</Bold>
        </Body>
        <Body>
          If you import goods from China, Canada, or Mexico, it&apos;s crucial
          to stay informed about these tariff changes and any potential updates
          to the de minimis threshold. Factor these new duties into your pricing
          and shipping strategies to avoid unexpected costs.
        </Body>
        <Body>
          <Bold>Stay informed</Bold>
        </Body>
        <Body>
          We are closely monitoring the situation and will provide timely
          updates and guidance to help you navigate these changes effectively.
          Be sure to check back for the latest news and information.
        </Body>
        <Body>
          <Bold>Need help?</Bold>
        </Body>
        <Body>
          If you have any questions or concerns about these new tariffs and how
          they might affect your business, please don&apos;t hesitate to contact
          us at
          <br />
          <Bold>internationalexperts@dpdgroup.co.uk</Bold>
        </Body>
      </>
    ),
  },
  {
    id: 106,
    date: "March 2025",
    dateTitle: "06 March 2025",
    category: "Blog",
    country: "Europe",
    title:
      "Beyond Borders 5: Unlock the potential of Central & Eastern Europe!",
    cardTitle:
      "Beyond Borders 5: Unlock the potential of Central & Eastern Europe!",
    imgs: [img106],
    navImg: [""],
    body: (
      <>
        <Body>
          You&apos;re Invited! Beyond Borders 5: CEE OOH & E-commerce Insights -
          March 18th, 11 AM
        </Body>
        <Body>Unlock the potential of Central & Eastern Europe!</Body>
        <Body>
          Join us for Beyond Borders 5, a vital webinar exploring the dynamic
          landscape of Out-of-Home (OOH) advertising and the booming C2C
          e-commerce market in Central and Eastern Europe (CEE).
        </Body>
        <Body>
          <Bold>What you&apos;ll discover:</Bold>
        </Body>
        <BulletPoints>
          <li>
            Cutting-Edge OOH Solutions: Dive into the latest innovations and
            capabilities shaping the CEE OOH industry.
          </li>
          <li>
            C2C E-commerce: Understand the explosive growth of platforms like
            Etsy and Vinted.
          </li>
          <li>
            Customs Tips & Tricks: Master the essentials of smooth and efficient
            customs clearance to streamline your CEE operations.
          </li>
        </BulletPoints>
        <Body>
          <Bold>Why Attend?</Bold>
        </Body>
        <BulletPoints>
          <li>
            Gain actionable insights to expand your business into the thriving
            CEE market.
          </li>
          <li>
            Discover innovative strategies to reach your target audience through
            OOH advertising.
          </li>
          <li>
            Learn practical tips for navigating the complexities of C2C
            e-commerce and customs.
          </li>
          <li>Get up to date information from experts in their fields.</li>
        </BulletPoints>
        <Body>
          <AnchorLink
            to="https://app.livestorm.co/dpd-uk/beyond-borders-5-unlock-the-potential-of-central-and-eastern-europe?s=46a32d22-54bb-4dd2-ab1a-ba4c401076b1"
            externalLink={true}
            linkColor={variables.linkColor}
          >
            <Bold>Date:</Bold>
          </AnchorLink>{" "}
          March 18th at 11am
        </Body>
      </>
    ),
  },
  {
    id: 105,
    date: "March 2025",
    dateTitle: "04 March 2025",
    category: "Fulfilment",
    country: "Europe",
    link: "https://www.geopost.com/en/news/geopost-s-2024-annual-results/",
    cardTitle: "Geopost's 2024 Annual Results",
    imgs: [img105],
  },
  {
    id: 104,
    date: "March 2025",
    dateTitle: "06 March 2025",
    category: "Blog",
    country: "United Kingdom",
    title:
      "Post Office and DPD Expand Partnership with New International Delivery Services",
    cardTitle:
      "Post Office and DPD Expand Partnership with New International Delivery Services",
    imgs: [img104],
    navImg: [""],
    body: (
      <>
        <Heading4>DPD & Post Office: International Services expansion</Heading4>
        <Body>
          <Bold>
            Now you can send parcels abroad from thousands of Post Office
            branches with DPD&apos;s reliable international delivery options!
          </Bold>
        </Body>
        <Body>
          <Bold>
            We have expanded our successful partnership with the Post Office to
            include international delivery services, offering customers even
            more choice and convenience when sending parcels overseas.
          </Bold>
        </Body>
        <Body>
          Following a successful trial at 300 branches, customers at over 4,100
          Post Offices across the UK can now choose from a range of DPD&apos;s
          international delivery services, including:
        </Body>
        <BulletPoints>
          <li>
            <Body>
              <Bold>DPD Classic:</Bold>
              Cost-effective delivery to Europe and beyond.
            </Body>
          </li>
          <li>
            <Body>
              <Bold>DPD Direct Lite:</Bold>
              Lightweight and affordable international shipping.
            </Body>
          </li>
          <li>
            <Body>
              <Bold>DPD Air Classic & Air Express:</Bold>
              Faster delivery options for urgent shipments.
            </Body>
          </li>
        </BulletPoints>
        <Body>
          This expansion builds on the existing partnership between the Post
          Office and DPD, which began in 2021 with &apos;Click and Collect&apos;
          and Next Day delivery services within the UK.
        </Body>
        <Quotation>
          <Quotee>Elaine Kerr, DPD UK CEO, commented:</Quotee>
          &quot;We really value our relationship with the Post Office and
          introducing international services is the logical next step... We have
          the largest delivery network in Europe and deliver to over 200
          destinations worldwide. Our approach is designed to make it easy and
          affordable to find the right international service with free tracking
          included and duty and customs made as straightforward as
          possible.&quot;
        </Quotation>
        <Quotation>
          <Quotee>Neil Brocklehurst, Post Office Acting CEO, added: </Quotee>
          &quot;As part of delivering our &apos;New Deal for Postmasters&apos;
          it&apos;s vital that we strengthen postmasters&apos; offer to
          customers... In today&apos;s fast-paced world, customers and
          businesses expect international parcels to reach their destination in
          a matter of days and having DPD international delivery services
          available provides them with the options to meet their needs.&quot;
        </Quotation>
        <Body>
          <Bold>
            This expanded partnership provides customers with greater
            flexibility and convenience when sending parcels abroad, combining
            the accessibility of the Post Office&apos;s vast network with
            DPD&apos;s reliable and efficient international delivery services.
          </Bold>
        </Body>
      </>
    ),
  },
  {
    id: 103,
    date: "February 2025",
    dateTitle: "27 February 2025",
    category: "Fulfilment",
    country: "Europe",
    link: "https://www.geopost.com/en/news/customer-support-teams-across-geopost/",
    cardTitle: "Customer Support Teams across Geopost",
    imgs: [img103],
  },
  {
    id: 102,
    date: "February 2025",
    dateTitle: "06 February 2025",
    category: "Blog",
    country: "France",
    title: "Expand your online business in France with DPD Direct",
    cardTitle: "Expand your online business in France with DPD Direct",
    imgs: [img102],
    navImg: [""],
    body: (
      <>
        <Heading4>
          <Bold>Expand your online business in France with DPD Direct</Bold>
        </Heading4>
        <Body>
          France is one of Europe&apos;s largest e-commerce markets, valued at
          over €129 billion annually.
        </Body>
        <Body>
          Leveraging the expertise of Colissimo, we have combined our
          international expertise to build a tailored solution with DPD Direct,
          making it easy for you to enter the French market.
        </Body>
        <Body>With our DPD Direct service you&apos;ll get:</Body>
        <BulletPoints>
          <AccentRed>
            <li>
              <Body>
                Real-time parcel tracking with delivery updates in their local
                language
              </Body>
            </li>
            <li>
              <Body>Seamless customs support into the EU</Body>
            </li>
            <li>
              <Body>
                Cost-effective prices with a first class final-mile delivery
                service through our partner Colissimo
              </Body>
            </li>
          </AccentRed>
        </BulletPoints>
        <Body>
          Let your customers in France say &apos;Merci&apos; for giving them the
          best possible delivery experience.
        </Body>
        <Body>
          We can show you how to unlock the potential of the French market.
          Contact our experts today.
        </Body>
      </>
    ),
  },
  {
    id: 101,
    date: "January 2025",
    dateTitle: "29 January 2025",
    category: "Blog",
    country: "China",
    title: "Celebrate the Chinese New Year of the Snake!",
    cardTitle: "Celebrate the Chinese New Year of the Snake!",
    imgs: [img101],
    navImg: [""],
    body: (
      <>
        <Heading4>
          <Bold>Celebrate the Chinese New Year of the Snake!</Bold>
        </Heading4>
        <Body>
          China’s rapid economic growth has created a vast market for innovative
          products and services which as a UK business, you can tap into and
          boost your exports.
        </Body>
        <Body>Why start shipping to China?</Body>
        <Body>
          Massive Potential: China’s growing online shoppers (almost a billion
          last year) offer a huge customer base for your products and services.
        </Body>
        <Body>
          High-Value Market: Chinese consumers are increasingly willing to pay a
          premium for quality and innovation
        </Body>
        <Body>
          Government Support: The Chinese government offers various incentives
          and supports programmes for foreign businesses.
        </Body>
        <img
          style={{ width: "50%" }}
          className="article-image"
          src={chineseNewYear}
        />
        <Quotee>Source: https://www.great.gov.uk/markets/china/</Quotee>
        <Heading4>DPD: Your partner in Chinese e-commerce</Heading4>
        <Heading5>
          Our expertise and services can help you navigate the Chinese market:
        </Heading5>
        <br />
        <Body>
          <Bold>Customs Clearance:</Bold> Efficiently clear your goods through
          Chinese customs
        </Body>
        <Body>
          <Bold>Last-Mile Delivery:</Bold> Deliver your products to customers’
          doorsteps
        </Body>
        <Body>
          <Bold>Local Market Insights:</Bold> Leverage our expertise in China
          with our dedicated international website
        </Body>
        <br />
        <Body>
          We can show you how to unlock the potential of the Chinese market.
          Contact our experts today below.
        </Body>
      </>
    ),
  },
  {
    id: 100,
    date: "October 2024",
    dateTitle: "02 October 2024",
    category: "Blog",
    country: "Europe",
    title: "Beyond Borders 4: Boost your exports to Ireland",
    cardTitle: "Beyond Borders 4: Boost your exports to Ireland",
    imgs: [img100],
    navImg: [""],
    body: (
      <>
        <Heading4>
          <Bold>Beyond Borders 4: Boost your exports to Ireland</Bold>
        </Heading4>
        <Body>Boost your exports to Ireland</Body>
        <Body>
          Register now for our latest Beyond Borders webinar to help grow your
          business and maximise your cross-border export deliveries to Ireland.
        </Body>
        <Body>
          We’ll be joined by DPD Ireland where we’ll discuss all the latest
          trends, solutions and best practices for cross-border e-commerce.
        </Body>
        <Body>Date: Thursday 23 October, 1pm</Body>
        <Body>
          <CallToActionBtn
            btnLink="https://app.livestorm.co/dpd-uk/beyond-borders-4-boost-your-exports-to-ireland?s=6a3fc9df-4f4a-4b94-9533-6b3aaeca7e93"
            externalBtnLink={true}
          >
            Register now
          </CallToActionBtn>
        </Body>
        <Body>
          In our upcoming webinar you’ll learn about:{" "}
          <BulletPoints>
            <li>
              <Body>The Irish market and consumer preferences</Body>
            </li>
            <li>
              <Body>Our brand new returns solution</Body>
            </li>
            <li>
              <Body>How to handle customs regulations and compliance</Body>
            </li>
            <li>
              <Body>
                Leveraging DPD Ireland’s large network and excellent delivery
                service
              </Body>
            </li>
          </BulletPoints>
        </Body>
        <Body>
          Don’t miss this opportunity to hear from our team at DPD Ireland who
          will give you crucial knowledge to help you unlock the potential of
          the vast Irish market.
        </Body>
        <Body>Register now to secure your spot!</Body>
      </>
    ),
  },
  {
    date: "October 2024",
    dateTitle: "01 October 2024",
    category: "e-Commerce",
    country: "Europe",
    cardTitle:
      "Chronopost expands its digital services to support small businesses with their e-commerce operations",
    imgs: [chronopost],
    link: "https://www.geopost.com/en/news/chronopost-e-commerce-operations/",
  },
  {
    date: "September 2024",
    dateTitle: "30 September 2024",
    category: "Technology",
    country: "Spain",
    cardTitle:
      "TIPSA awarded for its commitment to Corporate Social Responsibility at the AEDHE Awards for Business Excellence",
    imgs: [tipsa],
    link: "https://www.geopost.com/en/news/tipsa-awarded-aedhe-awards/",
  },
  {
    id: 99,
    date: "September 2024",
    dateTitle: "19 September 2024",
    category: "Blog",
    country: "United Kingdom",
    title:
      "New deadline for shipping to Great Britain to Northern Ireland under the Windsor Framework",
    cardTitle:
      "New deadline for shipping to Great Britain to Northern Ireland under the Windsor Framework",
    imgs: [img99],
    navImg: [""],
    body: (
      <>
        <Heading4>
          <Bold>
            New deadline for shipping to Great Britain to Northern Ireland under
            the Windsor Framework
          </Bold>
        </Heading4>
        <Body>
          The Government has announced today that it is allowing more time for
          shippers to prepare for the new arrangements and that the deadline to
          implement the Windsor Framework has changed from 30 September to March
          31, 2025, see their statement{" "}
          <AnchorLink
            to="https://www.gov.uk/guidance/sending-parcels-between-great-britain-and-northern-ireland"
            externalLink={true}
            linkColor={variables.linkColor}
          >
            here
          </AnchorLink>
          .
        </Body>
        <Body>
          This new date provides vital time for you to prepare for the
          significant changes in movement of parcels to Northern Ireland from
          Great Britain, required by the Windsor Framework.
        </Body>
        <Body>
          We have previously issued the IT specifications detailing the new data
          requirements, so please use this extra time to test your data and
          declaration processes to ensure the smooth flow of your goods to
          Northern Ireland after this date.
        </Body>
        <Body>
          Our teams are here to support you so please reach out to your Account
          Manager if you have any questions on the new arrangements or consult
          our FAQ document{" "}
          <AnchorLink
            to="https://e.marketing.dpd.co.uk/l/269632/2024-09-12/2t1wmz/269632/1726757362eP1nF0zz/DPD___Exports_to_NI_FAQs.pdf"
            externalLink={true}
            linkColor={variables.linkColor}
          >
            here
          </AnchorLink>
          .
        </Body>
        <Body>
          We will continue to work with AICES, the trade body that represents
          the express sector, and the Government to enable the move to the new
          Windsor Framework arrangements by 31 March, 2025 for a smooth
          transition for all our customers.
        </Body>
      </>
    ),
  },
  {
    date: "September 2024",
    dateTitle: "12 September 2024",
    category: "Sustainability",
    country: "Europe",
    cardTitle:
      "Geopost wins Sustainability Strategy Award at the Global Sustainability & ESG Awards 2024",
    imgs: [sustainabilityAward2024],
    link: "https://www.geopost.com/en/news/geopost-wins-sustainability-strategy-award/",
  },
  {
    id: 98,
    date: "September 2024",
    dateTitle: "12 September 2024",
    category: "Blog",
    country: "Europe",
    title:
      "SMEs Prioritise Delivery: New Geopost Report Reveals How B2B Businesses Think",
    cardTitle:
      "SMEs Prioritise Delivery: New Geopost Report Reveals How B2B Businesses Think",
    imgs: [img98],
    navImg: [""],
    body: (
      <>
        <Heading4>
          <Bold>
            SMEs Prioritise Delivery: New Geopost Report Reveals How B2B
            Businesses Think
          </Bold>
        </Heading4>
        <Body>
          <Bold>
            The Geopost 2B Recipient Barometer 2024 dives deep into the
            experiences of over 2,500 businesses across 11 European countries.
          </Bold>{" "}
          This comprehensive report analyses the entire recipient journey, from
          sourcing to final delivery, for both SMEs (small and medium-sized
          enterprises) and large companies. Covering key industries like
          fashion, healthcare, and automotive, the Barometer uncovers critical
          insights for B2B success.
        </Body>
        <Body>
          <Bold>Key takeaway? Delivery is a game-changer.</Bold> Reliable
          carriers and flexible delivery options are top priorities for SMEs. In
          today&apos;s competitive landscape, efficient delivery directly
          impacts customer satisfaction and operational efficiency, ultimately
          driving business success.
        </Body>
        <Body>
          <Bold>Beyond efficiency, the report explores sourcing trends.</Bold>{" "}
          Interestingly, word-of-mouth recommendations remain a significant
          influence (25%) for SMEs when choosing suppliers. Long-term supplier
          relationships are also prevalent, with 62% of SMEs reviewing
          partnerships every 3 years or less.
        </Body>
        <Body>
          <Bold>Curious to learn more?</Bold>
          <br />
          <br />
          The Geopost 2B Recipient Barometer 2024 is a valuable resource for any
          B2B business. Visit{" "}
          <AnchorLink
            to="https://www.geopost.com/en/expertise/2b-recipient-barometer/"
            externalLink={true}
            linkColor={variables.linkColor}
          >
            https://www.geopost.com/en/expertise/2b-recipient-barometer/
          </AnchorLink>{" "}
          to download the full report and discover how to optimise your B2B
          recipient experience.
          <br />
          <br />
          <br />
          <ReactPlayer
            url={"https://www.youtube.com/watch?v=-LkPkbfs01I "}
            width="100%"
            controls={true}
          />
          <br />
          Or see{" "}
          <AnchorLink
            to="https://www.youtube.com/watch?v=-LkPkbfs01I"
            externalLink={true}
            linkColor={variables.linkColor}
          >
            https://www.youtube.com/watch?v=-LkPkbfs01I
          </AnchorLink>
        </Body>
      </>
    ),
  },
  {
    date: "June 2024",
    dateTitle: "19 June 2024",
    category: "International Trade",
    country: "Europe",
    cardTitle:
      "As many as 20,000 small businesses have stopped exporting to EU since 2019, says new report",
    imgs: [internationalTrade],
    link: "https://www.export.org.uk/insights/trade-news/as-many-as-20-000-small-businesses-have-stopped-exporting-to-eu-since-2019-says-new-report/",
  },
  {
    id: 97,
    date: "June 2024",
    dateTitle: "19 June 2024",
    category: "Blog",
    country: "Australia",
    title: "Spotlight on Australia",
    cardTitle: "Spotlight on Australia",
    imgs: [img97],
    navImg: [""],
    body: (
      <>
        <Heading5>
          <Bold>Spotlight on Australia:</Bold>
        </Heading5>
        <Heading4>
          <Bold>
            Say g&apos;day to your Aussie customers <br />
            with DPD’s great services to Australia
          </Bold>
        </Heading4>
        <Heading5>
          Australia is the perfect place to boost your exports and reach new
          customers. They have a thriving economy, a love for British brands and
          a growing desire for quality imported goods, and DPD can provide
          everything you need to reach this lucrative market with great service
          every step of the way.
        </Heading5>
        <span style={{ textAlign: "center" }}>
          <Heading5>
            <Bold>Why Australia?</Bold>
          </Heading5>
        </span>
        <Body>
          Australia is the 14th largest economy in the world (IMF, 2020). The
          country shares a common language and culture with the UK, as well as
          business and legal practices such as intellectual property (IP)
          protection and the rule of law. This makes it easier for UK companies
          to do business there. The trade deal, combined with Australia’s stable
          institutions, sophisticated financial systems and good governance,
          makes it a very attractive market for UK companies. Australia is a
          sophisticated, wealthy and tech-savvy market. Its 25 million people,
          including one million Britons (Australian government, 2018), make it
          an ideal place to test and develop new products or services. Around
          three-fifths of the total population live in Australia’s four largest
          cities, making it easy to prioritise where to launch your product or
          service.
        </Body>
        <span style={{ textAlign: "center" }}>
          <Heading5>
            <Bold>A fast, reliable service</Bold>
          </Heading5>
        </span>
        <Body>Here&apos;s what you can expect with our improved service:</Body>
        <Body>
          <Bold>Fast & reliable</Bold>: Get your goods to your customers quicker
          with our enhanced options: <Bold>Air Express</Bold>: Delivers in just
          4-5 days. <Bold>Air Classic</Bold>: Gets your products there in 5-7
          days.
        </Body>
        <Body>
          <Bold>Competitive rates</Bold>: Great prices as well as great service.
        </Body>
        <Body>
          <Bold>Streamlined customs clearance</Bold>: Our team of experts
          navigates customs efficiently, minimising delays.
        </Body>
        <Body>
          <Bold>Widespread coverage</Bold>: We now deliver to a whopping 90% of
          Australia, ensuring your products reach almost anywhere on the
          continent.
        </Body>
        <span style={{ textAlign: "center" }}>
          <Body>
            <Bold>
              To tap into the Australian market <br />
              contact us: internationalexperts@dpdgroup.co.uk
            </Bold>
          </Body>
        </span>
      </>
    ),
  },
  {
    date: "June 2024",
    dateTitle: "13 June 2024",
    category: "Sustainability",
    country: "Europe",
    cardTitle: "Geopost’s latest Sustainability Report is out!",
    imgs: [sustainabilityReport],
    link: "https://www.geopost.com/en/news/geopost-s-latest-sustainability-report-is-out/",
  },
  {
    id: 96,
    date: "June 2024",
    dateTitle: "13 June 2024",
    category: "Blog",
    country: "India",
    title:
      "Capturing the Indian E-commerce Boom: Insights from our Exclusive Webinar",
    cardTitle:
      "Capturing the Indian E-commerce Boom: Insights from our Exclusive Webinar",
    imgs: [img96],
    navImg: [""],
    body: (
      <>
        <Heading5>
          Are you looking to expand your reach and tap into the thriving Indian
          market?
        </Heading5>
        <Heading5>
          Check out our exclusive webinar and find out everything you need to
          know to boost your exports throughout India!
        </Heading5>
        <BulletPoints>
          <li>
            <Body>
              Unlock the potential of the Indian market: Learn about the booming
              e-commerce industry in India and the opportunities it presents for
              your business.
            </Body>
          </li>
          <li>
            <Body>
              Seamless cross-border shipping: Discover how our combined
              expertise simplifies the process of sending parcels between the UK
              and India.
            </Body>
          </li>
          <li>
            <Body>
              DTDC&apos;s extensive network: Leverage DTDC’s expansive network
              to ensure fast, reliable, and cost-effective deliveries across
              India.
            </Body>
          </li>
        </BulletPoints>
        <Body>
          Fill out the form below, and we&apos;ll send you a recording of the
          webinar to help you to make informed decisions and capitalise on the
          potential of the Indian e-commerce market.
        </Body>
        <Body>
          <CallToActionBtn
            btnLink="https://docs.google.com/forms/d/e/1FAIpQLSf5zDjtMEjgLtmVAuLWFjkaLPC9rC5DZwDhnIYaV4bxsd9gJw/viewform"
            externalBtnLink={true}
          >
            Webinar Form
          </CallToActionBtn>
        </Body>
        <Body>
          Expand your reach and propel your business forward or you can contact
          our team of international experts to find out how you can grow your
          business by reaching new markets worldwide.
        </Body>
        <Body>
          <CallToActionBtn
            btnLink="https://mail.google.com/mail/u/0/?tf=cm&fs=1&to=internationalexperts@dpdgroup.co.uk&hl=en-GB"
            externalBtnLink={true}
          >
            Contact International Expert
          </CallToActionBtn>
        </Body>
      </>
    ),
  },
  {
    id: 95,
    date: "June 2024",
    dateTitle: "12 June 2024",
    category: "Blog",
    country: "Europe",
    title: "Le Groupe La Poste abroad",
    cardTitle: "Le Groupe La Poste abroad",
    imgs: [img95],
    navImg: [""],
    body: (
      <>
        <Heading4>
          <AccentBlue>
            La Poste group generates 44% of its sales internationally, and is
            present in 63 countries on 5 continents. As Europe&apos;s leading
            express parcel operator, it delivered 2.1 billion parcels in 2023
            under the DPD, SEUR, Chronopost and BRT brand names.
          </AccentBlue>
        </Heading4>
        <span style={{ textAlign: "center" }}>
          <Heading5>
            <Bold>Did you know?</Bold>
          </Heading5>
        </span>
        <Body>
          Geopost’s parent company, La Poste group, was the first European
          postal operator to position itself in the express parcel market for
          the rapid delivery of parcels weighing less than 30 kg, which until
          then had been dominated by American logistics integrators (UPS, FedEx,
          DHL). In 1999, he created the Geopost holding company and, through
          partnerships and acquisitions, built an entity capable of meeting the
          needs of international customers.
        </Body>
        <Body>
          DPD, Germany&apos;s No. 2 parcel service, was the group&apos;s first
          international acquisition in 2001.
        </Body>
        <Body>
          It gave rise to the international DPD brand, which brings together all
          La Poste Group&apos;s national and international express parcel
          activities.
        </Body>
        <Body>
          This was followed by SEUR in Spain, Masterlink and then Siodemka in
          Poland, BRT in Italy, JadLog in Brazil, DPD Laser in South Africa,
          Ninja Van in Southeast Asia, etc.
        </Body>
        <Body>
          Today, La Poste Group is the European leader in express parcels, with
          over 1,400 hubs and depots worldwide.
        </Body>
        <span style={{ textAlign: "center" }}>
          <Heading4>
            <AccentBlue>A growing international ambition</AccentBlue>
          </Heading4>
        </span>
        <Body>
          <Bold>
            As Europe&apos;s leading parcel delivery network, La Poste group
            boasts a strong presence in many countries:
          </Bold>
        </Body>
        <div style={{ width: "50%", margin: "0 auto" }}>
          <Body>
            No.1 in France, Spain, Ireland, Italy, Slovakia, Lithuania and
            Latvia
          </Body>
          <Body>
            No. 2 in Germany, the United Kingdom, Austria, Portugal, the Czech
            Republic, Bulgaria, Poland and Estonia
          </Body>
          <Body>No. 3 in Switzerland, Croatia and Slovenia</Body>
        </div>
        <Body>
          <Bold>
            The Group has expanded its presence beyond Europe into dynamic
            markets:
          </Bold>
        </Body>
        <div style={{ width: "50%", margin: "0 auto" }}>
          <Body>
            Via wholly-owned or controlled subsidiaries: in Russia, Belarus,
            China (acquisition of Lenton), Kazakhstan, South Africa and Brazil.
          </Body>
          <Body>
            Via minority stakes in India, Turkey, Southeast Asia,
            French-speaking Africa (Morocco and Burkina Faso) and Egypt
          </Body>
        </div>
        <span style={{ textAlign: "center" }}>
          <Heading4>
            <AccentBlue>Setting course for international mail</AccentBlue>
          </Heading4>
        </span>
        <Body>
          Asendia was created in 2012 to join the efforts of La Poste and Swiss
          Post in international mail, then expanded into services for sending
          small goods, parcels and cross-border mail to businesses from 16
          countries (Europe, North America and the Asia/Pacific region) and
          finally into technology services connecting major global brands to
          consumers. Starting with international mail, Asendia has become a
          market leader in international e-commerce services.
        </Body>
        <Body>
          Previously part of the Services-Courrier-Colis branch, Asendia joins
          Geopost, the international parcel branch of La Poste group,
          Europe&apos;s leading parcel delivery network, to develop synergies
          and accelerate La Poste group&apos;s development in logistics and
          parcel services on a global scale, based on unprecedented growth in
          e-commerce.
        </Body>
        <Heading4>
          <AccentBlue>Service innovation without borders</AccentBlue>
        </Heading4>
        <Body>
          Thanks to the local experts in the Geopost network, La Poste Group
          benefits from in-depth knowledge of the markets in which it operates,
          and a panoramic view of local e-shopper practices and e-commerce
          trends in Europe, which Geopost shares every year in its
          &quot;e-shopper barometer&quot;. The Group uses this intelligence to
          offer innovative services that meet the targeted needs of its
          customers in each country:
        </Body>
        <Body>
          <Bold>Predict</Bold>: home delivery within a one-hour window,
          announced on the morning of delivery (by e-mail or SMS). A flexible
          service par excellence, Predict also offers the possibility, if the
          recipient so wishes, of rescheduling delivery from their smartphone or
          online to another date or location: a new address, a neighbour&apos;s
          home or a Pickup relay of their choice.
        </Body>
        <Body>
          <Bold>The Pickup out-of-home delivery network</Bold>: over 100,000
          Pickup points (relays and lockers) in 30 countries worldwide. This
          network enables 95% of Europeans to be within 15 minutes of a Pickup
          point.
        </Body>
        <Body>
          <Bold>The myDPD portal</Bold>: a recipient portal, accessible via an
          app and the web, which keeps recipients informed in real time about
          their delivery. Thanks to Live Tracking, they can follow the exact
          route of their parcel on a map. The recipient can also interact at any
          time: for example, redirect a parcel to a relay or a neighbour, or
          change the delivery date.
        </Body>
        <Body>
          <Bold>The Chronofresh offer</Bold>: transporting foodstuffs without
          breaking the cold chain. DPD also delivers ambient and fresh products
          throughout Europe.
        </Body>
        <Body>
          <Bold>BioLogistic</Bold>: temperature-controlled transport of
          healthcare products.
        </Body>
        <Body>
          <Bold>Biocair</Bold>: the specialist in the delivery of
          pharmaceutical, biotech and life science products.
        </Body>
        <span style={{ textAlign: "center" }}>
          <Body>
            <Bold>
              To learn more about our global presence <br />
              contact us: internationalexperts@dpdgroup.co.uk
            </Bold>
          </Body>
        </span>
      </>
    ),
  },
  {
    date: "June 2024",
    dateTitle: "04 June 2024",
    category: "Fulfilment",
    country: "Europe",
    cardTitle:
      "Geopost strengthens its cross-border services for Out-of-Home deliveries, now connecting 28 countries in Europe",
    imgs: [outOfHomeDeliveries],
    link: "https://www.geopost.com/en/news/geopost-out-of-home-deliveries/",
  },
  {
    date: "May 2024",
    dateTitle: "22 May 2024",
    category: "Sustainability",
    country: "Croatia",
    cardTitle: "DPD Croatia enriched its fleet with 20 new electric vehicles",
    imgs: [croatiaFleet],
    link: "https://www.geopost.com/en/news/dpd-croatia-fleet-new-electric-vehicles/",
  },
  {
    date: "May 2024",
    dateTitle: "02 May 2024",
    category: "Technology",
    country: "Ireland",
    cardTitle:
      "Biocair and Aramex collaborate to revolutionise life science logistics in Ireland",
    imgs: [lifeScienceLogistics],
    link: "https://www.geopost.com/en/news/science-logistics-ireland/",
  },
  {
    id: 94,
    date: "April 2024",
    dateTitle: "29 April 2024",
    category: "e-Commerce",
    title:
      "Find out how to make global eCommerce easy and unlock your international opportunities.",
    cardTitle:
      "Find out how to make global eCommerce easy and unlock your international opportunities.",
    imgs: [img94],
    navImg: [""],
    body: (
      <>
        <Body>
          This webinar is for companies looking to expand their international
          reach but are not sure how to go about it? ESW and DPD will show you
          exactly how to build your business globally.
        </Body>
        <Body>
          ESW is a leading global direct-to-consumer (DTC) eCommerce company,
          empowering the world’s best-loved brands and retailers with their
          end-to-end solution to expand their international sales.
        </Body>
        <Heading4>
          <AccentBlue>IN THIS WEBINAR YOU WILL LEARN</AccentBlue>
        </Heading4>
        <BulletPoints>
          <li>
            <Body>
              <AccentBlue>
                Identify strategic market opportunities for profitable growth
              </AccentBlue>
            </Body>
          </li>
          <li>
            <Body>
              <AccentBlue>
                Boost conversion rates with localised checkouts
              </AccentBlue>
            </Body>
          </li>
          <li>
            <Body>
              <AccentBlue>
                Overcome the biggest barriers for cross-border eCommerce success
              </AccentBlue>
            </Body>
          </li>
          <li>
            <Body>
              <AccentBlue>
                Navigate the changing landscape of global deliveries
              </AccentBlue>
            </Body>
          </li>
          <li>
            <Body>
              <AccentBlue>
                Streamline your customs clearance and minimise delays
              </AccentBlue>
            </Body>
          </li>
        </BulletPoints>
        <Heading4>
          <AccentRed>Missed the Webinar? Watch it now!</AccentRed>
        </Heading4>
        <Body>
          If you couldn&apos;t join us on the day, we&apos;ve recorded the
          webinar which you can watch at your convenience here:
        </Body>
        <Body>
          <AnchorLink
            to="https://dpdgroup.wistia.com/medias/kjxzm88f4l"
            externalLink={true}
            linkColor={variables.linkColor}
          >
            {" "}
            https://dpdgroup.wistia.com/medias/kjxzm88f4l
          </AnchorLink>
        </Body>
      </>
    ),
  },
  {
    date: "April 2024",
    dateTitle: "26 April 2024",
    category: "Sustainability",
    country: "Europe",
    cardTitle:
      "Pickup is launching the first solar-powered lockers for parcel collection",
    imgs: [solarPoweredLockers],
    link: "https://www.geopost.com/en/news/pickup-solar-powered-lockers/",
  },
  {
    id: 93,
    date: "April 2024",
    dateTitle: "10 April 2024",
    category: "Blog",
    title: "Reach the US for less with DPD Direct",
    cardTitle: "Reach the US for less with DPD Direct",
    imgs: [img93],
    navImg: [img93Header],
    body: (
      <>
        <Heading4>
          Our Air Classic service is a great way to reach the booming US market,
          with fast delivery and great rates for those lightweight packages, all
          arriving with no fuss, straight to the doorsteps of your American
          customers.
        </Heading4>
        <Heading5>Why choose Air Classic for your US shipments?</Heading5>
        <BulletPoints>
          <li>
            <Body>
              Cost-effective: Air Classic offers significant savings compared to
              other standard services through deadweight billing, so you pay for
              the weight of your product rather than the size
            </Body>
          </li>
          <li>
            <Body>
              Reliable Delivery: You can be confident that your products will
              arrive safely and securely within a typical 3-7 day transit time
              across the states, so your customers receive their orders promptly
            </Body>
          </li>
          <li>
            <Body>
              Simplified US Customs Clearance: Our international delivery
              experts will handle your customs clearance for a smooth and
              seamless delivery experience
            </Body>
          </li>
        </BulletPoints>
        <Heading5>
          <AccentBlue>Did you know</AccentBlue>
        </Heading5>
        <BulletPoints>
          <li>
            <Body>
              <AccentBlue>
                The US e-commerce market is expected to surge by over $388
                billion between 2024 and 2029, a massive 51% growth potential
                for your business! *
              </AccentBlue>
            </Body>
          </li>
          <li>
            <Body>
              <AccentBlue>
                The US is the UK’s top export market, meaning a massive audience
                awaits your products and services
              </AccentBlue>
            </Body>
          </li>
          <li>
            <Body>
              <AccentBlue>
                In 2024, U.S. retail e-commerce revenue from the sale of fashion
                apparel, footwear, and accessories is set to reach over 145
                billion U.S. dollars
              </AccentBlue>
            </Body>
          </li>
        </BulletPoints>
        <Quotation>* Sources:</Quotation>
        <Quotation>
          https://www.statista.com/statistics/272391/us-retail-e-commerce-sales-forecast/#:~:text=The%20revenue%20in%20the%20E,a%20new%20peak%20in%202029
        </Quotation>
        <Quotation>https://www.great.gov.uk/markets/united-states/</Quotation>
        <Body>
          Contact our team of international experts to find out how you can grow
          your business by reaching new customers in the USA.{" "}
        </Body>
      </>
    ),
  },
  {
    id: 92,
    date: "March 2024",
    dateTitle: "20 March 2024",
    category: "Blog",
    title: "We hope you enjoyed our Exporting to Europe webinar",
    cardTitle: "We hope you enjoyed our Exporting to Europe webinar",
    imgs: [img92],
    navImg: [img92Header],
    body: (
      <>
        <Heading4>
          Thank you for registering for our recent webinar about EU VAT and the
          Import One-Stop Shop scheme (IOSS).
        </Heading4>
        <Heading5>
          We hope you found the session useful with some valuable insights to
          help you boost your exports throughout Europe.
        </Heading5>
        <Heading5>
          <AccentRed>Missed the Webinar? </AccentRed>
        </Heading5>
        <Body>Don’t worry!</Body>
        <Body>
          If you couldn’t join us live, we’ve recorded the webinar for your
          convenience which you can watch here:
        </Body>
        <CallToActionBtn
          btnLink="https://dpdgroup.wistia.com/medias/rg6flmy7zj "
          externalBtnLink={true}
        >
          Watch now
        </CallToActionBtn>
        <Body>
          Our experts explained everything you need to know about EU VAT, IOSS
          and shipping parcels to Europe seamlessly.
        </Body>
        <Body>
          DPD can help you boost your exports to Europe with our DPD Classic
          service - one of the best-connected and most reliable delivery
          networks in Europe, with our industry-leading Predict notifications
          across Europe giving your customers the best delivery experience, no
          matter where they’re based.
        </Body>
        <Body>Ready to unlock the opportunity of the European market?</Body>
        <Body>
          Contact our team of international experts to find out how you can grow
          your business by reaching new customers in Europe.
        </Body>
      </>
    ),
  },
  {
    id: 91,
    date: "March 2024",
    dateTitle: "13 March 2024",
    category: "Blog",
    title: "Boost your exports with the ‘Help to Grow’ scheme",
    cardTitle: "Boost your exports with the ‘Help to Grow’ scheme",
    imgs: [img91],
    navImg: [img91Header],
    body: (
      <div>
        <span style={{ textAlign: "center" }}>
          <Heading4>
            DPD is proudly supporting the Department for Business and
            Trade&apos;s (DBT) Help to Grow scheme, which provides comprehensive
            support and resources to empower and help UK SMEs to grow, thrive
            and export.
          </Heading4>
        </span>
        <Heading5>
          Alongside the support from DBT, DPD offers the perfect springboard for
          your success and you can tap into DPD&apos;s expertise and ship your
          international parcels seamlessly with our services to ensure reliable
          and efficient deliveries anywhere in the world.
        </Heading5>
        <Heading5>
          The Help to Grow scheme provides long-term support to UK businesses to
          deliver a brighter future for Britain and improve economic security
          and opportunity for everyone.
        </Heading5>
        <Body>
          For more information visit: https://helptogrow.campaign.gov.uk/
          <AnchorLink
            to="https://helptogrow.campaign.gov.uk/"
            externalLink={true}
            linkColor={variables.linkColor}
          >
            {" "}
            Here
          </AnchorLink>
        </Body>
        <Body>
          If you&apos;re ready to take your products further afield, DPD is here
          to help. Check out our international services on our website or
          contact our International Experts at{" "}
          <Bold>internationalexperts@dpdgroup.co.uk</Bold>
        </Body>
      </div>
    ),
  },
  {
    date: "March 2024",
    dateTitle: "12 March 2024",
    category: "Fulfilment",
    cardTitle:
      "Talking about commerce and delivery: Geopost at Leaders in Logistics Summit 2024",
    imgs: [logisticsSummit24],
    link: "https://www.geopost.com/en/news/geopost-leader-in-logistics-summit/",
  },
  {
    id: 90,
    date: "March 2024",
    dateTitle: "06 March 2024",
    category: "Blog",
    title:
      "DPD can help your business to tap into the thriving Middle Eastern Market",
    cardTitle:
      "DPD can help your business to tap into the thriving Middle Eastern Market",
    imgs: [img90],
    navImg: [img90Header],
    body: (
      <>
        <Heading4>
          Did you know UK exports to the Middle East topped £35 billion in 2023?
          This booming market presents a wealth of opportunities for your
          business.
        </Heading4>
        <Body>[source https://great.gov.uk]</Body>
        <Heading5>
          DPD, as your trusted logistics partner, can help you take advantage of
          the thriving trade routes between the UK and the Middle East.
        </Heading5>
        <br />
        <span style={{ textAlign: "center" }}>
          <Heading4>
            <AccentYellow>
              Why the Middle East is great for UK exports...
            </AccentYellow>
          </Heading4>
        </span>
        <BulletPoints>
          <li>
            <Body>
              <AccentYellow>
                Booming demand for UK goods: From luxury fashion and high-tech
                gadgets to premium foodstuffs and innovative homewares, British
                products enjoy a strong reputation in the Middle East.
              </AccentYellow>
            </Body>
          </li>
          <li>
            <Body>
              <AccentYellow>
                Rising disposable incomes: a rapidly growing middle class with a
                penchant for high-quality imported goods is fueling retail
                expansion across the region.
              </AccentYellow>
            </Body>
          </li>
          <li>
            <Body>
              <AccentYellow>
                Infrastructure boom: modern ports, airports, and logistics
                networks facilitate smooth and efficient cargo movement.
              </AccentYellow>
            </Body>
          </li>
        </BulletPoints>
        <br />
        <span style={{ textAlign: "center" }}>
          <Heading4>Unlocking £35 Billion in Opportunity</Heading4>
          <Heading5>
            DPD can be your gateway to business success in the Middle East as
            our experts understand the intricacies of Middle Eastern logistics
            and offer:
          </Heading5>
        </span>
        <BulletPoints>
          <li>
            <Body>
              Market-leading air solutions: choose from our two premium
              services, Air Classic and Air Express, tailored to your specific
              needs and budget.
            </Body>
          </li>
          <li>
            <Body>
              Unmatched Speed and Reliability: Whether you need your goods in
              Dubai urgently or Riyadh next week, our extensive network and
              industry-leading partners guarantee rapid and reliable transit.
            </Body>
          </li>
          <li>
            <Body>
              Real-Time Tracking and Support: Monitor your shipment every step
              of the way with our intuitive online platform and receive support
              from our dedicated customer service team.
            </Body>
          </li>
        </BulletPoints>
        <br />
        <span style={{ textAlign: "center" }}>
          <Heading4>Ready to tap into the Middle East market?</Heading4>
          <Heading5>
            Don&apos;t let logistical hurdles hold you back. With DPD as your
            partner, you can confidently navigate the exciting trade landscape
            of the Middle East and unleash your export potential.
          </Heading5>
        </span>
      </>
    ),
  },
  {
    date: "March 2024",
    dateTitle: "04 March 2024",
    category: "Sustainability",
    cardTitle:
      "Geopost as proud partner at The Economist's 9th annual Sustainability Week",
    imgs: [economistsSustainabilityWeek],
    link: "https://www.geopost.com/en/news/geopost-as-partner-at-the-economist-s-9th-annual-sustainability-week/",
  },
  {
    date: "February 2024",
    dateTitle: "16 February 2024",
    category: "Fulfilment",
    cardTitle:
      "DPD UK tops Money Saving Expert’s annual parcel delivery poll for ninth time",
    imgs: [moneySavingExpert],
    link: "https://www.geopost.com/en/news/dpd-uk-tops-money-saving-expert-s-annual-parcel-delivery-poll-for-ninth-time/",
  },
  {
    id: 89,
    date: "January 2024",
    dateTitle: "8 January 2024",
    category: "Fulfilment",
    title:
      "Global E-commerce company Asendia marks the New Year with a new look!",
    cardTitle:
      "Global E-commerce company Asendia marks the New Year with a new look!",
    imgs: [img89],
    body: (
      <>
        <Heading4>
          For this new year, Asendia, a global leader in e-commerce solutions,
          proudly announces a significant rebrand that marks a decade-long
          journey of transformative business evolution.
        </Heading4>
        <Body>
          This strategic move follows the introduction of the new Group Holding
          Structure in 2023, featuring two distinct brands for the respective
          business divisions: Asendia and ESW. Asendia specialises in the
          delivery of cross-border parcels, small packets, and mail solutions,
          while operating under the brand of Asendia. ESW, on the other hand, is
          dedicated to global Direct-to-Consumer (D2C) e-commerce technology
          solutions and operates under the brand of ESW. The alignment of the
          two brands represents a recognisable image, fostering a shared purpose
          within the Group.
        </Body>
        <Body>
          Asendia has successfully transformed from a mail-driven business into
          a global e-commerce leader. Within the Asendia Group, whose revenue
          reached €2.5 billion in 2023, Asendia has undergone an impressive
          growth trajectory over the past decade, more than doubling its
          revenue.
        </Body>
        <Body>
          Asendia currently employs over 1,500 people across 4 continents (North
          America, Europe, Asia and Oceania), in 17 countries and delivers
          packages, parcels and mail to more than 200 destinations across the
          globe.
        </Body>
        <Body>
          The new branding represents Asendia’s evolution and aligns to its
          e-commerce business transformation. It is a testament to
          Asendia&apos;s commitment to providing seamless cross-border
          e-commerce solutions and reinforcing its position as a market leader
          in the industry, putting the customer at the centre of everything we
          do, which is why we stand for trust, friendliness, and ease of use.
        </Body>
        <Quotation>
          &quot;Our commitment to e-commerce excellence is unwavering, but to
          better reflect the company we are today, we felt now was the right
          time to give Asendia a new look. The updated brand elements aim to
          communicate a cohesive narrative that reflects our successful
          transformation, our values, vision, and innovative spirit&quot;
          <Quotee>
            Comments Domenico Pereira, Chief Marketing Officer, Asendia.
          </Quotee>
        </Quotation>
        <Body>
          For more information about Asendia Group:
          https://www.asendia.com/asendia-insights/press-release-new-strategic-organisation
          <AnchorLink
            to="https://www.asendia.com/asendia-insights/press-release-new-strategic-organisation"
            externalLink={true}
            linkColor={variables.linkColor}
          >
            {" "}
            here.
          </AnchorLink>
        </Body>
      </>
    ),
  },
  {
    id: 88,
    date: "January 2024",
    dateTitle: "5 January 2024",
    category: "Fulfilment",
    title: "International Goods and Service Tax Update",
    cardTitle: "International Goods and Service Tax Update",
    imgs: [img88],
    body: (
      <>
        <Heading4>
          We have an important update for you about various tax changes in
          Singapore, Malaysia and Norway which may affect your shipments.
        </Heading4>
        <Heading5>
          <AccentRed>Norway</AccentRed>
        </Heading5>
        <Body>
          Please be advised that with effect from 1 January 2024, the low-value
          threshold of NOK 350 will be removed and VAT will be applicable on all
          goods sent to Norway.
        </Body>
        <Heading5>
          <AccentRed>Singapore</AccentRed>
        </Heading5>
        <Body>
          Effective from 1 January 2024, the Singaporean government will be
          raising their National Rate Tax (GST) from 8% to 9%. The Duty
          threshold will remain at SG$400 where shipments below SG$400 will not
          require GST payment, except for controlled items.
        </Body>
        <Body>
          If the importer has obtained an in-payment permit with an 8% GST rate
          but is unable to clear their goods by 31st December 2023, they can
          still use this permit after the date as long as it remains valid.
          However, the importer must also apply for a GST Short-Payment Permit
          (SPGST) to address the 1% GST shortfall for their goods before
          clearing.
        </Body>
        <Heading5>
          <AccentRed>Malaysia</AccentRed>{" "}
        </Heading5>
        <Body>
          The Malaysian Government have introduced a new scheme for payment of
          sales tax, effective 1 January 2024. Sales tax will apply to goods
          with a &apos;CIF value&apos; (cost of the product plus the shipping
          and insurance) up to and including MYR500, known as low-value goods
          (&apos;LVG&apos;).
        </Body>
        <Body>
          You will be required to register with the Royal Malaysian Customs
          department if your total sales value of LVG into Malaysia in the
          previous 12 months exceeds RM500,000 (approximately £84,500). A
          registered seller will be assigned an LVG registration number and will
          be required to charge, collect, and remit sales tax on LVG.
        </Body>
        <Body>
          You can register for Malaysian tax{" "}
          <AnchorLink
            to="https://lvg.customs.gov.my/Home"
            externalLink={true}
            linkColor={variables.linkColor}
          >
            here.
          </AnchorLink>
        </Body>
        <Body>
          Once registered for GST, you are required to charge and account for
          10% sales tax on B2C sales made to customers in Malaysia. This is
          based on the price of the product, excluding shipping and any other
          costs. For more information, please click{" "}
          <AnchorLink
            to="http://www.customs.gov.my//en/_layouts/15/CustomApplication/AnnouncementsDetails.aspx?ID=414"
            externalLink={true}
            linkColor={variables.linkColor}
          >
            here.
          </AnchorLink>
        </Body>
        <Body>
          All customers need to provide their GST registration number in the
          data provided to us.
        </Body>
      </>
    ),
  },
  {
    id: 87,
    date: "December 2023",
    dateTitle: "21 December 2023",
    category: "e-Commerce",
    country: "Brasil",
    title:
      "Jadlog is a two-time champion in the Reclame Aqui 2023 Awards, among the companies with the best reputations for Brazilian customers",
    cardTitle: "Jadlog is a two-time champion in the Reclame Aqui 2023 Awards",
    imgs: [img87],
    body: (
      <>
        <Body>
          Reclame Aqui is a Brazilian website for complaints against companies
          about customer service, purchases, sales, products and services, which
          once a year awards companies that have stood out among consumers by
          providing solutions to their complaints.
        </Body>
        <Body>
          On December 12th 2023, the Reclame Aqui 2023 Award recognized 186
          companies with the best reputations that Brazilian consumers trust the
          most.
        </Body>
        <Body>
          It is with great joy that we share the news that Jadlog has once again
          been recognized as the big champion of the Reclame Aqui Award in the
          &quot;Logistics and Transport&quot; category, consolidating our
          prominent position in the sector.
        </Body>
        <Body>Jadlog is a two-time champion of the award.</Body>
        <Body>
          This achievement is especially significant as it is the result of
          public recognition, with more than 25,000 votes expressing confidence
          in the company&apos;s work throughout 2023.
        </Body>
        <Quotation>
          “This award is a public recognition that we are in the right direction
          to have the consignee satisfaction as one of our main objectives”,
          <Quotee>says Jadlog CEO Bruno Tortorello.</Quotee>
        </Quotation>
        <Body>
          The award reinforces Jadlog&apos;s commitment to offering fast, safe
          and efficient freight services and our ongoing commitment to exceeding
          expectations and providing an exceptional customer experience.
        </Body>
      </>
    ),
  },
  {
    id: 86,
    date: "November 2023",
    dateTitle: "30 November 2023",
    category: "Fulfilment",
    title: "Geopost SA announces the closing of its SRT Group sale transaction",
    cardTitle:
      "Geopost SA announces the closing of its SRT Group sale transaction",
    imgs: [img86],
    body: (
      <>
        <Heading4>
          Successful completion of previously announced sale of SRT Group to
          Mutares SE & Co. KGaA
        </Heading4>
        <Body>
          Geopost today announces the completion of the previously announced
          sale of SRT Group to Mutares SE & Co. KGaA. Also known as Stuart, SRT
          Group is a European leader in urban on-demand delivery, especially in
          the food and groceries segments, which operates in more than 100 major
          European cities, generating over EUR 400 million in revenues in 2022.
        </Body>
        <Body>
          Under the terms of the agreement, which was approved by the Board of
          directors of La Poste Groupe and by the Employee representative groups
          within Geopost SA, this transaction concludes the purchasing of SRT
          group by Mutares SE & Co. KGaA, a listed private equity holding
          company with offices in Munich (HQ), Amsterdam, Frankfurt, Helsinki,
          London, Madrid, Milan, Paris, Shanghai, Stockholm, Vienna and Warsaw.
          Mutares is specialised in acquiring medium-sized companies in special
          situations, based in Europe, which show significant operational
          improvement potential.
        </Body>
        <Body>
          This sale marks a new stage in the history of SRT Group after a
          collaboration of almost a decade with Geopost, present from the
          beginning of the adventure as an investor. During this period, the SRT
          Group teams have accomplished significant work to create the
          conditions for growth, with a sustained pace particularly during the
          Covid period.
        </Body>
        <Body>
          Geopost management thanks the SRT Group teams and wishes every success
          to SRT Group and Mutares.
        </Body>
      </>
    ),
  },
  {
    id: 85,
    date: "November 2023",
    dateTitle: "17 November 2023",
    category: "Fulfilment",
    country: "United Kingdom",
    title:
      "DPD France ranked best for Customer Service in ESCDA Awards for the 4th time in a row",
    cardTitle:
      "DPD France ranked best for Customer Service in ESCDA Awards for the 4th time in a row",
    imgs: [img85],
    body: (
      <>
        <Heading4>
          DPD France has won the ESCDA Customer Service of the Year award for
          the 4th time running in the Mail and Parcel Delivery category*. This
          recognition is a testament to their constant commitment to improving
          customer satisfaction.
        </Heading4>
        <Body>
          Over the last few years, DPD France has strengthened their
          organisation, optimised call and email handling, launched online tools
          enabling a smoother communication with their customers and invested in
          ongoing training for their teams.
        </Body>
        <Quotation>
          &quot;This award testifies to our commitment and passion for customer
          satisfaction. We set ourselves the goal of providing the best possible
          customer service, and we&apos;re proud to have achieved it,&quot;
          <Quotee>
            says Nathalie Nahmias, Customer Relations Director at DPD France.
          </Quotee>
          &quot;But this award is not just the fruit of our work. It is also the
          result of our customers&apos; trust. They have given us their opinions
          and suggestions, and we have taken them into account to improve our
          service. We would like to thank them for their support. It is thanks
          to them and for them that we have achieved this success.&quot;
        </Quotation>
        <Body>
          Congratulations to DPD France for the excellent service you provide
          for your customers!
        </Body>
      </>
    ),
  },
  {
    id: 84,
    date: "November 2023",
    dateTitle: "17 November 2023",
    category: "Sustainability",
    title:
      "Stéphanie Berlioz, EVP and CFO of Geopost, wins Special Award for Responsible Finance!",
    cardTitle:
      "Stéphanie Berlioz, EVP and CFO of Geopost, wins Special Award for Responsible Finance!",
    imgs: [img43],
    body: (
      <>
        <Heading4>
          As the convergence of Sustainability and Finance has become a
          strategic issue, Geopost is happy to announce that Stéphanie Berlioz,
          EVP and Chief Financial Officer of Geopost, has been awarded a special
          Responsible Finance Executive Award by the DFCG, the French
          Association of Financial Controllers and Directors. This award is a
          further recognition of our ambition and determination to become the
          international reference of sustainable delivery.
        </Heading4>
        <Heading5>The Responsible Finance Executive Award</Heading5>
        <Body>
          The Responsible Finance Executive Award of the DFCG recognises the
          initiatives of finance executives who have contributed to significant
          projects focusing on Sustainability issues in finance processes. The
          aim is to measure the role of the CFO within the company, and to
          assess their impact on the company&apos;s overall performance.
        </Body>
        <Body>
          Geopost’s EVP and CFO Stéphanie Berlioz has been awarded during a
          ceremony held at Financium, the major conference for financial
          executives, on Nov 16th 2023 in Paris. The panel of judges was made up
          of finance and sustainability professionals and senior partners from
          the world of finance and business.
        </Body>
        <Heading5>
          Geopost’s Net Zero Plan by 2040 and the Carbon budget process
        </Heading5>
        <Body>
          In 2023, Geopost defined an ambitious Net Zero plan by 2040. To
          achieve the decarbonisation commitment endorsed by the Science Based
          Targets initiative (SBTi) - a 43% reduction in CO2 emissions by 2030
          compared with the 2020 baseline and net zero emissions by 2040 -
          effective governance (operational and financial) at Group level is
          required.
        </Body>
        <Body>
          With the ambition of jointly assessing financial and environmental
          aspects, the Finance and Sustainability Departments have put in place
          an annual carbon budget process: a maximum amount of CO2 emissions to
          be emitted for a given year and by each business unit. The aim of this
          is to enable business units to manage their CO2 emissions and
          associated investments to ensure that they meet the decarbonisation
          targets set out in the SBTi trajectory.
        </Body>
        <Quotation>
          &quot;I’m extremely proud of winning this Jury’s special Responsible
          Finance Executive Award, which recognizes the work of all our teams on
          defining new processes to better align our financial and sustainable
          objectives&quot;
          <Quotee>commented Stéphanie Berlioz, EVP and CFO of Geopost.</Quotee>
        </Quotation>
        <Body>
          More details on the Responsible Finance Executive Awards of the DFCG{" "}
          <AnchorLink
            to="https://www.financium.fr/prix-du-rse/"
            externalLink={true}
            linkColor={variables.linkColor}
          >
            here.
          </AnchorLink>
        </Body>
      </>
    ),
  },
  {
    id: 83,
    date: "November 2023",
    dateTitle: "9 November 2023",
    category: "Fulfilment",
    country: "United Kingdom",
    title:
      "Docklands eco-sortation centre creates 650 new jobs and enables clean, all-electric delivery for London",
    cardTitle:
      "Docklands eco-sortation centre creates 650 new jobs and enables clean, all-electric delivery for London",
    imgs: [img83],
    body: (
      <>
        <BulletPoints>
          <li>
            Giant new site entirely diesel-free with 500 EVs and all HGVs
            powered by HVO
          </li>
          <li>
            DPD achieves pledge to deliver to 30 of the largest towns and cities
            in the UK with green final mile delivery vehicles.
          </li>
        </BulletPoints>
        <Body>
          Leading parcel delivery company DPD UK has officially opened a new
          £40m eco-regional sortation centre in Bromley-By-Bow that will enable
          the firm to deliver up to 80,000 ‘green’ parcels into London each day.
        </Body>
        <Body>
          The opening ceremony was performed by the Secretary of State for
          Transport, The Rt Hon Mark Harper MP, who praised the investment in
          the new eco-centre for helping to drive the green economic growth the
          UK needs, in front of an audience comprising some of the UK’s leading
          online retailers.
        </Body>
        <Body>
          The new facility, which is the size of 8.5 football pitches, will
          create 650 new jobs when fully operational early in the new year and
          will mean that DPD’s delivery service within London’s north and south
          circular area will be all-electric.
        </Body>
        <Body>
          The innovative new site is a hybrid eco-sortation and distribution
          facility. In addition to the parcel delivery operation, which will be
          DPD’s greenest in the UK, the 430 metre long state-of-the-art conveyor
          system will automatically sort all intra-London parcels for next-day
          delivery on-site, instead of them being trunked to the Midlands and
          back for sorting, as currently.
        </Body>
        <Body>
          As part of being a diesel-free operation, the site has solar panels on
          the parking canopy to help charge the 500 electric delivery vans and a
          40,000 litre HVO tank to enable the fleet of LGVs and 7.5T trucks to
          fill-up with the renewable biofuel. The plan also includes the
          installation of solar panels on the main roof in the new year, which
          could generate up to 1 million kwh per annum to help power the entire
          building.
        </Body>
        <Body>
          Docklands will also see DPD achieve its pledge to deliver to 30 of the
          largest towns and cities in the UK with zero and low-emission final
          mile delivery vehicles.
        </Body>
        <Quotation>
          <Quotee>Elaine Kerr, DPD UK CEO commented,</Quotee>
          “I am absolutely delighted to welcome the Secretary of State for
          Transport to our brand-new Docklands site. This investment represents
          another huge step towards our net zero goals and, I believe,
          demonstrates the new gold standard for a green parcel delivery
          operation in London, or any city. It is also great to be able to share
          our vision with so many of our retail partners, who have backed us on
          this journey to create genuinely cleaner and greener solutions for
          their customers.
          <br />
          <br />
          “This represents a major milestone in our green journey. We opened the
          UK’s first all-electric parcel delivery depot in Westminster almost
          exactly five years ago. Now Docklands demonstrates what is possible,
          on a much bigger scale. But this is far from the end. We are
          constantly looking for new and smarter solutions, as we demonstrated
          earlier this year with the roll-out of autonomous robot deliveries,
          and we will continue investing in green innovation to accelerate our
          decarbonisation pathway.”
        </Quotation>
        <Quotation>
          <Quotee>
            Secretary of State for Transport, The Rt Hon Mark Harper MP
            commented,
          </Quotee>
          “I was delighted to attend the opening of DPD’s new London hub, which
          will speed up delivery times, create new jobs and support an important
          industry that contributes billions to our economy.”
        </Quotation>
      </>
    ),
  },
  {
    id: 82,
    date: "November 2023",
    dateTitle: "7 November 2023",
    category: "Fulfilment",
    country: "United Kingdom",
    title:
      "Post Office partners with DPD UK to offer in-branch customers a new parcel delivery option",
    cardTitle:
      "Post Office partners with DPD UK to offer in-branch customers a new parcel delivery option",
    imgs: [img82],
    body: (
      <>
        <Heading4>
          Post Office has teamed up with DPD UK as part of a plan to offer
          in-branch customers a wider range of parcel delivery options.
        </Heading4>
        <Body>
          The new Buy-In-Branch parcel service will begin in selected branches
          in time for the peak Christmas delivery season, complementing the
          recent launch of Parcels Online which enables customers to compare and
          purchase shipping online for drop-off in Post Offices.
        </Body>
        <Body>
          In chosen branches, Post Office customers will be able to walk up to a
          counter and choose to send their parcels with DPD. The announcement
          marks the continued diversification of the Post Office as it evolves
          to become a one-stop hub for customers and a place where they can
          choose the delivery carrier which best suits their needs.
        </Body>
        <Quotation>
          <Quotee>Elaine Kerr, DPD UK CEO, commented,</Quotee>
          “Buy-in-Branch extends our working relationship with the Post Office
          and will help provide more choice and convenience for UK parcel
          shippers. DPD parcel recipients can already collect their parcels from
          Post Office branches and regard them as safe and trusted locations for
          a wide range of services. The new service will help reinforce the
          unique position the branches have and, for the first time, give
          face-to-face parcel customers genuine choice over how they ship.”
        </Quotation>
        <Quotation>
          <Quotee>
            Neill O’Sullivan, MD of Parcels and Mails at Post Office, comments:
          </Quotee>
          “Post Office is innovating to meet changing customer preferences and
          the increasingly competitive market. Our goal is to deliver unmatched
          access to parcel services, addressing the needs of businesses and
          retail customers, both now and in the future.”
          <br />
          <br />
          “Our Buy-in-Branch parcel service will not only widen delivery choice
          for our customers, but our in-person service provided by our dedicated
          Postmasters will also give them additional peace-of-mind knowing their
          parcels will be taken care of and arrive safely.”
        </Quotation>
        <Body>
          The launch of Buy In-Branch forms part of Post Office’s broader Mails
          Strategy which has already introduced thousands of new carrier
          opportunities across its UK branch network, including Click & Collect,
          Customer Drop Off and Customer Return points, as well as extending its
          offering online with the launch of Parcels Online, with DPD earlier
          this year.
        </Body>
      </>
    ),
  },
  {
    id: 81,
    date: "November 2023",
    dateTitle: "2 November 2023",
    category: "Sustainability",
    title:
      "Asendia marks the continuation of its sustainability journey with new fashion industry research in partnership with RetailX",
    cardTitle:
      "Asendia's sustainability journey continues with fashion industry research with RetailX",
    imgs: [img81],
    body: (
      <>
        <Heading4>
          Since 2000, the fashion industry has doubled production, and in 2014
          exceeded 100 billion garments. However, stringent legislation against
          fast fashion and changing consumer desires are driving change, and
          Asendia is helping brands and retailers reach their sustainability
          goals.
        </Heading4>
        <Body>
          Asendia, the joint venture between La Poste and Swiss Post, is
          delighted to mark a decade of unwavering commitment to sustainability
          and announces the launch of its ebook “Mobilising for sustainability
          in fashion”, in association with RetailX. Asendia is also thrilled to
          reveal that it has initiated the Science-Based Targets initiative
          (SBTi) process to further solidify its sustainability efforts.
        </Body>
        <Body>
          Over the past 10 years, Asendia has been at the forefront of
          environmental and social responsibility in the logistics and parcel
          delivery industry. Its dedication to sustainability has enabled the
          company to set new standards and achieve remarkable milestones,
          earning the trust and admiration of its customers and partners around
          the world. Since the start of 2022, Asendia offset 100% of its scope
          1, 2 and 3 emissions keeping in line with a promise to be 100% carbon
          neutral through offsetting – an ambition that has been certified.
        </Body>
        <Body>
          To further showcase its dedication to sustainability, Asendia has
          partnered with RetailX, a renowned authority in retail research, to
          develop an in-depth sustainability ebook. Titled “Mobilising for
          sustainability in fashion” this ebook will shed light on
          Asendia&apos;s continued efforts to reduce its environmental impact,
          share valuable insight into the fashion sector’s transformation, and
          support a more sustainable and responsible global supply chain.
        </Body>
        <Body>
          The fashion industry is rapidly changing. Retailers are measuring,
          testing, learning and listening to their consumers and other
          stakeholders as new models for retail emerge. According to the RetailX
          research, detailed in Asendia’s ebook, 17% of fashion retailers in the
          RetailX Global Elite Top1000 offer a recycling scheme for at least
          some of the products they sell. 2% of RetailX Global Elite Top250
          retailers offer a product rental service. It is estimated that rental
          and resale will account for nearly one quarter (23%) of the global
          fashion market by 2030.
        </Body>
        <Quotation>
          “The fashion industry is forging ahead with sustainability ambitions
          and a focus to be net zero by 2050. Managing sustainability with
          business growth and innovation with day-to-day trading are inspiring a
          range of industries to redefine their priorities. Asendia, following
          in the footsteps of its founders, has set a strong precedent in the
          journey towards sustainability, and we are proudly continuing that
          heritage within the global ecommerce industry.”
          <Quotee>Says Simon Batt, CEO, Asendia.</Quotee>
          “Sustainability requires ambitious targets to bring about change at
          scale. Therefore, Asendia is reinforcing its commitment to
          sustainability by joining the Science Based Targets initiative process
          to set and monitor measurable targets to reduce carbon emissions,
          including those caused by its transport suppliers, by the end of
          2026.”
          <Quotee>Explains Batt.</Quotee>
        </Quotation>
        <Body>
          By setting science-based targets, the company aims to ensure its
          carbon emissions reduction goals are in line with the Paris
          Agreement&apos;s objective to limit global warming to well below 2°C.
          Many global retailers, including Nike, Adidas and eBay are already
          listed, enabling Asendia to support such retailers with sustainable,
          reliable first-mile collection, last-mile delivery, returns or
          cross-border commerce.
        </Body>
        <Body>
          The research in collaboration with RetailX and the initiation of the
          SBTi process are just the beginning of Asendia&apos;s ongoing journey
          towards a more sustainable and responsible future. The company remains
          committed to exploring new avenues for environmental and social
          improvement and welcomes ongoing collaboration with its partners and
          customers to drive positive change.
        </Body>
        <Body>
          Asendia does not own transport assets and infrastructure – trucks and
          airplanes. Instead, the company purchases services from the transport
          providers covering its shipping routes, and 95% of its emissions come
          from these vehicles. This makes it challenging for Asendia to manage
          its carbon reduction beyond partnering with ‘carbon conscious’
          suppliers and carrier partners. So, Asendia has focused on offsetting
          to achieve carbon neutrality. A very low percentage of emissions come
          from its buildings, machinery and essential business travel.
        </Body>
        <Body>
          To learn more about Asendia’s sustainable actions, click{" "}
          <AnchorLink
            to="https://www.youtube.com/watch?v=p4d5v-1zlzw"
            externalLink={true}
            linkColor={variables.linkColor}
          >
            here.
          </AnchorLink>
        </Body>
        <Body>
          To download Asendia’s ebook “Mobilising for sustainability in
          fashion”, click{" "}
          <AnchorLink
            to="https://www.asendia.com/resource/mobilising-for-sustainability-in-fashion?utm_content=170462684&utm_medium=social&utm_source=linkedin&hss_channel=lcp-2653170"
            externalLink={true}
            linkColor={variables.linkColor}
          >
            here.
          </AnchorLink>
        </Body>
      </>
    ),
  },
  {
    id: 80,
    date: "November 2023",
    dateTitle: "1 November 2023",
    category: "Sustainability",
    title:
      "Geopost’s Net Zero Plan wins at the Reuters Responsible Business Awards 2023!",
    cardTitle:
      "Geopost’s Net Zero Plan wins at the Reuters Responsible Business Awards 2023!",
    imgs: [img80],
    body: (
      <>
        <Heading4>
          Limiting global warming to 1.5 degrees celsius requires investment and
          innovation across all business sectors. As freight transport accounts
          for 7% of total greenhouse gas emissions, the sector has a key role to
          play in decarbonisation. On November 1st 2023, Geopost has been
          recognised by the 14th Reuters Responsible Business Awards for taking
          bold action to significantly reduce greenhouse gas (GHG) emissions
          from its operations by 90% by 2040 compared to 2020.
        </Heading4>
        <Body>
          At a ceremony held yesterday in London, the judges of the Reuters
          Responsible Business Awards awarded the concrete and coherent nature
          of Geopost’s bold 2040 Net Zero ambition and roadmap, as well as the
          company’s efforts to achieve such a strong target. In a pool of eleven
          strong contenders, Geopost won the Net Zero Transition Award!
        </Body>
        <Body>
          Responsible Business Awards are a world-renowned celebration of
          strategies and organisations that are truly challenging the status quo
          of business and transforming the world around them for a more
          purpose-driven, sustainable future for all. With over 700 applications
          coming from 51 countries, Geopost was selected by an independent panel
          of 25 expert judges from all over the globe based on the innovation,
          impact and scalability of their work.
        </Body>
        <Body>
          In February 2023, Geopost became the first global parcel delivery
          company to have both its near- and long-term science-based targets
          approved by the Science Based Target initiative (SBTi), with the goal
          of reaching Net Zero by 2040.
        </Body>
        <Body>
          In line with a 1,5°C trajectory, Geopost has committed to reducing
          absolute GHG emissions from scopes 1, 2 and 3 by 43% by 2030 and by
          90% by 2040, compared with a 2020 baseline. This commitment and
          associated roadmap are fundamental to Geopost stepping up its efforts
          to achieve its climate goals and become recognised as the
          international reference in sustainability. As of July 2023, Geopost
          can already celebrate some strong achievements: the company has
          deployed 7,600 low emission vehicles, offers fully low emission
          delivery solutions in 102 European cities, and 76% of electricity
          consumed on its logistics sites comes from renewable sources.
        </Body>
        <Quotation>
          “We are extremely proud of winning Reuters Responsible Business
          Awards, recognizing our leadership on the net zero transition in our
          sector, and thank all our teams across Geopost’s business units for
          their contribution in helping raise the bar high and for their
          continued support in the delivery of this ambition”,
          <Quotee>
            commented Dominique Mamcarz, Geopost Sustainability Director
          </Quotee>
        </Quotation>
        <Body>
          The full list of winners and other highly commended companies can be
          found{" "}
          <AnchorLink
            to="https://events.reutersevents.com/sustainable-business/responsible-business-awards/winners"
            externalLink={true}
            linkColor={variables.linkColor}
          >
            here.
          </AnchorLink>
        </Body>
        <Body>
          Read more about Geopost&apos;s sustainability commitments{" "}
          <AnchorLink
            to="https://www.dpd.com/group/en/sustainability/"
            externalLink={true}
            linkColor={variables.linkColor}
          >
            here.
          </AnchorLink>
        </Body>
      </>
    ),
  },
  {
    id: 79,
    date: "October 2023",
    dateTitle: "13 October 2023",
    category: "Fulfilment",
    country: ["Netherlands", "Belgium", "Luxembourg"],
    title:
      "DPD Netherlands and DPD Belux invest €250 million in expansion of Benelux sorting capacity",
    cardTitle:
      "DPD Netherlands and DPD Belux invest €250 million in expansion of Benelux sorting capacity",
    imgs: [img79],
    body: (
      <>
        <Heading4>
          As an international hub for trade and logistics, the Benelux plays an
          important role in transport. DPD has therefore invested over €250
          million in expanding and improving its hubs and depots in the Benelux.
          This will result in a significant increase in capacity to 750,000
          parcels per day. DPD also continues to invest in growing the number of
          European network connections from the Benelux with higher delivery
          reliability. In doing so, DPD is strengthening its position as the
          market leader in international parcel transport by road and responding
          to changing customer needs.
        </Heading4>
        <Quotation>
          <Quotee>
            Erik Uljee, CEO DPD Netherlands & Richard de Haas, CEO DPD Belgium
            and Luxembourg:
          </Quotee>
          DPD works together closely within the Benelux and is ready for the
          future
        </Quotation>
        <Body>
          DPD&apos;s three sorting hubs in the Benelux - the logistics centres
          where parcels are collected and sorted - are located in Eindhoven,
          Veenendaal and Brussels. Despite the fact that the centres in
          Eindhoven and Brussels opened not long ago and Veenendaal was
          completely modernised this year, DPD continues to invest in these
          locations. For example, DPD has introduced state-of-the-art artificial
          intelligence for parcel recognition, which allows for faster and more
          accurate work.
        </Body>
        <Header2>Expansion of DPD&apos;s international network</Header2>
        <Body>
          DPD, part of Geopost, is the market leader in parcel transport by road
          in Europe. As the modern hubs in the Benelux form part of the unique
          international Geopost network with own operations in 21 European
          countries, DPD customers have the convenience of dealing with one
          reliable partner from pick-up to delivery.
        </Body>
        <Quotation>
          <Quotee>Erik Uljee, CEO DPD Netherlands:</Quotee>
          &quot;By investing in our sorting locations but also in new depots in
          Amsterdam, Brussels, Goes and Maastricht, our network has expanded to
          three hubs and twenty depots in the Benelux. We are proud of this
          position and keep moving forward. We have therefore worked hard
          throughout the Benelux to increase sorting capacity and connections
          with Europe and the rest of the world. This leads to reliable delivery
          times to more destinations.&qout;
        </Quotation>
        <Quotation>
          <Quotee>Richard de Haas, CEO DPD Belux:</Quotee>
          &quot;From the Benelux, next business day delivery to much of Europe
          is already standard in a range of about 700 kilometres from the
          Benelux. We also see that the current economic conditions increase
          customer demand for predictable and cheaper shipping within Europe
          compared to classic express transport.&quot;
        </Quotation>
        <Header2>
          Benelux market and network of parcel shops and lockers as alternative
          increasingly important
        </Header2>
        <Body>
          The revamped three-hub strategy enables DPD to align deliveries within
          the Benelux even more efficiently. This allows DPD to offer a reliable
          door-to-door service in the Benelux to customers. The depots now
          further allow sorting of parcels delivered or collected from DPD
          parcel shops and lockers. This enables DPD to respond well to
          increasing market demand for flexible delivery options. The
          investments allow DPD customers to deliver parcels faster with more
          options for parcel recipients; both in the Benelux and in Europe where
          Geopost has the largest network of parcel shops and lockers in the
          market, totaling more than 100,000 locations.
        </Body>
        <Header2>Sustainability impact</Header2>
        <Body>
          DPD&apos;s mission is to be net zero by 2040, and to do so it aims to
          further reduce its carbon footprint every year. This is 10 years
          earlier than required under the climate agreement. These plans have
          been endorsed by the Science Based Target initiative (SBTi). Part of
          the investments have included providing both hubs and depots with
          charging infrastructure for the fleet of electric vehicles that will
          continue to expand in the coming years.
        </Body>
      </>
    ),
  },
  {
    id: 78,
    date: "October 2023",
    dateTitle: "10 October 2023",
    category: "Fulfilment",
    country: "Germany",
    title: "DPD Germany further expands its global shipping services",
    cardTitle: "DPD Germany further expands its global shipping services",
    imgs: [img78],
    body: (
      <>
        <Heading4>
          With the DPD EXPRESS international shipping option, customers can
          already send their parcels to over 220 countries. For 28 destinations,
          DPD now offers a cost-optimized shipping alternative with attractive
          transit times and an excellent range of services.
        </Heading4>
        <Body>
          Whether China, South Africa or the USA - the international shipping
          market is becoming increasingly demanding. Thanks to the combination
          of its own network and renowned cooperation partners, DPD Germany
          specializes in the worldwide delivery of parcels. In order to offer
          customers an even broader range of services, DPD Germany is
          introducing the{" "}
          <AnchorLink
            to="https://www.dpd.com/de/en/carrying-the-world/?_gl=1*1qcrncs*_ga*MTI5MDk1MzY0NS4xNjgwNTEyNjQ5*_ga_Q3RB6RNZ25*MTY5ODg1MDY0My4zMi4xLjE2OTg4NTEyNjEuNDYuMC4w"
            externalLink={true}
            linkColor={variables.linkColor}
          >
            DPD CLASSIC Worldwide
          </AnchorLink>{" "}
          shipping option for 28 destinations. With the new service DPD
          customers can, for example, send parcels to the United Arab Emirates
          in a cost-optimized way - including cross-partner shipment tracking.
          In addition, the innovative transit time calculator gives shippers a
          constant overview of the standard transit times for international
          EXPRESS parcels, enabling them to offer their customers an all-round
          carefree package. This makes DPD Germany the perfect partner for
          international parcel shipping.
        </Body>
        <Quotation>
          <Quotee>
            Andreas Thams, Chief Sales Officer at DPD Germany, underlines the
            importance of the expanded offering:
          </Quotee>
          ”In international parcel shipping, predictability and reliability are
          more in demand than ever. With DPD CLASSIC Worldwide we are expanding
          and differentiating our product range in this area. Regardless of
          whether a customer wants to ship his parcel within Germany, Europe or
          across one of the great oceans, DPD is the right shipping partner with
          its 800 depots worldwide. With the new DPD CLASSIC Worldwide shipping
          option we offer optimum delivery times at attractive prices. The
          further development of our international shipping offering will remain
          a central component of our product strategy in the future in order to
          make the difference for our customers.”
        </Quotation>
      </>
    ),
  },
  {
    id: 77,
    date: "October 2023",
    dateTitle: "9 October 2023",
    category: "Sustainability",
    country: "United Kingdom",
    title:
      "DPD UK wins Sustainable Transport category at National Sustainability Awards",
    cardTitle:
      "DPD UK wins Sustainable Transport category at National Sustainability Awards",
    imgs: [img77],
    body: (
      <>
        <Heading4>
          The honour is the tenth ‘green’ award that DPD has won since the start
          of 2021
        </Heading4>
        <Body>
          Parcel delivery company DPD UK has been honoured at the 2023 National
          Sustainability Awards in London, winning the Sustainable Transport
          category.
        </Body>
        <Body>
          At a glittering ceremony on The Strand at London’s Waldorf Hilton
          Hotel, DPD UK beat off stiff competition from six other companies to
          top the Sustainable Transport category.
        </Body>
        <Body>
          The annual National Sustainability Awards seek to highlight great
          innovation and breadth of impact across all sectors that can help
          build a more sustainable and better future. As well as highlighting
          best practice, the awards aim to show how society can, with good
          leadership, find a more sustainable path so that future generations
          will be able to live in a cleaner, greener world.
        </Body>
        <Body>
          DPD UK was the only parcel carrier to feature in the event’s
          100-strong shortlist and the win follows success at the same event
          last year when the firm scooped the Supply Chain Initiative award.
        </Body>
        <Body>
          Other high-profile winners on the night included NatWest, Bellway
          Homes, OVO, ITV and Arla Foods.
        </Body>
        <Body>
          The judges praised the breadth of DPD’s sustainability initiatives –
          which go well beyond the 3,500 strong green fleet of electric delivery
          vehicles – as well as highlighting innovations such as robot
          deliveries and the speed of progress towards net zero.
        </Body>
        <Quotation>
          <Quotee>Elaine Kerr, DPD UK CEO commented,</Quotee>
          “Sustainability is absolutely core to our business DNA and this award
          is recognition for the progress we are continuing to make across the
          whole operation. From decarbonising our HGVs to the roll-out of our
          autonomous robot fleet, we are innovating across the board. We aim to
          be pioneers of sustainability in the UK’s transport sector and winning
          ten ‘green’ awards in less than three years is outstanding,
          independent endorsement that we are on the right track.”
        </Quotation>
      </>
    ),
  },
  {
    id: 76,
    date: "September 2023",
    dateTitle: "29 September 2023",
    category: "Fulfilment",
    country: "United Kingdom",
    title: "Further update on shipping goods from Great Britain to Northern",
    cardTitle:
      "Further update on shipping goods from Great Britain to Northern",
    imgs: [img76],
    body: (
      <>
        <Body>
          Further update on shipping goods from Great Britain to Northern
          Ireland
        </Body>
        <Body>
          The UK Government has recently published statutory guidance regarding
          future changes to the current arrangements for sending parcels from
          Great Britain to Northern Ireland.
        </Body>
        <DownloadBtn
          variant="danger"
          downloadLink="https://www.gov.uk/government/publications/moving-parcels-from-great-britain-to-northern-ireland-under-the-windsor-framework-from-30-september-2024"
        >
          View Guidance
        </DownloadBtn>
        <Body>
          HMRC continues to be actively engaged with parcel carriers to ensure a
          smooth transition and DPD is committed to supporting its customers on
          this topic with more detailed guidance expected in due course.
        </Body>
        <Heading4>
          Please find additional government links and guidance below:
        </Heading4>
        <Body>
          Here are{" "}
          <AnchorLink
            to="https://www.gov.uk/government/collections/the-windsor-framework-further-detail-and-publications"
            externalLink={true}
            linkColor={variables.linkColor}
          >
            further details
          </AnchorLink>{" "}
          on Windsor Framework.
        </Body>
        <Body>
          Click{" "}
          <AnchorLink
            to="https://www.gov.uk/government/publications/the-windsor-framework-goods-movements-general"
            externalLink={true}
            linkColor={variables.linkColor}
          >
            here
          </AnchorLink>{" "}
          to read about goods movements including The Green Lane, Duty
          Reimbursement Scheme and Customs Duty Waiver Scheme.
        </Body>
        <Body>
          Apply for authorisation for the{" "}
          <AnchorLink
            to="https://e.marketing.dpd.co.uk/e/269632/ng-goods-into-northern-ireland/2htjrg/539659864?h=5SlLx_9gI8UyojK_4n1zyzoU_fdBSkKcJCnw3m4FTPs"
            externalLink={true}
            linkColor={variables.linkColor}
          >
            UK Internal Market Scheme
          </AnchorLink>{" "}
          if you bring goods into Northern Ireland.
        </Body>
        <Body>
          Read more about the{" "}
          <AnchorLink
            to="https://e.marketing.dpd.co.uk/e/269632/-ENG-toc-OJ3AL3A20233A1493ATOC/2htjrk/539659864?h=5SlLx_9gI8UyojK_4n1zyzoU_fdBSkKcJCnw3m4FTPs"
            externalLink={true}
            linkColor={variables.linkColor}
          >
            EU Delegated Act
          </AnchorLink>
          .
        </Body>
      </>
    ),
  },
  {
    id: 75,
    date: "September 2023",
    dateTitle: "28 September 2023",
    category: "e-Commerce",
    country: "United Kingdom",
    title: "Big in America",
    cardTitle: "Big in America",
    imgs: [img75],
    body: (
      <>
        <Heading5>
          Are you interested in selling your products or services to the US, but
          not sure where to start? Well did you know...
        </Heading5>
        <BulletPoints>
          <li>
            The US is the UK&apos;s largest export market for goods and services
            and the world&apos;s largest economy. It made up 20.8% of total UK
            exports for the four quarters to the end of Q2 2022.
          </li>
          <li>
            There were £161.5 bn total UK exports to the US for the four
            quarters to the end of Q3 2022, which makes up 13.9% of all UK goods
            exports.
          </li>
          <li>
            That trade with the US is growing - UK exports to the US in the four
            quarters to the end of Q1 2023 grew by 23.3% compared to a year
            earlier
          </li>
        </BulletPoints>
        <Body>
          To coincide with the Department for Business and Trade&apos;s new
          series, Big in America, DBT has produced a guide for exporting to the
          USA. It contains loads of useful information, including an overview of
          the wide-ranging opportunities, sector-focused insights and details of
          the support available.
        </Body>
        <DownloadBtn
          variant="danger"
          downloadLink="https://paas-s3-broker-prod-lon-8db94025-3db3-41df-9a2a-fbb251b24433.s3.amazonaws.com/documents/Export_Guide_to_North_America.pdf"
        >
          Download the guide
        </DownloadBtn>
        <Body>
          DPD is proud to be a Department for Business and Trade strategic
          partner, and we&apos;re committed to helping all UK companies achieve
          their goals. Our flagship product, Air Classic, is a popular choice
          for businesses that want to ship to the USA.
        </Body>
        <Body>
          If you&apos;re ready to take your business to the next level, DPD is
          here to help. Check out our Air Classic service on our{" "}
          <AnchorLink
            to="/services/air-classic"
            externalLink={false}
            linkColor={variables.linkColor}
          >
            Website
          </AnchorLink>{" "}
          or contact our International Experts below to learn more about
          shipping to the USA.
        </Body>
      </>
    ),
  },
  {
    id: 74,
    date: "September 2023",
    dateTitle: "26 September 2023",
    category: "Fulfilment",
    country: "United Kingdom",
    title: "ESW and DPD UK Announce Strategic Collaboration",
    cardTitle: "ESW and DPD UK Announce Strategic Collaboration",
    imgs: [img74],
    body: (
      <>
        <Heading4>
          The new alliance enables frictionless cross-border ecommerce selling
          and shipping for DPD customers
        </Heading4>
        <Body>
          ESW, the global direct-to-consumer (DTC) ecommerce company, and DPD
          UK, the leading UK parcel delivery brand, today jointly announced a
          strategic partnership which enables DPD’s retail customers in Great
          Britain to accelerate their DTC expansion to reach global consumers in
          more than 200 countries.
        </Body>
        <Body>
          This alliance gives DPD’s UK customers the ability to access ESW’s
          one-stop solution providing technology and deep localisation expertise
          through a capital-light approach - alleviating the friction that often
          impedes brands and retailers from selling directly to international
          consumers cross-border.
        </Body>
        <Body>
          The partnership with DPD UK expands ESW’s UK market share and will see
          it supporting the delivery leader’s extensive network of top-tier
          retail and brand customers.
        </Body>
        <Body>
          ESW’s Global Voices research of more than 16,000 consumers across
          sixteen countries, reveals a diverse map of demand for UK products in
          contrast to ongoing low consumer confidence in the UK, with just 7% of
          UK consumers surveyed indicating they intend to spend more online in
          the coming 12 months. The UK was one of the top three most shopped
          markets for international online purchases across categories including
          luxury goods (with 32% of consumers buying from British brands),
          clothing (29%), footwear (29%), cosmetics (27%) and consumer
          electronics (24%).
        </Body>
        <Quotation>
          “At ESW, our core mission is to facilitate seamless DTC access to
          brands seeking to find new ways to grow revenue, optimise inventory
          and improve margins using a cross-border strategy. We’re delighted to
          partner with DPD to offer its clients the opportunity to grow beyond
          British borders and access the significant revenue opportunities
          available,”
          <Quotee>
            commented Martim Avillez Oliveira, Global Chief Revenue Officer,
            ESW.
          </Quotee>
        </Quotation>
        <Quotation>
          “At DPD we are committed to innovating to offer our clients new
          opportunities to grow their businesses and add value. This alliance
          with ESW offers DPD customers the ability to seamlessly expand beyond
          the UK to sell and ship around the world, with the confidence that
          they are delivering the same quality shopping experience globally as
          they do locally,”
          <Quotee>
            Jonathan Pratt, Director of Sales & CRM, DPD UK commented.
          </Quotee>
        </Quotation>
        <Body>
          ESW, whose brand partners include DSquared2, Michael Malul London and
          Movado, supports DPD customers at every stage of their global growth.
          The company leverages its deep understanding of local markets to
          create unique DTC opportunities that drive customer lifetime value.
          Brands integrating with ESW’s DTC solutions can enter both domestic
          and new international markets in a matter of weeks while maintaining
          control over every aspect of their experience, from their data to the
          look and feel of their ecommerce sites to their content, catalogue,
          payments, fulfilment, and delivery and returns experience.
        </Body>
      </>
    ),
  },
  {
    id: 73,
    date: "September 2023",
    dateTitle: "12 September 2023",
    category: "Fulfilment",
    title: "Geopost extends its Day Definite delivery service all over Europe",
    cardTitle:
      "Geopost extends its Day Definite delivery service all over Europe",
    imgs: [img73],
    body: (
      <>
        <Heading5>
          Now available in 30 destination countries across Europe, the enhanced
          DPD Classic offers a lead time from shipping postcode to a destination
          postcode to support the development of its customers in Europe.
        </Heading5>
        <Body>
          <Bold>
            In February, Geopost launched the enhanced version of its standard
            DPD service within a first set of European countries with
            information on parcel arrival day to better support its customers in
            their cross-border journeys. Further to this successful rollout
            phase involving 10 countries, DPD Classic updated to Day Definite
            has now been deployed to Geopost’s full European network, enabling
            DPD clients to strengthen their business in 30 European markets with
            reliable lead times and an attractive price.
          </Bold>
        </Body>
        <Body>
          Today, in a global challenging economical context, companies need to
          find new ways to empower both their BtoB and BtoC businesses and make
          them match their customers’ expectations. Our Geopost teams, through
          our extensive European brand network (DPD, SEUR, BRT), daily accompany
          their customers amid increasingly competitive perspective and across a
          large array of segments: e- commerce, fashion beauty and sportswear,
          manufacturing, and consumers products, high-tech, food and healthcare.
        </Body>
        <Body>
          In this context, cross-border operations are essential to business
          flows​. Based on a strong experience in e-commerce and delivery
          services in Europe, Geopost experts have upgraded the standard DPD
          Classic / DPD Business and DPD Home with:
        </Body>
        <BulletPoints>
          <li>
            A comprehensive reach, by offering access to the densest network in
            Europe covered by DPD Classic:
            <ul>
              <li>
                From 24 countries: Austria, Belgium, Bulgaria, Croatia, Czech
                Republic, Estonia, France, Germany, Greece, Hungary, Ireland,
                Italy, Latvia, Lithuania, Luxemburg, Poland, Portugal, Romania,
                Slovakia, Slovenia, Spain, Switzerland, The Netherlands, United
                Kingdom
              </li>
              <li>
                To 30 countries: Austria, Belgium, Bulgaria, Croatia, Czech
                Republic, Estonia, France, Germany, Greece, Hungary, Ireland,
                Italy, Latvia, Lithuania, Luxemburg, Poland, Portugal, Romania,
                Slovakia, Slovenia, Spain, Switzerland, The Netherlands, United
                Kingdom, Bosnia, Serbia, Norway, Denmark, Sweden and Finland.
              </li>
            </ul>
          </li>
        </BulletPoints>
        <Body>The upgraded version of DPD Classic also includes:</Body>
        <BulletPoints>
          <li>
            Lead time from origin postcode to a destination postcode, with a
            collection/pickup cut-off time.
          </li>
          <li>
            Digital tools to offer predictability to customers, help businesses
            to better optimize their on-site deliveries and to encourage the
            purchase decision on the part of consumers:v
          </li>
          <li>
            Lead Time Map: an interactive and exhaustive view of the
            door-to-door lead times.
          </li>
          <li>
            Lead Time calculator: lets clients discover lead times for parcels
            between two postcodes everywhere in Europe
          </li>
          <li>
            Geopost&apos;s pioneering notification system Predict, part of our
            DPD Classic services for more than 10 years and which offers clients
            and customers the flexibility to change delivery options with
            redirections to +84,000 Pickup locations in Europe, beyond
            end-to-end tracking and live tracking during delivery tour.
          </li>
        </BulletPoints>
        <Quotation>
          “The first phase of Day Definite has sparked true interest with our
          clients,”
          <Quotee>Eric Dietz, Geopost CEO for Europe said,</Quotee>
          “what is mostly appreciated is the alliance between key components for
          their commerce journey intra-Europe: a stable and high-quality level
          of service with reliable lead times at an affordable price. Extending
          this offering to our complete network is therefore a significant step.
          It strategically completes our range of services covering reliability
          –via Day Definite–, predictability –through Predict notification
          system–, and sustainability –via our decarbonization plan and
          low-emission fleets.”
        </Quotation>
      </>
    ),
  },
  {
    id: 72,
    date: "September 2023",
    dateTitle: "08 September 2023",
    country: "Poland",
    category: "Fulfilment",
    title: "DPD Poland wins TSL Award from the Rzeczpospolita daily newspaper",
    cardTitle:
      "DPD Poland wins TSL Award from the Rzeczpospolita daily newspaper",
    imgs: [img72],
    body: (
      <>
        <Heading5>
          On August 31s was held the annual Meeting of TSL Industry Leaders - an
          event dedicated to the logistics and transport sector, which was
          organised for the fifth time by the Rzeczpospolita daily and the
          industry website logstyka.rp.pl. The event is not only an opportunity
          to answer the most burning questions about the directions of the
          industry&apos;s development, but also to identify the best practices
          in the market during the crowning TSL Awards Gala. DPD Poland was
          awarded in the Ecology in Logistics category.
        </Heading5>
        <Body>
          <Bold>The best of the best</Bold>
        </Body>
        <Body>
          During the gala, both the companies that recorded the highest
          financial performance parameters and the winners of the non-financial
          categories were awarded, selected by a specially appointed jury
          consisting of representatives of the TSL industry, universities and
          editorial staff.
        </Body>
        <Body>
          DPD Polska was the winner in the Ecology in Logistics category for its
          development of an electric car fleet and electric cargo bikes. On
          behalf of DPD Poland, Maksymilian Pawlowski, Corporate Communications
          Manager, accepted the award.
        </Body>
        <Quotation>
          &quot;We feel proud of the Chapter&apos;s decision that the activities
          within our sustainable development strategy are rated the highest in
          the TSL industry in Poland. This is thanks to our employees,
          co-workers, customers and business partners who are following the path
          with us, towards NET-ZERO by 2040. It is particularly gratifying to
          have been recognised by the Chapter for our solutions in the field of
          sustainable urban logistics and the rapidly progressing
          electrification of our fleet, which now numbers more than 420 cars. We
          are also delighted with the high evaluation of the development of our
          fleet of electric cargo bikes, of which we already have more than 70,
          which are perfect for last-mile logistics in cities.&qout; -
          <Quotee>concluded Maksymilian Pawłowski.</Quotee>
        </Quotation>
        <Heading4>Low-emission fleet a step towards NET-ZERO 2040</Heading4>
        <Body>
          The successive growth of DPD Poland’s low-carbon fleet is the result
          of the green transformation of Geopost, the owner of DPD Poland, which
          has been implementing a sustainability plan in the spirit of carbon
          neutrality for several years. Geopost has committed to reducing
          greenhouse gas (GHG) emissions by 43% by 2030 and 90% by 2040 against
          a 2020 baseline. This ambitious NET-ZERO 2040 target has been
          validated by the Science Based Targets (SBTi) initiative.
        </Body>
      </>
    ),
  },
  {
    id: 71,
    date: "September 2023",
    dateTitle: "07 September 2023",
    country: "United Kingdom",
    category: "Fulfilment",
    title: "DPD UK wins three awards at the road transport ‘Oscars’",
    cardTitle: "DPD UK wins three awards at the road transport ‘Oscars’",
    imgs: [img71],
    body: (
      <>
        <Heading4>
          Firm has now won over 40 Motor Transport Awards in total
        </Heading4>
        <Body>
          Parcel delivery company DPD UK took home three Motor Transport awards
          following a glittering ceremony at London’s Grosvenor House Hotel.
        </Body>
        <Body>
          The firm, which is already the most decorated brand in the history of
          the awards, known as the road transport industry ‘Oscars’, won the
          Home Delivery Operator of the Year, New Talent Development and Urban
          Operator of the Year awards.
        </Body>
        <Body>
          The evening celebrates outstanding achievements in the industry and
          recognises innovation, customer service, efficiency and quality. The
          awards are judged by an independent panel of experts drawn from the
          industry.
        </Body>
        <Body>
          Now in their 37th year, the awards were hosted by comedian and
          broadcaster Jack Dee and Motor Transport editor Steve Hobson.
        </Body>
        <Body>
          It is the fifth time in seven years that DPD has been crowned Home
          Delivery Operator of the Year and the second year running the firm has
          won the Urban Operator award.
        </Body>
        <Quotation>
          <Quotee>Elaine Kerr, DPD UK CEO commented,</Quotee>
          “I&apos;m absolutely delighted to win another three Motor Transport
          Awards and continue the incredible run we have had, given the strength
          of the competition. To win the Home Delivery award again and be the
          only express parcel delivery company honoured on the night is clear
          evidence that we are still the one to beat in this market and I’m
          incredibly proud of the whole team for the service we provide our
          customers, every single day.”
        </Quotation>
      </>
    ),
  },
  {
    id: 70,
    date: "August 2023",
    dateTitle: "31 August 2023",
    country: "United Kingdom",
    category: "Fulfilment",
    title:
      "Post Office partners with DPD UK to launch new online parcel sales website",
    cardTitle:
      "Post Office partners with DPD UK to launch new online parcel sales website",
    imgs: [img70],
    body: (
      <>
        <Heading4>
          The Post Office has joined forces with leading parcel delivery company
          DPD UK to launch a new online parcel service.
        </Heading4>
        <Body>
          Parcels Online will enable customers to select and pay for UK and
          international parcel services online and then drop off the parcels at
          their nearest participating Post Office branch. Customers can choose
          to print the label at home from the website or ask staff in the branch
          to do it for them.
        </Body>
        <Body>
          The new service extends DPD UK’s relationship with the Post Office
          following the launch of a click and collect service in 2021 that
          enables DPD parcel recipients to pick up their DPD parcels from Post
          Office branches, if they aren’t going to be at home for the delivery.
        </Body>
        <Body>
          At launch, the Post Office will have 3,600 branches live for the
          Parcels Online DPD service, increasing to 4,000 by September.
        </Body>
        <Quotation>
          <Quotee>
            Neill O’Sullivan, Post Office managing director parcels and mails,
            commented,
          </Quotee>
          “We are very excited to be launching Parcels Online and delighted to
          partner again with DPD UK. A significant proportion of the consumer
          parcels market is now purchased online, and we want to be present in
          that space, with a competitive and appealing offer. I believe Parcels
          Online is further evidence of our commitment to innovate, invest in
          new solutions for our customers, drive footfall and remuneration
          opportunities for Postmasters as well as ensure we remain competitive
          in this market.”
        </Quotation>
        <Quotation>
          <Quotee>Elaine Kerr, DPD UK CEO, commented,</Quotee>
          “It is great to be able to extend our partnership with the Post
          Office. The option to collect DPD deliveries from your local post
          office has proved very popular with our parcel recipients. The Post
          Office brand is trusted by millions of households in the UK and this
          service will help encourage new customers into the online parcel space
          as well as providing another option for those already buying their
          delivery services online, including marketplace shippers.”
        </Quotation>
        <Body>
          The Post Office Parcels Online site can be viewed here:{" "}
          <AnchorLink
            to="https://parcelsonline.postoffice.co.uk/"
            externalLink={true}
            linkColor={variables.linkColor}
          >
            https://parcelsonline.postoffice.co.uk/
          </AnchorLink>
        </Body>
      </>
    ),
  },
  {
    id: 69,
    date: "August 2023",
    dateTitle: "09 August 2023",
    category: "Sustainability",
    country: "Estonia",
    title:
      "DPD Estonia invests €1.5 million in electric van fleet and charging infrastructure",
    cardTitle:
      "DPD Estonia invests €1.5 million in electric van fleet and charging infrastructure",
    imgs: [img69],
    body: (
      <>
        <Heading5>
          The logistics company DPD Estonia has increased its fleet by another
          25 electric vans and is constructing Estonia’s largest electric
          vehicle charging park at its Jüri sorting centre. By 2030, the company
          plans to replace 95% of its current delivery vehicles with electric
          vans. According to Remo Kirss, CEO of DPD Estonia, electric vans have
          become a fully viable replacement for diesel courier vehicles.
        </Heading5>
        <Quotation>
          “Investing in electric vans is capital intensive, but when one
          considers the total cost (price of vans, energy consumption,
          maintenance and repair), it is possible to achieve a cost base similar
          to that of diesel vans through smart management. This also requires
          conscious and deliberate action, including the continuous optimisation
          and tactical planning of delivery routes and other processes, which in
          turn support the more widespread utilisation of electric vans,”
          <Quotee>explained Remo Kirss, CEO of DPD Estonia.</Quotee>
        </Quotation>
        <Body>
          The company is purchasing 25 Ford E-Transit electric vans, which will
          save 56 tons of CO2 emissions this year.
        </Body>
        <Quotation>
          “This particular electric van is known for its capacity and range,
          which are both greater compared to other electric vans. The van can
          travel an estimated 260 km on a single charge,” explained a
          representative from Infoauto, adding that interest in electric vans
          among companies is on the rise.
        </Quotation>
        <Heading4>
          The company plans to purchase 100 electric vans by 2025
        </Heading4>
        <Body>
          DPD has the largest number of electric vans in Estonia among private
          courier companies. With the addition of these 25 new vans, the
          company’s fleet now comprises nearly 50 electric vans. By 2025, the
          company plans to increase the number of its electric vans to 100,
          serving six major Estonian cities – Tallinn, Tartu, Pärnu, Rakvere,
          Viljandi and Jõhvi.
        </Body>
        <Body>
          The higher price of electric vans and economic downturn are not
          slowing the company’s investments.
        </Body>
        <Quotation>
          “Our goal is to reach a point by the end of 2030 where 95% of DPD
          Estonia’s vans are electric. This requires consistent action,
          regardless of the current economic situation. For this reason, we are
          not only purchasing new electric vans; we are also building charging
          stations and updating the energy infrastructure of our buildings,”
          <Quotee>said the CEO of DPD Estonia.</Quotee>
        </Quotation>
        <Body>
          He added that the company continues to focus on optimising its
          operations, such as continuing the further development of tools for
          delivery route planning and optimisation.
        </Body>
        <Body>
          Kirss expressed his satisfaction that the Estonian government is
          supporting the purchase of zero-emission vehicles this year but
          addressed concerns about the impact of future taxes.
        </Body>

        <Quotation>
          “In the coming years, vehicle and registration tax will be introduced
          for electric vehicles. It must be ensured that the introduction of
          vehicle taxes does not diminish the value of the purchase support,”
          <Quotee>
            explained Kirss, pointing out that if the vehicle tax negates the
            purchase support during the vehicle’s use period, the impact of the
            support would be disproportionately small and would not achieve its
            goal of encouraging companies to electrify their fleet.
          </Quotee>
        </Quotation>
        <Heading4>
          The additional electric vans are used to test a new business model
        </Heading4>
        <Quotation>
          “Many small businesses, including subcontractors that provide services
          for large logistics companies, find it difficult to keep up with the
          green revolution due to the size of the investments required.
          Therefore, we are testing a new model for DPD with the additional
          electric vans – we are purchasing the electric vans for our own fleet
          and plan to lease them to our subcontractors,”
          <Quotee>
            said Kirss, explaining that, under usual practice, the vans belong
            to the subcontractors.
          </Quotee>
        </Quotation>
        <Body>
          He added that although testing new business models is not something
          that would be unusual for companies, in this case, fleet development
          is not the main focus of DPD’s business.
        </Body>
        <Quotation>
          “At DPD, we want to primarily focus on building package delivery
          infrastructure and developing a sustainable package delivery business,
          and I hope that the government will soon find a solution to help small
          businesses keep up with the green transition,”
          <Quotee>said the CEO of DPD Estonia.</Quotee>
        </Quotation>
      </>
    ),
  },
  {
    id: 68,
    date: "August 2023",
    dateTitle: "04 August 2023",
    category: "e-Commerce",
    title:
      "Geopost steadily rolls-out strategy in a challenging market environment",
    cardTitle:
      "Geopost steadily rolls-out strategy in a challenging market environment",
    imgs: [img68],
    body: (
      <>
        <BulletPoints>
          <li>
            Against a backdrop of persistent inflation, Geopost[1] announces a
            €7,639 million revenue, slightly increasing (+2,2%) despite an
            overall decline in parcel volumes (-1,6%) and the impact of some
            one-off effects
          </li>
          <li>
            Further strengthening in strategic market segments such as
            out-of-home deliveries through the development of the Pickup
            network, and temperature-controlled delivery with extension of
            Chronopost networks
          </li>
          <li>
            Dynamic cross-border performance and continued global expansion with
            the creation of DPD Argentina through a Joint-Venture with domestic
            market leader Tasa Logística
          </li>
          <li>
            Key milestone on the environmental roadmap: the company&apos;s
            net-zero trajectory for 2040 approved by SBTi[2] and launch of
            Carbon Calculator tool.
          </li>
        </BulletPoints>
        <Quotation>
          <Quotee>Yves Delmas, CEO of Geopost, stated:</Quotee>
          “Despite significant headwinds in most of Geopost&apos;s markets, I am
          satisfied that in the first half of the year, Geopost kept on
          rolling-out its “Together & Beyond” strategy with determination. In
          Europe, our business units showed notable resilience in the B2B
          segment and promising growth in cross-border flows thanks to an
          enhanced day certain service. And we strengthened our position in
          strategic segments such as out-of-home and temperature-controlled
          delivery, which confirm their significant growth potential. I am also
          proud of the tremendous work achieved by our teams to move the company
          forward on its decarbonization trajectory. Early 2023, Geopost became
          the first global parcel delivery company to have both its near and
          long-term CO2 emissions reduction targets approved by the SBTi, and we
          launched our own Carbon Calculator to help our customers manage their
          own carbon emissions. These achievements are significant milestones on
          our environmental roadmap and reflect our unwavering determination to
          achieve our net zero ambition.”
        </Quotation>
        <Header2>Slight revenue growth in a challenging environment</Header2>
        <Body>
          The market dynamics that prevailed in 2022 have continued into the
          first half of 2023. Uncertainties and disruptions caused by the war in
          Ukraine remain, inflation, high energy prices and volatility are
          persistent. This adverse market environment is impacting household
          spending and consumption habits in most of the Geopost&apos;s markets
          that show a decline of e-commerce activity, namely Germany and the UK.
        </Body>
        <Body>
          To contain the negative effects of these difficult market conditions,
          Geopost implemented a set of operational efficiency measures adapting
          its pricing policy to the environment. This resulted in a slight 2,2%
          increase in revenue despite industry-wide volume variations. Volumes
          recorded by Geopost over the first half of the year were down by 1,6%
          compared to the first half of 2022. Of note, Geopost managed to keep
          on rolling-out its BtoB to BtoC rebalancing strategy: 57% of volumes
          were BtoC in the first half of 2023, compared to 56% in the same
          period in 2022.
        </Body>
        <Body>
          Some of Geopost&apos;s business units have demonstrated a good
          performance over the first half of the year, with an increase of their
          organic turnover such as France (+2,6%)[3], Italy (+5,4%), Poland
          (+4,1%) and Spain (+4,2%) while others recorded a decline such as the
          UK (-1,8%), Germany (-4,9%) and Benelux (-1,9%).
        </Body>
        <Body>
          Geopost&apos;s operating income amounted to +€74,7 million, down €228
          million. At constant scope and exchange rate effects (€20 million),
          the organic decline was -€208 million.
        </Body>
        <Body>
          Significant non-recurring items impact Geopost&apos;s operating
          income:
        </Body>
        <BulletPoints>
          <li>
            As of 30th June 2022, the impairment of DPD Russia assets of -€157
            million, and
          </li>
          <li>
            As of 30th June 2023, the provisions relating to legal proceedings
            in progress at its Italian subsidiary of -€162 million[4] and the
            impairment of Stuart&apos;s assets of €134 million[5].
          </li>
        </BulletPoints>
        <Body>
          Excluding significant non-recurring items, the decrease in Geopost’s
          operating income is - €70 million at constant scope and exchange
          rates, mainly reflecting the economic slowdown in parcel and express
          activities in an adverse macro-economic context.
        </Body>
        <Heading4>
          Sustained and fruitful implementation of “Together & Beyond” strategy
        </Heading4>
        <Body>
          In the first half of 2023, Geopost stayed the course and grew along
          the three axes of its “Together & Beyond” strategy: expanding core
          activities, accelerating initiatives in strategic segments and
          conquering new horizons.
        </Body>
        <Heading4>Key developments in strategic segments</Heading4>
        <Body>
          Geopost significantly increased its out-of-home activity, one of the
          main pillars of its BtoC strategy. The company extended its Pickup
          network by more than 14,000 points in the first half of 2023 and now
          has more than 97,000 Pickup points worldwide. Geopost achieved this
          extension notably by joining the Esso gas stations network and Louvre
          Hotels Group network in France, and the Zabka stores network in
          Poland, among other actions.
        </Body>
        <Body>
          Significant progress was achieved in temperature-controlled delivery,
          another strategic area of development. Indeed, both
          temperature-controlled revenues and volumes increased by respectively
          12,7% and 15,6% compared to H1 2022. Future growth is expected in this
          segment as Geopost announced the launch of four new-generation
          Chronopost hubs in France which are dedicated to food and healthcare,
          two temperature-controlled activities. Chronopost, a member of
          Geopost, thus became the French carrier with the largest
          temperature-controlled network in the country. In Spain, SEUR frío
          became the first in the country to receive ISO 23412 certification for
          temperature-controlled transport. Compared to H1 2022, DPDfresh’s
          revenue increased by 79% and volumes by 82%. In 2023, DPD Portugal and
          DPD Lithuania will also launch a fresh delivery solution.
        </Body>
        <Heading4>
          New milestones reached in strengthening global network
        </Heading4>
        <Body>
          Further developing in LatAm after the successful acquisition of Jadlog
          in Brazil in 2017, Geopost set foot in Argentina through DPD
          Argentina, a joint venture with domestic market leader, family-run
          TASA Logística. DPD Argentina will seize opportunities from rapidly
          growing Argentinian e-commerce (+23% in 2022 vs 2021[6]) by combining
          TASA’s heritage footprint and Geopost’s experience in last-mile
          delivery for BtoB and BtoC. DPD Argentina aims at becoming a market
          leader in last-mile delivery over the next five years.
        </Body>
        <Body>
          Geopost, alongside La Poste group, entered a major partnership with
          CMA CGM and CEVA Logistics to harness both groups’ complementary
          supply chain assets in Europe outside France. This alliance will
          reinforce its partners in last-mile delivery, reverse logistics,
          circular economy services and carbon emissions control, as many gears
          of Geopost’s “Together & Beyond” strategy.
        </Body>
        <Body>
          Overall, Geopost is recording solid growth on international flows,
          with a +4,2% of volumes in H1 2023 vs H1 2022, and +1,3% of sales,
          showing the high potential of this strategic priority for the company.
        </Body>
        <Heading4>
          Tangible actions to lead the way in sustainable delivery
        </Heading4>
        <Body>
          In February 2023, Geopost became the first global parcel delivery
          company to have both its near- and long-term CO2 emissions reduction
          targets approved by the Science Based Targets initiative (SBTi). By
          significantly reducing its absolute greenhouse gas (GHG) emissions
          across scopes 1, 2 and 3 and with a first reduction step by 2030,
          Geopost will contribute to limiting global temperature rises to 1.5°C.
          This landmark approval scientifically endorsed Geopost’s plan to reach
          net-zero by 2040 and strengthened the company on its path to be the
          international reference in sustainable delivery.
        </Body>
        <Body>
          In June 2023, Geopost launched its Carbon Calculator. This digital
          tool is a service built for and with Geopost’s customers to support
          shippers’ carbon reduction strategies and enable customers to track,
          analyse, report and ultimately reduce carbon emissions over the long
          term.
        </Body>
        <Body>
          Several local initiatives were also launched across Europe by
          subsidiaries to push Geopost forward in its sustainability ambitions:
          DPD Switzerland, DPD Czech Republic, Chronopost and DPD France are now
          using e-trucks for specific localized distribution needs, DPD UK is
          currently switching all diesel HGVs to renewable fuel, Biocair added
          100% electric vans to its fleet of delivery and collection vehicles,
          SEUR and Repsol entered into a sustainability agreement which includes
          the installation and operation of more than 150 recharging points for
          electric vehicles in Spain, and DPD Latvia launched an air quality
          monitoring programme in Riga, among others
        </Body>
      </>
    ),
  },
  {
    id: 67,
    date: "July 2023",
    dateTitle: "26 July 2023",
    category: "e-Commerce",
    title:
      "Asendia announces a new strategic organisation of Asendia Group and the appointment of Simon Batt as new CEO of its logistics division worldwide",
    cardTitle:
      "Asendia announces a new strategic organisation of Asendia Group and the appointment of Simon Batt",
    imgs: [img67],
    body: (
      <>
        <Heading4>
          Today, Asendia announces the appointment of Simon Batt as its new
          Chief Executive Officer of its logistics division worldwide, in the
          context of a new strategic organisation of Asendia Group.
        </Heading4>
        <Body>
          In recent years, Asendia as a Group has undergone major transformation
          to become a market leader for sustainable cross-border e-Commerce
          solutions.
        </Body>
        <Body>
          The Group structure is going to be reviewed. In addition to the
          existing holding company, whose role will evolve, two new divisions
          will be created, one specialised in cross-border parcels, small
          packets and mail solutions operating under the brand of Asendia; one
          responsible for global D2C e-commerce technology solutions operating
          under the brand of ESW.
        </Body>
        <Body>
          In this context, Marc Pontet will be the CEO of Asendia Group and
          Matthieu Martin will assume the role of Deputy CEO and CFO at Group
          level. Simon Batt will become the CEO of Asendia and as announced
          recently, Eric Eichmann will be the new CEO of ESW.
        </Body>
        <Body>
          Simon Batt is currently Chief Executive Officer of Asendia UK and will
          take on his new role from August 2023.
        </Body>
        <Body>
          As leading player in the cross-border e-commerce mail, packets and
          parcels industry, Asendia has a revenue of 1 billion EUR, and its
          customer portfolio includes major global and local retailers,
          platforms and marketplaces. Asendia has more than 1,500 employees
          across 4 continents and provides distribution solutions to more than
          200 destinations.
        </Body>
        <Body>
          Simon Batt is a well-known business leader in the industry, with over
          20 years’ experience in mail, parcels and logistics, the majority
          within cross-border and international strategic development. Since
          2019, Batt has been the CEO of Asendia in the UK, successfully merging
          the company with wnDirect having led that company back to profit after
          taking over in 2017. Under his leadership, and having put in place a
          new strategy, Asendia UK has seen revenues, profitability, customer
          experience and employee engagement improve, with significant
          investments made in technology and innovation. Before that, Batt
          worked at GeoPost in Paris as Managing Director (Global Solutions),
          focusing on international growth and e-commerce solution development
          in new geographies as well as new market entry strategy. Batt
          previously held senior roles in Royal Mail Group, leading teams within
          e-commerce, sales & marketing and programme management before becoming
          the International Director for parcels. He holds a degree in
          Management Studies having studied both in Nottingham and Madrid, and
          an MBA from the University of Warwick.
        </Body>
        <Quotation>
          <Quotee>Commenting on Batt’s promotion, Pontet states,</Quotee>
          “Simon is the perfect person to lead Asendia as he’s a dynamic
          business leader with an outstanding track record in the industry. Not
          only does he have exceptional strategic capabilities, he also
          possesses an excellent ability to deliver change and innovation for
          customers. Combined with his proven experience in both mature and
          developing markets, it’s clear he’s the right person to take our
          worldwide logistics division on this next phase of its growth journey.
          The Board looks forward to Simon realising Asendia’s full potential.”
        </Quotation>
        <Quotation>
          <Quotee>Commenting on his promotion, Batt said,</Quotee>
          “Asendia has successfully transformed from a mail to a global
          cross-border parcels business, along with expanding its footprint with
          leading e-commerce global and local retailers, and I’ve been proud to
          be a part of this journey in the UK. Now being appointed to lead the
          company worldwide is very exciting. The next phase of the journey is
          about delivering our vision to make Asendia a leading provider of
          e-commerce logistics solutions to enable our customers to effectively
          leverage the benefits of cross border trade. I can’t wait to get
          started and want to thank Marc and the Board for this opportunity.”
        </Quotation>
        <Body>
          To find out more about Asendia, click{" "}
          <AnchorLink
            to="https://www.asendia.com"
            externalLink={true}
            linkColor={variables.linkColor}
          >
            here
          </AnchorLink>
        </Body>
        <Body>
          <Bold>About Asendia</Bold>
        </Body>
        <Body>
          Asendia is one of the world&apos;s leaders in international e-commerce
          and mail, delivering packages, parcels and documents to more than 200
          destinations across the globe. Combining the experience and expertise
          of our founding companies, La Poste and Swiss Post, Asendia brings
          together a wealth of international and local know-how. Asendia&apos;s
          expertise is broad reaching encompassing different aspects of
          e-commerce, from webshop software and marketplace management to
          international logistics. Asendia is committed to sustainability,
          offsetting all carbon emissions including first-mile collection,
          last-mile delivery, returns, and building emissions, machinery, and
          business travel. Asendia employs over 1,500 people in Europe, UK, Asia
          Pacific and the USA - a global network blended with a local presence.
          www.asendia.com
        </Body>
        <Body>
          <Bold>About ESW</Bold>
        </Body>
        <Body>
          ESW is the leading global and domestic direct-to-consumer (DTC)
          ecommerce company, empowering the world&apos;s best-loved brands and
          retailers to expand their DTC channel. ESW acquired Scalefast in June
          2022, and the combined organization offers brands and retailers a
          complete portfolio of technology and services that cost-effectively
          support any stage of a company&apos;s development. From compliance,
          data security, fraud protection, taxes and tariffs to demand
          generation, checkout, delivery, returns and customer service, our
          powerful combination of technology and human ingenuity covers the
          entire shopper journey across 200 markets, with 100% carbon neutral
          shipping to consumers. Headquartered in Dublin, Ireland, ESW has
          global offices in the US, UK, Spain, France, Italy, Japan, Hong Kong
          and Singapore. ESW is an Asendia Group company, a joint venture
          between La Poste and Swiss Post. www.esw.com
        </Body>
      </>
    ),
  },
  {
    id: 66,
    date: "July 2023",
    dateTitle: "19 July 2023",
    category: "Fulfilment",
    title:
      "DPD Croatia is building the Adriatic Hub – a new sorting centre for Croatia and Slovenia",
    cardTitle:
      "DPD Croatia is building the Adriatic Hub – a new sorting centre for Croatia and Slovenia",
    imgs: [img66],
    body: (
      <>
        <Heading4>
          DPD Croatia, which operates as part of the global Geopost network, has
          started the construction of the largest distribution centre under the
          name Adriatic Hub – in which parcels for Croatia and Slovenia will be
          processed at the same time.
        </Heading4>
        <Body>
          The construction of the Adriatic Hub is the largest investment in the
          improvement of infrastructure since the establishment of DPD Croatia,
          which is celebrating its 20th anniversary of operations in Croatia
          this year. The total value of the investment is estimated at 23
          million euros, while the value of the technological investment is 10
          million euros. The location where the construction of the new complex
          has already begun is a strategically important place for the
          development and improvement of the parcel delivery process in Croatia
          and Slovenia.
        </Body>
        <Body>
          The opening of the Adriatic Hub is scheduled for 2024 and will be the
          largest sorting and logistics centre to date and will cover 37,500
          square meters. The new complex will be the first sorting centre that
          will process parcels for Croatia and Slovenia at the same time, and it
          is being built according to the latest standards of Geopost , which
          implies the use of the currently best available sorting technology
          that will enable the processing of up to 12,000 parcels per hour. We
          believe that the qualitative shift will be felt by all domestic and
          foreign 2B and 2C clients in Croatia, as well as in Slovenia.
        </Body>
        <Quotation>
          „This investment in strengthening logistics capacities and improving
          all operational processes is expected to further strengthen the
          connection between Croatia and Slovenia and increase the quality of
          service to the satisfaction of shippers and end recipients in both
          countries “,
          <Quotee>said Igor Jakovljević, CEO Croatia and Slovenia.</Quotee>
        </Quotation>
        <Body>
          DPD Croatia currently has 18 depots strategically arranged to cover
          all areas of Croatia, and from this month, DPD will further increase
          its logistics/storage capacity by opening a new distribution centre in
          Dugopolje and expanding the existing central distribution centre in
          Zagreb, in Sesvete.
        </Body>
        <Body>
          By investing in the mentioned projects, and especially in the Adriatic
          Hub, we contribute to the improvement of the quality of the service,
          but also to the standards that are increasing day by day in the
          package distribution segment. With the construction of a new modern
          sorting and logistics centre, we expect to raise the quality of our
          service to the highest possible level, which DPD intends to achieve
          primarily by fast and simple shipment of packages and by meeting the
          needs of all our users. Undoubtedly, with these continuous
          investments, DPD Croatia will improve the overall delivery experience,
          as well as meet market demand.
        </Body>
        <Body>
          <Bold>About DPD Croatia</Bold>
        </Body>
        <Body>
          a Pickup network with more than 83,000 parcel pickup locations,
          Geopost delivers 8.3 million parcels daily – 2.1 billion parcels
          annually - through the DPD, Chronopost, SEUR, BRT, Speedy and Jadlog
          brands. DPD started operating in Croatia in 2003. In 20 years of
          existence in the domestic market, it has become one of the key players
          and expert in the domestic market, providing a high-quality parcel
          delivery service. DPD Croatia operates day in and day out on behalf of
          thousands of satisfied customers and combines innovative technology
          and local knowledge to provide flexible and user-friendly service for
          both shippers and customers. With its flagship Predict service, myDPD
          app, pickup points, DPD Croatia sets a new standard for convenience by
          keeping customers closely related to the delivery of their parcels.
        </Body>
      </>
    ),
  },
  {
    id: 65,
    date: "July 2023",
    dateTitle: "10 July 2023",
    category: "Sustainability",
    country: "Germany",
    title:
      "DPD Germany also masters shipping process with sustainable packaging",
    cardTitle:
      "DPD Germany also masters shipping process with sustainable packaging",
    imgs: [img65],
    body: (
      <>
        <Heading4>
          DPD Germany is currently conducting a test with reusable shipping
          packaging together with Munich-based start-up hey circle and German
          clothing manufacturer TRIGEMA. Among other things, the practical
          suitability of the reusable boxes from hey circle in size XL (60 x 40
          x 20 cm) will be tested under real conditions - between TRIGEMA
          headquarters and its test stores by DPD.
        </Heading4>
        <Body>
          Good ideas and bold thinking drive DPD Germany: For the parcel service
          provider, this is already the second test run with reusable shipping
          packaging within a few months. In April, DPD Germany, together with
          the start-up Boomerang®, had used such packaging in the systems and
          sorting facilities at DPD Depot 120 in Hamburg to the satisfaction of
          all involved. With the start-up hey circle, the sustainably designed
          reusable solutions had already been successfully tested in the DPD
          system over several months last year. In close cooperation with hey
          circle and TRIGEMA, an internal test phase is now being conducted
          between TRIGEMA headquarters and test stores to examine the potential
          and costs of the logistics process. The companies involved are relying
          on a multi-stage test procedure: After a test phase with 100 boxes,
          the number could be doubled in the next step.
        </Body>
        <Quotation>
          &quot;As an internationally active parcel and express service
          provider, we want to make our contribution to sustainable
          environmental protection. To do this, we like to break new ground with
          new partners,&quot;
          <Quotee>
            explains Michael Knaupe, Chief Customer Experience & Business
            Development Officer at DPD Germany.
          </Quotee>
          &quot;We were therefore delighted to be able to contribute our
          know-how as a long-standing logistics expert to this innovation
          ecosystem. Because one thing is clear: we believe in a nationwide
          reusable concept in e-commerce.&quot;
        </Quotation>
        <Quotation>
          &quot;Good handling of our shipping packages is a high priority for
          us, which is why we optimized the products for logistics right from
          the start. In close exchange with DPD, we receive confirmation that
          this is exactly what we are getting,&quot;
          <Quotee>says Doris Diebold, CEO and founder of hey circle.</Quotee>
        </Quotation>
        <Body>
          <Bold>About DPD Germany</Bold>
        </Body>
        <Body>
          DPD Germany is part of Geopost, the largest parcel network in Europe.
          DPD Germany has 79 depots and 7,700 pickup parcel shops throughout
          Germany. More than 9,600 employees and 12,000 delivery staff work for
          customers every day. Every year the number two in the German parcel
          market transports over 400 million parcels - and does so in a
          completely climate-neutral way, without any additional costs for
          customers. With numerous initiatives for sustainable parcel delivery
          DPD Germany shows, especially in inner cities, how parcel shipping can
          be made environmentally friendly and locally emission-free.
        </Body>
        <Body>
          With innovative technologies and a consistent focus on the needs of
          all customers and recipients DPD Germany offers a simple, convenient
          and flexible service for B2B and B2C parcel shipping. One example of
          this is the Predict Service, which is unique in the industry. It
          enables DPD Germany to tell the recipient on the day of delivery
          exactly when the parcel will arrive, down to the hour. In live
          tracking within the last hour before delivery, the time window even
          updates to up to 30 minutes. Only recently the testers of Stiftung
          Warentest praised the live tracking of the test parcels they posted
          and the 100 per cent adherence to DPD&apos;s predicted delivery times.
          On the recipient side DPD Germany is also therefore the only company
          in the test to receive the grade &quot;good&quot; for delivery.
        </Body>
        <Body>
          Geopost ships to 230 countries worldwide and has a uniform network of
          83,000 pick-up points with harmonised services also in cross-border
          shipping. Last year around 120,000 employees and business partners
          throughout Europe ensured that more than 2 billion parcels were
          successfully delivered to customers. DPD Germany&apos;s parent company
          is Geopost, a wholly owned subsidiary of the French Groupe La Poste.
          Geopost achieved a consolidated annual turnover of 15.6 billion euros
          last year.
        </Body>
      </>
    ),
  },
  {
    id: 64,
    date: "July 2023",
    dateTitle: "06 July 2023",
    category: "e-Commerce",
    title:
      "Asendia’s e-PAQ Returns: the ultimate solution as six in ten retailers anticipate surge in returns",
    cardTitle:
      "Asendia’s e-PAQ Returns: the ultimate solution as six in ten retailers anticipate surge in returns",
    imgs: [img64],
    body: (
      <>
        <Heading4>
          Asendia, the leader in international e-commerce and mail delivery
          solutions, is proud to announce the continued advancement of its
          global e-PAQ returns service. This leverages an extensive global
          reverse logistics network and is executed in partnership with ESW
          (formerly eShopWorld) technology. The solution will help online
          retailers simplify the returns process for their shoppers delivering a
          more efficient, faster and hassle-free experience.
        </Heading4>
        <Body>
          e-PAQ Returns is a comprehensive end-to-end solution which complements
          the panel of international e-commerce delivery solutions Asendia
          already offers. It enables international retailers to accelerate
          growth, reaching consumers in more than 200 countries.
        </Body>
        <Body>
          Returns are one of the biggest retailer challenges. A recent global
          study (*) by Asendia revealed that on average, retailers said their
          returns rate was approaching a quarter of their total purchases (23%).
          This figure increases to 27% in the US and in Switzerland, and 26% in
          Germany and the UK. And when shoppers were asked to consider, when
          having an international order fulfilled, what would influence their
          decision to make a purchase currently, their top three responses
          revolved around shipping. Nearly one shopper in three (32%) will
          ensure their preferred returns method is available (paperless, drop
          off box, collect, postal office, in-store) before finalising an order.
        </Body>
        <Body>
          As shopper returns expectations vary from one country to another, and
          from one purchase to the next, it is essential for retailers to
          seamlessly offer shoppers a range of return options. Satisfying
          various customer expectations, e-PAQ provides a selection of methods
          to choose from.
        </Body>
        <Body>
          Involving easy-to-use return QR codes instead of labels, automated
          tracking, high density drop-off points, and flexible return options,
          Asendia’s e-PAQ Returns platform allows shoppers to monitor the entire
          returns process; from when the package is shipped to when it is
          received by the retailer, giving them complete visibility and control
          over the process.
        </Body>
        <Body>
          Six in ten (57%) retailers are projecting an increase in returns
          volumes in the next two and five years and therefore a third (29%)
          want to tackle this issue and reduce the number of returns. The urge
          to tackle returns is readily understood in the context of lost income
          and the costs of processing them. It is worth noting that in the US
          alone, industry figures show that shoppers returned $816billion of
          goods in 2022.
        </Body>
        <Body>
          By choosing e-PAQ Returns, retailers are selecting a 100% carbon
          neutral solution. Sustainability is a core aspect of the business,
          Asendia has offset all of its scope 1, 2 & 3 CO2 emissions and as of
          2023, the company has been awarded the carbon offsetting certificate
          for 2022, keeping its promise of becoming 100% neutral.
        </Body>
        <Quotation>
          “Returns are a significant headache for retailers, but shoppers want
          to buy with the confidence that they can return their items if they
          wish to. We’re excited to join forces with ESW to offer an end-to-end
          global solution entirely focused on our customer’s needs and which
          simplifies the returns process from A to Z. We believe that by
          designing and providing a sustainable returns solution, we can help
          our customers meet their sustainability goals whilst also improving
          the e-commerce experience for shoppers.”
          <Quotee>explains Marc Pontet, CEO, Asendia Management.</Quotee>
        </Quotation>
        <Quotation>
          “Globally, we’re noticing people are cutting back on their spending,
          so they are more likely to choose retailers who provide the best
          end-to-end shopping experience. With tightened budgets, consumers are
          only keeping purchases they truly love and plan to use, so brands and
          retailers must ensure they have the proper logistics network and
          technology in place to keep up with shifting behaviours. The
          state-of-the-art technology behind e-PAQ Returns provides retailers
          and consumers with full control and visibility of the return journey.”
          <Quotee>comments Tommy Kelly, CEO of ESW.</Quotee>
        </Quotation>
        <Quotation>
          “Like it or not, returns are normal and central to customer experience
          in the e-commerce age. They don’t have to drag down revenues, but they
          do need to be managed professionally. e-PAQ Returns is setting a new
          benchmark in online returns, simplyfing the entire process management,
          including taxes, duties and payments. We understand the crucial role
          returns play in customer satisfaction, and our goal is to simplify the
          process, making it more efficient and customer-centric. With our
          innovative technology and global network, we are confident that
          retailers will benefit from reduced costs, increased conversions, and
          enhanced customer loyalty.”
          <Quotee>
            says Helen Scurfield, Director of Innovation and Development,
            Asendia UK.
          </Quotee>
        </Quotation>
        <Body>
          e-PAQ Returns is completing the 360° e-commerce value chain Asendia is
          offering to retailers. This solution, as part of the company’s e-PAQ
          range, complements the panel of international e-commerce delivery
          solutions Asendia is already offering. It represents a highly
          competitive advantage to handle and propose shipping and returns
          solutions.
        </Body>
        <Body>
          To find out more about Asendia’s e-PAQ Returns service, click{" "}
          <AnchorLink
            to="https://www.asendia.com/e-paq/returns"
            externalLink={true}
            linkColor={variables.linkColor}
          >
            here
          </AnchorLink>
          .
        </Body>
        <Body>
          <Bold>(*) Research Methodology</Bold>
        </Body>
        <Body>
          Quantitative research undertaken by Savanta, a UK-based data, market
          research and advisory company in collaboration with Fieldworks, in
          February 2023 based on online interviews with 8,000 global shoppers in
          the UK, U.S., Canada, France, Spain, Germany, Switzerland and Hong
          Kong. 100% had bought something online, both from their home country
          and cross-border, in their lifetime. Five demographics were
          represented: Gen Z (1997-2012), Millennials (1981-1996), Gen X
          (1965-1980), Baby Boomers (1946-1964) and the Silent Generation
          (1928-1945). There was an even split between male and female
          respondents.
        </Body>
      </>
    ),
  },
  {
    id: 63,
    date: "July 2023",
    dateTitle: "05 July 2023",
    category: "Sustainability",
    country: "United Kingdom",
    title: "DPD UK sign for Forest Green Rovers with two-year partnership deal",
    cardTitle:
      "DPD UK sign for Forest Green Rovers with two-year partnership deal",
    imgs: [img63],
    body: (
      <>
        <Heading4>
          Partnership between two of the UK&apos;s green leaders
        </Heading4>
        <Body>
          Leading parcel delivery company DPD UK has signed a two-year
          partnership deal with football club Forest Green Rovers.
        </Body>
        <Body>
          The deal brings together two of the most sustainable brands in their
          respective fields. DPD&apos;s stated aim is to be the most sustainable
          delivery company in the UK, while Forest Green Rovers have been
          recognised by global footballing body FIFA as the greenest football
          club in the world.
        </Body>
        <Body>
          DPD, which has over 3,000 electric vehicles on the road in the UK, has
          been the club&apos;s parcel delivery partner for the past two seasons,
          delivering replica kit and merchandise for fans. Today&apos;s
          announcement will see that partnership extended with a new two-year
          deal through to the end of the 2024/25 season.
        </Body>
        <Body>
          The deal means that DPD&apos;s logo and branding will appear on the
          back of player&apos;s shirts for the 2023-24 and 2024-25 seasons.
          Perimeter advertising and hospitality packages are also included.
        </Body>
        <Body>
          Forest Green Rovers (FGR) is known for being the only vegan football
          club in the world and in 2017, FGR chairman Dale Vince was appointed
          as a Climate Champion for the United Nations who also recognised FGR
          as the first Carbon Neutral football club in the world as part of the
          UN Climate Neutral Now pledge. The club&apos;s green initiatives cover
          every aspect of its operation including energy, transport, food, waste
          and supporting nature and biodiversity.
        </Body>
        <Body>
          Going forward, the club has planning permission to build a sustainable
          Eco Park complex which will include a new 5,000 capacity,
          state-of-the-art wooden stadium, and training facility.
        </Body>
        <Body>
          DPD opened the UK&apos;s first all-electric parcel delivery depot in
          2018 and by the end of this year will be delivering to 30 of the
          largest towns and cities in the UK using only all-electric final mile
          delivery vehicles. By that time, the DPD UK fleet will include 4,000
          electric vans and all 1,600 of its HGVs will have been converted from
          diesel to renewable biofuel. Across the DPD network, buildings are
          powered by solar panels and renewable electricity, while 100% of waste
          avoids landfill. Recycling initiatives generate cash for the
          innovative DPD Eco Fund which awards funding to sustainable projects
          benefiting the community and the environment.
        </Body>
        <Quotation>
          <Quotee>
            Tim Jones, Director of Marketing, Communications & Sustainability
            DPD UK commented,
          </Quotee>
          “We are absolutely delighted to be partnering up with Forest Green
          Rovers. The club has been such a leader on sustainability, not just
          within sport, they have shown all organisations what is possible. We
          are proud to be their delivery partner and just as proud to see our
          name on their shirts next season. Together we are reducing emissions,
          investing in green innovation and helping protect the planet for
          future generations. We wish them every success on and off the pitch in
          the next two seasons and hopefully together we can continue to raise
          awareness of what can be achieved.”
        </Quotation>
        <Quotation>
          <Quotee>Forest Green Rovers chairman, Dale Vince, commented:</Quotee>
          “Transport is one of the big three drivers of the climate crisis - and
          a focus of our work at FGR. Delivering stuff is a key part of that and
          it&apos;s fantastic to see the work DPD are doing, specifically the
          electrification of parcel deliveries. This is how we get to a Green
          Britain - and at the same time clean up the air that we breathe. As a
          club we&apos;re very excited to have the opportunity to work with DPD
          UK.”
        </Quotation>
        <Body>
          Details on all of DPD UK&apos;s sustainability initiatives can be
          found on its dedicated DPD green website
          <AnchorLink
            to={"https://green.dpd.co.uk/"}
            externalLink={true}
            linkColor={variables.linkColor}
          >
            {" "}
            https://green.dpd.co.uk/
          </AnchorLink>
          .
        </Body>
      </>
    ),
  },
  {
    id: 62,
    date: "July 2023",
    dateTitle: "03 July 2023",
    category: "Sustainability",
    country: "United Kingdom",
    title: "DPD UK to roll-out robot deliveries to 10 UK towns and cities",
    cardTitle: "DPD UK to roll-out robot deliveries to 10 UK towns and cities",
    imgs: [img62],
    body: (
      <>
        <Heading4>
          Raunds becomes DPD UK’s second robot base following successful Milton
          Keynes trial
        </Heading4>
        <Body>
          Leading parcel delivery company DPD UK has confirmed plans to expand
          autonomous robot deliveries to 10 UK towns and cities in the next 12
          months, following a successful trial in Milton Keynes, starting with
          Raunds in Northamptonshire.
        </Body>
        <Body>
          The announcement extends DPD’s relationship with AI-powered robotics
          and last mile delivery specialists, Cartken and follows work by DPD UK
          that identified up to 30 UK depot locations which could potentially
          deploy robots.
        </Body>
        <Body>
          Cartken’s level 4 autonomy and leading micro-AV technology platform is
          regarded as one of the safest last mile delivery solutions on the
          market and enables full autonomy on footpaths with only cameras - one
          of the only companies in the autonomous vehicle delivery space to
          achieve this with a purely vision-based system.
        </Body>
        <Body>
          DPD UK first introduced the Cartken robots in Milton Keynes in July
          2022 and they have since travelled almost 500 miles and successfully
          performed over 2,500 trips delivering parcels to DPD customers.
        </Body>
        <Body>
          Based at DPD’s depot at Warth Park on the western edge of Raunds, the
          robots will deliver to nearby residential areas within a mile of the
          depot, via the existing network of footpaths. In Raunds, the robots
          will have three separate parcel compartments, instead of just one in
          the original trial, and an extended battery life of up to 12 hours.
        </Body>
        <Body>
          DPD UK parcel recipients in Raunds will be notified of a robot
          delivery in advance, and once they confirm that they are at home to
          accept the parcel, the robot will be dispatched.
        </Body>
        <Body>
          Customers will then be able to track the robot’s progress on a map and
          are notified when it reaches their property. They then use a code to
          open the secure compartment and access their parcel. Once the
          compartment is closed, the robot will either continue to its next
          delivery or return to the depot.
        </Body>
        <Body>
          DPD UK has worked closely with North Northamptonshire Council on the
          introduction of the robots in Raunds.
        </Body>
        <Quotation>
          <Quotee>
            Cllr Graham Lawman, North Northamptonshire Council’s Executive
            Member for Highways, Travel and Assets, said:
          </Quotee>
          “We are delighted that DPD is introducing delivery robots in Raunds.
          The council is committed to the environment and supporting clean and
          green alternatives to traditional forms of transport to reduce
          emissions. It is great to be able to help bring this innovation to
          North Northamptonshire.”
        </Quotation>
        <Quotation>
          <Quotee>
            Tim Jones, Director of Marketing, Communications & Sustainability
            DPD UK commented,
          </Quotee>
          “The trial in Milton Keynes has been a huge success for us. The robots
          have exceeded our expectations, and the feedback has been fantastic.
          We can see people’s reactions when they meet them, and it is
          overwhelmingly positive. We set out to understand the role that
          delivery robots could play in certain locations in the UK and the
          trial has helped us identify more sites where we could deploy the
          robots successfully. “Our aim is to be the UK’s most sustainable
          delivery company and that means investing in innovations and trying
          new things. We are well on our way to decarbonising our van fleet with
          EVs and we are also making a big step forward by switching our HGVs to
          renewable biofuel, but we are also interested in brand new solutions
          that can help us transition to a smarter, more sustainable future.”
        </Quotation>
        <Quotation>
          <Quotee>
            Cartken Co-Founder and COO, Anjali Jindal Naik, commented,
          </Quotee>
          “Autonomous mobility will be a key lever for decarbonisation, as it
          transforms last-mile transportation into a much greener process. By
          partnering with delivery companies, such as DPD, we’re propelling this
          transformation forward, making sustainable delivery the norm, rather
          than a nice to have.
        </Quotation>
        <Quotation>
          “2023 has been a year of tremendous growth for Cartken and we’re only
          in Q2. Developing our partnership with DPD is a critical step in
          expanding our presence globally, beginning with the UK, while also
          being at the forefront of sustainable last-mile delivery by cutting
          down on C02 emissions.”
        </Quotation>
        <Body>
          Details on all of DPD UK&apos;s sustainability initiatives can be
          found on its dedicated DPD green website
          <AnchorLink
            to={"https://green.dpd.co.uk/"}
            externalLink={true}
            linkColor={variables.linkColor}
          >
            {" "}
            https://green.dpd.co.uk/
          </AnchorLink>
          .
        </Body>
      </>
    ),
  },
  {
    id: 61,
    date: "June 2023",
    dateTitle: "28 June 2023",
    category: "Sustainability",
    title: "Discover Geopost’s 2022 Sustainability Report",
    cardTitle: "Discover Geopost’s 2022 Sustainability Report",
    imgs: [img61],
    link: "https://www.geopost.com/wp-content/uploads/sites/286/2023/06/Geopost_Sustainability_Report_Final.pdf",
  },
  {
    id: 60,
    date: "June 2023",
    dateTitle: "21 June 2023",
    category: "Sustainability",
    country: "United Kingdom",
    title:
      "DPD UK to trial innovative mobile charging solution with OnCharge Energy",
    cardTitle:
      "DPD UK to trial innovative mobile charging solution with OnCharge Energy",
    imgs: [img60],
    body: (
      <>
        <Heading4>
          Leading parcel delivery company DPD UK is set to trial an innovative,
          new mobile powerbank charging concept that could provide a solution
          for commercial van drivers unable to charge their vehicles at home
          overnight.
        </Heading4>
        <Body>
          The OnCharge ZIR0 prototype charging system will be trialed at
          DPD&apos;s Bicester eco-depot - the most sustainable facility in the
          company&apos;s UK network. The multi-van trial will allow drivers to
          collect a take-home powerbank from the depot, before driving home. The
          prototype power bank will charge the main traction battery of the van
          overnight, resulting in a 100% charge by the start of the
          driver&apos;s day.
        </Body>
        <Body>
          The prototype is the world&apos;s first purpose-built ruggedised
          powerbank, designed for fleet commercial vehicles. With a real
          operating capacity of 50kWh, the system is designed to improve the
          Electric Light Commercial Vehicle (eLCV) driver experience. The
          OnCharge system takes just 90 seconds to load in the evening, and
          unload in the morning.
        </Body>
        <Body>
          The OnCharge system has the potential to provide a solution to drivers
          that don&apos;t have access to off-street parking and will help avoid
          reliance on public charging.
        </Body>
        <Body>
          The prototype trials will allow OnCharge and DPD engineers to gather
          field data and review driver feedback. It will also provide insight
          into potential integration with DPD&apos;s Bicester on-site solar
          panel array.
        </Body>
        <Body>
          The trial is part of OnCharge Energy&apos;s Field Test and Development
          programme and a key step towards scaling up customer production in
          late 2023.
        </Body>
        <Body>
          DPD&apos;s aim is to be the most sustainable parcel delivery company
          in the UK and the company is on track to have over 4,000 electric
          vehicles (EVs) on the road by the end of this year, when it will be
          delivering to 30 towns and cities using electric vehicles only. Nearby
          Oxford was DPD&apos;s first &apos;green city&apos;, with all
          deliveries made by the Bicester eco-depot&apos;s zero emission
          delivery vehicles.
        </Body>
        <Quotation>
          <Quotee>
            Olly Craughan, Head of Sustainability, DPD UK commented,
          </Quotee>
          &quot;It&apos;s fantastic to work with OnCharge to try this system in
          the real-world. Working together will give us the opportunity to
          really understand the impact it can have on our drivers first-hand. It
          allows both us and OnCharge to see how the system works under
          different scenarios and allows our transport and sustainability teams
          to explore how an agile powerbank model can amplify our investment in
          EV and on-site renewable generation.&quot;
        </Quotation>
        <Quotation>
          <Quotee>Philip Clarke, CEO OnCharge Energy commented,</Quotee>
          &quot;In terms of productivity, the most efficient time to charge a
          commercial EV is overnight, but around 70% of fleet van drivers
          can&apos;t charge at home. We believe that a take-home powerbank that
          you can collect at the end of the working day could revolutionise
          commercial EV fleet management and help encourage more drivers to
          switch to EVs. DPD already have a huge amount of experience running a
          large EV fleet and the Bicester eco-depot is the perfect place to
          trial our solution. We are looking forward to working with the team
          and hopefully making their EV fleet even more efficient and
          effective.&quot;
        </Quotation>
        <Body>
          Details on all of DPD&apos;s sustainability initiatives can be found
          on its dedicated DPD green website
          <AnchorLink
            to={"https://green.dpd.co.uk/"}
            externalLink={true}
            linkColor={variables.linkColor}
          >
            {" "}
            https://green.dpd.co.uk/
          </AnchorLink>
          .
        </Body>
      </>
    ),
  },
  {
    id: 59,
    date: "June 2023",
    dateTitle: "15 June 2023",
    category: "Sustainability",
    title: "Shipping goods from Great Britain to Northern Ireland",
    cardTitle: " Shipping goods from Great Britain to Northern Ireland",
    imgs: [img59],
    body: (
      <>
        <Heading4>
          The UK Government has recently published a statement on the new
          arrangements for sending parcels from Great Britain to Northern
          Ireland which will be in place from 30 September 2024.
        </Heading4>
        <Heading5>
          Please read the full notice here{" "}
          <span>
            <AnchorLink
              externalLink={true}
              linkColor={variables.linkColor}
              to="https://assets.publishing.service.gov.uk/government/uploads/system/uploads/attachment_data/file/1162135/Parcels.pdf"
            >
              View Statement
            </AnchorLink>
          </span>
        </Heading5>
        <Body>
          The statement confirms that businesses do not need to take further
          steps at this point, additional guidance will be provided by 30
          September 2023.
        </Body>
        <Body>
          They have also confirmed that HMRC is actively engaging with parcel
          carriers to ensure a smooth transition.
        </Body>
        <Heading4>Additional government links and guidance below:</Heading4>
        <Body>
          <AnchorLink
            to="https://www.gov.uk/government/collections/the-windsor-framework-further-detail-and-publications"
            externalLink={true}
            linkColor={variables.linkColor}
          >
            Further details
          </AnchorLink>{" "}
          on Windsor Framework
        </Body>
        <Body>
          Goods movement{" "}
          <AnchorLink
            to="https://www.gov.uk/government/collections/the-windsor-framework-further-detail-and-publications"
            externalLink={true}
            linkColor={variables.linkColor}
          >
            general
          </AnchorLink>{" "}
          with further links such as Green Lane, Duty Reimbursement, and Customs
          Duty Waiver Scheme
        </Body>
        <Body>
          Applying for{" "}
          <AnchorLink
            to="https://www.gov.uk/government/collections/the-windsor-framework-further-detail-and-publications"
            externalLink={true}
            linkColor={variables.linkColor}
          >
            UKIMS
          </AnchorLink>
        </Body>
        <Body>
          EU{" "}
          <AnchorLink
            to="https://www.gov.uk/government/collections/the-windsor-framework-further-detail-and-publications"
            externalLink={true}
            linkColor={variables.linkColor}
          >
            Delegated Act
          </AnchorLink>
        </Body>
      </>
    ),
  },
  {
    id: 58,
    date: "June 2023",
    dateTitle: "12 June 2023",
    category: "Sustainability",
    title: "DPD UK partners with L Marks and launches new EcoLab",
    cardTitle: "DPD UK partners with L Marks and launches new EcoLab",
    imgs: [img58],
    body: (
      <>
        <Body>
          DPD UK is looking for companies that specialise in sustainable
          solutions to reduce carbon emissions in transport, delivery, and
          buildings for their first EcoLab.{" "}
        </Body>
        <Body>
          DPD UK is partnering with L Marks to launch an EcoLab to discover
          additional sustainable solutions for their business. This initiative
          will be centered around carbon reduction in transport, delivery, and
          buildings, with the goal of minimising DPD&apos;s environmental
          impact.{" "}
        </Body>
        <Body>
          The EcoLab will bring together experts in sustainability, technology,
          and innovation to develop and test new ideas that can help DPD UK
          further reduce its carbon footprint. This partnership is a key step
          towards DPD&apos;s goal of reaching net zero by 2040.{" "}
        </Body>
        <Quotation>
          <Quotee>
            Tim Jones, Director of Marketing, Communications & Sustainability
            DPD UK commented,
          </Quotee>
          &quot;We are delighted to be partnering with L Marks to launch this
          EcoLab. The project will play a key part in shaping our sustainability
          strategy and helping achieve our stated aim of being the most
          sustainable parcel delivery company in the UK. Investing in and
          developing new sustainable technologies and innovations is crucial in
          the next few years to ensure we can reach net zero, and partnering
          with experts in this way can accelerate that progress.&quot;
        </Quotation>
        <Body>
          L Marks will scout and identify businesses to apply for a unique
          opportunity to take their innovative ideas to the next level. The
          EcoLab will be a ten-week immersive programme where participants will
          have the opportunity to validate their solutions, access to DPD&apos;s
          network and resources, and mentorship from industry experts.
        </Body>
        <Body>
          Applications open on the 12th of June 2023 seeking to solutions in the
          following areas:
        </Body>
        <Heading5>
          <Bold>
            Carbon Emission Reduction in Transport: Fleet Decarbonisation
          </Bold>
        </Heading5>
        <Body>
          DPD UK has over 1,000 Heavy Goods Vehicles (HGVs) operating on the
          roads across the UK every day. DPD has greatly improved their
          HGVs&apos; environmental impact but wants to reduce their fleet&apos;s
          carbon footprint further. The company is looking for solutions around
          alternative fuel sources, electric batteries and alternative delivery
          methods.
        </Body>
        <Heading5>
          <Bold>Carbon Emission Reduction in Collection & Delivery</Bold>
        </Heading5>
        <Body>
          In order to be the UK&apos;s most sustainable delivery company, DPD is
          investing heavily in their all-electric delivery fleet, which
          currently consists of 3,000 electric vehicles in their
          operation&apos;s Collection and Delivery area. DPD UK is looking for
          solutions to make driving electric vehicles easier for drivers.
          Solutions may include EV charging stations and alternative electric
          vehicles.{" "}
        </Body>
        <Heading5>
          <Bold>
            Carbon Emission Reduction in Buildings: &apos;Hub of the
            Future&apos;
          </Bold>
        </Heading5>
        <Body>
          DPD UK operates across a network of more than 100 buildings, including
          depots, hubs, and offices. As this number is constantly growing,
          reducing the carbon footprint of these buildings and the operations
          that take place within them is essential. DPD UK wants to find the
          leading solutions and innovations that can be retrofitted into their
          current depots and/or installed in their future depots. Looking
          further out, DPD UK is also looking to design and build depots in the
          &apos;greenest&apos; way possible moving forward.{" "}
        </Body>
        <Body>
          &quot;We are excited to be working with DPD UK on this important
          initiative,&quot; said Daniel Saunders, CEO of L Marks. &quot;The
          EcoLab is a testament to DPD&quot;s commitment to sustainability and
          their willingness to embrace new ideas and technologies to achieve
          their goals. As the world becomes more conscious of the impact of
          carbon emissions, companies like DPD UK are leading the way in finding
          innovative solutions that safeguard the planet for future
          generations&quot;.{" "}
        </Body>
        <Body>Applications will close on 16/07/23.</Body>
        <Body>
          Further information on the EcoLab and how to apply can be found on the
          website:{" "}
          <AnchorLink
            externalLink={true}
            linkColor={variables.linkColor}
            to={
              "https://dpdecolab.com/?_gl=1%2Azwnysc%2A_ga%2AMTI5MDk1MzY0NS4xNjgwNTEyNjQ5%2A_ga_Q3RB6RNZ25%2AMTY4Nzc4MjI5MS4yNC4wLjE2ODc3ODIzNTguNjAuMC4w"
            }
          >
            dpdecolab.com
          </AnchorLink>
          .
        </Body>
        <br />
        <br />
        <Body>---</Body>
        <br />
        <Body>
          <Bold>About DPD UK</Bold>
        </Body>
        <Body>
          DPD UK is a member of Geopost, a European leader in parcel delivery
          and solutions for e-commerce. Part of the La Poste Groupe, Geopost
          generated a €15.6 billion revenue and delivered 2.1 billion parcels
          worldwide in 2022.
        </Body>
        <Body>
          The company operates more than 10,000 vehicles in the UK from 85
          locations and delivers over 400 million parcels a year.
        </Body>
        <Body>
          In 2018, DPD opened the UK&apos;s first all-electric parcel delivery
          depot in Westminster. DPD currently has over 3,000 EVs in the UK and
          has announced plans to deliver to 30 of the largest towns and cities
          in the UK with zero and low-emission final mile delivery vehicles, by
          the end of 2023.
        </Body>
        <Body>
          Details of all of DPD&apos;s sustainability initiatives can be found
          on its dedicated green website:{" "}
          <AnchorLink
            to={"https://green.dpd.co.uk/"}
            linkColor={variables.linkColor}
            externalLink={true}
          >
            https://green.dpd.co.uk/
          </AnchorLink>
        </Body>
        <Body>
          <Bold>About L Marks:</Bold>
        </Body>
        <Body>
          L Marks is a leading global innovation specialist which has created
          over 90 programmes with its partners in Europe, Asia, and North
          America. Founded in 2014 with a presence in the UK and the US, L Marks
          has developed and delivered results-driven corporate innovation
          solutions, trusted by world-leading brands including BMW, Lloyd&apos;s
          of London, and United Utilities. Through their award-winning
          Innovation Labs, L Marks&apos; partners have worked with over 400
          startups with a successful deployment rate of over 70%. The L Marks
          New Ventures unit supports its clients to create and develop new
          revenue-generating businesses through its Venture Studio, and
          transform their corporate culture within their Intra-Lab. To learn
          more, visit{" "}
          <AnchorLink
            to={"https://lmarks.com/"}
            externalLink={true}
            linkColor={variables.linkColor}
          >
            lmarks.com
          </AnchorLink>
          .
        </Body>
      </>
    ),
  },
  {
    id: 57,
    date: "June 2023",
    dateTitle: "07 June 2023",
    category: "Sustainability",
    title:
      "La Poste Group and the CMA CGM Group execute a deal to cooperate in last-mile deliveries, logistics and decarbonized transportation",
    cardTitle:
      "La Poste Group and the CMA CGM Group execute a deal to cooperate in last-mile deliveries",
    imgs: [img57],
    body: (
      <>
        <Header2>
          Philippe Wahl, Chairman and Chief Executive Officer of La Poste Group,
          Rodolphe Saadé, Chairman and Chief Executive Officer of the CMA CGM
          Group, Yves Delmas, Chief Executive Officer of Geopost, and Mathieu
          Friedberg, Chief Executive Officer of CEVA Logistics, have finalized a
          cooperation deal harnessing both groups’ complementary supply chain
          strengths in Europe outside France.
        </Header2>
        <Header3>
          International cooperation between two leading French logistics
          providers
        </Header3>
        <Body>
          The CMA CGM Group and La Poste Group have developed first-class
          transportation and logistics expertise, especially via CEVA Logistics
          and Geopost, their two respective subsidiaries.
        </Body>
        <Body>
          Geopost is a European leading player in parcel delivery to businesses
          and consumers, with an extensive parcel delivery network in Europe. It
          delivered 2.1 billion parcels worldwide in 2022 thanks to its network
          of over 1,400 hubs and depots, more than 94,000 vehicles and 122,000
          employees and partners around the world.
        </Body>
        <Body>
          CEVA Logistics operates at 1,300 locations in 170 countries. It is a
          global contract logistics provider, and handles freight shipments and
          medium- and long-distance forwarding by road and last-mile deliveries
          in France via its Colis Privé subsidiary.
        </Body>
        <br />
        <Body>The key benefits of the finalized cooperation deal are:</Body>
        <BulletPoints>
          <li>
            CEVA’s customers will benefit from Geopost’s know-how in last-mile
            deliveries in international markets other than France.
          </li>
          <li>
            Reverse logistics and circular economy solutions will be developed
            by leveraging Geopost’s first/last mile parcel delivery expertise
            and the CEVA group’s logistics know-how (inspection, quality
            control, order fulfillment, reconditioning).
          </li>
          <li>
            Both groups’ carbon emissions will be reduced through joint
            optimization of long-distance road transportation in low-carbon
            vehicles.
          </li>
        </BulletPoints>
        <Body>
          These initiatives will help to achieve both groups’ ambitious targets:
          the CMA CGM Group aims to reach Net Zero carbon emissions by 2050. La
          Poste Group’s deliveries have been carbon neutral since 2012 based on
          carbon footprint per parcel, which it reduces as far as it can, then
          offsets the remainder. Its goal is to reduce its carbon emissions by
          parcel by 30% by 2025 (versus 2013).
        </Body>
        <Quotation>
          <Quotee>Philippe Wahl commented:</Quotee>
          “We have decided to combine our strengths and pool our expertise to
          deliver high-performance, yet responsible logistics by consolidating
          our partnership with CMA CGM. Our sincere hope is that this close
          cooperation between Geopost, a European leader in last-mile
          deliveries, and Ceva Logistics, a specialist in freight and
          long-distance forwarding, will give rise to more efficient and more
          environmentally friendly shipping solutions. This commitment reflects
          La Poste Group’s strategic priority of decarbonizing the economy.”
        </Quotation>
        <Quotation>
          <Quotee>Rodolphe Saadé added:</Quotee>
          “As a French group, we are delighted to be joining forces with La
          Poste and pooling our expertise in areas such as international parcel
          delivery and the development of circular economy logistics solutions.
          La Poste will be able to count on our international network and on the
          know-how of our CEVA Logistics subsidiary in certain key business
          lines – just as we will be able to count on theirs. This partnership
          will enhance the quality and increase the competitiveness of our
          respective service offerings while also reducing our carbon
          footprint.”
        </Quotation>
        <br />
        <Body>---</Body>
        <br />
        <Heading5>About CMA CGM</Heading5>
        <Body>
          Led by Rodolphe Saadé, the CMA CGM Group, a global player in sea,
          land, air and logistics solutions, serves more than 420 ports around
          the world across 5 continents, with a fleet of around 600 vessels. The
          Group transported 21.7 million TEU containers (twenty-foot equivalent
          units) in 2022. With its subsidiary CEVA Logistics, a global logistics
          player which transported 522,000 tonnes of air cargo and more than 22
          million shipments of inland freight, and its air cargo division CMA
          CGM AIR CARGO, the CMA CGM Group is constantly innovating to provide
          customers a comprehensive and increasingly efficient offering, thanks
          to new shipping, inland, air freight and logistics solutions. Firmly
          committed to the energy transition in shipping and a pioneer in its
          use of alternative fuels, the CMA CGM Group has set a Net Zero-Carbon
          target for 2050. Each year, via the CMA CGM Foundation, the Group
          supports thousands of children as part of its efforts to promote
          education for all and equal opportunities. The CMA CGM Foundation also
          intervenes in humanitarian crises requiring an emergency response by
          calling on the Group’s shipping and logistics expertise to deliver
          humanitarian supplies around the world. Present in 160 countries
          through its network of more than 400 offices and 750 warehouses, the
          Group employs 155,000 people worldwide, including nearly 4,000 in
          Marseille where its head office is located. cmacgm-group.com
        </Body>
        <Heading5>About La Poste group</Heading5>
        <Body>
          La Poste is a state-owned public limited company, a subsidiary of
          Caisse des Dépôts and the French State. La Poste group is divided into
          four business units: Services-Mail-Parcels, Retail Customers and
          Digital Services, Geopost and La Banque Postale, which, with its
          subsidiary CNP Assurances, is the 11th largest European banking and
          insurance company. La Poste group has four public service missions
          that shape its identity: universal postal service, regional
          development, banking accessibility, and press transport and delivery.
          Committed to its regional coverage, La Poste relies on a delivery
          network of over 35,600 retail outlets, including 17,300 contact points
          (post offices, local postal agencies, retail pickup points) and 18,300
          points of access to postal services (Pickup, business centres, lockers
          and the the parcel drive-thru collection service). La Poste group
          delivers over 17 billion items worldwide (letters, printed advertising
          media and parcels), six days a week. In 2022, La Poste group generated
          €35.4 billion in revenue (44% outside France) and had a headcount of
          238,000, in more than 60 countries over 5 continents, of which 184,000
          in France. As a mission-driven company since June 2021 and a leader in
          environmental transition and sustainable finance, La Poste group aims
          to achieve &quot;zero net emissions&quot; by 2040. Through its
          strategic plan “La Poste 2030, committed for you”, the company has set
          itself the goal of becoming the leading European platform for links
          and exchanges, providing digital, people-oriented, green and
          socially-responsible services for the benefit of its customers and the
          transformation of society as a whole.
        </Body>
        <Heading5>About Geopost</Heading5>
        <Body>
          Geopost, a European leader in parcel delivery and solutions for
          e-commerce, operates in 49 countries across all continents through its
          network of expert delivery brands, including: DPD, Chronopost, SEUR,
          BRT, Speedy and Jadlog. Leveraging its industry-specific expertise,
          Geopost is further growing the development of out-of-home delivery
          services with Pickup, the densest parcel shop and locker network in
          Europe and is also expanding temperature-controlled solutions for food
          and healthcare business. In a world of acceleration and
          transformation, Geopost engages and embraces new e-commerce
          territories with eShopWorld via Asendia, epicery and Pourdebon.com.
          With 122,000 employees and partners, Geopost endeavours to make
          commerce more convenient, profitable and sustainable for their
          customers and communities. Committed to becoming an international
          reference in sustainable delivery, Geopost is the first global
          delivery company to have its roadmap to Net Zero by 2040 approved by
          the Science Based Targets initiative (SBTi). Part of the La Poste
          Group, Geopost generated a €15.6 billion revenue and delivered 2.1
          billion parcels worldwide in 2022.
        </Body>
      </>
    ),
  },
  {
    id: 56,
    date: "June 2023",
    dateTitle: "05 June 2023",
    category: "Fulfilment",
    title: "Geopost at the 2023 Deliver fair",
    cardTitle: "Geopost at the 2023 Deliver fair",
    imgs: [img43],
    body: (
      <>
        <Body>
          On 7th and 8th June, Geopost will participate, for the 7th time, at
          Deliver 2023 in Amsterdam, Europe’s premier event for e-commerce and
          logistics.
        </Body>
        <Body>Meet us at booth B60!</Body>
        <Body>
          Deliver is a worldwide event known for bringing together those from
          the commerce and supply chain ecosystem to share insights into new
          solutions and to shape the future of the industry.
        </Body>
        <Body>
          On 7th June from 17:00 – 17:45 CET, Thibault Guez, Out-of-home Project
          Director, and Hervé Crochet, Group Head of Sales at Geopost, will take
          part in a workshop on how to roll out a global OOH strategy that
          responds to local specificities.
        </Body>
        <Body>
          On 8th June from 11:00 - 11:45, Thiemo van Spellen, Group Global
          Accounts Director, together with Jean-Claude Sonet, EVP Marketing,
          Communications and Sustainability, will join the debate at a
          roundtable on how to co-build strategies for sustainable outcomes in
          our value chain.
        </Body>
        <Body>
          This two-day event is a great opportunity to engage in discussions
          with potential and existing customers and to participate in networking
          sessions with other industry players, while fostering collaboration
          and sharing knowledge.
        </Body>
        <Body>The focus this year will be on our out-of-home solutions.</Body>
        <Quotation>
          <Quotee>
            Thibault Guez, Out-of-home Project Director at Geopost, says:
          </Quotee>
          Our locker and parcel shop network is hybrid, and this makes us
          unique. We stay the course of this strategy by building, buying, and
          implementing lockers in Europe and recruiting new parcel shops to
          address different needs. The key is not finding a place, it’s finding
          the right place, in close proximity to consignees. E-shoppers are
          looking for more flexible delivery options, and OOH is a solution in
          sync with this type of need. Our offering is not home vs. OOH; rather,
          we see ourselves as a delivery company offering both consignees and
          e-merchants the broadest range of delivery solutions possible.&quot;
        </Quotation>
        <Body>
          Follow the fair on our social media accounts –{" "}
          <AnchorLink
            Linkto="https://twitter.com/Geopost_news"
            externalLink={true}
            linkColor={variables.linkColor}
          >
            Twitter
          </AnchorLink>
          ,
          <AnchorLink
            Linkto="https://www.linkedin.com/company/geopost"
            externalLink={true}
            linkColor={variables.linkColor}
          >
            LinkedIn
          </AnchorLink>
          ,{" "}
          <AnchorLink
            Linkto="https://www.instagram.com/geopost.news/"
            externalLink={true}
            linkColor={variables.linkColor}
          >
            Instagram
          </AnchorLink>{" "}
          and our{" "}
          <AnchorLink
            Linkto="https://www.youtube.com/channel/UCqQgjVUrIjw8TsoDUooBaFQ/videos"
            externalLink={true}
            linkColor={variables.linkColor}
          >
            YouTube
          </AnchorLink>{" "}
          channel.
        </Body>
      </>
    ),
  },

  {
    id: 55,
    date: "June 2023",
    dateTitle: "05 June 2023",
    category: "Sustainability",
    title:
      "Geopost launches its Carbon Calculator to better support its customers on their journey towards net zero",
    cardTitle:
      "Geopost launches its Carbon Calculator to support its customers on their journey towards net zero",
    imgs: [img55],
    body: (
      <>
        <Heading4>
          Geopost and a group of strategic e-merchants have partnered for
          several months to test the transport company’s new Carbon Calculator,
          a digital tool that supports shippers’ carbon management and reduction
          strategies.
        </Heading4>
        <BulletPoints>
          <li>
            After an initial phase of collaborative in-house development,
            Geopost and selected key customers have successfully tested the new
            Geopost Carbon Calculator, to precisely measure the carbon
            emissions[1] of their deliveries in real time.
          </li>
          <li>
            Geopost’s Carbon Calculator is accredited by Smart Freight
            Centre[2], and pioneers carbon reporting by providing greater
            visibility to shippers so they can better understand, report and
            reduce their carbon emissions.
          </li>
          <li>
            The tool will be available to all Geopost’s customers in 2023,
            across the company’s 20 largest European business units.
          </li>
        </BulletPoints>
        <Header3>
          <Bold>
            The Carbon Calculator: an accredited digital tool for strategic
            decision-making
          </Bold>
        </Header3>
        <Body>
          Built for and with Geopost’s customers, this powerful technology
          supports shippers’ carbon reduction strategies by enabling customers
          to analyze, in real time, report and ultimately reduce emissions over
          the long term.
        </Body>
        <Body>
          Geopost’s Carbon Calculator provides a unique service to shippers
          looking to accurately track their carbon emissions, by using scan
          events to track each parcel as it moves through the Geopost network.
          This allows Geopost to provide its customers with emissions data
          specific to their shipments and real-time visibility on their scope 3
          emissions.
        </Body>
        <Body>
          Geopost’s Carbon Calculator is in methodological conformance with
          robust, international carbon accounting standards, notably the Global
          Logistics Emissions Council Framework for Logistics Emissions
          Methodologies (GLEC Framework). The tool is officially accredited by
          Smart Freight Centre and is authorized to provide carrier greenhouse
          gas (GHG) emissions data for freight and logistics.
        </Body>
        <Body>
          The Carbon Calculator is already available in 10 business units
          (Chronopost, DPD France, DPD UK, DPD Belgium, DPD Poland, DPD Estonia,
          DPD Latvia, DPD Croatia, DPD Slovenia and DPD Switzerland) and Geopost
          will gradually roll it out to its shippers in the course of 2023,
          covering by the end of 2023 the company’s 20 largest business units in
          Europe[3]
        </Body>
        <Quotation>
          <Quotee>
            Commenting on this key announcement for the company, Jean-Claude
            Sonet, Executive Vice President, in charge of Marketing,
            Communications and Sustainability at Geopost, says:
          </Quotee>
          “At Geopost, we have proven since 2012 our expertise in innovating for
          sustainable outcomes by constantly improving how we quantify and
          report on carbon emissions to the benefit of our customers. I firmly
          believe that our Carbon Calculator is a perfect example of this. It is
          a pioneering tool, built in line with international carbon accounting
          standards, designed to help businesses meet the need for accurate,
          regular CO2 emissions reporting – all while helping them make more
          sustainable decision making in the long-term.”
        </Quotation>
        <Header2>
          <Bold>
            Growing expectations from customers and consumers on sustainability
          </Bold>
        </Header2>
        <Body>
          For both brands and final consumers, sustainability and transparency
          have become major expectations throughout the value chain. As
          Geopost’s “European e-shoppers” study shows, consumers are thinking
          more than ever about the impact of their purchases on their local
          communities and their social and environmental responsibility. 64% of
          regular e-shoppers are willing to go to a different website to find a
          more sustainable delivery option. Yet, the same study also reveals
          that e-shoppers largely feel that sustainability is the responsibility
          of brands and companies.
        </Body>
        <Body>
          In this context, shippers need to measure carbon emissions across
          their transport networks so they can reduce emissions where it matters
          most. They require accurate scope 3 emissions data from their
          transport service providers to accurately consolidate, compare and
          reduce carbon emissions. In addition, they also need to provide
          reliable and transparent data to their consumers. Geopost, in line
          with its ambition to become the international reference in sustainable
          delivery, has developed, with the support of its Sustainability,
          Operations and IT teams, an industry-leading carbon quantification and
          reporting tool, the Carbon Calculator.
        </Body>
        <Header2>
          <Bold>
            Growing expectations from customers and consumers on sustainability
          </Bold>
        </Header2>
        <Body>
          For both brands and final consumers, sustainability and transparency
          have become major expectations throughout the value chain. As
          Geopost’s “European e-shoppers” study shows, consumers are thinking
          more than ever about the impact of their purchases on their local
          communities and their social and environmental responsibility. 64% of
          regular e-shoppers are willing to go to a different website to find a
          more sustainable delivery option. Yet, the same study also reveals
          that e-shoppers largely feel that sustainability is the responsibility
          of brands and companies.
        </Body>
        <Body>
          In this context, shippers need to measure carbon emissions across
          their transport networks so they can reduce emissions where it matters
          most. They require accurate scope 3 emissions data from their
          transport service providers to accurately consolidate, compare and
          reduce carbon emissions. In addition, they also need to provide
          reliable and transparent data to their consumers. Geopost, in line
          with its ambition to become the international reference in sustainable
          delivery, has developed, with the support of its Sustainability,
          Operations and IT teams, an industry-leading carbon quantification and
          reporting tool, the Carbon Calculator.
        </Body>
        <Header2>
          <Bold>Sustainability at the core of Geopost strategy</Bold>
        </Header2>
        <Body>
          As the largest parcel delivery network in Europe, the company has
          experienced strong and rapid growth in recent years. Since 2012,
          Geopost has been taking meaningful action to improve the environmental
          performance of its network:
        </Body>
        <BulletPoints>
          <li>
            The company has been carbon neutral since 2012 and compensates all
            its residual transport and buildings emissions.
          </li>
          <li>
            The company is well on track to deliver to 350 cities in Europe (110
            million Europeans) with 100% low-emission delivery means by 2025 –
            through 15,000 low-emission delivery vehicles, 6,700 charging points
            and 250 urban depots.
          </li>
          <li>
            This will result in an 83% reduction of carbon emissions and a 95%
            reduction of air pollutants in targeted cities.
          </li>
          <li>
            pollutants in targeted cities. In addition, given the impact of air
            quality on people’s health, Geopost is partnering with Pollutrack to
            monitor air quality in 28 European cities.
          </li>
        </BulletPoints>
        <Body>
          In February 2023, the Science Based Targets initiative (SBTi) formally
          approved Geopost’s 2040 net zero targets. The company will contribute
          to limiting global temperature rise to 1.5°C by accelerating its
          greenhouse gas (GHG) emissions reduction plan: drastically reduce its
          scope 1, 2, 3 GHG emissions by 43% by 2030 and by 90% by 2040 compared
          to 2020. The company will reach net zero in 2040 by neutralizing all
          residual emissions (around 10%) through robust carbon offset projects.
        </Body>
        <img className="article-image" src={carbonScreenshot} />
        <Heading5>Key Definitions</Heading5>
        <Body>
          Scope [1] emissions are direct emissions from company-owned and
          controlled resources.
        </Body>
        <Body>
          Scope [2] emissions are indirect emissions from the generation of
          purchased energy.
        </Body>
        <Body>
          Scope [3] emissions are all indirect emissions – not included in scope
          2 – that occur in the value chain.
        </Body>
        <SmallPrint>(Source: GHG Protocol)</SmallPrint>
        <br />
        <Body>GLEC Framework</Body>
        <Body>
          Comparing greenhouse gas emissions (GHG) across different modes of
          transport can be like comparing apples with oranges. That’s why the
          Global Logistics Emissions Council (GLEC) developed the GLEC
          Framework: the only globally recognized methodology for harmonized
          calculation and reporting of the logistics GHG footprint across the
          multi-modal supply chain. It can be implemented by shippers, carriers
          and logistics service providers. Designed to inform business decisions
          and steer efforts to reduce emissions. It is in alignment with:
        </Body>
        <BulletPoints>
          <li>Greenhouse Gas Protocol</li>
        </BulletPoints>
        <SmallPrint>(Source: Smart Freight Centre)</SmallPrint>
        <br />
        <Body>----</Body>
        <Heading5>
          <Body>About Geopost</Body>
        </Heading5>
        <Body>
          Geopost, a European leader in parcel delivery and solutions for
          e-commerce, operates in 49 countries across all continents through its
          network of expert delivery brands, including: DPD, Chronopost, SEUR,
          BRT, Speedy and Jadlog. Leveraging its industry-specific expertise,
          Geopost is further growing the development of out-of-home delivery
          services with Pickup, the densest parcel shop and locker network in
          Europe and is also expanding temperature-controlled solutions for food
          and healthcare business. In a world of acceleration and
          transformation, Geopost engages and embraces new e-commerce
          territories with eShopWorld via Asendia, epicery and Pourdebon.com.
        </Body>
        <Body>
          With 122,000 employees and partners, Geopost endeavours to make
          commerce more convenient, profitable and sustainable for their
          customers and communities. Committed to becoming an international
          reference in sustainable delivery, Geopost is the first global
          delivery company to have its roadmap to Net Zero by 2040 approved by
          the Science Based Targets initiative (SBTi).
        </Body>
        <Body>
          Part of the La Poste Groupe, Geopost generated a €15.6 billion revenue
          and delivered 2.1 billion parcels worldwide in 2022.
        </Body>
        <SmallPrint>
          [1] The Carbon Calculator covers all relevant greenhouse gas (GHG)
          emissions, which are reported as CO2-equivalents (CO2e).
        </SmallPrint>
        <SmallPrint>
          [2] Smart Freight Centre: international non-profit organization
          focused on reducing greenhouse gas emission from freight
          transportation
        </SmallPrint>
        <SmallPrint>
          [3] Business Units covered: BRT (Italy), Chronopost (France), DPD
          Belgium, DPD Croatia, DPD Czech Republic, DPD Estonia, DPD France DPD
          Germany, DPD Hungary, DPD Ireland, DPD Latvia, DPD Lithuania, DPD
          Netherlands, DPD Poland, DPD Portugal, DPD Slovakia, DPD Slovenia, DPD
          Switzerland, DPD UK, SEUR (Spain).
        </SmallPrint>
      </>
    ),
  },
  {
    id: 54,
    date: "May 2023",
    dateTitle: "31 May 2023",
    category: "Fulfilment",
    country: "Spain",
    title:
      "SEUR awarded with the prize Best Service of the Year 2023 for its services",
    cardTitle:
      "SEUR awarded with the prize Best Service of the Year 2023 for its services",
    imgs: [img54],
    body: (
      <>
        <Heading4>
          The recognition, granted by the retail specialist “Comercio del año”,
          is the result of a survey in which 8,500 consumers from all over Spain
          participated
        </Heading4>
        <Body>
          SEUR, the leading company in express transport in Spain, has received
          the prize Best Service of the Year 2023 in the “Courier and Parcel”
          category in the contest organized by &apos;Comercio del año&apos;.
          Through the largest online consumer survey in Spain, in which 8,500
          users from all over the country have participated, satisfaction with
          brands has been measured and it has been determined that SEUR is the
          company preferred by customers when making their shipments.
        </Body>
        <Quotation>
          <Quotee>
            Karen Thouret, head of Digital, eCommerce and Customer Experience at
            SEUR, points out that:
          </Quotee>
          &quot;Being recognized by consumers as a brand of excellence in the
          provision of parcel and courier services is not only an honor, but the
          fruit of the demand and commitment of all SEUR employees towards our
          customers. An award that we dedicate to all those who are part of our
          company&quot;.
        </Quotation>
        <Body>
          <Bold>
            SEUR already has the seal of Best Service of the year 2023{" "}
          </Bold>
        </Body>
        <Body>
          Throughout this year, SEUR will have in its digital channels the seal
          of Best Service of the Year 2023. In this sense, the seal provides a
          differential value that allows SEUR to differentiate itself from other
          companies while being a symbol of trust and quality for current and
          potential customers.
        </Body>
        <Body>
          The award recognizes, therefore, the wide range of specific and
          personalized services offered by SEUR; from SEUR frío, the solution
          for shipments of fresh products at controlled temperature, to the Out
          of Home solutions, aimed at covering the e-commerce segment with the
          Pickup network at the head, which currently has more than 4,000 points
          between convenience stores and lockers . An option that allows the
          user to pick up their package whenever and wherever they want, even on
          weekends and that reduces CO2 emissions associated with last mile
          services by 63%.
        </Body>
        <Body>
          In this sense, the emissions reduction and protection of the planet is
          part of SEUR’s sustainability strategy, launched since 2013, and whose
          objective is to make the most sustainable company in the transport and
          logistics sector through actions, among others, such as implementing a
          distribution by 2025 with low emission vehicles in 64 Spanish cities
          with more than 50,000 inhabitants. An initiative that will involve a
          reduction of around 85% of CO2 emissions in these cities and that will
          have a direct impact on 17 million inhabitants throughout Spain.
        </Body>
        <Header3>
          <Bold>About SEUR</Bold>
        </Header3>
        <Body>
          Our 80 years of history have allowed us to be pioneers in urgent
          transport in Spain and lead the sector with three main business axes:
          international, e-commerce and the cold service focused on online food.
        </Body>
        <Body>
          Thanks to our 10,000 professionals and our fleet of 6,500 vehicles, we
          serve companies of all sizes and sectors, and as part of Geopost, one
          of the largest international express transport networks, we deliver
          worldwide.
        </Body>
        <Body>
          We constantly invest in innovation and infrastructures to be closer to
          our customers and offer them greater flexibility through solutions
          such as Predict, an interactive system to arrange delivery, or SEUR
          Now, for super urgent deliveries in one or two hours. We are committed
          to sustainable logistics with the integration of alternative delivery
          systems in large cities such as the use of ecological vehicles, urban
          hubs or our network of Pickup points with more than 4,000 convenience
          stores and lockers.
        </Body>
        <Body>For more information:</Body>
        <Body>
          <AnchorLink
            to="https://www.facebook.com/FundacionSEUR"
            externalLink={true}
            linkColor={variables.linkColor}
          >
            https://www.facebook.com/FundacionSEUR
          </AnchorLink>
        </Body>
        <Body>
          <AnchorLink
            to="https://twitter.com/SEUR"
            externalLink={true}
            linkColor={variables.linkColor}
          >
            https://twitter.com/SEUR
          </AnchorLink>
        </Body>
        <Body>
          <AnchorLink
            to="http://www.linkedin.com/company/SEUR"
            externalLink={true}
            linkColor={variables.linkColor}
          >
            http://www.linkedin.com/company/SEUR
          </AnchorLink>
        </Body>
        <Body>
          <AnchorLink
            to="https://www.instagram.com/seur.es/"
            externalLink={true}
            linkColor={variables.linkColor}
          >
            https://www.instagram.com/seur.es/
          </AnchorLink>
        </Body>
      </>
    ),
  },
  {
    id: 53,
    date: "May 2023",
    dateTitle: "22 May 2023",
    category: "Fulfilment",
    title: "Geopost and Tasa Logística announce the creation of DPD Argentina",
    cardTitle:
      "Geopost and Tasa Logística announce the creation of DPD Argentina",
    imgs: [img53],
    body: (
      <>
        <Bold>
          <BulletPoints>
            <li>
              Hand in hand with TASA Logística, a family company specialized in
              comprehensive logistics solutions for mass consumption, Geopost
              sets foot in Argentina to deploy its expertise throughout a new
              joint-venture.
            </li>
            <li>
              In a context of strong growth for the e-commerce sector in
              Argentina (73% in H1 2022 vs H1 2021[1]), the new DPD Argentina
              joint venture intends to seize regional development opportunities,
              by bringing the experience of the European leader in last-mile
              delivery for B2B and B2C markets.
            </li>
          </BulletPoints>
        </Bold>
        <Normal>
          Through an alliance with the local company TASA Logística, a family
          company specialized in comprehensive logistics solutions for mass
          consumption and founded in 1936, with operations in Argentina, Chile,
          Paraguay and Uruguay; Geopost sets foot in Argentina to offer
          last-mile logistics solutions by creating DPD Argentina.
        </Normal>
        <Quotation>
          <Quotee>
            Commenting on this announcement, Yves Delmas, CEO of Geopost, said:
          </Quotee>
          “Within this new joint-venture, we are bringing our experience as the
          leading parcel operator in Europe to the recognized logistics
          expertise of Tasa Logistica. We today aim to seize the great
          opportunities of the last-mile delivery sector of Argentina, which is
          pulled by the very dynamic growth of e-commerce, to offer robust
          solutions to the customers of the region. As a second step, we
          anticipate pursuing our business development in the region.”
        </Quotation>
        <Quotation>
          “Our goal is to be a top last-mile player in Argentina in the next 5
          years,”
          <Quotee>
            said Javier Mazzarella, Country Manager of DPD Argentina.
          </Quotee>
          “Online sales channels continue to develop and the momentum of
          e-commerce in Argentina is very strong. The 2020 pandemic has
          profoundly changed the sector and installed new uses, anchoring the
          importance of e-commerce. Home delivery continues to gain ground. This
          is one of the main segments we want to cover with DPD Argentina,
          providing an excellent service”.
        </Quotation>
        <Header3>
          A dynamic business ramp-up based on Geopost’s successful LatAm journey
        </Header3>
        <Quotation>
          “We are relying on a successful journey in LatAm, started with Jadlog
          in Brazil in 2017”
          <Quotee>
            comments Olivier Establet, Executive Vice President of Geopost, in
            charge of Americas.{" "}
          </Quotee>
          “With our DPD Argentina journey, more particularly, we are looking
          forward to experiencing our Jadlog success story of constant growth[1]
          within e-commerce in Brazil, a sector which is becoming more strategic
          every day, and which confirmed our expectations when we started
          there”.
        </Quotation>
        <Normal>
          The business project will directly answer the customers’ needs, as
          emphasized Mazzarella:
        </Normal>
        <Quotation>
          “We have planned a progressive national rollout to complete the value
          chain with the last mile delivery. Home services are clearly a first
          step for 2023 as we know it is the preferred delivery spot (67% of
          total[2]). Customers demand real-time info on delivery, multiple
          delivery options and of course reliable deliveries, and this is where
          DPD experience will make the difference, especially for tight time
          deliveries. But we also plan on developing our services offers to
          reach global standards based on DPD’s recognized business services
          such as Out-of-home deliveries or everywhere in Argentina.”
        </Quotation>
        <Header3>DPD Argentina: strong objectives for sustainability</Header3>
        <Normal>
          DPD Argentina resolutely endorses Geopost commitment for sustainable
          delivery. The sustainability roadmap built for the new DPD member of
          DPD global network in Argentina combines innovative technology and
          local knowledge to provide a flexible and easy-to-use service for both
          sellers and buyers with a strong focus on sustainability impact for
          every delivery.
        </Normal>
        <Normal>
          With a positive result of CO2 emissions reduction (-14% carbon
          emissions per delivery in 2019 compared to 2013), TASA Logística
          intends to accelerate the move with DPD Argentina via the acquisition
          of an electric fleet, a first for Argentina. The roadmap also relies
          on infrastructure, as the Distribution Center is to be fully equipped
          with charging stations and solar panels.
        </Normal>
        <Quotation>
          “A cooperative approach is set up to benefit from the group’s
          innovative approach to sustainability in all domains, from low
          emissions delivery to employees and via relations with communities”
          <Quotee>said Javier Mazzarrella.</Quotee>
        </Quotation>
        <Header3>Portfolio of trusted services to come</Header3>
        <Normal>
          The new player wishes to enhance the B2B activity and provide them in
          a near-term with the renowned and best-selling DPD services for
          business which will include Return my parcel. Customers will also
          benefit from the robust digital services of Geopost, starting with
          myDPD for business, a business portal that helps shippers to manage
          and monitor their shipments.
        </Normal>
        <Normal>
          End-consumers will also benefit of myDPD for consumers, an app
          designed to get the list of parcels and directly track them online.
        </Normal>
        <Quotation>
          <Quotee>
            Concluding, Facundo Casillas, Executive Director of TASA Logística
            underlined:
          </Quotee>
          “We are happy to be able to share this major announcement regarding
          our alliance with the Geopost team. Our new company benefits from both
          the best last-mile logistics expertise in Europe and our knowledge of
          the local market, offering the best delivery service for e-commerce.
          Our logistics group now operates one of the highest investment
          dynamics in the country, and we continue on the path of growth by
          developing at the regional level. In addition, we share the same
          values ​​of sustainability and social inclusion, which are among our
          main objectives”
        </Quotation>
        <Header3>About Geopost</Header3>
        <Normal>
          Geopost, a European leader in parcel delivery and solutions for
          e-commerce, operates in 49 countries across all continents through its
          network of expert delivery brands, including: DPD, Chronopost, SEUR,
          BRT, Speedy and Jadlog. Leveraging its industry-specific expertise,
          Geopost is further growing the development of out-of-home delivery
          services with Pickup, the densest parcel shop and locker network in
          Europe and is also expanding temperature-controlled solutions for food
          and healthcare business. In a world of acceleration and
          transformation, Geopost engages and embraces new e-commerce
          territories with eShopWorld via Asendia, epicery and Pourdebon.com.
        </Normal>
        <Normal>
          With 122,000 employees and partners, Geopost endeavours to make
          commerce more convenient, profitable and sustainable for their
          customers and communities. Committed to becoming an international
          reference in sustainable delivery, Geopost is the first global
          delivery company to have its roadmap to Net Zero by 2040 approved by
          the Science Based Targets initiative (SBTi).
        </Normal>
        <Normal>
          Part of the La Poste Groupe, Geopost generated a €15.6 billion revenue
          and delivered 2.1 billion parcels worldwide in 2022.
        </Normal>
        <SmallPrint>
          [1] According to a recent report from the CACE (Argentine Chamber of
          Electronic Commerce), e-commerce in Argentina grew by 73% during the
          first half of 2022 compared to the first half of 2021, with 14% more
          purchase orders, reaffirming that the online sales channels continue
          to grow.
        </SmallPrint>
        <SmallPrint>
          [2] Since this entry into the capital in 2017, Jadlog has developed
          its activity by 50% per year, thrusting the company to the position of
          third player of last-mile delivery market in Brazil.
        </SmallPrint>
        <SmallPrint>[3] According to a recent report from the CACE</SmallPrint>
      </>
    ),
  },
  {
    id: 52,
    date: "May 2023",
    dateTitle: "22 May 2023",
    category: "Sustainability",
    title:
      "In May, Geopost tackles sustainability at The Global Mobility Executive Forum and Change NOW",
    cardTitle:
      "Geopost tackles sustainability at The Global Mobility Executive Forum and Change NOW",
    imgs: [img52],
    body: (
      <>
        <Header3>
          The Global Mobility Executive Forum: Smart Coalitions for Sustainable
          Mobility
        </Header3>
        <Normal>
          <AnchorLink
            to="https://www.oliverwymanforum.com/events/the-global-mobility-executive-forum-2023.html?utm_source=exacttarget&utm_medium=email&utm_campaign=ready-or-not-30&utm_content=26-mar-2023&utm_id=cmp-15059-f8j2d1"
            externalLink={true}
            linkColor={variables.linkColor}
          >
            The Global Mobility Executive Forum
          </AnchorLink>
          &nbsp;convenes senior leaders from business, academia, and financial,
          public, and social institutions to build the future of mobility
          together.
        </Normal>
        <Normal>
          Organized by Oliver Wyman, this 7th edition will take place at Station
          F in Paris on May 23rd and May 24th.
        </Normal>
        <Normal>
          Yves Delmas, CEO of Geopost, will join a panel discussion on May 23rd
          entitled “The road towards sustainable mobility” and will discuss
          about Geopost’s ambitions and biggest challenges of sustainable
          delivery.
        </Normal>

        <Header3>Change NOW</Header3>
        <Normal>
          Change NOW is a 3-day summit (May 25th-27th, in Paris) that brings
          together the most innovative solutions and change-makers who are
          tackling our planet&apos;s biggest challenges to take action together.
        </Normal>
        <Normal>
          It also connects entrepreneurs, business leaders and policy makers to
          accelerate change.
        </Normal>
        <Normal>
          On May 25th, Jean-Claude Sonet, Executive Vice President in charge of
          Marketing, Communication & Sustainability, will discuss with&nbsp;
          <AnchorLink
            to="https://www.linkedin.com/checkpoint/challengesV2/AQE19Rhbih4WbAAAAYh2QcJIpwEqEj0ZmPeBkNhj0xZp5qtdpsBQ55d6efQFKARwmmFB2NrRG4O5QIx2C28yfdQvg9OAFEutSg?original_referer=https%3A%2F%2Fwww.geopost.com%2F"
            externalLink={true}
            linkColor={variables.linkColor}
          >
            Emma RECCO
          </AnchorLink>
          &nbsp;- Strategy & Development, Sustainability Director at Ikea
          and&nbsp;{" "}
          <AnchorLink
            to="https://www.linkedin.com/in/david-holderbach-36b4926/?originalSubdomain=fr"
            externalLink={true}
            linkColor={variables.linkColor}
          >
            David Holderbach
          </AnchorLink>
          &nbsp;– CEO at Hyvia about “Rethinking mobility & transport”.
        </Normal>
        <Normal>
          For more Sustainability initiatives visit&nbsp;
          <AnchorLink
            to="https://international.dpd.co.uk/about/sustainability"
            externalLink={true}
            linkColor={variables.linkColor}
          >
            https://international.dpd.co.uk/about/sustainability
          </AnchorLink>
        </Normal>
      </>
    ),
  },
  {
    id: 51,
    date: "May 2023",
    dateTitle: "17 May 2023",
    category: "Sustainability",
    title:
      "Ninja Xpress now holds two ISO Certifications, a demonstration of the company’s commitment towards globally recognised standards of service quality and operational excellence",
    cardTitle:
      "Ninja Xpress now holds two ISO Certifications, recognised standards of service quality and operational excellence",
    imgs: [img51],
    body: (
      <>
        <Normal>
          Ninja Xpress, a leading technology-based delivery service company in
          Indonesia, continues to strive to improve service quality by
          implementing international standard quality management. For these
          efforts, Ninja Xpress has achieved two important certifications,
          namely International Organization for Standardization (ISO) 9001: 2015
          certification in the field of warehousing quality management services
          and ISO 45001: 2018 related to the implementation of Occupational
          Health and Safety (K3) procedures.
        </Normal>
        <Normal>
          The quality management principles in ISO 9001: 2015 consist of seven
          focus areas, including customer focus, leadership, engagement of
          people, process approach, improvement, evidence based decision making,
          relationship management. Meanwhile, ISO 45001:2018 is not limited to
          ensuring the safety and health of workers, but has the aim of
          increasing work productivity and comfort at work.
        </Normal>
        <Normal>
          With Ninja Xpress services that have spread from Aceh to Papua, it is
          important to maintain and improve the quality of on-time delivery
          services, while still paying attention to the safety of human
          resources. For this reason, an ISO certificate is needed to ensure
          that the services provided by Ninja Xpress meet applicable standards
          and are recognized in Indonesia and internationally.
        </Normal>
        <Normal>
          Both certifications show that Ninja Xpress has an international
          standard quality management system and implements an Occupational
          Health Safety management system. They are a testament to Ninja
          Xpress’s ability to provide the best quality service to maintain
          customer satisfaction. Ninja Xpress will continue to improve in terms
          of employee knowledge so that they can follow and understand the
          various Standard Operating Procedures (SOPs) and work standards that
          have been made. In addition, Ninja Xpress will also improve
          infrastructure related to Operational Health and Safety (OHS) and
          ensure that the quality management system and OHS implementation go
          hand in hand.
        </Normal>
        <Quotation>
          <Quotee>
            Rista Damonty, SVP of Business Process Excellence & Standardization
            of Ninja Xpress explained
          </Quotee>
          &quot;Our growing business requires the same standardization to ensure
          Ninja Xpress’s quality and service throughout Indonesia is maintained.
          In addition, we also want to create a healthy work environment and
          minimize the risks arising from health and safety management. In the
          future, we hope it will be easier to maintain service quality and
          ensure the safety of human resources to ensure productivity and
          customer satisfaction.&quot;
        </Quotation>
        <Normal>
          The ISO 9001:2015 and ISO 45001:2018 certificates were obtained by
          Ninja Xpress simultaneously in March 2023. To obtain both ISO
          certifications, Ninja Xpress has carried out a series of audit
          activities and Ninja Xpress&apos; management system is considered to
          be in accordance with the scope of certification. In addition, to
          continue to ensure that management is in accordance with standards,
          Ninja Xpress also routinely conducts internal audits.
        </Normal>
        <Quotation>
          <Quotee>
            Andi Djoewarsa, Chief Marketing Officer (CMO) of Ninja Xpress said,
          </Quotee>
          &quot;With a commitment to be ready to deliver to the destination,
          Ninja Xpress not only prioritizes customer satisfaction, but also
          prioritizes the protection of our employees’ safety and health. With
          the achievement of two ISO certifications, we also hope that Ninja
          Xpress can continue to expand its business in various sectors.&quot;
        </Quotation>
      </>
    ),
  },
  {
    id: 50,
    date: "May 2023",
    dateTitle: "16 May 2023",
    category: "Fulfilment",
    title:
      "Stuart partners with waste management company First Mile to improve delivery efficiency",
    cardTitle:
      "Stuart partners with waste management company First Mile to improve delivery efficiency",
    imgs: [img46],
    body: (
      <>
        <Bold>
          <BulletPoints>
            <li>
              Stuart will assist First Mile in expanding its sustainable waste
              and recycling management solutions to businesses throughout London
            </li>
            <li>
              The first two months of the partnership have seen a 300% increase
              in deliveries
            </li>
          </BulletPoints>
        </Bold>
        <Normal>
          Leading last-mile logistics company,&nbsp;
          <AnchorLink
            to="https://stuart.com/"
            externalLink={true}
            linkColor={variables.linkColor}
          >
            Stuart
          </AnchorLink>
          , has partnered with sustainable recycling and waste management
          services company,&nbsp;
          <AnchorLink
            to="https://www.thefirstmile.co.uk/"
            externalLink={true}
            linkColor={variables.linkColor}
          >
            First Mile
          </AnchorLink>
          . The collaboration will utilise Stuart’s Scheduled from Hub model
          which consists of a final mile delivery offering including same day
          and next day services via a fleet of 100% electric vehicles. As a
          result, the partnership will aim to improve delivery efficiency,
          reduce carbon emissions, and ensure the best possible service for
          First Mile customers in London.
        </Normal>
        <Normal>
          As part of its goal to reduce the climate impact of production and
          waste disposal, by improving waste management and moving materials
          into a circular economy, First Mile has committed to decarbonising its
          operations over the next five years. As part of this commitment, it
          has partnered with Stuart, which places sustainability at its heart,
          to support First Mile’s pick-up and delivery services throughout
          Greater London.
        </Normal>
        <Normal>
          Previously, the company has taken steps to reduce carbon emissions by
          using more electric cargo bikes and vehicles, and by making collection
          and delivery rounds more efficient. Partnering with Stuart for
          deliveries further afield will enable First Mile to improve route
          efficiencies and further reduce carbon emissions.
        </Normal>
        <Normal>
          In the first two months since the two businesses started working
          together, not only has the partnership seen a 300% increase* in
          package delivery, but 97% of these deliveries have been made on time
          within the precise window communicated to customers.
        </Normal>
        <Quotation>
          “The success of this partnership in just over eight weeks is testament
          to our advanced logistics technology, said Marcus Hurd, Director of
          Stuart’s Scheduled from Hub operations. &quot;This, coupled with First
          Mile&apos;s waste management expertise, will ensure the provision of a
          more efficient and sustainable delivery service for businesses across
          the UK.&quot;
          <Quotee>Bruce Bratley, CEO and founder, First Mile said</Quotee>
        </Quotation>
        <Quotation>
          “Stuart is a key partner in helping us to deliver on our commitment to
          reduce the carbon impact of our operational platform to zero. We are
          delighted to be working with Stuart, which has moved swiftly to
          integrate our recycling and waste bags into its fulfilment system,
          helping us reduce our impact.”
        </Quotation>
        <Header3>About Stuart</Header3>
        <Bold>
          <BulletPoints>
            <li>
              Stuart is a tech logistics company that has revolutionised urban
              delivery since it was founded in Paris in 2015. Committed to
              providing logistics for a sustainable world, Stuart connects
              businesses of all sizes to high quality couriers to offer
              industry-leading delivery built on precision, speed and
              flexibility. This is powered by innovative logistics technology
              and operational expertise which allows global retailers and local
              businesses to meet their customers’ needs for on-demand, same day
              and next day delivery.
            </li>
            <li>
              Stuart currently operates millions of deliveries every month
              across over 150 cities in the UK, France, Spain, Portugal, Poland
              and Italy. Stuart is a subsidiary of Geopost. Find out more on{" "}
              <AnchorLink
                to="https://stuart.com/"
                externalLink={true}
                linkColor={variables.linkColor}
              >
                stuart.com
              </AnchorLink>
            </li>
          </BulletPoints>
        </Bold>
        <Header3>About First Mile </Header3>
        <Normal>
          From a handful of customers on one street in central London in 2004,
          to over 30,000 today, First Mile has served UK businesses for almost
          20 years and continues to grow rapidly. First Mile is at the forefront
          of the waste industry, investing in technologies and continually
          innovating to transform how businesses recycle, manage waste and
          reduce their impact on the environment. We provide and develop the
          best possible services to help businesses manage waste, with high
          operational excellence, and low carbon impact - making over five
          million collections every year with our ultra-low or zero emission
          fleet. First Mile makes it easy for businesses to reduce, recycle,
          track, and close the loop on waste. Find out more at{" "}
          <AnchorLink
            to="https://www.thefirstmile.co.uk/"
            externalLink={true}
            linkColor={variables.linkColor}
          >
            https://thefirstmile.co.uk/
          </AnchorLink>
        </Normal>
        <Normal>
          Read our 2022 Sustainability Report:{" "}
          <AnchorLink
            to=": https://thefirstmile.co.uk/sustainability-report-2022"
            externalLink={true}
            linkColor={variables.linkColor}
          >
            https://thefirstmile.co.uk/sustainability-report-2022
          </AnchorLink>
        </Normal>
      </>
    ),
  },
  {
    date: "May 2023",
    dateTitle: "2 May 2023",
    category: "e-Commerce",
    country: "United Kingdom",
    link: "https://www.geopost.com/en/expertise/e-shoppers/?utm_campaign=eshopper-barometer-2023&utm_medium=social.post&utm_source=twitter",
    cardTitle:
      "New Geopost Barometer reveals that purchases of medicine, health & beauty products increased in 2022.",
    imgs: [img50],
  },
  {
    id: 49,
    date: "April 2023",
    dateTitle: "28 March 2023",
    category: "Fulfilment",
    country: "United Kingdom",
    title: "DPD Poland awarded the title of Poland's Best Employers by Forbes",
    cardTitle:
      "DPD Poland awarded the title of Poland's Best Employers by Forbes",
    imgs: [img49],
    body: (
      <>
        <Header2>
          <Bold>
            DPD Poland has been awarded Poland&apos;s Best Employers by Forbes
            magazine for the third time, taking the 3rd place on the podium in
            the Transport and Logistics category. Forbes magazine&apos;s
            Poland&apos;s Best Employers is an annual plebiscite in which awards
            are given to companies for above-average achievements in HR.
          </Bold>
        </Header2>
        <Normal>
          The basis for this year&apos;s survey was a list of about 1,900
          employers with at least 250 employees in Poland. The winners were
          selected on the basis of anonymous questionnaires filled out by
          employees of the organization. The survey collected more than 190,000
          individual employer evaluations.
        </Normal>
        <Quotation>
          &quot;It is in our company&apos;s DNA to create a welcoming, safe and
          diverse workplace in a spirit of partnership, where people are the
          foundation of deeply held values and mutual respect. The last few
          years related to the pandemic and the geopolitical situation have
          challenged us, and at the same time taught us a well-done lesson on
          the way to creating an even stronger DPD community. Being awarded for
          the third time in the Poland&apos;s Best Employers by Forbes poll is
          for us a confirmation that our HR strategy is right, and that the
          workplaces we create generates real value for our employees, and thus
          also for our customers.&quot; -
          <Quotee>
            comments Marta Westrych-Andrzejczyk, HR Director of DPD Poland and
            Member of the Management Board.
          </Quotee>
        </Quotation>
        <Normal>
          DPD Poland, member of Geopost, employs more than 4,500 people and
          works with more than 9,000 couriers in 61 branches across Poland. The
          company offers extensive professional development opportunities and a
          range of benefits for the well-being of its employees, as well as
          internal mentoring and support initiatives aimed at the DPD Poland
          community. The award is a success that stems from the daily commitment
          of all employees and co-workers based on DPD&apos;s 5 values - people,
          quality, responsibility, commitment, development. The effectiveness of
          DPD Poland&apos;s HR strategy has also been confirmed by other
          certificates, including the &quot;HR of the Highest Quality&quot; of
          PSZK, the &quot;Great Place to Work&quot; award, and the best employer
          of the year 2022 in the TSL industry of the Rzeczpospolita newspaper.
        </Normal>
      </>
    ),
  },
  {
    id: 48,
    date: "April 2023",
    dateTitle: "27 April 2023",
    country: "United Kingdom",
    title:
      "DPD has this month launched its latest international delivery solution - DPD Direct Lite, which offers easy and reliable worldwide shipping with tracking and customs handling built-in.",
    cardTitle:
      "DPD UK Launches DPD Direct Lite - an extension of our tracked worldwide service",
    imgs: [img48],
    navImg: img48,
    body: (
      <>
        <Normal>
          DPD Direct Lite is an extension of our tracked worldwide service that
          provides you with even more choice and convenience.
        </Normal>
        <Normal>
          With DPD Direct Lite, we cover parcels up to 2kgs and have strong
          partnerships with local postal operators to provide wide coverage,
          cost-effective, and reliable delivery. Our service also offers export,
          import, and delivery tracking, providing peace of mind and assurance
          that parcels are on their way. You can choose to have your parcel
          delivered directly to a mailbox or conveniently collected from local
          post offices.
        </Normal>
        <Normal>
          Our customs handling is easy and flexible, and we offer the added
          advantage of handling goods shipped with or without IOSS registration
          for your European orders.
        </Normal>
        <Normal>
          At DPD, we care about your business and your plans for international
          expansion. With our ever-expanding range of global delivery options,
          we aim to have every solution your business could ever need to boost
          those exports.
        </Normal>
        <Normal>
          <Bold>
            If you would like to learn more about our DPD Direct Lite service or
            have any questions please get in touch or visit &nbsp;
            <AnchorLink
              to={"http://international.dpd.co.uk/services/dpd-direct"}
              externalLink={true}
              linkColor={variables.linkColor}
            >
              http://international.dpd.co.uk/services/dpd-direct
            </AnchorLink>
          </Bold>
        </Normal>
      </>
    ),
  },

  {
    id: 47,
    date: "April 2023",
    dateTitle: "24 April 2023",
    category: "Sustainability",
    country: "Lithuania",
    title:
      "DPD Lithuania has been recognized as most sustainable brand in Courier sector",
    cardTitle:
      "DPD Lithuania has been recognized as most sustainable brand in Courier sector",
    imgs: [img47],
    body: (
      <>
        <Header2>
          <Bold>
            DPD Lithuania among the best reputed companies in Lithuania
          </Bold>
        </Header2>
        <Normal>
          In the published list of best reputed companies in Lithuania,
          &quot;DPD Lithuania&quot; has become the leader in the parcel delivery
          sector, and ranked 4th overall in the country&apos;s company rankings.
          This year, for the 8th time, the study included two more sectors: food
          production and parcel delivery services. In total, the reputation of
          51 companies was analyzed, including 11 new companies added to this
          year&apos;s study.
        </Normal>
        <Normal>
          The study is conducted by the consulting and business management
          service company &quot;Civitta&quot;, in collaboration with the
          communication agency &quot;Fabula Rud Pedersen Group&quot;, the
          Lithuanian Marketing Association LiMA, Investors&apos; Forum, the
          Lithuanian Startup Association &quot;Unicorns Lithuania&quot; and the
          data analysis company &quot;Repsense&quot;. The study analyzes the
          Lithuanian business, opinion leaders, and public&apos;s view of the
          country&apos;s largest companies, their activities, evaluates the
          companies&apos; reputation, and its change over the years. The
          reputation study surveys three different audiences: the general
          public, opinion leaders (experts, bloggers, journalists, business
          representatives, marketing specialists, etc.) and decision-makers
          (CEOs of the largest Lithuanian companies, business organizations or
          associations, media owners, politicians, ambassadors, etc.).
        </Normal>
        <Normal>
          We are pleased that our efforts to ensure high-quality service
          provision and at the same time reduce the impact of our activities on
          the environment are noticed and appreciated by both experts and the
          public. The first place in terms of reputation among the
          country&apos;s parcel delivery companies confirms that we are on the
          right track and motivates us to continue improving.
        </Normal>
        <Normal>
          <br></br>
          <Bold>Reputation Index Study:</Bold>
          <br></br>
          <br></br>🏆 1st place among parcel delivery companies;
          <br></br>🏆 2nd place in the general public opinion;
          <br></br>🏆 4th place in the overall index;
          <br></br>🏆 9th place in the opinion leaders&apos; ranking
        </Normal>
      </>
    ),
  },
  {
    id: 46,
    date: "March 2023",
    dateTitle: "30 March 2023",
    category: "e-Commerce",
    country: "United Kingdom",
    title:
      "New study shows delivery businesses brace for increasing demand and heightened expectations in the UK in 2023",
    cardTitle:
      "New study shows delivery businesses brace for increasing demand and heightened expectations in the UK",
    imgs: [img46],
    body: (
      <>
        <Normal>
          Research from one of Europe’s leading tech logistics companies,&nbsp;
          <AnchorLink
            to="https://stuart.com/"
            externalLink={true}
            linkColor={variables.linkColor}
          >
            Stuart
          </AnchorLink>
          , has revealed that, despite the cost-of-living crisis, demand for
          online delivery services looks set to increase in the UK in 2023. The
          survey, conducted by YouGov and polling over 2,100 adults and over
          2,000 business representatives, showed that far from diminishing –
          appetite for delivery services will likely grow, but that businesses
          will have to face up to key challenges to meet the demand.
        </Normal>
        <Normal>
          Despite one of the most difficult economic periods on record, nearly
          two-thirds (63%) of businesses polled reported seeing demand for
          delivery services increase in October, November and December 2022.
        </Normal>
        <Normal>
          The survey found that alongside an expected increase in demand,
          businesses will also face rising expectations from their customers.
          Businesses report that consumers will look for improved levels of
          service in the coming year: almost 2 out of 5 (38%) saying that they
          want faster delivery times, nearly a third (30%) saying that they
          would look for more precise time slot offerings with their deliveries
          and 1 in 4 (26%) expecting advanced tracking with their delivery
          service.
        </Normal>
        <Normal>
          Consumers do not seem to just be focused on customer service levels,
          however. Almost a third (30%) of business representatives also
          reported that they believe their customers want more sustainable
          options for deliveries. This was proven in Metapack’s Ecommerce
          Delivery Benchmark&nbsp;
          <AnchorLink
            to="https://drive.google.com/file/d/14h-EBbBd_Uc7hhqM8u-q9TCkvGMu2f_h/view"
            externalLink={true}
            linkColor={variables.linkColor}
          >
            Report
          </AnchorLink>
          &nbsp;2023, which showed that 28% more consumers considered green
          delivery options more important compared to 2023.
        </Normal>
        <Normal>
          These trends are also reflected in the consumers polled. Of the 2,100
          adults surveyed, nearly two-thirds of consumers (65%) intend to order
          takeaways and grocery delivery at least as often in 2023 as they did
          in 2022.
        </Normal>
        <Normal>
          Additionally, around a quarter (24%) of 18-24 year olds said that they
          intended on ordering even more products this year than they did in
          2022. As the demographic that already uses delivery services more than
          any other, this means even more demand for delivery services and the
          businesses they work with.
        </Normal>
        <Normal>
          If these trends continue throughout the year, it will mean sustained
          demand for a higher volume of quick, efficient and sustainable
          delivery solutions in 2023 and beyond.
        </Normal>
        <Quotation>
          &quot;2022 saw robust demand from retailers for delivery services, and
          that only looks set to grow in 2023. The challenge, however, is that
          businesses will have to step up to the plate to offer fast,
          sustainable and data-driven services, all against a backdrop of rising
          expectations on customer service.&quot;
          <Quotee>says John Gillan, General Manager of Stuart UK.</Quotee>
          &quot;Stuart is proudly at the forefront of cutting-edge innovation in
          this space, working with businesses to provide customised and entirely
          sustainable delivery solutions to meet their customers’ needs.&quot;
        </Quotation>
        <Header2>About Stuart</Header2>
        <Normal>
          Stuart is a tech logistics company that has revolutionised urban
          delivery since it was founded in Paris in 2015. Committed to providing
          logistics for a sustainable world, Stuart connects businesses of all
          sizes to high quality couriers to offer industry-leading delivery
          built on precision, speed and flexibility. This is powered by
          innovative logistics technology and operational expertise which allows
          global retailers and local businesses to meet their customers’ needs
          for on-demand, same day and next day delivery.
        </Normal>
        <Normal>
          Stuart currently operates millions of deliveries every month across
          over 150 cities in the UK, France, Spain, Portugal, Poland and Italy.
          Stuart is a subsidiary of Geopost. Find out more on&nbsp;
          <AnchorLink
            to="www.stuart.com"
            externalLink={true}
            linkColor={variables.linkColor}
          >
            www.stuart.com
          </AnchorLink>
        </Normal>
      </>
    ),
  },
  {
    id: 45,
    date: "March 2023",
    dateTitle: "29 March 2023",
    category: "e-Commerce",
    title:
      "Authenticity is now a key new driver of revenue and loyalty for global shoppers, according to research by Asendia",
    cardTitle:
      "Authenticity is now a key new driver of revenue and loyalty for global shoppers, according to research by Asendia",
    imgs: [img45],
    body: (
      <>
        <BulletPoints>
          <Bold>
            <li>
              67% of global shoppers spend more with brands they perceive to be
              authentic
            </li>
            <li>73% are more loyal to authentic retailers</li>
            <li>
              Shoppers’ spend in Hong Kong, France and Spain most influenced by
              authenticity
            </li>
          </Bold>
        </BulletPoints>
        <Normal>
          Authenticity is now a key new driver for revenue and loyalty among
          global shoppers as the trend for more mindful consumption continues,
          the latest research from&nbsp;
          <AnchorLink
            to="https://www.asendia.com/"
            externalLink={true}
            linkColor={variables.linkColor}
          >
            Asendia
          </AnchorLink>
          , the leader in international e-commerce and mail delivery solutions,
          reveals.
        </Normal>
        <Normal>
          Original research of over 8,000 global shoppers in Asendia’s&nbsp;
          <AnchorLink
            to="https://www.asendia.com/resource/how-to-sell-direct"
            externalLink={true}
            linkColor={variables.linkColor}
          >
            ‘How To Sell Direct In The Age Of The Conflicted Shopper’
          </AnchorLink>
          &nbsp;Report showed more than two thirds (67%) of global shoppers
          would spend more money with retailers they perceived to be authentic,
          with 59% saying they would only shop exclusively with authentic retail
          brands.
        </Normal>
        <Normal>
          The key values defining authenticity for global shoppers were: being
          straightforward on delivering promises (58%); transparency within
          supply chains (43%); standing up for sustainability (39%); clear brand
          values (39%); and transparency with supplier relationships (34%).
        </Normal>
        <Header3>
          Authenticity – the new currency for conversion, spend and loyalty
        </Header3>
        <Normal>
          Seventy three percent (73%) of global shoppers also said authenticity
          made them more loyal to brands, with a further 65% saying they would
          switch to a competitor if they felt a retailer wasn’t authentic.
          Shoppers in Hong Kong (73%), France and Spain (both 72%) proved the
          most likely to switch brand allegiances, while shoppers in Hong Kong
          (80%) and the United States (75%) were the consumers whose spend
          propensity was the most impacted by a brand’s authenticity.
        </Normal>
        <Normal>
          In the context of economic global headwinds and the rising
          cost-of-living putting extra pressure on household budgets and
          discretionary spend, authenticity is also helping retailers fend off
          rising price-sensitivity among shoppers. While a third of global
          shoppers plan to cut back on the volume of things they buy in 2023,
          almost half (48%) said a brand’s authenticity would make them less
          sensitive to inflationary price increases. However, this dipped to 43%
          of shoppers in the UK,&nbsp;
          <AnchorLink
            to="https://www.bbc.co.uk/news/business-64637705"
            externalLink={true}
            linkColor={variables.linkColor}
          >
            where inflation remains close to a 40-year high
          </AnchorLink>
          .
        </Normal>
        <Quotation>
          <Quotee>
            Renaud Marlière, Global Chief of Business Development of Asendia,
            commented:
          </Quotee>
          “Of course, we see that shoppers are acting with caution due to the
          rising cost-of-living, but invariably they are also consuming
          consciously and mindfully. Shoppers now want to engage and buy from
          brands who act authentically. Increasingly, shoppers are holding the
          retailers and brands they shop with to account - and it is clear that
          authenticity is now having a significant impact on share of wallet,
          revenue and loyalty.” “Brands and retailers need to take control of
          their own destiny when it comes to shaping the authentic shopping
          journeys consumers are now demanding. Shoppers increasingly expect
          accountability and transparency from retailers, whether through
          greater transparency around supply chain emissions, delivery promises
          or offering low- or carbon-neutral shipping options,”
          <Quotee>Renaud Marlière continued.</Quotee>
        </Quotation>
        <Normal>
          In 2022 Asendia announced it had&nbsp;
          <AnchorLink
            to="https://www.asendia.co.uk/asendia-insights/asendia-is-100-carbon-neutral"
            externalLink={true}
            linkColor={variables.linkColor}
          >
            reached 100% carbon neutrality
          </AnchorLink>
          &nbsp;through its carbon offsetting projects. It now offers carbon
          neutrality across all shipments carried out for international retail
          customers via its e-PAQ solution, a specialist range of international
          packet and parcel services designed for online retailers.
        </Normal>
        <Header3>
          Channel matters – the impact of DTC on authenticity perception
        </Header3>
        <Normal>
          Overwhelmingly, global shoppers feel brands that operated
          Direct-To-Consumer (DTC) were more authentic than those retailers who
          just operated via marketplaces. Over half (56%) of global shoppers
          felt that retailers that operated their own DTC channels were more
          authentic, rising to 68% of consumers in Hong Kong and 60% in both
          Spain and the U.S.
        </Normal>
        <Normal>
          Combining global presence with local expertise, Asendia empowers
          online retailers to grow their cross-border operations by improving
          international shopping experiences. As well as its&nbsp;
          <AnchorLink
            to="https://www.asendia.com/e-paq"
            externalLink={true}
            linkColor={variables.linkColor}
          >
            international parcel services
          </AnchorLink>
          &nbsp;and&nbsp;
          <AnchorLink
            to="https://www.asendia.com/order-fulfilment"
            externalLink={true}
            linkColor={variables.linkColor}
          >
            fulfilment solutions
          </AnchorLink>
          , it also offers sophisticated digital e-commerce platforms and
          solutions, including ESW, that deliver the seamless experiences online
          shoppers want when shopping DTC across borders, wherever they are
          located around the globe.
        </Normal>
        <Normal>
          For further information on optimising cross-border strategies for
          success and how brands can build international consumer relationships
          that better meet the needs of today’s shoppers, download the full
          report:
          <AnchorLink
            to="https://www.asendia.com/resource/how-to-sell-direct"
            externalLink={true}
            linkColor={variables.linkColor}
          ></AnchorLink>
          .
        </Normal>
        <Normal>
          Asendia is committed to putting the customer at the centre of
          everything it does, which is why it stands for trust, friendliness and
          ease of use as the key pillar representing its core values.
        </Normal>
        <Header2>Notes to editors</Header2>
        <Header3>Research methodology</Header3>
        <Normal>
          Original research of over 8,000 global shoppers in the UK, U.S.,
          Canada, Germany, France, Spain, Switzerland and Hong Kong. Each
          individual market polled had a sample size of over 1,000 shoppers.
        </Normal>
        <Normal>
          The research was conducted via online polling by Savanta on behalf of
          Asendia in February 2023.
        </Normal>
        <Header3>About Asendia</Header3>
        <Normal>
          <AnchorLink
            to="https://www.asendia.com"
            externalLink={true}
            linkColor={variables.linkColor}
          >
            Asendia
          </AnchorLink>
          &nbsp;is one of the world&apos;s leaders in international e-commerce
          and mail, delivering packages, parcels and documents to more than 200
          destinations across the globe.
        </Normal>
        <Normal>
          Combining the experience and expertise of our founding companies, La
          Poste and Swiss Post, Asendia brings together a wealth of
          international and local know-how. Asendia&apos;s expertise is broad
          reaching encompassing different aspects of e-commerce, from webshop
          software and marketplace management to international logistics.
          Asendia is committed to sustainability, offsetting all carbon
          emissions including first-mile collection, last-mile delivery,
          returns, and building emissions, machinery, and business travel.
        </Normal>
        <Normal>
          Asendia employs over 1,500 people in Europe, UK, Asia Pacific and the
          USA - a global network blended with a local presence.
        </Normal>
        <AnchorLink
          to="https://www.asendia.com"
          externalLink={true}
          linkColor={variables.linkColor}
        >
          www.asendia.com
        </AnchorLink>
      </>
    ),
  },
  {
    id: 44,
    date: "March 2023",
    dateTitle: "29 March 2023",
    category: "Sustainability",
    country: "United Kingdom",
    title: "DPD UK supports RSPB with wetlands restoration project",
    cardTitle: "DPD UK supports RSPB with wetlands restoration project",
    imgs: [img44],
    body: (
      <>
        <Header2>
          Parcel delivery firm DPD UK has donated £90,000 to the RSPB to support
          the nature conservation charity’s work restoring a sand and gravel
          quarry on the banks of the River Trent, in Nottinghamshire.
        </Header2>
        <Normal>
          Langford Lowfields is a flagship conservation project, created out of
          a partnership involving the RSPB and Tarmac. The reserve currently
          covers 120ha and provides a haven for nature. At the heart of the site
          sits the largest reedbed in the East Midlands, surrounded by
          wildflower-rich meadows, blocks of scrub and an area of mature
          woodland. Wildlife highlights include booming bitterns, brown hares,
          breeding bearded tits, marsh harriers, avocets, large flocks of
          wintering wildfowl and mesmerising starling murmurations.
        </Normal>
        <Normal>
          Managing water levels at Langford Lowfields is essential to keep the
          reedbed healthy and attractive to the range of wildlife that uses this
          threatened habitat, but this management is currently hindered by old,
          failing sluices.
        </Normal>
        <Normal>
          DPD UK has donated £90,000 from the firm’s innovative Eco Fund to help
          the RSPB install two new, double-leaf penstock sluices, to ensure
          water levels can be accurately and efficiently controlled, aiding the
          on-going establishment of the reedbed and increasing the biodiversity
          of the site. The funding will enable 18 hectares of wetland to be
          managed effectively for nature, equivalent to 144 Olympic-size
          swimming pools.
        </Normal>
        <Normal>
          The work will be carried out during the late summer, after the bird
          breeding season and once water levels are as low as possible, with the
          project due to complete by the end of Autumn 2023.
        </Normal>
        <Normal>
          DPD’s Eco Fund is part of the company’s wide-ranging sustainability
          programme and is funded solely by the firm’s circular economy
          initiatives. Plastic shrink wrap used throughout the operation is
          broken down, and then recycled to create new shrink wrap. The money
          generated from this, along with the recycling of wooden pallets, goes
          straight into the Eco Fund to fund sustainable projects benefiting the
          environment.
        </Normal>
        <Quotation>
          <Quotee>
            Rebecca Munro, Director of Income and Conservation Investment, the
            RSPB commented:
          </Quotee>
          “Wetlands are some of the most biodiverse places on the planet and
          provide a vital habitat for wildlife. Globally, freshwater species
          have declined by 83% since the 1970s, so it’s essential we manage our
          existing wetlands in the best way for nature. We’re thrilled that
          DPD’s generous donation will help the RSPB to improve precious wetland
          habitat and boost biodiversity at Langford Lowfields. This is a
          fantastic demonstration of how businesses can act for nature, and I
          look forward to seeing the results over the year.”
        </Quotation>
        <Quotation>
          <Quotee>
            Tim Jones, Director of Marketing, Communications & Sustainability,
            DPD UK commented;
          </Quotee>
          “We are absolutely delighted to be able to support the RSPB on this
          project. Our aim is to be the most sustainable parcel delivery company
          in the UK and as well as transforming our own operation, we want to
          support organisations able to directly protect and enhance the
          ecosystems we are all reliant on. Langford Lowfields is already a
          great example of how business and conservation can co-exist and we
          look forward to working together this year and to making a difference
          for many years to come.”
        </Quotation>
        <Normal>
          DPD’s Eco Fund is also supporting WWF’s ground-breaking Seagrass Ocean
          Rescue project, which aims to help restore 15% of seagrass meadows
          across the UK by 2030, to capture and store hundreds of tonnes of
          carbon each year. While in 2022, DPD announced a four-year partnership
          with Forestry England to plant and restore four separate woodland
          sites across England.
        </Normal>
        <Normal>
          Details on all of DPD UK’s sustainability initiatives can be found on
          its dedicated&nbsp;
          <AnchorLink
            to="https://www.youtube.com/watch?v=RQ5xqAEtl6o&feature=youtu.be"
            externalLink={true}
            linkColor={variables.linkColor}
          >
            DPD Green
          </AnchorLink>
          &nbsp;website.
        </Normal>
        <Normal>Picture credit: Matt Self (rspb-images.com)</Normal>
        <Header3>About DPD UK</Header3>
        <Normal>
          DPD UK was awarded the Queen’s Award for Enterprise 2015 in the
          Innovation category, for its unique one-hour delivery service,
          Predict.
        </Normal>
        <Normal>
          In 2018, DPD opened the UK’s first all-electric parcel delivery depot
          in Westminster. DPD currently has 3,000 EVs in the UK and has
          announced plans to deliver to 30 of the largest towns and cities in
          the UK with zero and low-emission final mile delivery vehicles, by the
          end of 2023. In July 2021, Oxford became DPD’s first all-electric city
          in the UK. Details of all of DPD’s sustainability initiatives can be
          found on its dedicated green website:&nbsp;
          <AnchorLink
            to="https://green.dpd.co.uk/"
            externalLink={true}
            linkColor={variables.linkColor}
          >
            https://green.dpd.co.uk/
          </AnchorLink>
        </Normal>
        <Normal>
          The company operates more than 10,000 vehicles from 84 locations and
          delivers over 400 million parcels a year.
        </Normal>
        <Normal>
          DPD UK is a member of Geopost, a European leader in parcel delivery
          and solutions for e-commerce. Part of the La Poste Groupe, Geopost
          generated a €15.6 billion revenue and delivered 2.1 billion parcels
          worldwide in 2022.
        </Normal>
        <Header3>About the RSPB</Header3>
        <Normal>
          The RSPB is the UK’s largest nature conservation charity, protecting
          habitats, saving species, and helping to end the nature and climate
          emergency. For over a century we’ve acted for nature through practical
          conservation and powerful partnerships, campaigning and influence, and
          inspiring and empowering millions of people, including almost 1.2
          million members. Our network of over 200 nature reserves sits at the
          heart of our world-leading science and conservation delivery.
        </Normal>
        <Normal>
          To find out more about visiting Langford Lowfields visit&nbsp;
          <AnchorLink
            to="https://rspb.org.uk/reserves-and-events/reserves-a-z/langford-lowfields/"
            externalLink={true}
            linkColor={variables.linkColor}
          >
            rspb.org.uk/langfordlowfields
          </AnchorLink>
        </Normal>
        <Normal>Nature is in crisis, but together we can save it.</Normal>
        <Normal>
          The Royal Society for the Protection of Birds The Lodge, Sandy, Beds
          SG19 2DL&nbsp;
          <AnchorLink
            to="www.rspb.org.uk"
            externalLink={true}
            linkColor={variables.linkColor}
          >
            www.rspb.org.uk
          </AnchorLink>
        </Normal>
        <Normal>
          The Royal Society for the Protection of Birds (RSPB) is a registered
          charity. In England and Wales, no: 207076. In Scotland, no: SC037654.
        </Normal>
      </>
    ),
  },
  {
    id: 43,
    date: "March 2023",
    dateTitle: "01 March 2023",
    category: "Sustainability",
    title:
      "DPDgroup becomes Geopost: a new corporate identity to support the geographical and expertise expansion",
    cardTitle: "DPDgroup becomes Geopost: a new corporate identity",
    imgs: [img43],
    body: (
      <>
        <Header2>
          DPDgroup becomes Geopost: a new corporate identity to support the
          geographical and expertise expansion​
        </Header2>
        <Normal>
          In a changing world that is reshaping the logistics industry and
          business, GeoPost/DPDgroup transforms its brand to support its growth
          ambition and embrace its diversification strategy. In 2023,
          GeoPost/DPDgroup becomes Geopost, a new brand allowing the company to
          cover its core areas of expertise today as well as areas of future
          growth:
        </Normal>
        <BulletPoints>
          <li>
            Delivery: Geopost’s core business of B2B, B2C and cross-border
            shipping and parcel delivery solutions, including Business, Home and
            Out-of-Home delivery{" "}
          </li>
          <li>
            Specialized Delivery: Expert delivery solutions designed to meet
            specific, specialized needs in sectors that include Healthcare, Food
            and sameday delivery​
          </li>
          <li>
            New Commerce: Developing integrated, digitally-native and
            data-powered solutions that cover the full E-commerce value chain
            and support our future expansion
          </li>
          <li>
            Delivering Progress: Strategic initiatives, ideas, pilot programmes
            and thought leadership that help to define the future direction of
            our industry, create a more sustainable future and advance a better
            way of doing business
          </li>
        </BulletPoints>
        <Normal>
          Commercial brands (Chronopost, DPD, SEUR, BRT, Jadlog etc) do not
          change, and Geopost’s new brand will endorse them, creating a single
          and global banner for all its local and diverse expertises. This
          strategic move is in line with Geopost’s ambition to become the
          reference in sustainable delivery and the leading enabler of the
          e-commerce acceleration.
        </Normal>
        <Normal>
          <AnchorLink
            to="https://www.youtube.com/watch?v=RQ5xqAEtl6o&feature=youtu.be"
            externalLink={true}
            linkColor={variables.linkColor}
          >
            Learn more
          </AnchorLink>
        </Normal>
      </>
    ),
  },
  {
    id: 42,
    date: "February 2023",
    dateTitle: "28 February 2023",
    category: "Fulfilment",
    title:
      "Geopost shows sustained operating performance in a challenging 2022",
    cardTitle:
      "Geopost shows sustained operating performance in a challenging 2022",
    imgs: [img42],
    body: (
      <>
        <Header2>
          Geopost announces a 6% increase of revenue despite the challenging
          operating environment of the past year
        </Header2>
        <BulletPoints>
          <li>
            <Bold>
              Further increase in revenue despite slight adjustment of volumes
              in 2022
            </Bold>
          </li>
          <BulletPoints>
            <li>
              €15.6 billion in revenue[1], up 6% from 2021, demonstrating
              Geopost’s ability to adapt to difficult market conditions and
              maintain growth.
            </li>
            <li>
              2.1 billion parcels[2] delivered worldwide, down slightly from
              2021 (-1.3%), reflecting an overall slowdown in consumption and
              the normalization of the parcel delivery market.
            </li>
            <li>
              Group result[3] of €669 million, down from 2021 (€1,071 million),
              which was a record year. A decline mainly related to the slight
              decline in express volumes, reflecting the first impacts of the
              decline in purchasing power in an inflationary context, reinforced
              by a negative base effect related to the peak traffic in the first
              half of 2021 and the impairment of DPD Russia&apos;s assets (-€149
              million).
            </li>
          </BulletPoints>
          <li>
            <Bold>
              Sustained progress on the company’s strategic roadmap, aiming at
              positioning Geopost as the international reference in sustainable
              delivery and as a leading enabler of the e-commerce acceleration
            </Bold>
          </li>
          <BulletPoints>
            <li>
              Accelerated growth in strategic market segments, such as
              out-of-home, food services, and healthcare and
              temperature-controlled delivery solutions.
            </li>
            <li>
              Strengthened the offer of new urban and specialized services with
              the acquisition of CitySprint, UK&apos;s leading same-day delivery
              network, and of CChezVous, a specialist in the delivery of heavy
              and bulky goods in France.
            </li>
            <li>
              Continued international expansion, with the increase in
              Aramex&apos;s capital from 24.9% to 28%.
            </li>
          </BulletPoints>
          <li>
            <Bold>
              Continued implementation of the company’s sustainable development
              strategy with the 2040 net-zero ambition formally approved by the
              Science Based Targets initiative (SBTi)
            </Bold>
          </li>
          <li>
            <Bold>
              In 2023, GeoPost/DPDgroup becomes Geopost, its new corporate
              identity, affirming its customer-focused strategy and global scale
            </Bold>
          </li>
        </BulletPoints>
        <Quotation>
          <Quotee>
            Commenting on these results, Yves Delmas, CEO of Geopost, stated:
          </Quotee>
          “2022 was an incredibly challenging year that tested Geopost&apos;s
          resiliency and ability to adapt to volatile and adverse market
          conditions. Thanks to the hard work and commitment of all our teams,
          we have been able to stay the course and record another year of growth
          while maintaining a solid operating performance. We look forward to
          2023 with determination and confidence in our capacity to continue our
          profitable growth path. As a pioneer in the decarbonization of the
          parcel delivery industry, we remain committed to accelerating our
          energy transition and reaching our ambitious science-based net-zero by
          2040 targets.”
        </Quotation>
        <Header2>
          Geopost recorded revenue growth in 2022 amid challenging market
          conditions
        </Header2>
        <Normal>
          After two years of record results driven by the dynamism of the parcel
          delivery market in the wake of the Covid-19 pandemic and successive
          lockdowns, 2022 proved to be much more challenging, marked by several
          major geopolitical and macroeconomic shocks, such as the war in
          Ukraine, record inflation in many countries, a sharp rise in energy
          costs and an overall slowdown in household consumption.
        </Normal>
        <Normal>
          As a result, Geopost recorded a slight adjustment in volumes (-1.3%
          compared with 2021), while maintaining a volume of parcels delivered
          in excess of 2 billion per year, representing more than 8 million
          parcels delivered each day. Despite this limited decline in volumes,
          sales continued to grow (+6% vs. 2021, already a record year),
          demonstrating Geopost&apos;s ability to adapt to difficult market
          conditions and enforce price increase to its customers. Besides, 2022
          saw a slight rebalancing of the BtoB and BtoC segments, showing the
          relevance of the strategic plan and Geopost&apos;s hybrid model: in
          2022, 43% of the volumes were BtoB, compared to 42% in 2021.
        </Normal>
        <Normal>
          In May 2022, Geopost announced its withdrawal from the Russian market,
          after having suspended all shipments to and from Russia in February
          2022. Geopost depreciated the assets of its Russian subsidiary by €149
          million.
        </Normal>
        <Normal>
          Restated from this depreciation, Geopost&apos;s operating result
          stands at €818 million in 2022, versus €1,071 million in 2021, a
          record year.
        </Normal>
        <Header2>
          In 2022, the company successfully continued to roll out its “Together
          & beyond” strategy
        </Header2>
        <BulletPoints>
          <li>
            N°1 in France, Spain, Ireland, Italy, Slovakia, Latvia, Lithuania.
          </li>
          <li>
            N°2 in Portugal, the UK, Germany, Austria, the Czech Republic,
            Bulgaria, Poland, and Estonia.
          </li>
          <li>N°3 in Switzerland, Croatia, and Slovenia.</li>
        </BulletPoints>
        <Normal>
          In line with its strategic agenda, the company kept on expanding along
          three strategic pillars: developing core activities, accelerating in
          strategic segments and conquering new geographic horizons.
        </Normal>
        <Header2>Solid contribution from our main growth drivers</Header2>
        <Normal>
          The deployment of out-of-home delivery services accelerated sharply in
          several of the key countries where Geopost and its subsidiaries are
          present. Volumes increased by 40% throughout 2022. The company now
          offers 13,000 more Pickup points than at the end of 2021, reaching
          almost 83,000 Pickup points worldwide.
        </Normal>
        <Normal>
          Geopost aims at offering the best coverage in Europe with 100,000
          Pickup contact points by 2025, allowing 90% of Europeans to be within
          10 minutes of a Pickup point. These distribution services lie at the
          heart of Geopost global strategy as they enrich the range of services
          through which the company seeks to respond to the changing needs of
          consumers
        </Normal>
        <Normal>
          In food delivery, Geopost recorded a slight growth (+1% in sales
          compared to 2021, a year strongly impacted by lockdowns), reflecting
          how consumers’ habits have deeply transformed since the sanitary
          crisis. Geopost intends to further accelerate in that field, as
          indicated by the acquisition of the fresh products delivery platform
          Pourdebon.com as well as Cool Runnings, an expert company in
          temperature-controlled fresh and frozen food transport. Geopost also
          bolstered its subsidiary Chronofresh, a refrigerated express delivery
          specialist, which gained significant momentum in France.
        </Normal>
        <Normal>
          The healthcare vertical also recorded significant sales growth
          (+22,8%) and Geopost cemented Chronopost&apos;s investment in its
          Chronopost Healthcare division to enhance its logistics capabilities.
          Biocair, the pharmaceutical, biotech and life sciences logistics
          subsidiary of Geopost posted another year of significant growth with a
          30% increase in sales volume year on year.
        </Normal>
        <Normal>
          Geopost secured a stronger presence in new urban and specialized
          logistics in 2022. Through its DPD UK subsidiary, the company
          completed the acquisition of CitySprint, the United-Kingdom&apos;s
          leading same-day delivery network. CitySprint, for its part, announced
          the acquisition of two major regional players, EcoSpeed and Astral. In
          France, Geopost acquired a strategic stake in CChezVous, a company
          specializing in the delivery of heavy and bulky goods. In Spain, the
          SEUR Now urgent delivery offer is now available in 10 major cities.
          More than one million parcels were delivered by SEUR Now in 2022 with
          an almost entirely low emission fleet. In Portugal, DPD Portugal has
          partnered with Stuart to launch DPD Now, a new service providing
          brands and companies with an on-demand, fast, efficient and flexible
          delivery experience.
        </Normal>
        <Normal>
          In 2022, Geopost strengthened its footprint in the e-commerce value
          chain through the acquisition by eShopWorld of Scalefast, a Los
          Angeles-based provider of global e-commerce solutions. eShopWorld is a
          subsidiary of Asendia, whose operational management Geopost has been
          responsible for since July 2021. The contribution of Asendia to
          Geopost’s turnover reached €2,514 million euros in 2022, a 16% growth
          versus 2021. The company took advantage of the e-commerce boom linked
          to the COVID-19 pandemic and was able to quickly adapt its processes
          to match growing demand.
        </Normal>
        <Header2>
          Geopost kept on expanding and deepening its global network
        </Header2>
        <Normal>
          Geopost reinforced its position in Aramex by increasing its stake in
          the company from 24.9% in 2021 to 28% in 2022. Aramex has a powerful
          international network in the Middle East, Asia, Africa and Oceania.
          This network ideally complements Geopost’s strong presence across
          European delivery markets.
        </Normal>
        <Header2>
          Geopost made significant commitments in line with its pioneering role
          in sustainable delivery
        </Header2>
        <Normal>
          In July 2022, the company submitted its “Net Zero Emissions by 2040”
          trajectory to the Science Based Targets initiative, which formally
          approved it in February 2023.
        </Normal>
        <Normal>
          By doing so, the company will contribute to limiting the global
          temperature increase to 1.5°C by accelerating its greenhouse gas (GHG)
          emissions reduction plan. Geopost is committed to drastically reducing
          its GHG emissions from scopes 1, 2 and 3, by 43% by 2030 compared to
          2020, and by 90% by 2040 compared to 2020. The company will also
          neutralize all residual emissions (around 10%) through relevant offset
          projects from 2040 onwards.
        </Normal>
        <Normal>
          Among the initiatives contributing to this ambition, Geopost further
          accelerated in 2022 the gradual transformation of its vehicle fleets.
          By the end of year, 8,458 low-emission vehicles (electric, natural gas
          vehicles and cargo bikes) were deployed. Geopost aims at delivering
          350 cities in Europe (of more than 50,000 inhabitants) with low
          emission means by 2025: as end of 2022, the roll out has started in
          251 European cities, 97 of which are already served by 100%
          low-emission vehicles.
        </Normal>
        <Normal>
          Last September, Geopost joined the EV100+ road transport initiative as
          a founding member. This initiative is led by the international
          non-profit organization Climate Group. By joining, Geopost is
          committed to transitioning its fleet of vehicles over 7.5 metric tons
          to zero tailpipe emissions by 2040.
        </Normal>
        <Header2>
          A new corporate identity to support the geographical and expertise
          expansion
        </Header2>
        <Normal>
          In a changing world that is reshaping the logistics industry and
          business, GeoPost/DPDgroup transforms its brand to support its growth
          ambition and embrace its diversification strategy. In 2023,
          GeoPost/DPDgroup becomes Geopost, a new brand allowing the company to
          cover its core areas of expertise today as well as areas of future
          growth:
        </Normal>
        <BulletPoints>
          <li>
            Delivery: Geopost’s core business of B2B, B2C and cross-border
            shipping and parcel delivery solutions, including Business, Home and
            Out-of-Home delivery
          </li>
          <li>
            Specialized Delivery: Expert delivery solutions designed to meet
            specific, specialized needs in sectors that include Healthcare, Food
            and sameday delivery
          </li>
          <li>
            New Commerce: Developing integrated, digitally-native and
            data-powered solutions that cover the full E-commerce value chain
            and support our future expansion
          </li>
          <li>
            Strategic initiatives: to drive change on Geopost’s market (such as
            the e-shopper barometer) and act as a catalyst for the ecological
            and societal transition (such as Geopost’s sustainability program
            Driving Change™)
          </li>
        </BulletPoints>
        <Normal>
          Commercial brands (Chronopost, DPD, SEUR, BRT, Jadlog etc) do not
          change, and Geopost’s new brand will endorse them, creating a single
          and global banner for all its local and diverse expertises. This
          strategic move is in line with Geopost’s ambition to become the
          reference in sustainable delivery and the leading enabler of the
          e-commerce acceleration.
        </Normal>
        <Header2>About Geopost</Header2>
        <Normal>
          Geopost, a European leader in parcel delivery and solutions for
          e-commerce, operates in 49 countries across all continents through its
          network of expert delivery brands, including: DPD, Chronopost, SEUR,
          BRT, Speedy and Jadlog. Leveraging its industry-specific expertise,
          Geopost is further growing the development of out-of-home delivery
          services with Pickup, the densest parcel shop and locker network in
          Europe and is also expanding temperature-controlled solutions for food
          and healthcare business. In a world of acceleration and
          transformation, Geopost engages and embraces new e-commerce
          territories with eShopWorld via Asendia, epicery and{" "}
          <AnchorLink
            to="https://www.Pourdebon.com"
            externalLink={true}
            linkColor={variables.linkColor}
          >
            Pourdebon.com
          </AnchorLink>
          .
        </Normal>
        <Normal>
          With 122,000 employees and partners, Geopost endeavours to make
          commerce more convenient, profitable and sustainable for their
          customers and communities. Committed to becoming an international
          reference in sustainable delivery, Geopost is the first global
          delivery company to have its roadmap to Net Zero by 2040 approved by
          the Science Based Targets initiative (SBTi).
        </Normal>
        <Normal>
          Part of the La Poste Groupe, Geopost generated a €15.6 billion revenue
          and delivered 2.1 billion parcels worldwide in 2022.
        </Normal>
        <SmallPrint>
          [1] Asendia’s revenue included, as La Poste Groupe has incorporated
          its shareholding in Asendia into the operational management of GeoPost
          since 1st July 2021{" "}
        </SmallPrint>
        <SmallPrint>[2] Geopost volumes only</SmallPrint>
        <SmallPrint>[3] Asendia’s operating result included</SmallPrint>
      </>
    ),
  },
  {
    id: 41,
    date: "February 2023",
    dateTitle: "17 February 2023",
    category: "Sustainability",
    title:
      "GeoPost/DPDgroup’s 2040 net-zero pathway endorsed by the Science Based Targets initiative",
    cardTitle:
      "GeoPost/DPDgroup’s 2040 net-zero pathway endorsed by the Science Based Targets initiative",
    imgs: [img35],
    body: (
      <>
        <Header2>
          GeoPost/DPDgroup is the first global parcel delivery company to have
          both its near and long-term CO2 emissions reduction targets approved
          by the Science Based Targets initiative (SBTi). This landmark approval
          scientifically endorses GeoPost/DPDgroup’s plan to reach net-zero by
          2040 and sets the company on a clear path to be the international
          reference in sustainable delivery.
        </Header2>
        <Header2>
          An immediate and decisive step to lead on net-zero within the global
          parcel delivery market
        </Header2>
        <Normal>
          By significantly reducing its absolute greenhouse gas (GHG) emissions
          across scopes 1, 2 and 3, GeoPost/DPDgroup will contribute to limiting
          global temperature rises to 1.5°C.
        </Normal>
        <Normal>
          The SBTi has approved GeoPost/DPDgroup’s near and long-term
          science-based reduction pathway following a careful and exhaustive
          review, after the company submitted its targets in July 2022 (see{" "}
          <AnchorLink
            to="https://www.geopost.com/en/news/dpdgroup-sbti-net-zero-target/?_gl=1*yy8x6r*_ga*MTI5MDk1MzY0NS4xNjgwNTEyNjQ5*_ga_Q3RB6RNZ25*MTY4MDUyNjEwNS4zLjEuMTY4MDUzMjM1MS42MC4wLjA."
            externalLink={true}
            linkColor={variables.linkColor}
          >
            press release
          </AnchorLink>
          ).
        </Normal>
        <BulletPoints>
          <li>Overall net-zero science-based target</li>
        </BulletPoints>
        <Normal>
          GeoPost/DPDgroup commits to reach net-zero greenhouse gas (GHG)
          emissions across the value chain by FY2040 from a FY2020 base year.
        </Normal>
        <BulletPoints>
          <li>Near-term targets</li>
        </BulletPoints>
        <Normal>
          GeoPost/DPDgroup commits to reduce absolute scope 1 and 2 GHG
          emissions and absolute scope 3 GHG emissions from fuel and
          energy-related activities, upstream transportation and distribution
          and upstream leased assets 43% by FY2030 from a FY2020 base year[1].
        </Normal>
        <BulletPoints>
          <li>Long-term targets</li>
        </BulletPoints>
        <Normal>
          GeoPost/DPDgroup commits to reduce absolute scope 1, 2 and 3 GHG
          emissions 90% by FY2040 from a FY2020 base year1.
        </Normal>
        <Quotation>
          “The approval of our net-zero plan is a strong recognition of the
          efforts we have been making and will continue to make towards a
          sustainable future. Thanks to the tremendous dedication of our teams,
          I am confident that we will meet both our near and long-term targets.
          We take pride in building a net-zero emissions delivery business for
          the planet, for our communities and for our customers”,
          <Quotee>comments Yves Delmas, CEO of GeoPost/DPDgroup.</Quotee>
        </Quotation>
        <Header2>
          GeoPost/DPDgroup’s accelerated decarbonization pathway
        </Header2>
        <Normal>
          To achieve its net-zero science-based targets by 2040, the company
          will focus on:
        </Normal>
        <BulletPoints>
          <li>
            Diversifying the linehaul fleet to include alternative powertrain
            technologies such as biogas, biofuel, electric and hydrogen-powered
            trucks, as well as shifting more parcels to rail to increase the
            share of alternative linehaul transport solutions to 50% by 2030
            (100% in 2040).
          </li>
          <li>
            Switching to renewable energy to power logistics sites and offices
            and driving towards more energy efficiency, with the objective of
            using 70% of energy from renewable sources by 2030 (100% in 2035).
          </li>
          <li>
            Electrifying the fleet of company cars and on-site freight[2] by
            increasing the share of electric vehicles to 90% by 2030 (100% in
            2035).
          </li>
        </BulletPoints>
        <Normal>
          Our net-zero science-based targets cover our 20 largest business units
          in Europe[3]. These targets are aligned with the most ambitious GHG
          emissions reduction pathway to limit global temperature increase to
          1.5°C above preindustrial levels.
        </Normal>
        <Normal>
          This major step in GeoPost/DPDgroup’s decarbonization strategy is in
          line with La Poste Groupe’s objective to reach net-zero emissions in
          2040.
        </Normal>
        <Header2>Key definitions</Header2>
        <Header2>About the Science Based Targets initiative (SBTi)</Header2>
        <Quotation>
          “The Science Based Targets initiative (SBTi) is a global body enabling
          businesses to set ambitious emissions reductions targets in line with
          the latest climate science. It is focused on accelerating companies
          across the world to halve emissions before 2030 and achieve net-zero
          emissions before 2050. The initiative is a collaboration between
          Carbon Disclosure Project (CDP), the United Nations Global Compact,
          World Resources Institute (WRI) and the World Wildlife Fund for Nature
          (WWF) and one of the We Mean Business Coalition commitments. The SBTi
          defines and promotes best practice in science-based target setting,
          offers resources and guidance to reduce barriers to adoption, and
          independently assesses and approves companies’ targets.”
          <Quotee>(Source: Science Based Targets initiative)</Quotee>
        </Quotation>
        <Header2>Net Zero according to SBTi</Header2>
        <Quotation>
          “State of net-zero emissions: Companies shall set one or more targets
          to reach a state of net-zero emissions, which involves: (a) reducing
          their scope 1, 2 and 3 emissions to zero or to a residual level that
          is consistent with reaching net-zero emissions at the global or sector
          level in eligible 1.5°C scenarios or sector pathways and; (b)
          neutralizing any residual emissions at the net-zero target date and
          any GHG emissions released into the atmosphere thereafter.”
          <Quotee>(Source: SBTi Net Zero Standard).</Quotee>
        </Quotation>
        <Header2>Scopes 1, 2, 3</Header2>
        <BulletPoints>
          <li>
            Scope 1 emissions are direct emissions from company-owned and
            controlled resources.
          </li>
          <li>
            Scope 2 emissions are indirect emissions from the generation of
            purchased energy.
          </li>
          <li>
            Scope 3 emissions are all indirect emissions – not included in scope
            2 – that occur in the value chain.
          </li>
          <Quotee>(Source: GHG Protocol)</Quotee>
        </BulletPoints>
        <Header2>About GeoPost/DPDgroup</Header2>
        <Normal>
          DPDgroup is one of the world leading parcel delivery networks, aiming
          to be a reference in sustainable delivery and become a leading enabler
          of e-commerce acceleration.
        </Normal>
        <Normal>
          We combine innovative technology and local knowledge to provide a
          flexible and user-friendly service for both shippers and shoppers.
        </Normal>
        <Normal>
          With 120,000 delivery experts operating in nearly 50 countries, and a
          network of 70,000 Pickup points, we deliver 8.4 million parcels
          worldwide each day – 2.1 billion parcels per year – through the brands
          DPD, Chronopost, SEUR, BRT and Jadlog.
        </Normal>
        <Normal>
          DPDgroup is the parcel delivery network of GeoPost. GeoPost posted
          sales of €14.7 billion in 2021. GeoPost is a holding company owned by
          La Poste Groupe.
        </Normal>
        <SmallPrint>
          [1] The target boundary includes land-related emissions and removals
          from bioenergy feedstocks
        </SmallPrint>
        <SmallPrint>[2] Forklifts and trailer shunters</SmallPrint>
        <SmallPrint>
          [3] Business Units covered by the net-zero ambition: BRT (Italy),
          Chronopost (France), DPD Belgium, DPD Croatia DPD Czech Republic, DPD
          Estonia, DPD France DPD Germany, DPD Hungary, DPD Ireland, DPD Latvia,
          DPD Lithuania, DPD Netherlands, DPD Poland, DPD Portugal, DPD
          Slovakia, DPD Slovenia, DPD Switzerland, DPD UK, SEUR (Spain).
        </SmallPrint>
        <SmallPrint>
          Those Business Units represent 90% of GeoPost/DPDgroup’s revenue.
        </SmallPrint>
      </>
    ),
  },
  {
    id: 40,
    date: "February 2023",
    dateTitle: "16 February 2023",
    country: "Singapore",
    category: "e-Commerce",
    title:
      "Ninja Van Singapore releases its first-ever white paper on live selling in South East Asia",
    cardTitle: "Ninja Van Singapore releases its first-ever white paper",
    imgs: [img40],
    body: (
      <>
        <BulletPoints>
          <li>
            The live selling scene in Singapore is still in its nascent stage,
            with only two in ten local sellers doing live selling.
          </li>
          <li>
            As such, there is a first-mover advantage for e-commerce sellers who
            jump on the live selling bandwagon now.
          </li>
        </BulletPoints>
        <Normal>
          Ninja Van Singapore, a leading tech-enabled logistics company,
          releases its first-ever white paper on Live Selling in Southeast Asia
          (SEA). The white paper introduces Live Selling as one of the
          up-and-coming SEA e-commerce trends, and shares Live Selling insights
          collected from over 1,000 Ninja Van’s e-commerce sellers across
          Singapore, Malaysia, Indonesia, The Philippines, Thailand, and
          Vietnam.
        </Normal>
        <Normal>
          The paper revealed that Singapore has the lowest number of live
          sellers, with only 20% surveyed doing Live Selling versus the regional
          average of 33%. The top two reasons cited by non-adopters were that “a
          lot of effort and energy is required to do it” and “live selling takes
          up a lot of time”.
        </Normal>
        <Normal>
          As such, Singapore topped the chart amongst its Southeast Asian
          counterparts when it comes to engaging influencers to do Live Selling
          on their behalf. For e-commerce owners who have yet to explore this
          new marketing tactic, this paper explains why now is the best time to
          start.
        </Normal>
        <Quotation>
          “Live Selling is an interesting marketing tactic for e-commerce
          sellers,”
          <Quotee>
            says Winston Seow, Chief Marketing and Enablement Officer, Ninja Van
            Group.
          </Quotee>
          “It’s the only tactic that can fast-track shoppers’ purchase journeys
          from awareness straight to conversion. Live Selling also gives
          e-commerce sellers the ability to build relationships at scale with
          their shoppers, both new and existing.”
        </Quotation>
        <Normal>
          One in three of the surveyed SEA e-commerce sellers is now doing Live
          Selling, spending up to six hours weekly conducting Live Selling
          sessions. Most of the early adopters of Live Selling are from
          low-involvement product categories such as Fashion, Beauty & Personal
          Care, Food and Beverages, as well as Home and Living.
        </Normal>
        <Normal>
          While Shopee (27.0%), Facebook (25.5%), and TikTok (22.5%) are ranked
          as the top three Live Selling channels, the close margins signal that
          the champion has yet to emerge in SEA. This could be explained by the
          fact that on average, the surveyed SEA e-commerce sellers use two
          channels for Live Selling, presumably to maximise their outreach to
          Live Shoppers.
        </Normal>
        <Quotation>
          “We first explored live selling in 2021 when COVID heavily impacted
          our business. We took our stores to Facebook and are among the first
          few in Singapore to jump on the bandwagon of live selling,”
          <Quotee>says Mark Sim, founder of Moss Fashion.</Quotee>
          “We are currently running two four-hour live sessions weekly and have
          seen our online clientele steadily increasing since our launch. There
          is untapped potential in live selling, with its unrivalled convenience
          and cost saving, which can be trickled down to benefit customers.”
        </Quotation>
        <Normal>
          The white paper also explores the challenges of Live Selling, such as
          keeping Live Shoppers engaged, preparing on-set logistical
          requirements, as well as sales and post-sales arrangements.
          Recommended solutions are included too. Ninja Van Group’s white paper
          on Live Selling in SEA reaffirms their commitment to understanding the
          ever-changing landscape in order to provide e-commerce sellers with
          hassle-free delivery solutions.
        </Normal>
        <Normal>
          With its dominance in Southeast Asian e-commerce logistics, the Group
          continues to nurture an ecosystem that provides value-added services
          and tools to ensure a seamless experience for shippers and shoppers
          alike.
        </Normal>
        <Normal>
          Please click{" "}
          <AnchorLink
            to="http://events.great.gov.uk/madeinukawards"
            externalLink={true}
            linkColor={variables.linkColor}
          >
            here
          </AnchorLink>
          &nbsp;to access the full white paper.
        </Normal>
        <Header2>About Ninja Van</Header2>
        <Normal>
          Ninja Van is a tech-enabled express logistics company providing
          hassle-free delivery solutions for businesses of all sizes across
          Southeast Asia. Launched in 2014, Ninja Van started operations in
          Singapore and has become the region’s fastest-growing last-mile
          logistics company, powering businesses with innovative logistics
          solutions. Today, Ninja Van has grown its network to cover six
          countries: Singapore, Malaysia, Philippines, Indonesia, Thailand and
          Vietnam.
        </Normal>
        <Normal>
          For more information, visit{" "}
          <AnchorLink
            to="https://www.ninjavan.co./"
            externalLink={true}
            linkColor={variables.linkColor}
          >
            ninjavan
          </AnchorLink>
        </Normal>
      </>
    ),
  },
  {
    id: 39,
    date: "February 2023",
    dateTitle: "14 February 2023",
    country: "Turkey",
    category: "Fulfilment",
    title: "GeoPost/DPDgroup supports the people of Turkey and Syria",
    cardTitle: "GeoPost/DPDgroup supports the people of Turkey and Syria",
    imgs: [img39],
    body: (
      <>
        <Normal>
          The whole world is profoundly shocked and saddened by the news and
          images of the devastating earthquakes that have struck Turkey and
          Syria recently. In view of the scale of the loss of life and material
          damage, we would like to extend our support to all the bereaved
          families and populations who are now suffering the consequences of
          this disaster. Sadly, Yurtiçi Kargo, our business partner in Turkey
          since 2007, has suffered losses in this tragedy, with several
          casualties announced among the dead or missing to date.
        </Normal>
        <Normal>
          We stand by their side and, more broadly, all the Turkish and Syrian
          people and their families working in our subsidiaries around the
          globe.
        </Normal>
        <Normal>
          GeoPost is donating €100,000 through the French Red Cross, to support
          the provision of emergency aid now being coordinated by the
          International Federation of Red Cross and Red Crescent Societies
          (IFRC), the International Committee of Red Cross (ICRC), the Turkish
          Red Crescent, and the Syrian Arabic Red Crescent. The Red Cross & Red
          Crescent Movement is providing urgent financial, material and human
          support on the ground.
        </Normal>
        <Normal>
          Additional initiatives are currently underway in the various
          subsidiaries of La Poste Group.
        </Normal>
        <Normal>
          Despite the difficulties encountered in the affected areas, operations
          are maintained domestically and internationally. Yurtici Kargo, as a
          logistics operator, is participating in humanitarian aid providing
          logistical support on site. Our thoughts are with our Yurtiçi Kargo
          colleagues and their families at this difficult time.
        </Normal>
        <Normal>
          For those who want to make a donation, please do so via this{" "}
          <AnchorLink
            to="https://macollecte.croix-rouge.fr/projects/earthquake-in-syria-and-turkiye-739"
            externalLink={true}
            linkColor={variables.linkColor}
          >
            link
          </AnchorLink>
          &nbsp; to the French Red Cross fundraising page.
        </Normal>
      </>
    ),
  },
  {
    id: 38,
    date: "February 2023",
    dateTitle: "13 February 2023",
    category: "e-Commerce",
    title:
      "GeoPost/DPDgroup empowers businesses opening to Europe with an improved offer",
    cardTitle:
      "GeoPost/DPDgroup empowers businesses opening to Europe with an improved offer",
    imgs: [img38],
    body: (
      <>
        <Header2>
          DPDgroup network empowers Europe-based businesses with an enhanced
          offer, thus giving the ability to deliver in other European countries
          with detailed information on parcel arrival day at a competitive speed
          with an attractive price.
        </Header2>
        <Normal>
          Within increasingly competitive industries​ and a business model
          confirming their shift towards e-commerce,​ DPDgroup experts today
          accompany their customers across a large array of markets: E-
          commerce, Fashion beauty and sportswear, Manufacturing and consumers
          products, High Tech, Food and Healthcare ​. The need for multiple​
          activities and business flows​ leads DPDgroup to improve its DPD
          Classic/ DPD Home/ DPD Business in Europe in order to bring a higher
          level of customer experience in their cross-border growth and
          development journey.
        </Normal>
        <Normal>
          The broader and improved level of DPDgroup’s expertise is designed to
          support a robust business development for e-commerce players in the
          region, adapted to the e-commerce value-chain and its markets
          opportunities​:
        </Normal>
        <BulletPoints>
          <li>optimized network schedules​</li>
          <li>
            key metrics such as accurate lead times, via the DPD​ online transit
            time calculator​
          </li>
          <li>maps per initial depot</li>
          <li>
            120,000 delivery experts to give you the best international business
            experience
          </li>
          <li>deliveries that have been carbon neutral since 2012. ​</li>
        </BulletPoints>
        <Quotation>
          As e-commerce and delivery experience becomes more deeply embedded in
          consumers’ habits, we know how much our e-tailers and shippers need,
          above all, a response that is tailored and adapted to support the
          development of their cross-border business in Europe” 
          <Quotee>says Eric Dietz, COO Europe DPDgroup.</Quotee>
          “Beyond rapid delivery at a reasonable cost, we have improved our
          offer to provide a broad portfolio of services that match their
          specific needs. Our teams develop best in class solutions that bring
          flexibility and control, with defined lead times and seamless access
          to our cross-border network, the densest in Europe.”
        </Quotation>
        <Header2>
          Key benefits of DPD Classic/ DPD Home/ DPD Business e-tailers and
          their customers include:
        </Header2>
        <BulletPoints>
          <li>
            Predictability, a key demand from customers today, which is fully
            integrated to the enhanced DPD Classic/ DPD Home/ DPD Business with
            a new lead time calculator designed to provide strong level of
            reliability whatever the geography and required options, completing
            the Predict notifications on European international deliveries.
          </li>
          <li>
            The service also offers consumers the flexibility to change delivery
            options including redirections to 70 000 Pickup locations, beyond
            end-to-end tracking, live tracking during delivery tour.
          </li>
          <li>
            Available today from and to 10 countries: Germany, Belgium,
            Luxembourg, Netherlands, France, Spain, Italy, Poland, Ireland and
            the United-Kingdom. This primary network will soon extend to other
            European countries.
          </li>
          <li>
            Recommendation: [insert a customer quote explaining benefits if
            relevant]
          </li>
        </BulletPoints>
        <Normal>
          Learn more on our enhanced DPD Classic/ DPD Home/ DPD Business{" "}
          <AnchorLink
            to="https://www.geopost.com/en/?_gl=1%2A1s7ygpw%2A_ga%2AMTI5MDk1MzY0NS4xNjgwNTEyNjQ5%2A_ga_Q3RB6RNZ25%2AMTY4MDUyNjEwNS4zLjEuMTY4MDUzMDQ0NS41OC4wLjA."
            externalLink={true}
            linkColor={variables.linkColor}
          >
            here
          </AnchorLink>
        </Normal>
        <Header2>About GeoPost/DPDgroup</Header2>
        <Normal>
          DPDgroup is one of the world leading parcel delivery networks, aiming
          to be a reference in sustainable delivery and become a leading enabler
          of e-commerce acceleration.
        </Normal>
        <Normal>
          We combine innovative technology and local knowledge to provide a
          flexible and user-friendly service for both shippers and shoppers.
        </Normal>
        <Normal>
          With 120,000 delivery experts operating in nearly 50 countries, and a
          network of 70,000 Pickup points, we deliver 8.4 million parcels
          worldwide each day – 2.1 billion parcels per year – through the brands
          DPD, Chronopost, SEUR, BRT and Jadlog.
        </Normal>
        <Normal>
          DPDgroup is the parcel delivery network of GeoPost. GeoPost posted
          sales of €14.7 billion in 2021. GeoPost is a holding company owned by
          La Poste Groupe.
        </Normal>
      </>
    ),
  },
  {
    id: 37,
    date: "February 2023",
    dateTitle: "07 February 2023",
    category: "Sustainability",
    title: "The Department for International Trade Awards 2023",
    cardTitle: "The Department for International Trade Awards 2023",
    imgs: [img37],
    body: (
      <>
        <Header2>
          This month marks the one-year anniversary of the partnership between
          GeoPost/DPDgroup and the European Food Banks Federation (FEBA).
          Together, the partners have been working to reduce food insecurity and
          prevent food waste.
        </Header2>
        <Header2>
          European Food Banks Federation and GeoPost/DPDgroup share a common
          interest: support to local communities
        </Header2>
        <Normal>
          Founded in 1986, the European Food Banks Federation (FEBA) represents
          today more than 340 Food Banks committed to prevent food waste and
          reduce food insecurity in 30 European countries. FEBA supports their
          development and growth, where they are most needed.
        </Normal>
        <Normal>
          GeoPost/DPDgroup has a history of supporting the local communities in
          which it operates across Europe and several of its business units[1]
          have successfully already initiated local partnerships with national
          Food Banks. GeoPost/DPDgroup decided to go further by signing a group
          level partnership with FEBA one year ago to renew existing agreements
          and help federate other business units around key topics for the group
          and FEBA, notably embracing circular economy and ensuring the
          sustainable use of resources.
        </Normal>
        <Normal>
          In 2022, the conflict that started in Ukraine and the cost-of-living
          crisis (energy and food prices, inflation) have put many populations
          in difficulty and heightened demand for Food Banks’ services. The need
          of financial and in-kind donations to Food Banks reached critical
          levels, and GeoPost/DPDgroup is proud to have contributed to meeting
          this challenge.
        </Normal>
        <Header2>The partnership between GeoPost/DPDgroup and FEBA</Header2>
        <Normal>
          Driven by their mutual goal to prevent food waste while contributing
          to reducing food insecurity in Europe, GeoPost/DPDgroup and FEBA have
          joined forces and agreed on different types of support, such as
          in-kind contributions, logistics support and employee engagement:
        </Normal>
        <BulletPoints>
          <li>
            <Underline>Delivery expertise:</Underline> GeoPost/DPDgroup’s
            business units is providing parcel deliveries for food, material,
            between Food Banks and charity associations for instance
          </li>
          <li>
            <Underline>Donation:</Underline> GeoPost/DPDgroup makes an annual
            financial donation
          </li>
          <li>
            <Underline>Skills sharing:</Underline> GeoPost/DPDgroup is
            supporting the FEBA through exchange of information and expertise
          </li>
          <li>
            <Underline>Employee engagement:</Underline> GeoPost/DPDgroup is
            participating in certain events or other activities organized by
            FEBA, and allow its employees to volunteer.
          </li>
        </BulletPoints>
        <Normal>
          For several years, some of the group’s business units have already
          initiated partnerships with local Food Banks to provide financial and
          logistics support. For instance:
        </Normal>
        <BulletPoints>
          <li>
            DPD Germany is shipping foodstuff that has not been delivered to
            local Food Banks
          </li>
          <li>
            DPD Poland provides local Food Banks with administrative and
            financial support. In return, Federacja Polskich Banków Żywności
            offers DPD educational activities on everyday food management and
            reducing food waste
          </li>
          <li>
            DPD UK facilitates the donation of undelivered food parcels to Food
            Banks. They also support volunteers for specific events and big
            collections, with transport and employees volunteering
          </li>
          <li>
            DPD Hungary participates in specific initiatives and provides
            financial support.
          </li>
        </BulletPoints>
        <Normal>
          In March 2022, shortly after the start of the conflict in Ukraine,
          GeoPost/DPDgroup’s business units have also mobilized, notably in
          neighbouring countries, to support with food, material, hygiene
          products collections and shipments. The company also made a 200,000
          euros donation and launched, together with FEBA, a dedicated
          fundraising webpage to collect donations from employees, customers and
          partners, gathering 12,000 euros. All donations have been matched by
          GeoPost/DPDgroup, euro for euro.
        </Normal>
        <Normal>
          Recently in December, employees at headquarters donated food and
          essential non-food items to Fédération Française des Banques
          Alimentaires, triggering a 10,000 Euro donation
        </Normal>
        <Normal>
          In the future, GeoPost/DPDgroup will also work on building more
          synergies with our food delivery business, by finding ways for
          instance of redistributing undelivered meals.
        </Normal>
        <Quotation>
          “We are very proud of this partnership with FEBA. Our European
          business units and their employees are willing to contribute, at their
          own levels, to food waste prevention and food insecurity reduction.
          They will be able to work more closely with local communities, share
          time, passion and skills on meaningful initiatives, and we want to
          fully support such engagement, both at group and at local level.”
          <Quotee>
            commented Dominique Mamcarz, Sustainability Director at
            GeoPost/DPDgroup
          </Quotee>
        </Quotation>
        <SmallPrint>
          [1] DPD Germany, DPD Hungary, DPD Poland and DPD UK
        </SmallPrint>
      </>
    ),
  },
  {
    id: 36,
    date: "February 2023",
    dateTitle: "06 February 2023",
    category: "e-Commerce",
    title:
      "GeoPost/DPDgroup shares the latest e-shopper trends and behaviors in a context of accelerating change",
    cardTitle:
      "GeoPost/DPDgroup shares the latest e-shopper trends and behaviors",
    imgs: [img36],
    body: (
      <>
        <Header2>
          Despite heightened price consciousness caused by economic instability,
          consumers continue to choose e-commerce as a means of saving time,
          stress, and money.
        </Header2>
        <Normal>
          Regular European e-shoppers remain convinced by e-commerce. This
          choice is motivated by the practical aspects of this mode of purchase,
          which is considered less stressful, and by a search for “good deals”
          in a context of economic arbitration for households. While their
          impulse purchases have decreased, these buyers are buying a wider
          variety of products. While fashion remains the leading reason for
          purchasing, the year 2022 reflects new uses with the noticeable growth
          of fresh produce and groceries, essential everyday products.
          Healthcare, beauty and medicine also continue to grow, as do sales
          between individuals. Finally, delivery in a parcel shop is gaining
          points, especially in Northern and Eastern Europe, but home delivery
          remains the preferred option.
        </Normal>
        <Header2>Key take-aways:</Header2>
        <BulletPoints>
          <li>Price consciousness is shaping consumer behavior</li>
        </BulletPoints>
        <Normal>
          Against a backdrop of economic uncertainty, price is playing an
          especially powerful role in determining consumers’ choices. As a
          result, exchanges between private consumers are on the rise, with both
          buyers and sellers increasingly convinced by the positive impact of
          C2C transactions.
        </Normal>
        <BulletPoints>
          <li>E-shoppers have higher expectations than ever</li>
        </BulletPoints>
        <Normal>
          As e-commerce has become increasingly well-established as an everyday
          shopping behavior, European e-shoppers have become increasingly
          demanding. Their heightened expectations have resulted in a shopping
          and delivery experience that is perceived as somewhat less smooth than
          in the past.
        </Normal>
        <BulletPoints>
          <li>OOH solutions are gaining ground</li>
        </BulletPoints>
        <Normal>
          Home remains the preferred delivery place, but out-of-home (OOH)
          solutions are increasingly used and expected. A growing number of
          regular e-shoppers prefer to be delivered via OOH and among them is an
          equal preference for parcel shops and parcel lockers.
        </Normal>
        <BulletPoints>
          <li>Groceries and fresh food and beverages continue to thrive</li>
        </BulletPoints>
        <Normal>
          The explosive growth of e-commerce over the past two years has
          transitioned to a post-pandemic adjustment period, yet some recent
          consumer behavioral changes persist. While overall purchasing has
          slightly decreased, due in particular to inflation and rising prices
          stemming from the war in Ukraine, groceries and fresh food and
          beverages have confirmed the strength of their appeal.
        </Normal>
        <BulletPoints>
          <li>Predictability is key to customer satisfaction</li>
        </BulletPoints>
        <Normal>
          When it comes to delivery, predictability can make all the difference
          – being able to choose or at least know the one-hour window of the
          delivery is now among e-shoppers’ prerequisites and is bound to drive
          purchases from a website offering such possibilities.
        </Normal>
        <Normal>
          For more information and to submit your entry{" "}
          <AnchorLink
            to="http://events.great.gov.uk/madeinukawards"
            externalLink={true}
            linkColor={variables.linkColor}
          >
            visit the website
          </AnchorLink>
          . Good luck!
        </Normal>
      </>
    ),
  },
  {
    id: 35,
    date: "January 2023",
    dateTitle: "30 January 2023",
    country: "Poland",
    category: "Sustainability",
    title: "Over 5 million parcels handled by sustainable fleet for DPD Poland",
    cardTitle:
      "Over 5 million parcels handled by sustainable fleet for DPD Poland",
    imgs: [img35],
    body: (
      <>
        <Normal>
          DPD Poland is expanding the range of its zero-emission fleet. Last
          year saw 300 electric delivery vans, which handled over 4.5 million
          parcels and covered almost 2 million kilometers.
        </Normal>
        <Normal>
          Since November 2020, DPD Poland has also been developing the fleet of
          cargo bikes, operating in DPD Urban Depots. In 2022, couriers on 60
          electric bicycles handled 600,000 deliveries in the largest cities in
          Poland. Currently bike deliveries take place in Warsaw, Krakow,
          Wrocław, Łódź, Poznań, Gdańsk, Szczecin, Gdynia, Częstochowa, Toruń,
          Olsztyn, Kalisz and Sopot.
        </Normal>
        <Normal>
          The gradual growth of the low- and zero-emission fleet of DPD Poland
          results from the green transformation implemented by DPDgroup which
          has assumed a sustainable development plan in the spirit of carbon
          neutrality. It is also responds to the growing expectations of
          today&apos;s customers, who more frequently choose sustainable
          services and require the companies and the brands to take a
          responsible approach to lessen the impact on the climate change and
          strengthen the protection of natural resources. In pursuit of this
          goal, DPD Poland is not only developing the e-fleet and vehicle
          charging infrastructure but is also increasing the density of the DPD
          Pickup network (currently 12.1 thousand outlets), which reduce the
          carbon footprint of deliveries thanks to consolidation of more
          shipments in one location. The recipients can pick up their parcels in
          the local shop, while walking the dog or shopping in the supermarket.
        </Normal>
        <Normal>
          DPDgroup has committed to reducing greenhouse gas (GHG) emissions by
          43% by the year 2030 and by 90% by the year 2040 compared to the 2020
          baseline. This commitment has been submitted for acceptance to the
          Science Based Target (SBTi) initiative, which enables companies to set
          ambitious CO2 reduction targets in line with the latest scientific
          research concerning climate change.
        </Normal>
        <Header2>About DPD Poland</Header2>
        <Normal>
          DPD Poland is part of GeoPost/DPDgroup, one of the largest
          international courier networks in Europe. The company provides the
          highest quality services to senders and recipients of parcels and
          strives for neutrality of deliveries. It cooperates with over 9000
          couriers. The company has a network of over 9200 DPD Pickup points. In
          2021, it expanded the fleet of low-emission and zero-emission vehicles
          to include electric courier vans.
        </Normal>
        <Normal>
          DPDgroup delivers 8.4 million shipments worldwide every day. It has a
          team of 120 000 delivery experts operating under the brands DPD,
          Chronopost, SEUR, BRT and Jadlog and a network of 70 000 Pickup
          points. In 2020, DPDgroup strengthened its commitment to reducing its
          carbon footprint. As part of the corporate social responsibility
          strategy, the company will deliver parcels in many European cities in
          an environmentally friendly manner with the aim of improving the
          quality of life of their inhabitants.
        </Normal>
      </>
    ),
  },
  {
    id: 34,
    date: "January 2023",
    dateTitle: "17 January 2023",
    country: "France",
    category: "Sustainability",
    title: "GeoPost/DPDgroup at the SIRHA expo 2023 in Lyon, France",
    cardTitle: "GeoPost/DPDgroup at the SIRHA expo 2023 in Lyon, France",
    imgs: [img34],
    body: (
      <>
        <Normal>
          Together with Epicery and Pour de bon, DPDgroup presents at SIRHA its
          food offer being rolled out throughout Europe as well as key insights
          about online food habits.
        </Normal>
        <Normal>
          From ambient transport dedicated to dry products to
          temperature-controlled solutions for fresh or frozen food products,
          DPDgroup offers the variety of services needed by its customers to
          step forward in their delivery journey in more and more countries.
        </Normal>
        <Normal>
          For a few years now, food distribution schemes have had to constantly
          adapt to new habits and growing expectations of consumers. For
          instance, direct-to-consumer channels have been increasing
          significatively as consumers are eager to find local products,
          favoring quality.
        </Normal>
        <Normal>
          DPDgroup positions itself as a cold chain expert, rolling out an
          offering on domestic and cross-border markets, with the ambition to
          support food players in their future expansion plans.
        </Normal>
        <Normal>
          GeoPost/DPDgroup, along with its business units DPD fresh in Belgium,
          DPD Germany, DPD Netherlands, DPD Poland, SEUR and Epicery will be
          present in the international area of the event, to meet potential
          future clients and partners and explore new business opportunities in
          this most promising segment. The company will cover this extensive
          exhibition with another dedicated stand for Chronofresh which is a
          regular at this event.
        </Normal>
        <Normal>
          SIRHA is taking place at Eurexpo Lyon from January 19th to 23rd 2023.
        </Normal>
      </>
    ),
  },
  {
    id: 33,
    date: "January 2023",
    dateTitle: "5 January 2023",
    country: "Switzerland",
    category: "Sustainability",
    title:
      "DPD Switzerland measures the air quality in four Swiss cities in real time",
    cardTitle:
      "DPD Switzerland measures the air quality in four Swiss cities in real time",
    imgs: [img33],
    body: (
      <>
        <Header2>
          DPD Switzerland is measuring the air quality in four Swiss cities with
          its fleet of vehicles. Sensors have been installed on around 100
          vehicles to send information about particulate matter pollution in
          Zurich, Basel, Geneva and Bern in real time. The data is available to
          the public and paints a previously unavailable picture of the air
          quality on our own doorstep.
        </Header2>
        <Normal>
          Numerous studies have shown that particulate matter pollution
          increases the risk of cancer and respiratory and circulatory diseases.
          Although the concentration of coarser dust particles (PM10, less than
          10 micrometres) in most Swiss cities has fallen to such an extent in
          recent years that the thresholds are rarely exceeded, the occurrence
          of smaller dust particles (PM2.5) remains significant, especially in
          winter.
        </Normal>
        <Normal>
          In December 2022, the concentration of PM2.5 particulate matter in
          Swiss cities, as measured by DPD Switzerland, was virtually always far
          beyond the maximum daily limit of 15 micrograms per cubic metre
          recommended by the World Health Organisation (WHO). In some places,
          such as Geneva city centre, the sensors recorded values that are
          considered hazardous to health by the WHO.
        </Normal>
        <Normal>
          DPD Switzerland has launched the “Breathe” project to provide more
          detailed information about the distribution of particulate matter in
          cities. As part of the project, vehicle-mounted lasers continuously
          measure the level of fine PM2.5 particles.{" "}
          <AnchorLink
            to="https://www.dpd.com/ch/en/responsibility/air-quality/?_gl=1*1f2l0s*_ga*MTI5MDk1MzY0NS4xNjgwNTEyNjQ5*_ga_Q3RB6RNZ25*MTY4MDUyNjEwNS4zLjEuMTY4MDUyNjI0OS41Ni4wLjA."
            externalLink={true}
            linkColor={variables.linkColor}
          >
            The data obtained from all across the urban area
          </AnchorLink>
          &nbsp;will complement the stationary monitoring stations. “Breathe” is
          a DPD service that makes it possible to measure the current
          concentration of particulate matter on your doorstep, your normal
          commute or your running route, combined with personalised
          recommendations from the WHO.
        </Normal>
        <Quotation>
          Breathe”’ is another milestone in DPDgroup’s Europe-wide
          sustainability scheme. The air quality is already being monitored in
          Paris, Lisbon, London and Hamburg, and has already led to changes in
          traffic rules. For example, Lisbon has created a new low emission zone
          on the basis of the detailed measurement data.
          <br></br>
          “Although air quality in Switzerland has improved continuously over
          the last decades, fine particles and especially PM2.5 remain a problem
          in terms of its potential to endanger public. The better our data
          situation is, the better we can define clear measures. LUNGE ZÜRICH
          therefore welcomes the initiative of DPD”,
          <Quotee>
            says Dr. Michael Schlunegger, Managing Director of LUNGE ZÜRICH.
          </Quotee>
        </Quotation>
        <Quotation>
          “Breathe is a tremendously important project to DPD Switzerland. On
          the one hand, it ties in with our vision of green parcel deliveries –
          we are aiming for zero-emission deliveries in six cities in
          Switzerland by 2025. On the other, it is an innovation in the field of
          air quality measurement, which used to measure selected points at
          fixed locations. We can now use our vehicles to measure the air
          quality across large areas”,
          <Quotee>
            says Marc Frank, Director Strategy & Innovation at DPD Switzerland.
          </Quotee>
        </Quotation>
        <Quotation>
          “Breathe aims to detect local particulate matter hotspots in cities
          and make them visible to everyone. The new data provides an accurate
          insight into the air quality in four major Swiss cities and can lay
          the groundwork for improving the air quality”,
          <Quotee>
            says Ville Heimgartner, Senior Innovation Project & Sustainability
            Manager at DPD Switzerland.
          </Quotee>
        </Quotation>
        <Normal>
          DPD Switzerland sees environmental responsibility as a duty. All of
          the parcel service provider’s parcel deliveries have been carbon
          neutral since 2012, at no additional cost to its customers. It has
          managed this by measuring its carbon emissions, lowering them steadily
          and offsetting all remaining carbon emissions. In July 2022, GeoPost /
          DPDgroup even submitted its net-zero target to the Science Based
          Targets initiative with a view to lowering its emissions by 90 % by
          2040 (from a 2020 baseline) and offsetting the remaining emissions
          through carbon offset projects.
        </Normal>
        <Header2>How the mobile sensors work</Header2>
        <Normal>
          The lasers mounted on DPD vehicles take constant measurements of the
          concentration of particulate matter in the air. Every 12 seconds, the
          average value is sent to a server over a GSM connection. Using GPS
          data, every measurement is assigned coordinates and used to visualise
          the air quality on a high-resolution map. Even the finest PM2.5
          particles are measured accurately by the sensitive sensors. These can
          penetrate deeper into the respiratory system, remain there for longer
          and cause more lasting damage to the lungs than PM10 particles.
        </Normal>
        <Header2>
          Facts and figures for project “Breathe” in Switzerland
        </Header2>
        <BulletPoints>
          <li>The sensors travel 2 million kilometres per month</li>
          <li>5 million measurements per month</li>
          <li>150 km2 of urban area covered</li>
          <li>100 vehicles with sensors</li>
        </BulletPoints>
        <Normal>
          Air quality in four Swiss cities:{" "}
          <AnchorLink
            to="https://www.dpdgroup.pollutrack.net/?token=969e25db0076732ce27ed8f69a03e21a6e31ed9e1e3395608ed0639cde19e5d73ce6e15637b5421a08feb3d1580c0155fc923e3e4568a1dfb2052c41ba9e9390&lang=ch-all_en&_gl=1*8pcoin*_ga*MTI5MDk1MzY0NS4xNjgwNTEyNjQ5*_ga_Q3RB6RNZ25*MTY4MDUyNjEwNS4zLjEuMTY4MDUyNjg4Mi42MC4wLjA."
            externalLink={true}
            linkColor={variables.linkColor}
          >
            Click here for real-time measurements by DPD
          </AnchorLink>
        </Normal>
        <Header2>About DPD Switzerland</Header2>
        <Normal>
          DPD Switzerland is one of the leading private express and parcel
          service providers in Switzerland and, with its 1’200 employees and
          drivers, delivers over 24 million parcels to businesses and private
          individuals per year. Based in Buchs in Zurich, the company has 14
          locations and around 400 DPD Pickup parcelshops in Switzerland and
          bordering areas in other countries. DPD Switzerland is part of
          DPDgroup.
        </Normal>
      </>
    ),
  },
  {
    id: 32,
    date: "December 2022",
    dateTitle: "22 December 2022",
    country: "France",
    category: "Sustainability",
    title: "Pickup as the favorite network of French shopkeepers",
    cardTitle: "Pickup as the favorite network of French shopkeepers",
    imgs: [img32],
    body: (
      <>
        <Header3>
          In order to deal with the growth in e-commerce, Pickup, a subsidiary
          of Groupe La Poste and the leading pick-up and locker network in
          France, has greatly developed its reach over the last few years, and
          today there are over 16,000 retail points in the network. In order to
          improve network satisfaction and to understand its position in a
          competitive market, Pickup called upon Ipsos. The research institute
          surveyed almost 1,000 retail pick-up points* throughout France about
          their relationship with their pick-up network. After almost 2 weeks of
          telephone surveys, the results speak for themselves: Pickup is the
          favourite pick-up network for French retail locations.
        </Header3>
        <Header3>Pickup widely popular</Header3>
        <Normal>
          To compare how satisfied the French parcel shops are with the main
          network operators on the market, Ipsos first looked at general
          performance indicators such as: recommendations, how well their
          experience with current networks matches their initial expectations,
          and their sense of attachment.
        </Normal>
        <Normal>
          The first part of the analysis revealed that Pickup is the market
          operator most recommended by the pick-up points. With an NPS[1] of 48,
          Groupe La Poste’s pick-up network stood head and shoulders above its
          rivals, by at least 20 points. A high score that echoes Pickup network
          satisfaction: 91% of retailers feel that the Pickup experience meets
          or exceeds their expectations. It should also be noted that ¾ of the
          Pickup network is fond or very fond of Pickup.
        </Normal>
        <Header3>
          Detailed results of key themes: General assistance, Customer Service
          and Pick-up Application
        </Header3>
        <Normal>
          Ipsos also studied the different aspects of the relationship between
          the surveyed retailers and their pick-up network. At the end of this
          2nd round of analyses, Pickup was the clear leader in several areas
          assessed: the network is no.1 in terms of general support provided,
          no.1 for customer service, and no.1 for the PDA application.
        </Normal>
        <Header3>Pickup, no.1 for general support</Header3>
        <Normal>
          Pickup posted a satisfaction score of 88% for general network support.
          This is a decidedly higher score than what the other operators
          achieved in the area, demonstrating a genuine strength of the La Poste
          pick-up network. The result incorporates several aspects of the
          assistance that Pickup provides its retail partners:
        </Normal>
        <BulletPoints>
          <li>
            The speed with which problems are solved by the pickup network (88%
            satisfied)
          </li>
          <li>
            The quality of the information sent out in the training modules
            provided (90%)
          </li>
          <li>The tools available to help perform the service (86%)</li>
        </BulletPoints>
        <Normal>
          Everything is designed to help the retail partners provide their
          parcel service as well as possible. Pickup is particularly careful not
          to overload its network points, so that their regular business is
          never affected.
        </Normal>
        <Header3>No.1 for customer service </Header3>
        <Normal>
          93% of the Pickup network points are satisfied or very satisfied with
          Pickup customer service. Customer service truly sets Pickup apart, as
          shown by the significant lead it holds over its competitors for all
          customer service aspects that were studied:
        </Normal>

        <BulletPoints>
          <li>Courteous and receptive advisors (98%)</li>
          <li>Advisor availability and speed of response (93%)</li>
          <li>
            The ability to solve problems and the quality of the information
            provided (94%)
          </li>
        </BulletPoints>
        <Normal>
          Such high scores testify to both the strong commitment and the
          availability of the Pickup teams when dealing with the retail network.
        </Normal>
        <Header3>No.1 for the Pickup Network application[2]</Header3>
        <Normal>
          In terms of tools, 83% of the pick-up points surveyed are satisfied
          with the Pickup PDA application. This is a reflection of the
          simplicity of customer parcel drop-offs (95%) and pick-ups (92%), as
          well as how easy it is to use and navigate through the device (90%).
          In general, satisfaction with the Pickup PDA and application is far
          higher than with the competition’s equivalents.
        </Normal>
        <Quotation>
          ”At Pickup, we place great emphasis on our relationships with our
          retail network. The positive results of this survey are an
          acknowledgement of the work we do every day and of the actions we
          undertake for them, which we have been doing for over 20 years now. We
          will, of course, be continuing to make every effort in the same
          direction, and will continue to work alongside them to help them
          develop their business and to make the way they handle parcels every
          day easier for them.”
          <Quotee>Maxime d’Hauteville, Pickup Chairman</Quotee>
        </Quotation>
        <Normal>
          Even though we are in the middle of Peak Period – the annual sales
          high point for e-commerce - the quality of the support the network
          provides the pick-up points is making more of a difference than ever.
          To absorb the delivery boom under the best conditions, and to continue
          to ensure the pick-up points and customers have the best possible
          experience, the Pickup network has reinforced its support for the
          period. The number of Customer service staff has been increased by 30%
          and Pickup hotline hours extended, so now from 8 am to 8 pm (instead
          of 9 am to 7 pm) from Monday to Friday, and 8 am to 5 pm on Saturday
          (instead of 9 am to 5 pm outside of Peak Period), so the retail
          network can have the best possible assistance during the peak business
          months, and to prevent overloads.
        </Normal>
        <SmallPrint>
          * Methodology:
          <br></br>
          Quantitative satisfaction telephone survey, conducted by Ipsos France
          from April 21st to May 2nd, 2022, of 961 people from a representative
          sample of French retailers affiliated with pick-up point network,
          using the quota method.
        </SmallPrint>
        <SmallPrint>
          Questions:
          <br></br>
          ”On a scale of 0 to 10, how would you describe [NETWORK] as a pick-up
          point network to other retailers? How do you feel your current
          experience with [NETWORK] corresponds to the expectations you had when
          you began your relationship with the network? On a scale of 0 to 10,
          how satisfied are you with the flow of customers brought in by the
          [NETWORK] parcel business? In general, on a scale of 0 to 10, how
          would you rate [NETWORK] customer service? In general, how would you
          rate the support provided by [NETWORK], on a scale of 0 to 10? In
          general, on a scale of 0 to 10, how would you rate the PDA you were
          provided? How attached to the [NETWORK] network do you feel, on a
          scale of 0 to 10?”
        </SmallPrint>
        <Header3>About Pickup</Header3>
        <Normal>
          Pickup is a subsidiary of Groupe La Poste via its GeoPost/DPDgroup
          subsidiary, and operates France&apos;s leading parcel pick-up network,
          with 16,000 pick-up points and 1,000 lockers. The Pickup points
          receive daily deliveries from the carriers Colissimo, Chronopost and
          DPD France. Pickup point delivery is available from over 90,000
          e-retailers, including: Vinted, Veepee, Showroomprivé, Sarenza,
          CDiscount, Zalando, Amazon, Orange, Nespresso, i.run, Sephora, Mango,
          Decathlon, and more. Pickup has also developed a wide range of
          solutions to meet the challenges of urban logistics, including in
          partnership with the retail and mass distribution sectors. The Pickup
          network also maintains a presence abroad, with 70,000 points in 31
          countries around the world, through the various entities of DPDgroup,
          Groupe La Poste&apos;s international parcel delivery network, the
          largest such network in Europe. Pickup&apos;s head office is based in
          the Paris region and the company employs 350 people.
        </Normal>
        <Normal>
          More information on the{" "}
          <AnchorLink
            to="https://www.pickup.fr/"
            externalLink={true}
            linkColor={variables.linkColor}
          >
            Pickup Internet site
          </AnchorLink>
          .
        </Normal>
        <SmallPrint>
          [1] NPS: The Net Promoter Score is an indicator of customer
          satisfaction and loyalty. More specifically, it reflects the tendency
          or likelihood that customers or users will recommend a product, brand,
          or service.
        </SmallPrint>
        <SmallPrint>
          [2] PDA (Personal Digital Assistant): in other words, the device used
          by retailers to track and scan parcels.
        </SmallPrint>
      </>
    ),
  },
  {
    id: 31,
    date: "November 2022",
    dateTitle: "29 November 2022",
    country: "Belgium",
    category: "Sustainability",
    title:
      "GeoPost/DPDgroup at 2022 Polis Conference : Advancing together towards zero-emission urban logistics by 2030",
    cardTitle:
      "GeoPost/DPDgroup at 2022 Polis Conference : Advancing together towards zero-emission",
    imgs: [img31],
    body: (
      <>
        <Header3>
          On 30th November – 1st December, GeoPost/DPDgroup is taking part to
          POLIS, the leading European network for urban mobility issues held in
          Brussels, Belgium. This year marks GeoPost/DPDgroup&apos;s 5th annual
          participation and the opportunity to showcase the company&apos;s
          transport achievements and expertise in sustainable delivery.
        </Header3>
        <Normal>
          POLIS is the leading network of European cities and regions working
          together to develop innovative technologies and policies for local
          transport. This two-day annual conference is a must-attend event to
          engage with a panel of renowned mobility experts, practitioners and
          decision-makers.
        </Normal>
        <Normal>
          GeoPost/DPDgroup’ s annual participation allowed to confirm that this
          event is key to be aware of the topics of interest for city
          authorities but also to address the mobility stakeholders at a
          European scale.
        </Normal>
        <Quotation>
          <Quotee>
            Caryn-Ann Allen, Group Deputy Director, Sustainability at
            GeoPost/DPDgroup, will be speaking on 1st December at 2.15 pm on a
            panel entitled:
          </Quotee>
          “5F: POLIS & ALICE: Advancing together towards zero-emission urban
          logistics by 2030”.
        </Quotation>
        <Normal>
          Caryn-Ann Allen will address the portfolio of solutions that
          GeoPost/DPDgroup is rolling out throughout Europe to face the
          e-shoppers’ demands on a one hand, while meeting the company&apos;s
          commitments to achieve net-zero target by 2040 on the other hand.
        </Normal>
        <Normal>
          These solutions notably include increasing GeoPost/DPDgroup&apos;s
          low-emission fleet, optimizing urban depots and reinforcing the air
          quality monitoring programme.
        </Normal>
        <Normal>
          To know more about GeoPost/DPDgroup&apos;s sustainable commitments and
          net-zero target, please visit the company&apos;s{" "}
          <AnchorLink
            to="https://www.geopost.com/en/news/dpdgroup-sbti-net-zero-target/?_gl=1*jtpup3*_ga*MTI5MDk1MzY0NS4xNjgwNTEyNjQ5*_ga_Q3RB6RNZ25*MTY4MDUxNjQ4MS4yLjEuMTY4MDUxNjY3Mi42MC4wLjA."
            externalLink={true}
            linkColor={variables.linkColor}
          >
            Website
          </AnchorLink>
          .
        </Normal>
      </>
    ),
  },
  {
    id: 30,
    date: "November 2023",
    dateTitle: "23 November 2023",
    country: "Spain",
    category: "Sustainability",
    title:
      "SEUR purchases a fleet of 200 FORD E-Transit, the 100% electric version of the world’s best-selling cargo van",
    cardTitle:
      "SEUR purchases a fleet of 200 FORD E-Transit, the 100% electric version",
    imgs: [img30],
    body: (
      <>
        <BulletPoints>
          <li>
            SEUR, the leading delivery company in Spain, has purchased the new
            Ford E-Transit model, the 100% electric version of the world’s
            best-selling cargo van, in order to facilitate the achievement of
            its sustainability, productivity and efficiency goals.
          </li>
          <li>
            The Ford E-Transit offers both cargo capacity and maintenance cost
            savings to European customers. All supported by the Ford Pro’s
            connectivity ecosystem, designed to maximize business productivity.
          </li>
          <li>
            The two companies share values such as clear commitment to
            sustainability, productivity and efficiency.
          </li>
          <li>
            Three commemorative videos have been released to celebrate the
            agreement between SEUR and Ford, also coinciding with SEUR’s 80th
            anniversary.
          </li>
        </BulletPoints>
        <Normal>
          SEUR, the leading delivery company in Spain, has purchased a fleet of
          200 Ford E-Transit, the 100% electric version of the world’s
          best-selling cargo van. This is a new step in the company’s firm
          commitment to sustainability.
        </Normal>
        <Normal>
          In this regard, both companies share values related to sustainability
          and the ongoing quest for maximum productivity and efficiency. The
          relationship between the two companies has therefore been reinforced.
        </Normal>
        <Quotation>
          “At FORD PRO we have a very clear goal: to improve uptime and reduce
          operating costs for companies and professionals. In order to reach
          this objective, we rely on a wide range of commercial vehicles, on
          seven years as sales leaders in Europe, and on innovative digital
          solutions. With productivity and sustainability as two of our main
          priorities, we are proud to be able to collaborate with SEUR in its
          goal to be the most sustainable delivery company in Spain”,
          <Quotee> says Elena Burguete, Director of Ford Pro.</Quotee>
        </Quotation>

        <Quotation>
          <Quotee>
            For his part, José Manuel Fernández Pons, Ford Pro Operations
            Manager, added:
          </Quotee>
          “SEUR&apos;s objectives fit perfectly with FORD PRO&apos;s present and
          future plans, spearheaded by the new Ford E-Transit. Thanks to its
          more than 300 kilometers of autonomy, its 15 m3 of cargo and its
          connectivity solutions, the E-Transit is the ideal response to the
          current mobility needs of companies” .
        </Quotation>
        <Quotation>
          <Quotee>
            According to Benjamín Calzón, SEUR’s Operational Excellence Officer,
          </Quotee>
          “this agreement with FORD reflects one of our company’s goals: to
          become the most sustainable company in the entire sector. In this
          sense, 10% of our fleet is already green and, by 2030, we are planning
          to incorporate 3,000 electric vehicles” .
        </Quotation>
        <Quotation>
          <Quotee>
            In the opinion of Itxaso Larrañaga, Chief of People and
            Sustainability Officer at SEUR,
          </Quotee>
          “FORD and SEUR are two aligned companies since we share the main lines
          of the sustainability strategy. At SEUR, in terms of electrification,
          we have electric vehicles, but we have also installed charging
          stations for the vehicles and solar panels in the warehouses, with
          very important achievements regarding sustainability” .
        </Quotation>
        <Header2>Vamos Juntos, videos celebrating this common journey</Header2>
        <Normal>
          This new step in the common path between FORD Pro and SEUR coincides
          with the 80th anniversary of the express delivery company; for this
          reason, both companies have created a three videos series, Vamos
          Juntos (Let&apos;s Go Together), which celebrates the values that
          unite them: sustainability, productivity and innovation.
        </Normal>
        <Normal>
          These pieces include Elena Burguete, Director of FORD Pro; Benjamín
          Calzón, SEUR’s Operational Excellence Officer; Itxaso Larrañaga, Chief
          of People and Sustainability Officer at SEUR; José Manuel Fernández
          Pons, Operations Manager at FORD Pro; and Iván Andía and Sergio
          González, as delivery drivers from SEUR.
        </Normal>
        <Normal>
          Link Directivos:{" "}
          <AnchorLink
            to="https://youtu.be/Q-rQ_Ry8CiQ"
            externalLink={true}
            linkColor={variables.linkColor}
          >
            https://youtu.be/Q-rQ_Ry8CiQ
          </AnchorLink>
        </Normal>
        <Normal>
          Link Aniversario:{" "}
          <AnchorLink
            to="https://youtu.be/ISuOCxIRAQw"
            externalLink={true}
            linkColor={variables.linkColor}
          >
            https://youtu.be/ISuOCxIRAQw
          </AnchorLink>
        </Normal>
        <Normal>
          Link 1+1:{" "}
          <AnchorLink
            to="https://youtu.be/eGiZxi0nPIA "
            externalLink={true}
            linkColor={variables.linkColor}
          >
            https://youtu.be/eGiZxi0nPIA
          </AnchorLink>
        </Normal>
        <Header2>
          New FORD E-Transit: leading productivity for businesses
        </Header2>
        <Normal>
          The FORD E-Transit offers European customers a leading combination of
          cargo capacity, autonomy and savings in the cost of ownership and
          maintenance. All supported by the FORD PRO ecosystem of business
          mobility solutions designed to maximize business productivity.
        </Normal>
        <Normal>
          With a range of up to 317 km WLTP 2 and a load capacity of up to 15
          m3, the new E-Transit is the ideal connected vehicle for the vast
          majority of businesses and commercial fleets. Available in three
          possible lengths, this capability is complemented by a higher level of
          standard equipment including Euro NCAP-recommended intelligent driver
          assistance and safety systems, as well as comprehensive FORD PRO
          Recharge charging support and a whole integrated suite of FORD PRO
          solutions to optimize efficiency and cost of ownership.
        </Normal>
        <Normal>
          In this regard, features such as battery size, range, engine power and
          the unique ProPower Onboard exportable power function make the
          all-electric version of the world&apos;s best-selling cargo van1 offer
          unmatched performance for commercial customers.
        </Normal>
        <Normal>
          The model spearheads the European launch of FORD PRO, the new mobility
          and connectivity services division for businesses that includes the
          full range of FORD commercial vehicles, sales leaders in Europe for
          the past seven years, as well as a range of integrated solutions aimed
          at maximizing business productivity.
        </Normal>
        <Header2>SEUR: 80 years of express transportation</Header2>
        <Normal>
          SEUR aims to become the international benchmark for sustainable
          delivery. For this reason, it has launched an ambitious strategy that
          joins forces with customers, consumers, suppliers and other
          stakeholders to make a difference with a positive impact on people,
          our planet and the communities in which it operates. In this regard,
          by 2025, the company will use low-emission vehicles in 64 Spanish
          cities with more than 50,000 inhabitants.
        </Normal>
        <Normal>
          The company has also deployed a pioneering program in Madrid and
          several European cities to measure air quality through sensors
          installed in delivery vehicles, Pickup stores and urban hubs which,
          using Pollutrack laser technology, measure the quality of particulate
          matter in the air in real time, street by street. Low-emission
          deliveries will also be made in 64 Spanish cities by 2025, reducing
          CO2 emissions in these cities by around 85%, which will have a direct
          impact on 17 million inhabitants throughout Spain.
        </Normal>
        <Normal>
          All this is combined, for example, in the range of out-of-home
          services. These services are alternatives that offer greater
          flexibility and customization to customers in terms of delivery times
          and locations for picking up their orders, as well as being an
          environmentally friendly option. These characteristics represent the
          Pickup network, which is the number one pickup and return network in
          Europe, reducing by 63% the CO2 emissions associated with the last
          mile, while at the same time reducing failed deliveries, and which
          already has more than 4,000 points between convenience stores and
          lockers. In addition, SEUR plans to increase this figure to 5,000
          points by 2025.
        </Normal>
        <Header2>About SEUR</Header2>
        <Normal>
          Our 80 years of history have allowed us to be pioneers in express
          transport in Spain, leading the sector with three main lines of
          business: international, e-commerce and the refrigerated service
          focused on online food.
        </Normal>
        <Normal>
          Thanks to our 10,000 professionals, we serve companies of all sizes
          and sectors, and as part of DPDgroup, one of the largest international
          express transport networks, we deliver all over the world.
        </Normal>
        <Normal>
          We constantly invest in innovation and infrastructures to be closer to
          our customers and offer them greater flexibility through solutions
          such as Predict, an interactive system for arranging delivery, or SEUR
          Now, for super-urgent deliveries in one or two hours. We are committed
          to sustainable logistics with the integration of alternative delivery
          systems in large cities such as the use of ecological vehicles, urban
          hubs or our network of Pickup points with more than 3,000 convenience
          stores and lockers.
        </Normal>
      </>
    ),
  },
  {
    id: 29,
    date: "November 2022",
    dateTitle: "18 November 2022",
    country: "France",
    category: "e-Commerce",
    title:
      "DPD France ranked best for Customer Service in ESCDA Awards in the Mail and Parcel Delivery category, for the third year running",
    cardTitle:
      "DPD France ranked best for Customer Service by ESCDA, for the third year running",
    imgs: [img29],
    body: (
      <>
        <Header2>
          Yesterday, Viséo Customer Insights ESCDA jury awarded DPD France for
          excellence in customer services for the 3rd year in a row in the Mail
          and Parcel Delivery category. ESCDA evaluates organisations all over
          France for the quality of their customer service.
        </Header2>
        <Normal>
          DPD France is the French subsidiary of GeoPost/DPDgroup, the leading
          parcel delivery network in Europe. DPD France specialises in
          transporting parcels weighing less than 30 kilos and relies on a
          network of 68 depots and 5 national sorting hubs, which process and
          deliver over 385,000 parcels every day.
        </Normal>
        <Header2>An ongoing transformation initiated five years ago</Header2>
        <Normal>
          Over the past five years, DPD France, with its employees’ help, has
          been engaged in profoundly remodelling the customer journey. This
          employee commitment was particularly strong during the Covid crisis,
          which went hand-in-hand with a steep increase in e-commerce over the
          period. It was rewarded by being elected “Customer Service of the
          Year” in 2021, then again in 2022.
        </Normal>
        <Quotation>
          “This fresh win in the Customer Service of the Year election, for the
          third year in a row, is proof of our determination to give the
          customer pride of place. Our customers challenge us every day and the
          DPD France teams are once again demonstrating their commitment to
          living up to that challenge. It’s a wonderful reward for all of our
          employees,”
        </Quotation>
        <Header2>
          points out Samuel Gangnant, Chief Executive of DPD France.
        </Header2>
        <Header2>
          A transformation marked by the introduction of a dedicated CRM unit
        </Header2>
        <Normal>
          The transformation of the customer pathway undertaken by DPD France
          over the past five years has unfolded in two main stages. It began in
          2017 with the creation of the Customer Relations Department. Then, in
          2022, the company adopted the Salesforce Service Cloud solution
          (rolled out in six weeks) to centralise all of its customer service
          information and standardise its processes.
        </Normal>
        <Quotation>
          “Customer service has more importance now than the act of delivering
          the parcel. Customer relations and the ongoing effort to improve this
          aspect are major stakes for DPD France, which is both an intermediary
          and a trusted third party,”
        </Quotation>
        <Header2>
          adds Nathalie Nahmias, Customer Relations Director at DPD France.
        </Header2>
        <Normal>
          *Mail and Parcel Delivery Category – BVA – Viséo CI Survey – More info
          at escda.fr
        </Normal>
        <Header2>About DPD France</Header2>
        <Normal>
          Established in France 25 years ago, DPD France is part of DPDgroup,
          the international parcel delivery network headed by GeoPost, the
          leader on the European market and a subsidiary of Groupe La Poste.
          Catering to businesses, DPD France relies on a network of 68 depots
          and 5 sorting hubs across France, operated by over 2,600 employees who
          handle and deliver over 385,000 parcels in France every day. With its
          DPD CLASSIC Europe and DPD CLASSIC Intercontinental services, DPD
          France also enables its customers to increase exports via the robust
          and efficient DPDgroup network. DPD France boasts three major assets
          to support its customers: geographic proximity, with sales and
          customer service teams operating out of each of its 68 depots,
          alternative delivery with its 8,500 partner Pickup Points across
          France, and Predict, an interactive delivery service that enables
          consignees to rearrange delivery to suit their needs and fit in around
          their schedules. In 2021, DPD France delivered 100 million parcels. To
          find out more:{" "}
          <AnchorLink
            to="http://www.dpd.fr/"
            externalLink={true}
            linkColor={variables.linkColor}
          >
            www.dpd.fr
          </AnchorLink>
          .
        </Normal>
      </>
    ),
  },
  {
    id: 28,
    date: "January 2023",
    dateTitle: "10 January 2023",
    country: "United Kingdom",
    category: "Sustainability",
    title: "The Department for International Trade Awards 2023",
    cardTitle: "The Department for International Trade Awards 2023",
    imgs: [img28],
    body: (
      <>
        <Normal>
          The Department for International Trade has launched their inaugural
          Made in the UK, Sold to the World Awards, to recognise the global
          trading success of small businesses from across the UK.
        </Normal>
        <Normal>
          The awards to enter across the seven industry categories, with entries
          closing on the 23rd February 2023.
        </Normal>
        <Normal>
          For more information and to submit your entry{" "}
          <AnchorLink
            to="http://events.great.gov.uk/madeinukawards"
            externalLink={true}
            linkColor={variables.linkColor}
          >
            visit the website
          </AnchorLink>
          . Good luck!
        </Normal>
      </>
    ),
  },
  {
    id: 27,
    date: "November 2022",
    dateTitle: "10 November 2022",
    country: "United Kingdom",
    category: "Sustainability",
    title: "DPD UK to switch all diesel HGVs to renewable biofuel by end 2023",
    cardTitle:
      "DPD UK to switch all diesel HGVs to renewable biofuel by end 2023",
    imgs: [img27],
    body: (
      <>
        <Normal>87% reduction in CO2 emissions across 1,600 HGVs</Normal>
        <Normal>
          60% of DPD&apos;s HGV fleet will switch to biofuel this year
        </Normal>
        <Normal>
          Leading parcel delivery company DPD UK has announced that it is to
          switch its entire diesel HGV fleet to Gd+ HVO, an advanced
          hydrotreated vegetable oil (HVO) fuel, in conjunction with integrated
          energy provider, Essar and Green Biofuels Ltd.
        </Normal>
        <Normal>
          Gd+ HVO is a direct drop-in replacement for diesel, developed by Green
          Biofuels Ltd and is defined as renewable and sustainable under the
          EU&apos;s REDII legislation. It is the lowest-emission diesel
          replacement fuel available made from renewable feedstocks, and
          contains a specifically designed performance additive to ensure
          cleanliness and optimum combustion throughout the fuel system.
        </Normal>
        <Normal>
          Gd+ HVO can be used in any diesel engine without the need for
          modifications or capital expenditure. It is approved by vehicle
          manufacturers, and there is no degradation in performance.
        </Normal>
        <Normal>
          By using Gd+ HVO fuel, operators of diesel engines typically save
          lifecycle greenhouse gas emissions by 90%1, and consequently help
          improve local air quality2. In-field and controlled
          environment-independent tests have shown that compared to standard
          diesel emissions, Gd+ HVO achieves up to 80% reductions of
          particulates and up to 20% reductions of nitrogen oxides emissions.3
        </Normal>
        <Normal>
          DPD UK will begin switching its 1,600 strong HGV fleet to Gd+ HVO
          immediately, and following a four-month trial, the company aims to
          convert 60% of its vehicles within 2022 - reducing emissions by 70,282
          tonnes compared to 2021. The remaining vehicles will switch by the end
          of 2023. Gd+ HVO will be delivered directly to DPD&apos;s onsite tanks
          nationwide by existing bulk fuel supplier Essar.
        </Normal>
        <Normal>
          Justin Pegg, Chief Operating Officer at DPD UK commented,
        </Normal>
        <Normal>
          “This is a very significant step in the journey to decarbonising our
          entire fleet and achieving our aim of being the most sustainable
          parcel delivery company in the UK. While we are well on the way to
          electrifying our delivery van fleet, the HGV linehaul fleet has always
          been a very different challenge. We have assessed a range of options
          and it is clear now that this is the most effective and practical way
          to make a real difference. While there may be different solutions in
          years to come, we must start this process now by making our existing
          vehicles significantly cleaner.”
        </Normal>
        <Normal>
          Carlos Rojas, Head of Marketing & Logistics at Essar, said:
        </Normal>
        <Normal>
          “Essar is committed to playing a leading role in the decarbonisation
          of the UK economy and manufacturing the fuels of the future in a lower
          carbon way. As a key partner of choice, we have worked seamlessly to
          provide integrated energy, infrastructure, and logistical solutions to
          DPD nationally for over ten years and are proud to be its strategic
          supply partner as it decarbonises its fleet.”
        </Normal>
        <Normal>
          William Tebbit, CEO and Co-Founder of Green Biofuels, said:
        </Normal>
        <Normal>
          “We are delighted to be working with Essar and DPD. Our mission is to
          support the net-zero energy transition by providing a solution that
          that makes an immediate impact on local air quality, lowers CO2
          emissions, and supports decarbonisation. The challenges faced by DPD
          are typical of most large fleets in the UK; however, Gd+ HVO provides
          results with no additional costs of transforming the engines. Green
          Biofuels offers the most effective solution available today - it is an
          essential ingredient for the transition to an environmentally friendly
          world fuelled by 100% renewable energy.”
        </Normal>
        <Normal>
          Details on all of DPD&apos;s sustainability initiatives can be found
          on its dedicated{" "}
          <AnchorLink
            to="https://green.dpd.co.uk/"
            externalLink={true}
            linkColor={variables.linkColor}
          >
            DPD green
          </AnchorLink>{" "}
          website and include plans to have over 3,000 electric vehicles on the
          road in the UK this year and a total of 4,000 by 2023, delivering to
          30 towns and cities on all-electric vehicles only.
        </Normal>
        <Normal>Notes to editors</Normal>
        <Normal>
          1. Directive (EU) 2018/2001 of the European Parliament and of the
          Council of 11 December 2018 on the promotion of the use of energy from
          renewable sources (Text with EEA relevance.)
        </Normal>
        <Normal>
          2. Millbrook Trial (Independent Test), &apos;Refuse Collection Vehicle
          Engine Testing on Chassis dynamometer. Comparison of Emissions with
          Three Fuels: Diesel, Hydrotreated Vegetable Oils and Green D+&apos;,
          Jan-Mar 2018
        </Normal>
        <Normal>
          3. Millbrook Trial (Independent Test), &apos;Refuse Collection Vehicle
          Engine Testing on Chassis dynamometer. Comparison of Emissions with
          Three Fuels: Diesel, Hydrotreated Vegetable Oils and Green D+&apos;,
          Jan-Mar 2018; Chepstow (in field), &apos;Emissions testing on Three
          Construction Engines (Chepstow Plant Hire) at Tyttenhanger site with
          two fuels (Gd+ and Diesel fuels)&apos;, July 2021 - Jan 2022;
          Emissions Analytics (controlled test cell trial), &apos;Emissions
          testing of a 110kVA, Stage IIIA Power Generator. Analysis of Data
          measurements carried out by Emissions Analytics. Comparison of
          Emissions with Diesel, HVO and Green D+ fuels&apos;, Jan-Feb 2021.
        </Normal>
        <Normal>About DPD UK</Normal>
        <Normal>
          DPD UK was awarded the Queen&apos;s Award for Enterprise 2015 in the
          Innovation category, for its unique one-hour delivery service,
          Predict.
        </Normal>
        <Normal>
          In 2018, DPD opened the UK&apos;s first all-electric parcel delivery
          depot in Westminster. DPD currently has over 2,100 EVs on the road in
          the UK and has announced plans to deliver to 30 of the largest towns
          and cities in the UK with zero and low-emission delivery means by
          2023. In July 2021, Oxford became DPD&apos;s first all-electric city
          in the UK.
        </Normal>
        <Normal>
          Details of all of DPD&apos;s sustainability initiatives can be found
          on its dedicated green website:{" "}
          <AnchorLink
            to="https://green.dpd.co.uk/"
            externalLink={true}
            linkColor={variables.linkColor}
          >
            https://green.dpd.co.uk/
          </AnchorLink>
        </Normal>
        <Normal>
          DPD UK is a member of DPDgroup, the largest parcel delivery network in
          Europe. DPDgroup is the parcel delivery network of GeoPost, a holding
          company owned by France&apos;s La Poste.
        </Normal>
        <Normal>
          The company operates more than 10,000 vehicles from 84 locations and
          delivers over 400 million parcels a year.
        </Normal>
        <Normal>About Essar</Normal>
        <Normal>
          Essar Oil (UK) Ltd is committed to playing a key role in the
          decarbonisation of the UK economy. With ambitious plans to build an
          energy transition cluster in the North West of England, Essar is
          transforming for tomorrow. Through Essar Oil (UK), Essar is leading
          this change in the UK by developing projects in refinery
          decarbonisation, biofuels and new energies infrastructure and
          logistics to help deliver the UK&apos;s energy transition ambitions.
        </Normal>
        <Normal>About Green Biofuels</Normal>
        <Normal>
          Green Biofuels provides the bridging technology essential to help the
          transition to a greener and more renewably fuelled world. While new
          technologies and further advanced fuels are in development, Green
          Biofuels responds to the need for alternatives to standard fossil
          diesel fuels now.
        </Normal>
        <Normal>
          The company supplies its own brand of low emission and GHG saving
          fuels to customers using diesel consuming engines. Gd+ HVO is the
          lowest emission diesel replacement fuel available in the UK. Green
          Biofuels&apos; products include completely renewable diesel
          alternatives made from vegetable and/or animal fats and oils.
        </Normal>
        <Normal>
          Independent tests at the Millbrook specialist vehicle testing facility
          have shown that compared to standard diesel emissions, Gd+ HVO has up
          to an 85% reduction in particulates, and up to a 30% reduction in
          Nitrogen Oxides, thanks to a special additive not found in any other
          HVO fuel. This means that emissions contributing to climate change are
          reduced, as well as pollution affecting the local air quality.
        </Normal>
        <Normal>
          Using Green Biofuels&apos; products does not require any modification
          to engines. There is no need for costly upgrades to machinery, it can
          be filled into the tank and used just like diesel fuel.
        </Normal>
        <Normal>
          The company&apos;s aim is to encourage businesses who use diesel
          engines to look at adopting an environmentally friendly and
          commercially viable alternative to standard diesel fuels as an urgent
          interim solution to reducing emissions and GHG.
        </Normal>
        <Normal>
          Green Biofuels is a UK Business Climate Leader and the first HVO
          supplier to be approved by Zemo&apos;s Renewable Fuels Assurance
          Scheme.
        </Normal>
      </>
    ),
  },
  {
    id: 26,
    date: "November 2022",
    dateTitle: "2 November 2022",
    country: "United Kingdom",
    category: "Sustainability",
    title:
      "First Bus and DPD UK launch inaugural partnership in bid to make Glasgow greener",
    cardTitle:
      "First Bus and DPD UK launch inaugural partnership in bid to make Glasgow greener",
    imgs: [img26],
    body: (
      <>
        <Header3>
          One year on from COP26, First Bus sees its legacy ambitions come to
          life
        </Header3>
        <Normal>
          First Bus is helping UK delivery company, DPD, to carry out more green
          journeys in Glasgow in a milestone move which sees the transport
          operator&apos;s ambitions from COP26 come to life.
        </Normal>
        <Normal>
          At the global climate change conference last year, First Bus set out
          its aim offer local businesses use of the extensive electric vehicle
          (EV) charging infrastructure at its Caledonia Depot.
        </Normal>
        <Normal>
          One year on, DPD UK has become the first company to officially sign up
          to the scheme. The agreement means that DPD drivers will now have
          access to the site to charge their electric vehicles whilst in
          Glasgow, enabling them to travel a greater distance while making
          deliveries - without impacting the environment.
        </Normal>
        <Normal>
          Through this arrangement, the green potential of First Bus&apos;s
          charging hub will be maximised while its own electric fleet is out in
          service.
        </Normal>
        <Normal>
          Graeme Macfarlan, Commercial Director at First Bus, said:
        </Normal>
        <Normal>
          “The partnership with DPD reflects the commitment we made during COP26
          to help other businesses to achieve their green ambitions. We hope
          that this marks the first of many valuable partnerships and
          demonstrates the power that comes from working together to reduce
          carbon emissions.”
        </Normal>
        <Normal>
          “Electrifying fleets, and having the infrastructure in place to
          support them, is a mammoth undertaking, and it simply isn&apos;t
          feasible for each and every business to build its own charging
          station. Reducing carbon emissions is a priority for us - not just to
          meet our own targets but because we&apos;re committed to delivering
          cleaner, greener journeys for Glasgow. We&apos;re already in
          conversation with other businesses and look forward to announcing
          further partnerships in the near future.”
        </Normal>
        <Normal>
          Located on Glasgow&apos;s southside, First Bus&apos;s Caledonia Depot
          has 160 state-of-the art, rapid-charging points, supporting the
          operator&apos;s ambition to be emission-free by 2035.
        </Normal>
        <Normal>
          First Bus has worked closely with Hitachi ZeroCarbon to provide the
          systems that will enable charging commercial vehicles at the site.
          Originally designed for the buses operating out of the Caledonia
          depot, it is hoped the Plug and Charge solution will benefit
          businesses in Glasgow by providing the availability of DC ultra-rapid
          charging for commercial electric vehicles.
        </Normal>
        <Normal>Minister for Transport, Jenny Gilruth, said:</Normal>
        <Normal>
          “We all have to work together to end our contribution to climate
          change, and by making its charging infrastructure available to DPD UK,
          First Bus are leading the way.”
        </Normal>
        <Normal>
          “The Scottish Government provided over £5.9 million to help deliver
          the infrastructure at the depot, and I am delighted that it is being
          used to support decarbonisation of last mile delivery as well as
          public transport.”
        </Normal>
        <Normal>
          “Our £500k Bus Market Transition Scheme, which aims to support
          businesses prepare for our next round of capital funding, is currently
          open for applications and I hope that this announcement today by First
          Bus encourages others to think creatively about how Government funding
          can be used to maximum effect.”
        </Normal>
        <Normal>
          DPD&apos;s aim is to be the most sustainable parcel delivery company
          in the UK and the company is on track to have over 3,000 EVs on the
          road this year and 4,000 by 2023, when it will be delivering to 30
          towns and cities, including Glasgow, using EVs only.
        </Normal>
        <Normal>
          Initially, eight DPD EVs from the firm&apos;s Cambuslang depot will
          access the Caledonia site, as part of the trial period. The
          expectation is that this number will increase to help support
          DPD&apos;s growing EV fleet in the city, which is planned to total
          close to 200 electric vans by the end of next year.
        </Normal>
        <Normal>Olly Craughan, Head of Sustainability, DPD UK said:</Normal>
        <Normal>
          “The Caledonia depot is a fantastic facility and this is a really
          smart initiative between two brands that are investing in a greener
          future for Scotland. The partnership will be hugely beneficial as it
          will enable our drivers to access fast, reliable and secure charging
          stations in Glasgow during the day. Our plan is to be delivering in
          both Glasgow and Edinburgh city centres using only electric vehicles
          by the end of next year, and this is another step towards making that
          a reality.”
        </Normal>
        <Normal>
          First Bus is continuing to expand its zero-emission bus fleet and
          electric vehicle charging infrastructure in Scotland over the next 18
          months with plans already in place for the electrification of its
          Scotstoun and Aberdeen depots.
        </Normal>
        <Normal>
          The operator is interested in hearing from businesses who wish to
          benefit from this partnership opportunity which, if successful, could
          be rolled out to other sites in future.
        </Normal>
        <Normal>About First Bus</Normal>
        <Normal>
          First Bus is one of the UK&apos;s largest bus operators. Making
          journeys easier for our customers, we were the first national bus
          operator to accept contactless card payments across all of our
          services and our First Bus App is voted “best in class“ amongst UK bus
          operators. Our most recent investments are in new, state-of-the-art
          buses across our key networks.
        </Normal>
        <Normal>
          We work proactively with our local authority partners, making a
          positive impact on air quality, tackling congestion and improving
          customer experience. We are focused on First Bus becoming a leader in
          the transition to a low-carbon future and are committed to operating a
          zero-emission bus fleet by 2035; we have pledged not to purchase any
          new diesel buses after December 2022. We also operate the Aircoach
          network in Ireland.
        </Normal>
        <Normal>
          Hitachi ZeroCarbon is a strategic partner of First Bus&apos;s
          decarbonisation programme and was a principal partner of COP26.
        </Normal>
        <Normal>First Bus is a division of FirstGroup.</Normal>
        <Normal>First Bus is an equal opportunity employer.</Normal>
        <Normal>
          First Bus is an excellent place to work with opportunities for a
          rewarding career.
        </Normal>
        <Normal>
          We are committed to equality of opportunity, diversity and inclusion.
          We believe diverse experiences and attitudes help us better understand
          the needs of our customers.
        </Normal>
        <Normal>
          For more information on First Bus:{" "}
          <AnchorLink
            to="https://news-scot.firstbus.co.uk"
            externalLink={true}
            linkColor={variables.linkColor}
          >
            news-scot.firstbus.co.uk
          </AnchorLink>
        </Normal>
        <Normal>About DPD UK</Normal>
        <Normal>
          DPD UK was awarded the Queen&apos;s Award for Enterprise 2015 in the
          Innovation category, for its unique one-hour delivery service,
          Predict.
        </Normal>
        <Normal>
          In 2018, DPD opened the UK&apos;s first all-electric parcel delivery
          depot in Westminster and in July 2021, Oxford became DPD+&apos;s first
          all-electric city in the UK. Details of all of DPD&apos;s
          sustainability initiatives can be found on its dedicated green
          website:{" "}
          <AnchorLink
            to="https://green.dpd.co.uk/"
            externalLink={true}
            linkColor={variables.linkColor}
          >
            https://green.dpd.co.uk/
          </AnchorLink>
        </Normal>
        <Normal>
          DPD UK is a member of DPDgroup, the largest parcel delivery network in
          Europe. DPDgroup is the parcel delivery network of GeoPost, a holding
          company owned by France&apos;s La Poste.
        </Normal>
        <Normal>
          The company operates more than 10,000 vehicles from 84 locations and
          delivers over 400 million parcels a year.
        </Normal>
      </>
    ),
  },
  {
    id: 25,
    date: "October 2022",
    dateTitle: "11 October 2022",
    country: "Singapore",
    category: "Sustainability",
    title:
      "Ninja Van Singapore steps up on Sustainability efforts with two green initiatives",
    cardTitle:
      "Ninja Van Singapore steps up on Sustainability efforts with two green initiatives",
    imgs: [img25],
    body: (
      <>
        <Header3>
          Initiatives include Ninja Van&apos;s pilot trial of electric vehicles
          in Southeast Asia and the relaunch of an eco-friendly version of Ninja
          Packs
        </Header3>
        <Normal>
          Local express logistics company Ninja Van Singapore is rolling out two
          environmentally-focused initiatives as part of its effort to step up
          on its sustainability efforts. Both initiatives kick off in October
          and involve a pilot programme to trial electric vehicles (EV) and the
          relaunch of a redesigned eco-friendly version of the Ninja Packs,
          Ninja Van&apos;s pre-paid poly mailer product.
        </Normal>
        <Normal>Hassle-free deliveries made electric</Normal>
        <Normal>
          The EV pilot trial will see the addition of 10 electric vehicles to
          its fleet as part of its partnership with the leading commercial
          vehicle leasing company, Goldbell Leasing. The trial is the first such
          programme that Ninja Van is running within its Southeast Asian
          network, and is part of the company&apos;s broader plan to measure and
          manage its environmental impact.
        </Normal>
        <Normal>
          As part of the trial, Ninja Van will be assessing some factors before
          committing to a larger-scale roll-out across its fleet in Singapore.
          These factors include challenges drivers might face, and ground data
          such as the accessibility of commercial charging points and the
          driving range of a fully-loaded EV.
        </Normal>
        <Normal>
          When fully charged, the EVs can travel up to 235 km and carry a load
          of up to 1200kg.
        </Normal>
        <Normal>
          “Sustainability is part of Ninja Van&apos;s long-term goals, and it is
          important for us to make the transition in a deliberate and calculated
          way. Doing so allows us to maintain the “hassle-free” service
          experience for our shippers and customers that Ninja Van is known for,
          and recognise greater benefits for both our business and the
          environment,”
        </Normal>
        <Normal>said Ray Chou, Country Head of Ninja Van Singapore.</Normal>
        <Normal>
          Ninja Van is the first adopter of the newly launched Foton iBlue
          electric vans. As a long-standing fleet partner since 2014, Goldbell
          will work closely with Ninja Van to navigate the complexity of fleet
          electrification, such as providing consultation on electrical
          infrastructure to achieve the maximum economic, environmental, and
          experiential benefits of this trial.
        </Normal>
        <Normal>
          “The topic of sustainability is central to our own efforts in driving
          the electromobility agenda. We are therefore delighted to be involved
          in this pilot trial, which is a step forward in contributing towards
          contributing to Singapore&apos;s Green Plan,“
        </Normal>
        <Normal>said Keith Kee, General Manager of Goldbell Leasing.</Normal>
        <Normal>Hassle-free deliveries made eco-friendly</Normal>
        <Normal>
          The first version of Eco Ninja Packs was launched last year, with
          Ninja Van being the first in the logistics industry to pioneer an
          eco-friendly version of a pre-paid poly mailer in Singapore.
        </Normal>
        <Normal>
          “Looking beyond last-mile operations, we wanted to explore how we can
          manage other parts of the supply chain to reduce our overall carbon
          emissions footprint, and the Eco Ninja Pack is our solution to that.
          This is a product that is ideal for business owners who want to do
          their part for the environment as the Eco Ninja Packs are
          biodegradable and do not release toxins during the incineration
          process. Being sourced and supplied locally also means we are able to
          reduce carbon emissions incurred through air and sea freight,”
        </Normal>
        <Normal>
          said Kooh Wee Hou, Head of Commercial of Ninja Van Singapore.
        </Normal>
        <Normal>
          Eco Ninja Packs are available for sale and are priced at $3.80/piece
          with a bundle of 5, and $3.40/piece with a bundle of 30.
        </Normal>
        <Normal>
          Please find the press materials in the Google drive here:
        </Normal>
        <Normal>
          <ul>
            <li>Images of the EVs and Eco Ninja Packs</li>
            <li>Infographics on the EVs and Eco Ninja Packs</li>
            <li>Infographic on other green initiatives in our warehouses</li>
          </ul>
        </Normal>
        <Normal>About Ninja Van</Normal>
        <Normal>
          Ninja Van is a tech-enabled express logistics company providing
          hassle-free delivery solutions for businesses of all sizes across
          Southeast Asia. Launched in 2014, Ninja Van started operations in
          Singapore and has become the region&apos;s fastest-growing last-mile
          logistics company, powering businesses with innovative logistics
          solutions. Today, Ninja Van has grown its network to cover six
          countries: Singapore, Malaysia, Philippines, Indonesia, Thailand and
          Vietnam. For more information, visit{" "}
          <AnchorLink
            to="https://www.ninjavan.co"
            externalLink={true}
            linkColor={variables.linkColor}
          >
            www.ninjavan.co
          </AnchorLink>
          .
        </Normal>
      </>
    ),
  },
  {
    id: 24,
    date: "September 2022",
    dateTitle: "28 September 2022",
    country: "United Kingdom",
    category: "Sustainability",
    title: "DPD UK supports ground-breaking WWF seagrass restoration project",
    cardTitle:
      "DPD UK supports ground-breaking WWF seagrass restoration project",
    imgs: [img24],
    body: (
      <>
        <Header3>
          Seagrass Ocean Rescue programme to help re-establish seagrass meadows
          in UK coastal waters and help capture hundreds of tonnes of carbon
          each year
        </Header3>
        <Normal>
          Parcel delivery firm DPD UK has partnered with leading independent
          conservation organisation World Wide Fund for Nature (WWF) to support
          the charity&apos;s ground-breaking seagrass restoration project -
          Seagrass Ocean Rescue.
        </Normal>
        <Normal>
          The Project aims to help restore 15% of seagrass meadows across the UK
          by 2030, to capture and store hundreds of tonnes of carbon each year
          and help play a role in the UK reaching its carbon emission targets.
        </Normal>
        <Normal>
          Globally seagrass has been found to be more effective at absorbing and
          storing carbon than tropical rainforests. However, these sensitive
          seabed habitats are at risk from a variety of factors, including
          disease, pollution and physical disturbance from human activities.
        </Normal>
        <Normal>
          In addition, planting seagrass meadows can significantly enhance the
          biodiversity and health of the UK&apos;s seas and coastal ecosystems.
        </Normal>
        <Normal>
          DPD UK has donated £40,000 from the firm&apos;s innovative Eco Fund to
          help support phase two of the programme in The Solent. The work
          between now and 2024 will include working in partnership with
          government agencies on ecological field trials on best planting
          methodologies, seagrass restoration, alongside community and
          stakeholder engagement and the delivery of innovative educational
          programmes.
        </Normal>
        <Normal>
          DPD&apos;s Eco Fund is part of the company&apos;s wide-ranging
          sustainability programme and is funded solely by the firm&apos;s
          circular economy initiatives. Plastic shrink wrap used throughout the
          operation is broken down, and then recycled to create new shrink wrap.
          The money generated from this, along with the recycling of wooden
          pallets, goes straight into the Eco Fund to fund sustainable projects
          benefiting the environment.
        </Normal>
        <Normal>
          Ricardo Zanre, Ocean Restoration Programme Manager at WWF-UK
          commented;
        </Normal>
        <Normal>
          “We&apos;re thrilled that DPD UK has chosen to donate £40,000 towards
          our Seagrass Ocean Rescue project in The Solent. Thanks to this
          support we&apos;re able to deliver our ground-breaking restoration
          project, trialing the latest seagrass planting methodologies and
          building towards our long-term aim, which is to see 15% of seagrass
          meadows restored across the UK by 2030.
        </Normal>
        <Normal>
          “Seagrass is an incredible flowering marine plant which can absorb
          carbon up to 35 times faster than a tropical rainforest, it&apos;s
          vital that we continue to restore these ecosystems that also provide
          important habitat that supports biodiversity, including important UK
          fisheries, so that we can create a future where people and nature can
          thrive together. Thank you to DPD UK for playing a part in making this
          happen.”
        </Normal>
        <Normal>
          Tim Jones, Director of Marketing, Communications & Sustainability, DPD
          UK commented;
        </Normal>
        <Normal>
          “This is a fabulous project and something we are very proud to be
          involved in. At DPD, our aim is to be the most sustainable parcel
          delivery company in the UK and that means running an ambitious
          programme to transform our own operation as well as working with those
          organisations able to directly protect and enhance the ecosystems we
          are all reliant on.”
        </Normal>
        <Normal>
          Earlier this year, DPD UK announced a four-year partnership with
          Forestry England to plant and restore woodland across England to
          tackle climate change and create positive benefits for local wildlife
          and communities. The partnership will focus on four separate woodland
          sites where DPD support will enable Forestry England to plant trees to
          create new woodland areas and carry out work restoring valuable
          established woodlands.
        </Normal>
        <Normal>
          Details on all of DPD&apos;s sustainability initiatives can be found
          on its dedicated{" "}
          <AnchorLink
            to="https://green.dpd.co.uk/"
            externalLink={true}
            linkColor={variables.linkColor}
          >
            DPD green
          </AnchorLink>{" "}
          website and include plans to have over 3,000 electric vehicles on the
          road in the UK this year and 5,000 by 2023, when it will be delivering
          to 30 towns and cities on all-electric vehicles only.
        </Normal>
        <Normal>
          <strong>About DPD UK</strong>
        </Normal>
        <Normal>
          DPD UK was awarded the Queen&apos;s Award for Enterprise 2015 in the
          Innovation category, for its unique one-hour delivery service,
          Predict.
        </Normal>
        <Normal>
          In 2018, DPD opened the UK&apos;s first all-electric parcel delivery
          depot in Westminster. DPD currently has over 2,100 EVs on the road in
          the UK and has announced plans to deliver to 30 of the largest towns
          and cities in the UK with zero and low-emission delivery means by
          2023. In July 2021, Oxford became DPD&apos;s first all-electric city
          in the UK. Details of all of DPD&apos;s sustainability initiatives can
          be found on its dedicated green website:{" "}
          <AnchorLink
            to="https://green.dpd.co.uk/"
            externalLink={true}
            linkColor={variables.linkColor}
          >
            https://green.dpd.co.uk/
          </AnchorLink>
        </Normal>
        <Normal>
          DPD UK is a member of DPDgroup, the largest parcel delivery network in
          Europe. DPDgroup is the parcel delivery network of GeoPost, a holding
          company owned by France&apos;s La Poste.
        </Normal>
        <Normal>
          The company operates more than 10,000 vehicles from 84 locations and
          delivers over 400 million parcels a year.
        </Normal>
      </>
    ),
  },
  {
    id: 23,
    date: "September 2022",
    dateTitle: "20 September 2022",
    country: "France",
    category: "Sustainability",
    title:
      "GeoPost/DPDgroup joins non-profit Climate Group's EV100+ initiative on road freight transport",
    cardTitle:
      "GeoPost/DPDgroup joins non-profit Climate Group's EV100+ initiative",
    imgs: [img23],
    body: (
      <>
        <Header3>
          The company is joining forces with IKEA, Unilever, JSW Steel and
          Maersk.
        </Header3>
        <Normal>
          <ul>
            <li>
              GeoPost/DPDgroup joins a new zero emission{" "}
              <AnchorLink
                to="https://www.dpd.com/group/en/news/geopost-joins-climate-group-ev100-initiative/#_ftn1"
                externalLink={true}
                linkColor={variables.linkColor}
              >
                [1]
              </AnchorLink>{" "}
              road transport leadership commitment, EV100+, launched today by
              international non-profit Climate Group at this year&apos;s Climate
              Week NYC.
            </li>
            <li>
              As one of the initiative&apos;s five founding members,
              GeoPost/DPDgroup commits to transitioning its fleet of vehicles
              over 7.5 tonnes to zero tailpipe emissions by 2040.
            </li>
            <li>
              By tackling the heaviest and most polluting vehicles on its fleet,
              the company marks the logical next step on its climate action
              journey and strengthens its overall commitments to
              decarbonisation.
            </li>
          </ul>
        </Normal>
        <Normal>
          Today, GeoPost/DPDgroup joins an ambitious initiative, led by
          international non-profit organisation Climate Group, to accelerate the
          decarbonisation of its operations and encourage wider action across
          the road transport sector.
        </Normal>
        <Normal>
          GeoPost/DPDgroup reaffirms its ambition to become the international
          reference in sustainable delivery in the context of rapid business
          growth. After recently announcing its ambition to reach net zero by
          2040, GeoPost/DPDgroup is pushing ahead on its journey by tackling the
          decarbonisation of medium- and heavy-duty vehicles
          (MHDVs).GeoPost/DPDgroup reaffirms its ambition to become the
          international reference in sustainable delivery in the context of
          rapid business growth. After recently announcing its ambition to reach
          net zero by 2040, GeoPost/DPDgroup is pushing ahead on its journey by
          tackling the decarbonisation of medium- and heavy-duty vehicles
          (MHDVs).
        </Normal>
        <Normal>
          As a founding member of EV100+, the company commits to transitioning
          its fleet of vehicles over 7.5 tonnes to zero tailpipe emissions by
          2040. The company will also target the exclusive procurement of such
          vehicles in the medium-duty segment by 2030.
        </Normal>
        <Normal>
          GeoPost/DPDgroup will collaborate with its Business Units{" "}
          <AnchorLink
            to="https://www.dpd.com/group/en/news/geopost-joins-climate-group-ev100-initiative/#_ftn2"
            externalLink={true}
            linkColor={variables.linkColor}
          >
            [2]
          </AnchorLink>{" "}
          and transport partners to actively innovate and drive action towards
          these goals.
        </Normal>
        <Normal>
          Commenting on this key announcement for the company, Jean-Claude
          Sonet, Executive Vice President in charge of Marketing, Communication
          and Sustainability at GeoPost/DPDgroup, says:
        </Normal>
        <Normal>
          “We are committed to sustainable delivery and have set ourselves a
          bold ambition to reach net zero by 2040. Yet success will depend on
          our ability to transition our medium- and heavy-goods vehicles to zero
          tailpipe emissions by 2040. All sectors must work together if society
          is to be meet global climate goals. We are therefore excited to work
          alongside other like-minded businesses to send a powerful message to
          both governments and manufacturers that we believe the future of
          trucking is zero emission.”
        </Normal>
        <Normal>
          GeoPost/DPDgroup&apos;s medium- and heavy-duty vehicles
          decarbonisation plan
        </Normal>
        <Normal>
          According to Climate Group, MHDVs represent just 4% of all vehicles on
          the road globally but account for 40% of all road transport emissions
          and a third of total transport fuel use. These vehicles produced over
          5% of total global CO2 emissions in 2019, predicted to increase to
          over 11% by 2050 if steps are not taken.
        </Normal>
        <Normal>
          In line with its net zero by 2040 ambition, GeoPost/DPDgroup is
          working to transition and grow its fleet of medium- and heavy-duty
          vehicles towards alternative powertrain technologies, notably electric
          and hydrogen. The company expects to increase its fleet of alternative
          powertrain technologies to 50% by 2030 and 100% by 2040.
          GeoPost/DPDgroup is already running two electric trucks in
          Switzerland; one e-truck is expected to save 90 kilograms of CO2 per
          hundred kilometres covered compared to equivalent diesel-powered
          trucks, corresponding to 72 tonnes of CO2 saved per year.
        </Normal>
        <Normal>
          “We&apos;re very excited to launch EV100+ at this year&apos;s Climate
          Week NYC. It&apos;s a great demonstration of leadership from the
          founding members. MHDVs represent the final frontier of zero emission
          road transportation, and EV100+ will tackle the heaviest, most
          polluting vehicles across the world.”
        </Normal>
        <Normal>
          comments Sandra Roling, Director of Transport at Climate Group.
        </Normal>
        <Normal>
          “Welcoming DPDgroup, one of the key players of delivery worldwide, as
          active member of EV100+ represents a powerful lever of action in the
          fight against climate change.”
        </Normal>
        <Normal>
          EV100+ members are expected to submit, and regularly review, their
          roadmaps to 2040 and report annually to Climate Group on their
          progress. The commitment is based on the understanding that members
          will aim to source only renewable electricity and green hydrogen for
          recharging and refuelling its medium- and heavy-goods vehicles by
          2040.
        </Normal>
        <Normal>
          Sustainability at the core of GeoPost/DPDgroup&apos;s strategy
        </Normal>
        <Normal>
          As the largest parcel delivery network in Europe, the company has
          experienced strong and rapid growth in recent years. Since 2012,
          GeoPost/DPDgroup has been taking meaningful action to improve the
          environmental performance of its network:
        </Normal>
        <Normal>
          <ul>
            <li>
              The company has been carbon neutral since 2012 and compensates all
              its residual transport and buildings emissions through credible,
              certified renewable energy projects in Brazil, India and
              Indonesia.
            </li>
            <li>
              The company is well on track to deliver to 350 cities in Europe
              (reaching 110 million Europeans) with low-emission delivery means
              by 2025 - through 15,000 low-emission delivery vehicles, 6,700
              charging points and 250 urban depots.
            </li>
            <li>
              This will result in an 83% reduction of carbon emissions and a 95%
              reduction of air pollutants in targeted cities.
            </li>
            <li>
              In addition, given the impact of air quality on people&apos;s
              health, GeoPost/DPDgroup is partnering with Pollutrack to monitor
              air quality in 20 European cities by the end of 2022.
            </li>
          </ul>
        </Normal>
        <Normal>
          On 11 July 2022, GeoPost/DPDgroup marked a further step and submitted
          a 2040 net-zero ambition to the Science Based Target initiative. By
          doing so, the company will contribute to help limiting global
          temperature increases to 1.5°C by accelerating its greenhouse gas
          (GHG) emissions reduction plan. To achieve this, GeoPost/DPDgroup
          commits to drastically reduce its scope 1, 2 and 3* greenhouse gas
          (GHG) emissions:
        </Normal>
        <Normal>
          <ul>
            <li>by 43% by 2030 from a 2020 baseline</li>
            <li>by 90% by 2040 from a 2020 baseline</li>
            <li>
              the company will also neutralise all residual emissions (around
              10%) through relevant offsetting projects from 2040 onwards.
            </li>
          </ul>
        </Normal>
        <Normal>*Scope 1, 2, 3</Normal>
        <Normal>
          Scope 1 emissions are direct emissions from company-owned and
          controlled resources.
        </Normal>
        <Normal>
          Scope 2 emissions are indirect emissions from the generation of
          purchased energy.
        </Normal>
        <Normal>
          Scope 3 emissions are all indirect emissions - not included in scope 2
          - that occur in the value chain
        </Normal>
        <Normal>(Source: GHG Protocol)</Normal>
        <hr />
        <Normal>About GeoPost/DPDgroup</Normal>
        <Normal>
          DPDgroup is one of the world leading parcel delivery networks, aiming
          to be a reference in sustainable delivery and become a leading enabler
          of e-commerce acceleration.
        </Normal>
        <Normal>
          We combine innovative technology and local knowledge to provide a
          flexible and user-friendly service for both shippers and shoppers.
        </Normal>
        <Normal>
          With 120,000 delivery experts operating in nearly 50 countries, and a
          network of 70,000 Pickup points, we deliver 8.4 million parcels
          worldwide each day - 2.1 billion parcels per year - through the brands
          DPD, Chronopost, SEUR, BRT and Jadlog.
        </Normal>
        <Normal>
          DPDgroup is the parcel delivery network of GeoPost. GeoPost posted
          sales of €14.7 billion in 2021. GeoPost is a holding company owned by
          La Poste Groupe.
        </Normal>
        <Normal>About EV100+</Normal>
        <Normal>
          EV100+ is international non-profit Climate Group&apos;s new global
          commitment designed to create a strong corporate demand signal and
          help unlock a revolution in both zero-emission trucking and other
          heavy-duty vehicles.
        </Normal>
        <Normal>
          EV100+ builds on the success of Climate Group&apos;s global EV100
          initiative, launched in 2017 and now celebrating its fifth
          anniversary, which brings together companies committed to making
          electric transport the new normal by 2030. In five years, EV100 has
          grown to a group of over 120 businesses right across the globe,
          committing over 5.5 million vehicles to electric by 2030.
        </Normal>
        <Normal>About Climate Group</Normal>
        <Normal>
          Climate Group drives climate action. Fast. Our goal is a world of net
          zero carbon emissions by 2050, with greater prosperity for all. We
          focus on systems with the highest emissions and where our networks
          have the greatest opportunity to drive change. We do this by building
          large and influential networks and holding organisations accountable,
          turning their commitments into action. We share what we achieve
          together to show more organisations what they could do. We are an
          international non-profit organisation, founded in 2004, with offices
          in London, Amsterdam, Beijing, New Delhi and New York. We are proud to
          be part of the We Mean Business coalition. Follow us on Twitter{" "}
          <AnchorLink
            to="https://twitter.com/ClimateGroup?ref_src=twsrc%5Egoogle%7Ctwcamp%5Eserp%7Ctwgr%5Eauthor"
            externalLink={true}
            linkColor={variables.linkColor}
          >
            @ClimateGroup
          </AnchorLink>
          .
        </Normal>
        <Normal>
          <AnchorLink
            to="https://www.dpd.com/group/en/news/geopost-joins-climate-group-ev100-initiative/#_ftnref1"
            externalLink={true}
            linkColor={variables.linkColor}
          >
            [1]
          </AnchorLink>{" "}
          Zero emissions at the tailpipe
        </Normal>
        <Normal>
          <AnchorLink
            to="https://www.dpd.com/group/en/news/geopost-joins-climate-group-ev100-initiative/#_ftnref2"
            externalLink={true}
            linkColor={variables.linkColor}
          >
            [2]
          </AnchorLink>{" "}
          20 Business Units across Europe, all of which are under
          GeoPost/DPDgroup&apos;s full ownership. These businesses represent 90%
          of the company&apos;s revenue: BRT, Chronopost, DPD Belux, DPD
          Croatia, DPD Czech Republic, DPD Estonia, DPD France, DPD Germany, DPD
          Hungary, DPD Ireland, DPD Latvia, DPD Lithuania, DPD Netherlands, DPD
          Poland, DPD Portugal, DPD Slovakia, DPD Slovenia, DPD Switzerland, DPD
          UK, SEUR
        </Normal>
        <Normal></Normal>
        <Normal></Normal>
        <Normal></Normal>
        <Normal></Normal>
      </>
    ),
  },
  {
    id: 22,
    date: "September 2022",
    dateTitle: "9 September 2022",
    country: "Estonia",
    category: "Technology",
    title: "Self-driving delivery robot for the first time in Estonia",
    cardTitle: "Self-driving delivery robot for the first time in Estonia",
    imgs: [img22],
    body: (
      <>
        <Header3>
          Starting this Thursday, all Viljandi residents who bought a domestic
          appliance from the Euronics store can order its delivery to their
          doorstep with Clevon&apos;s self-driving delivery robot - for the
          first time in Estonia. On Thursday, the delivery robot delivered the
          first package to Aage Õunap, a Viljandi resident who this year was
          awarded the title of the mother of the year and who is the president
          of the Estonian Association of Large Families.
        </Header3>
        <Normal>
          This is the first time that the delivery robots created by Clevon
          (formerly Cleveron Mobility) are used in the retail trade and for the
          delivery of packages to private individuals. What makes Clevon&apos;s
          delivery courier CLEVON 1 unique is the fact that it is a vehicle
          resembling a smaller car which takes the goods to the customer while
          driving on a roadway. This is why Clevon&apos;s robots are able to
          deliver goods quickly, and the robot can pick up medium-sized domestic
          appliances and electronics. The project was realized in cooperation
          between Euronics, the largest electronics retailer in Estonia,
          international courier company DPD, and Clevon, a manufacturer of
          autonomous robots.
        </Normal>
        <Normal>
          The delivery robot will work for Euronics customers in Viljandi until
          23 September, and during that time every customer will be able to
          order delivery of the goods purchased from the Euronics store within
          the city limits of Viljandi with a self-driving autonomous robot,
          which will take the goods to the customer&apos;s doorstep. The service
          can be used for smaller and medium-sized home electronics, the outer
          dimensions of the package of which are up to 140x65x85cm and the
          weight of which does not exceed 100kg - for example, vacuum cleaners,
          microwave ovens and the like. However, to deliver large household
          appliances such as washing machines or refrigerators, the customer
          will need to order a standard transport service. The time required for
          one delivery is estimated to take about half an hour - 15 minutes to
          pick up the package and 15 minutes to deliver it to the customer.
        </Normal>
        <Normal>
          Automation of the journey of the goods is one of the long-term goals
          of Euronics, EstoniaZ&apos;s largest seller of home appliances and
          electronics.
        </Normal>
        <Normal>
          “The robot warehouse that opened in the spring, in the light of
          ever-increasing sales volumes has made it possible to save a
          significant amount of electricity and time, while simultaneously
          speeding up the order picking and shipping process many times over,”
        </Normal>
        <Normal>said Kaidi Kelt, retail sales manager of Euronics.</Normal>
        <Normal>
          “While today the first mile in our central warehouse is already
          covered by robots, with the Viljandi pilot project we are also
          automating the so-called last mile, i.e. the transport of goods to the
          end customer, in order to further speed up delivery of goods to
          customers.”
        </Normal>
        <Normal>
          DPD Estonia, which already today delivers all parcels in a
          carbon-neutral manner, sees delivery robots as an opportunity to
          further reduce the environmental footprint and to encourage consumers
          to choose package transport options that are less burdensome to the
          environment.
        </Normal>
        <Normal>
          “The decision about the last mile of package transport, or how the
          package reaches the customer, is usually up to the customer. The test
          period allows consumers to get to know the new innovative solutions
          more closely, while we can collect feedback necessary for the
          development of the service,”
        </Normal>
        <Normal>added Remo Kirss, CEO of DPD Estonia.</Normal>
        <Normal>
          Clevon delivery robots that will be driving on the streets of Viljandi
          are street legal and 100 percent electric, traveling up to 100
          kilometres on a single charge. Electric autonomous vehicles of the
          last mile make it possible to reduce the noise and pollution level of
          the urban environment in a modern and sustainable way, without forcing
          the customers to abandon the already common comforts of ordering
          packages and food to their home or office.
        </Normal>
        <Normal>
          “Estonia is an ideal country for the development and testing of unique
          new technology solutions, and so far, our delivery robots have
          integrated into everyday traffic very well,”
        </Normal>
        <Normal>added Arno Kütt, chairman of the board of Clevon.</Normal>
        <Normal>
          “Today we are starting a test period on the streets of Viljandi, but
          the ultimate goal is to make our technology available to the entire
          society and for it to be used as a preferred delivery solution.”
        </Normal>
        <Normal>
          All Euronics customers within the city of Viljandi can order a
          delivery robot on weekdays from 12:30 to 15:30. The last shipment will
          reach the customer no later than at 16:30. The autonomous robot sends
          an SMS notification to the customer when the robot starts moving, and
          when it arrives at the customer&apos;s address. Then it waits for 10
          minutes after reaching the customer, following which the customer
          receives a call. If nobody can be reached, the robot returns to the
          store, and the package will have to be picked up from there.
        </Normal>
        <Normal>About DPD Estonia</Normal>
        <Normal>
          DPD Estonia is a leading domestic and international parcel delivery
          service provider, serving both private and business customers in
          Estonia with nearly 260 couriers and 250 vehicles (15 of which are
          fully electric vans). In addition, DPD Estonia employs more than 170
          people. DPD currently has 256 parcel machines in Estonia.
        </Normal>
      </>
    ),
  },
  {
    id: 21,
    date: "September 2022",
    dateTitle: "7 September 2022",
    country: "Spain",
    category: "Sustainability",
    title:
      "SEUR celebrates its 80th anniversary as a leader in e-commerce with a focus on sustainability",
    cardTitle:
      "SEUR celebrates its 80th anniversary as a leader in e-commerce with a focus on sustainability",
    imgs: [img21],
    body: (
      <>
        <Normal>
          <ul>
            <li>
              80 years ago SEUR introduced express transport in our country.
              Today, integrated into the international DPDgroup network, it
              continues to transform itself every day to become a leader in
              e-commerce services.
            </li>
            <li>
              The company has just launched its space in Canalejas 360, the new
              and innovative electric mobility hub in the city of Madrid. The
              SEUR space will manage the last mile with ecological vehicles and
              will have a point of sale for order management.
            </li>
          </ul>
        </Normal>
        <Normal>
          SEUR, a pioneering company in introducing express transport in Spain,
          is celebrating its 80th anniversary as a leader in the sector in our
          country, thanks to its three main lines of business: ecommerce,
          international business and B2B services.
        </Normal>
        <Normal>
          80 years committed to innovation as the core of its strategy
        </Normal>
        <Normal>
          SEUR has always been focused on innovation and the latest technology
          at its disposal as the basis for its growth and for the development of
          new solutions that provide greater flexibility, swiftness and
          convenience to its customers, whether they are private users or
          companies of any size.
        </Normal>
        <Normal>
          One of the biggest challenges faced by the company in recent years,
          and the sector in general, has been adapting to the demands and new
          needs of online consumers. A market that has been expanding to almost
          all sectors, such as food and fresh produce, which last year, after
          the impact of the pandemic, continued to grow and experienced an
          increase of 34% compared to 2020. SEUR was able to cope with this
          increase thanks to SEUR frío. With this solution, the company
          transports food at a controlled temperature within 24 hours. The
          organization invested more than one million euros for this service in
          an innovative system, based on the Internet of Things (IoT), which
          guarantees real-time temperature traceability from the moment of
          pick-up to delivery.
        </Normal>
        <Normal>
          In order to respond to the immediacy required by e-commerce, SEUR has
          continued to develop innovative solutions such as SEUR Now, its
          express delivery service in one or two hours, which is now in its 25th
          anniversary. It has also launched different out of home solutions that
          allow customers to choose where and when to pick up their orders. The
          SEUR Pick up network now has more than 3,000 points of sale and
          lockers throughout Spain, facilitating the collection of online orders
          for all the company&apos;s customers and it plans to reach 5,000 by
          2025.
        </Normal>
        <Normal>Innovation in the centre of Madrid: Canalejas HUB</Normal>
        <Normal>
          Combining its innovative spirit with its sustainability strategy, the
          company has just opened a space in the recently inaugurated Canalejas
          360, the new electric mobility hub in the heart of the city centre of
          Madrid, which houses the most powerful urban electro-lane in Spain.
          SEUR will manage 140 daily shipments from this point with ecological
          vehicles, such as electric bicycles, as well as serving as a pick-up
          point for deliveries and returns, and it will deliver luggage to
          hotels in the area, since it is a point of high tourist traffic.
        </Normal>
        <Normal>
          The challenge of becoming the international reference of sustainable
          delivery
        </Normal>
        <Normal>
          SEUR has set itself the goal of becoming the most sustainable
          logistics operator internationally, an ambition led by the DPDgroup,
          the group to which the company belongs. To this goal, it has launched
          an ambitious strategy, consisting of low-emission deliveries in 64
          Spanish cities by 2025, and reducing CO2 emissions in these locations
          by around 85%, which will have a direct impact on 17 million
          inhabitants throughout Spain. To this end, the company will invest
          more than 4 million euros during 2022, and will incorporate 1,500
          low-emission vehicles by 2025.
        </Normal>
        <Normal>
          At the group level, DPDgroup has recently submitted its
          decarbonisation pathway to the SBTi (Science Based Target initiative)
          with the aim of achieving net zero emissions by 2040; that is, ten
          years ahead of the Paris Agreement
        </Normal>
        <Normal>SEUR in figures</Normal>
        <Normal>
          SEUR closed 2021 with a turnover of 875 million euros, an increase of
          5% compared to the previous year. In the ecommerce segment, the
          company had a revenue of 400 million euros, representing 50% of its
          business. International sales accounted for more than 30% of revenue
          last year, reaching 275 million euros.
        </Normal>
        <Normal>
          SEUR has an investment plan of 250 million euros by 2025 earmarked for
          new technological developments to improve daily operations and
          customer relations, as well as to adapt its infrastructure network to
          business growth.
        </Normal>
        <Normal>About SEUR</Normal>
        <Normal>
          Our 80 years of history have allowed us to be pioneers in express
          transport in Spain, leading the sector with three main lines of
          business: international, e-commerce and the refrigerated service
          focused on online food.
        </Normal>
        <Normal>
          Thanks to our 10,000 professionals, we serve companies of all sizes
          and sectors, and as part of DPDgroup, one of the largest international
          express transport networks, we deliver all over the world.
        </Normal>
        <Normal>
          We constantly invest in innovation and infrastructures to be closer to
          our customers and offer them greater flexibility through solutions
          such as Predict, an interactive system for arranging delivery, or SEUR
          Now, for super-urgent deliveries in one or two hours. We are committed
          to sustainable logistics with the integration of alternative delivery
          systems in large cities such as the use of ecological vehicles, urban
          hubs or our network of Pickup points with more than 3,000 convenience
          stores and lockers.
        </Normal>
      </>
    ),
  },
  {
    id: 20,
    date: "September 2022",
    dateTitle: "1 Septemeber 2022",
    country: "Thailand",
    category: "Fulfilment",
    title: "Ninja Van Thailand opens its largest hub",
    cardTitle: "Ninja Van Thailand opens its largest hub",
    imgs: [img20],
    body: (
      <>
        <Header3>
          Spanning over 20,000 square meters, the new hub is part of the
          company&apos;s plan to future-proof its volume growth trajectory
        </Header3>
        <Normal>
          Ninja Van Thailand, Southeast Asia&apos;s leading logistics provider,
          opens its new hub in Bang Saothong, Samut Prakan province. Spanning
          over 20,000 square meters, the warehouse is their biggest yet, in line
          with the company&apos;s plan to future-proof its volume growth
          trajectory.
        </Normal>
        <Normal>
          “Since COVID-19 pandemic in 2020, there has been a significant
          increase in the number of people who have made purchases online. Eight
          out of 10 people in Southeast Asia have made at least one purchase
          online while there are nine out 10 people in Thailand. From 2020 to
          2021, the pandemic accelerated the spending growth by an overwhelming
          68% CAGR. The CAGR growth is predicted to continue from 2021 to 2025
          albeit at a slower pace. Borders opening and inflation leading to
          recession speculation could contribute to the softer growth. However,
          the online spending forecast in the top three sectors combined is
          still lower than the e-commerce market spending alone. 1 There is a
          need for the logistics industry to scale up to handle the growth.
          Foresight and early planning is required to cater to the needs of our
          users. At Ninja Van Thailand, we are constantly innovating and
          developing better infrastructure for increased speed and efficiency
          and we started planning for our new hub last June 2020 in anticipation
          of the volume growth. Equipped with new sorting machinery, this is
          part of our efforts to enhance our express delivery capabilities,”
        </Normal>
        <Normal>
          said Mr. Pierce Ng, Chief Operating Officer of Ninja Van Thailand.
        </Normal>
        <Normal>
          The new hub can accommodate more than 800,000 parcels per day.
          Designed in an I-shape, it allows for parcels to be processed in a
          straight line, thereby increasing the sorting process by 40% or 80,000
          parcels per day than the existing hub. The newly installed
          four-kilometer semi-automated parcel sorting conveyor belt and 400
          loading docks will also increase sorting efficiency by 200%, resulting
          in an overall increase in delivery speed by up to four hours.
        </Normal>
        <Normal>
          In line with Ninja Van Thailand&apos;s aim of providing hassle-free
          delivery experiences for its users, the company is also increasing its
          delivery channels through its newly launched mobile application -
          Mobile Biz. Through partnership with SABUY Group, more Ninja Points
          are opened up, allowing for easier and more convenient access to Ninja
          Van&apos;s services.
        </Normal>
        <Normal>
          On top of providing safe and fast parcel delivery, Ninja Van Thailand
          also aims to deliver beyond logistics solutions to its shippers
          through its recently launched point-based brand loyalty 1 Source,
          Google e-conomy SEA 2021 report,
          https://economysea.withgoogle.com/report/fact/continued-growth-fact-1
          programme, Ninja Rewards. By accumulating Ninja Points, it allows
          shippers to redeem prizes, discounts or other privileges from the
          programme such as bill rebate, cashback or parcel shipping supplies.
        </Normal>
        <Normal>
          <strong>About Ninja Van</strong>
        </Normal>
        <Normal>
          Ninja Van is a leading tech-enabled express logistics company
          providing supply chain solutions for businesses of all sizes across
          Southeast Asia. Launched in 2014, Ninja Van started operations in
          Singapore as a last-mile logistics company. Since then, it has become
          one of the region’s fastest-growing tech logistics companies, powering
          businesses with innovative solutions that optimise e-commerce
          opportunities. Today, Ninja Van has grown its network to cover six
          countries: Singapore, Malaysia, Indonesia, Thailand, Vietnam and
          Philippines. For more information, visit{" "}
          <AnchorLink
            to="https://www.ninjavan.co"
            externalLink={true}
            linkColor={variables.linkColor}
          >
            www.ninjavan.co
          </AnchorLink>
        </Normal>
      </>
    ),
  },
  {
    id: 19,
    date: "July 2022",
    dateTitle: "19 July 2022",
    country: "United Kingdom",
    category: "Technology",
    title:
      "DPD UK adds more choice and convenience with first UK smart lockers",
    cardTitle:
      "DPD UK adds more choice and convenience with first UK smart lockers",
    imgs: [img18],
    body: (
      <>
        <Header3>
          Partnership with Quadient for nationwide network of parcel lockers
        </Header3>
        <Normal>
          Leading parcel delivery firm DPD UK has partnered with global smart
          locker specialist Quadient to access a new nationwide network of smart
          parcel lockers.
        </Normal>
        <Normal>
          Quadient will initially install 500 contactless, secure locker
          stations in the UK this year, with a plan for 5,000 in the coming
          years. 200 of the initial installations will be 24/7 outdoor
          facilities, and locations could include shopping precincts, housing
          estates, serviced car parks and higher education sites.
        </Normal>
        <Normal>
          Parcel recipients will be able to select a locker delivery from their
          DPD delivery notifications. They will then receive a confirmation once
          the parcel is in the locker and can access their parcel using a
          one-time PIN or by scanning the QR code in the notification to open
          the correct locker door.
        </Normal>
        <Normal>
          The initiative is in addition to DPD’s existing nationwide network of
          6,000 Pickup shops, which includes big names like the Post Office,
          Sainsbury’s, Homebase, Argos, Morrisons, Go Outdoors, Matalan and the
          Coop as well as a network of independent convenience stores including
          Collect+.
        </Normal>
        <Normal>Elaine Kerr, DPD UK CEO, comments:</Normal>
        <Normal>
          “We are delighted to be teaming up with Quadient to utilise this new
          parcel locker network. Our parcel recipients already have the option
          to divert a delivery to their chosen Pickup shop using the DPD app or
          from their parcel notifications, but now they will also be able to
          select a smart locker delivery. We know that, for some people, a
          secure and convenient out of home parcel pickup point is going to be
          desirable.”
        </Normal>
        <Normal>
          “For people who are at home or have a reliable safe place or a good
          relationship with their neighbours, home deliveries will remain the
          number one preference, but parcel recipients always tell us they also
          want choice and control over their deliveries. Many households have
          become more reliant on deliveries post-pandemic, but not everyone is
          at home all the time now. So, it is important that we continue to
          develop new smart, safe and secure delivery options. It is really
          exciting to think about the kind of locations that could be most
          convenient for people, going forward such as libraries or maybe even
          pubs. Whichever option parcel recipients choose, our market leading
          technology, including the DPD app, will keep them informed and in
          control every step of the way.”
        </Normal>
        <Normal>
          Ian Caminsky, SVP & MD Parcel Locker Solutions DACHIT/UKI at Quadient
          added:
        </Normal>
        <Normal>
          “We are very proud indeed to be partnering with DPD in the UK, giving
          more people than ever before access to secure, self-service electronic
          parcel lockers. Our Parcel Pending smart lockers are a natural
          extension to DPD’s fantastic range of delivery options, and our
          software integrates perfectly to enhance their real-time customer
          notifications.”
        </Normal>
        <Normal>
          “As the volume of parcels sent in the UK continues to grow, people
          need to be given more delivery options that fit alongside their
          everyday lives. Parcel lockers have an increasingly important part to
          play in the mix: preventing theft, supporting 24/7 availability, and
          helping cut the cost and environmental impact of repeated redelivery
          attempts. We cannot wait to see DPD customers using these parcel
          lockers across the UK.”
        </Normal>
        <Normal>
          <strong>About DPD UK</strong>
        </Normal>
        <Normal>
          DPD UK was awarded the Queen’s Award for Enterprise 2015 in the
          Innovation category, for its unique one-hour delivery service,
          Predict.
        </Normal>
        <Normal>
          In 2018, DPD opened the UK’s first all-electric parcel delivery depot
          in Westminster. DPD currently has over 1,900 EVs on the road in the UK
          and announced plans to deliver to 30 of the largest towns and cities
          in the UK with zero and low-emission delivery means by 2023. In July
          2021, Oxford became DPD’s first all-electric city in the UK. Details
          of all of DPD’s sustainability initiatives can be found on its
          dedicated green website:{" "}
          <AnchorLink
            to="https://green.dpd.co.uk/"
            externalLink={true}
            linkColor={variables.linkColor}
          >
            https://green.dpd.co.uk/
          </AnchorLink>
        </Normal>
        <Normal>
          DPD UK is a member of DPDgroup, the largest parcel delivery network in
          Europe. DPDgroup is the parcel delivery network of GeoPost, a holding
          company owned by France’s La Poste.
        </Normal>
        <Normal>
          The company operates more than 10,000 vehicles from 85 locations and
          delivers over 400 million parcels a year.
        </Normal>
        <Normal>
          <strong>About Quadient®</strong>
        </Normal>
        <Normal>
          Quadient is the driving force behind the world’s most meaningful
          customer experiences. By focusing on three key solution areas,
          Intelligent Communication Automation, Parcel Locker Solutions and
          Mail-Related Solutions, Quadient helps simplify the connection between
          people and what matters. Quadient supports hundreds of thousands of
          customers worldwide in their quest to create relevant, personalised
          connections and achieve customer experience excellence. Quadient is
          listed in compartment B of Euronext Paris (QDT) and is part of the SBF
          120®, CAC® Mid 60 and EnterNext® Tech 40 indices.
        </Normal>
        <Normal>
          For more information about Quadient, visit{" "}
          <AnchorLink
            to="http://www.quadient.com/"
            externalLink={true}
            linkColor={variables.linkColor}
          >
            www.quadient.com.
          </AnchorLink>
        </Normal>
        <Normal>Quadient Press Contact:</Normal>
        <Normal>
          Dominic Walsh, Spark Communications +44 (0)20 7436 0420 or
          quadient@sparkcomms.co.uk
        </Normal>
      </>
    ),
  },
  {
    id: 18,
    date: "July 2022",
    dateTitle: "11 July 2022",
    country: "France",
    category: "Sustainability",
    title:
      "GeoPost/DPDgroup accelerates in the fight against climate change and submits its net-zero target to the Science Based Target initiative",
    cardTitle:
      "GeoPost/DPDgroup accelerates in the fight against climate change",
    imgs: [img17],
    body: (
      <>
        <Normal>
          <ul>
            <li>
              In a context of rapid growth in the e-commerce and parcel delivery
              sectors, GeoPost/DPDgroup has today submitted both a near-term
              2030 decarbonization and an ambitious long-term 2040 net-zero
              target for approval by the Science Based Target initiative (SBTi).
              This step demonstrates GeoPost/DPDgroup’s ambition to lead on
              net-zero emissions in the European parcel delivery market.
            </li>
            <li>
              As a pioneer in its sector, the company is taking decisive action
              to achieve net-zero emissions by 2040 and commits to reduce its
              scope 1, 2 and 3 greenhouse gas (GHG) emissions by 43% by 2030 and
              90% by 2040 from a 2020 baseline– 10 years ahead of the Paris
              Agreement.
            </li>
            <li>
              SBTi provides companies with a clearly defined path to reduce
              emissions in line with the goals of the Paris Agreement.
              GeoPost/DPDgroup expects to achieve SBTi approval in early 2023.
            </li>
          </ul>
        </Normal>
        <Normal>
          Today, GeoPost/DPDgroup submitted a net-zero ambition to the Science
          Based Target initiative, marking a further step on its climate action
          journey. The company has submitted a near-term science-based target
          for 2030 in addition to its target to reach net zero emissions by
          2040, 10 years ahead of the Paris Agreement, thus enhancing its
          overall decarbonization ambition.
        </Normal>
        <Normal>
          GeoPost/DPDgroup reaffirms its ambition to become the international
          reference in sustainable delivery amid rapid business growth. In this
          context, the company will contribute to limiting global temperature
          increases to 1.5°C by accelerating its greenhouse gas (GHG) emissions
          reduction plan.
        </Normal>
        <Normal>
          To mark its leadership, the company is taking decisive action to
          achieve net-zero by 2040, and commits to drastically reduce its scope
          1, 2 and 3* greenhouse gas (GHG) emissions:
        </Normal>
        <Normal>
          <ul>
            <li>by 43% by 2030 from a 2020 baseline</li>
            <li>by 90% by 2040 from a 2020 baseline</li>
            <li>
              the company will also neutralise all residual emissions (around
              10%) through relevant offsetting projects from 2040 onwards.
            </li>
          </ul>
        </Normal>
        <Normal>
          Commenting on this key announcement for the company, Yves Delmas,
          Chief Executive Officer of GeoPost/DPDgroup, says:
        </Normal>
        <Normal>
          <em>
            “At DPDgroup, we are strongly committed to energy transition, in
            line with our leading role in the business of e-commerce and
            delivery solutions. Today, we resolutely accelerate on our
            decarbonization pathway and commit to a more ambitious level of
            action. Our goal is to play a pivotal role for our customers and
            support them in developing an ever-more virtuous business model. It
            is a huge step, but I am confident we can achieve it, as our teams
            are fully engaged to drive change and meet the highest challenge of
            our times.”
          </em>
        </Normal>
        <Normal>
          <strong>
            GeoPost/DPDgroup’s accelerated decarbonization pathway
          </strong>
        </Normal>
        <Normal>
          To achieve net-zero by 2040, the group will be focusing on:
        </Normal>
        <Normal>
          <ul>
            <li>
              Diversifying the linehaul fleet to include alternative powertrain
              technologies such as biogas, biofuel, electric and
              hydrogen-powered trucks, as well as shifting more parcels to rail
              to increase the share of alternative linehaul transport solutions
              to 50% by 2030 (100% in 2040).
            </li>
            <li>
              Switching to renewable energy to power logistics sites and offices
              and driving towards more energy efficiency, with the objective of
              using 70% of energy from renewable sources by 2030 (100% in 2035).
            </li>
            <li>
              Electrifying the fleet of company cars and on-site freight
              <AnchorLink
                to="https://www.dpd.com/group/en/news/dpdgroup-sbti-net-zero-target/#_ftn1"
                externalLink={true}
                linkColor={variables.linkColor}
              >
                [1]
              </AnchorLink>{" "}
              by increasing the share of electric vehicles to 90% by 2030 (100%
              in 2035).
            </li>
          </ul>
        </Normal>
        <Normal>
          This net zero target covers our 20 largest business units in Europe
          <AnchorLink
            to="https://www.dpd.com/group/en/news/dpdgroup-sbti-net-zero-target/#_ftn2"
            externalLink={true}
            linkColor={variables.linkColor}
          >
            [2]
          </AnchorLink>
          . It is aligned with the most ambitious GHG emissions reduction
          pathwayto limit global temperature increases to 1.5°C versus
          preindustrial levels.
        </Normal>
        <Normal>
          GeoPost/DPDgroup expects to have its decarbonization trajectory
          validated by SBTi by Q1 2023.
        </Normal>
        <Normal>
          This important new milestone in GeoPost/DPDgroup’s decarbonization
          strategy is in line with La Poste Groupe’s objective to reach net-zero
          emissions before 2050.
        </Normal>
        <Normal>
          <strong>
            Sustainability at the core of GeoPost/DPDgroup’s strategy
          </strong>
        </Normal>
        <Normal>
          As the largest parcel delivery network in Europe, the company has
          experienced strong and rapid growth in recent years. Since 2012,
          GeoPost/DPDgroup has continuously taking meaningful action to work
          towards a more sustainable future:
        </Normal>
        <Normal>
          <ul>
            <li>
              The group has been carbon neutral since 2012 and compensates all
              its residual transport and buildings emissions through credible,
              certified renewable energy projects in Brazil, India and
              Indonesia.
            </li>
            <li>
              The group is well on track to deliver to 350 cities in Europe
              (reaching 110 million Europeans) with low-emission delivery means
              by 2025 – through 15,000 low-emission delivery vehicles, 6,700
              charging points and 250 urban depots.
            </li>
            <li>
              This will result in an 83% reduction of carbon emissions and a 95%
              reduction of air pollutants in targeted cities.
            </li>
            <li>
              In addition, given the impact of air quality on people’s health,
              GeoPost/DPDgroup is partnering with Pollutrack to monitor air
              quality in 20 European cities by the end of 2022.
            </li>
          </ul>
        </Normal>
        <Normal>Key Definitions</Normal>
        <Normal>
          <em>About the Science Based Target initiative (SBTi)</em>
        </Normal>
        <Normal>
          <em>
            “The Science Based Targets initiative (SBTi) is a global body
            enabling businesses to set ambitious emissions reductions targets in
            line with the latest climate science. It is focused on accelerating
            companies across the world to halve emissions before 2030 and
            achieve net-zero emissions before 2050. The initiative is a
            collaboration between Carbon Disclosure Project (CDP), the United
            Nations Global Compact, World Resources Institute (WRI) and the
            World Wildlife Fund for Nature (WWF) and one of the We Mean Business
            Coalition commitments. The SBTi defines and promotes best practice
            in science-based target setting, offers resources and guidance to
            reduce barriers to adoption, and independently assesses and approves
            companies’ targets.”
          </em>
        </Normal>
        <Normal>(Source: Science Based Target initiative)</Normal>
        <Normal>
          <em>Net Zero according to SBTi</em>
        </Normal>
        <Normal>
          <em>
            “State of net-zero emissions: Companies shall set one or more
            targets to reach a state of net-zero emissions, which involves: (a)
            reducing their scope 1, 2 and 3 emissions to zero or to a residual
            level that is consistent with reaching net-zero emissions at the
            global or sector level in eligible 1.5°C scenarios or sector
            pathways and; (b) neutralizing any residual emissions at the
            net-zero target date and any GHG emissions released into the
            atmosphere thereafter.”
          </em>
        </Normal>
        <Normal>(Source: SBTi Net Zero Standard).</Normal>
        <Normal>
          <em>*Scope 1, 2, 3</em>
        </Normal>
        <Normal>
          <em>
            Scope 1 emissions are direct emissions from company-owned and
            controlled resources.
          </em>
        </Normal>
        <Normal>
          <em>
            Scope 2 emissions are indirect emissions from the generation of
            purchased energy.
          </em>
        </Normal>
        <Normal>
          <em>
            Scope 3 emissions are all indirect emissions – not included in scope
            2 – that occur in the value chain.
          </em>
        </Normal>
        <Normal>(Source: GHG Protocol)</Normal>
        <Normal>
          <strong>About GeoPost/DPDgroup</strong>
        </Normal>
        <Normal>
          DPDgroup is one of the world leading parcel delivery networks, aiming
          to be a reference in sustainable delivery and become a leading enabler
          of e-commerce acceleration.
        </Normal>
        <Normal>
          We combine innovative technology and local knowledge to provide a
          flexible and user-friendly service for both shippers and shoppers.
        </Normal>
        <Normal>
          With 120,000 delivery experts operating in nearly 50 countries, and a
          network of 70,000 Pickup points, we deliver 8.4 million parcels
          worldwide each day – 2.1 billion parcels per year – through the brands
          DPD, Chronopost, SEUR, BRT and Jadlog.
        </Normal>
        <Normal>
          DPDgroup is the parcel delivery network of GeoPost. GeoPost posted
          sales of €14.7 billion in 2021. GeoPost is a holding company owned by
          La Poste Groupe.
        </Normal>
        <Normal>
          <AnchorLink
            to="https://www.dpd.com/group/en/news/dpdgroup-sbti-net-zero-target/#_ftnref1"
            externalLink={true}
            linkColor={variables.linkColor}
          >
            [1]
          </AnchorLink>{" "}
          Forklifts and trailer shunters
        </Normal>
        <Normal>
          <AnchorLink
            to="https://www.dpd.com/group/en/news/dpdgroup-sbti-net-zero-target/#_ftnref2"
            externalLink={true}
            linkColor={variables.linkColor}
          >
            [2]
          </AnchorLink>{" "}
          Business Units covered by the net-zero ambition: BRT (Italy),
          Chronopost (France), DPD Belgium, DPD Croatia DPD Czech Republic, DPD
          Estonia, DPD France DPD Germany, DPD Hungary, DPD Ireland, DPD Latvia,
          DPD Lithuania, DPD Netherlands, DPD Poland, DPD Portugal, DPD
          Slovakia, DPD Slovenia, DPD Switzerland, DPD UK, SEUR (Spain).
        </Normal>
      </>
    ),
  },
  {
    id: 17,
    date: "July 2022",
    dateTitle: "7 July 2022",
    country: "France",
    category: "e-Commerce",
    title: "Asendia celebrates its 10th anniversary",
    cardTitle: "Asendia celebrates its 10th anniversary",
    imgs: [img16],
    body: (
      <>
        <Normal>
          <ul>
            <li>
              The e-commerce company has achieved continuous growth,
              transforming its business since launching in 2012
            </li>
            <li>
              Acquisitions of ESW and Scalefast and investment in Anchanto have
              powered growth
            </li>
            <li>
              Asendia has also become 100% carbon neutral in this landmark year
            </li>
          </ul>
        </Normal>
        <Normal>
          Asendia, the joint venture between La Poste and Swiss Post, is
          celebrating 10 years of continuous growth and transformation. 2022 is
          marking a decade of customer-centric logistics, helping brands of all
          sizes sell their products into new markets around the world. Having
          reached more than €2 billion annual revenue threshold in 2021, and
          achieved its objective of becoming 100% carbon-neutral, the company is
          celebrating in style. Over 2,000 trees will be planted to mark the
          occasion, one for each employee worldwide.
        </Normal>
        <Normal>
          Focused on supporting brands in their international growth ambitions
          through smart technology and logistics excellence , Asendia invested
          in the SaaS platform Anchanto in (2020) and fully acquired ESW in
          (2021). These acquisitions have enabled Asendia to broaden the range
          of e-commerce solutions it offers retailers and brands. In 2020
          Asendia launched e-PAQ, a product range of global parcel and package
          delivery services, for online retailers selling internationally.
          Thanks to this successful business transformation over the past years,
          Asendia follows its vision to provide brands with digital solutions
          improving consumers’ online shopping experience and giving our
          customers access to global distribution solutions worldwide.
        </Normal>
        <Normal>
          Along with these efforts to make international logistics easy and
          efficient, Asendia is now delivering to over 200 countries with
          subsidiaries in 17 markets, providing a service that spans the globe.
          Asendia makes cross-border commerce easy, reliable and sustainable
          worldwide.
        </Normal>
        <Normal>
          <strong>Sustainability at its foundation</strong>
        </Normal>
        <Normal>
          While Asendia has always focused on sustainability, the company has
          made a particular effort over the past three years to extend its
          sustainability strategy in response to growing concerns about the
          environmental impact of logistic operations. 2022 has made its way
          into Asendia’s history, as the year when the company has become 100%
          carbon neutral. As Asendia does not have its own fleet and relies on
          partners, this was best achieved by offsetting all international
          transport emissions, including first-mile collection, last-mile
          delivery, returns, and building emissions, machinery, and business
          travel.
        </Normal>
        <Normal>
          Sustainability is one of Asendia’s pillars along with its people, know
          internally as “Asendians”, and to celebrate both it has decided to
          plant a tree for every employee to mark the special occasion, which
          will result in 2,000 trees in Jura
          <AnchorLink
            to="https://www.dpd.com/group/en/news/asendia-10th-anniversary/#_ftn1"
            externalLink={true}
            linkColor={variables.linkColor}
          >
            [1]
          </AnchorLink>{" "}
          and Chantilly
          <AnchorLink
            to="https://www.dpd.com/group/en/news/asendia-10th-anniversary/#_ftn2"
            externalLink={true}
            linkColor={variables.linkColor}
          >
            [2]
          </AnchorLink>
          , France.
        </Normal>
        <Normal>
          Speaking about the anniversary and Asendia’s commitment to
          sustainability, Chief HR and CSR Officer Barbara Schielke says:
        </Normal>
        <Normal>
          <em>
            “As one of the largest global logistic providers, we at Asendia have
            the responsibility to protect people and protect our planet. We are
            proud of becoming 100% carbon neutral, and we are excited to have
            achieved this on our 10-year anniversary. However, while we are
            excited to lead the industry, there is a long way to go to protect
            our planet and we will continue to work to find more sustainable
            solutions for our clients.”
          </em>
        </Normal>
        <Normal>
          Marc Pontet Chief Executive Officer, Asendia Group, says:
        </Normal>
        <Normal>
          <em>
            “We are proud of what we have done over the past decade and grateful
            for the trust our clients have in us. We know how important it is to
            choose the right partner when building a customer base worldwide, so
            we are committed to providing a convenient, flexible, reliable
            service, that also takes into consideration vital sustainability
            commitments.”
          </em>
        </Normal>
        <Normal>
          <strong>About Asendia</strong>
        </Normal>
        <Normal>
          Asendia is one of the world&apos;s leaders in international e-commerce
          and mail, delivering packages, parcels and documents to more than 200
          destinations across the globe. Combining the experience and expertise
          of our founding companies, La Poste and Swiss Post, the Asendia Group
          brings together a wealth of international and local know-how.
          Asendia&apos;s expertise is broad reaching: encompassing different
          aspects of e-commerce, from webshop software and marketplace
          management to international logistics. Asendia is committed to
          sustainability, offsetting all CO2 emissions including first-mile
          collection, last-mile delivery, returns, and building emissions,
          machinery, and business travel.
        </Normal>
        <Normal>
          Asendia employs over 1,500 people in Europe, UK, Asia Pacific and the
          USA - a global network blended with a local presence.
        </Normal>
        <Normal>www.asendia.com</Normal>
        <Normal>
          <AnchorLink
            to="https://www.dpd.com/group/en/news/asendia-10th-anniversary/#_ftn1"
            externalLink={true}
            linkColor={variables.linkColor}
          >
            [1]
          </AnchorLink>{" "}
          https://www.reforestaction.com/en/chamblay-3
        </Normal>
        <Normal>
          <AnchorLink
            to="https://www.dpd.com/group/en/news/asendia-10th-anniversary/#_ftn2"
            externalLink={true}
            linkColor={variables.linkColor}
          >
            [2]
          </AnchorLink>{" "}
          https://www.reforestaction.com/en/foret-de-chantilly
        </Normal>
      </>
    ),
  },
  {
    id: 16,
    date: "July 2022",
    dateTitle: "5 July 2022",
    country: "United Kingdom",
    category: "Technology",
    title: "DPD UK to launch autonomous delivery robots in Milton Keynes",
    cardTitle: "DPD UK to launch autonomous delivery robots in Milton Keynes",
    imgs: [img15],
    body: (
      <>
        <Header3>
          Autonomous robots will each deliver up to 30 parcels a day, in two
          neighbourhoods
        </Header3>
        <Normal>
          Leading parcel delivery firm DPD UK is to commence autonomous robot
          deliveries in two Milton Keynes neighbourhoods, as part of a trial
          with AI-powered robotics and last mile delivery company, Cartken.
        </Normal>
        <Normal>
          The{" "}
          <AnchorLink
            to="https://green.dpd.co.uk/"
            externalLink={true}
            linkColor={variables.linkColor}
          >
            DPD
          </AnchorLink>{" "}
          branded robots, which will operate out of the firm’s Knowlhill depot,
          will navigate the city’s traffic-free Redway network to access the
          residential neighbourhoods of Shenley Church End and Shenley Lodge.
        </Normal>
        <Normal>
          Powered by AI technology, the robots are currently ‘learning’ the
          routes and will then be able to find their way to delivery addresses,
          fully autonomously. Cartken’s level 4 autonomy and leading navigation
          tech is regarded as one of the safest last mile delivery solutions on
          the market and is currently being used by automotive tech giant
          Mitsubishi in Japan, and leading food delivery company GrubHub in the
          United States.
        </Normal>
        <Normal>
          <AnchorLink
            to="https://vimeo.com/dpdgroup/review/724861871/af6ab59b2b"
            externalLink={true}
            linkColor={variables.linkColor}
          >
            Video showing a DPD autonomous delivery robot in action in Milton
            Keynes
          </AnchorLink>
        </Normal>
        <Normal>
          DPD parcel recipients will be notified of a robot delivery in advance,
          and once they confirm that they are at home to accept the parcel, the
          robot will be dispatched.
        </Normal>
        <Normal>
          Customers will be able to track the robot’s progress on a map and will
          be notified when it reaches their property. They will then use a code
          to open the secure compartment and access their parcel. Once the
          compartment is closed, the robot will return to the depot for its next
          delivery.
        </Normal>
        <Normal>
          If the trial is successful, DPD UK intends to extend the sustainable,
          autonomous final mile solution across the city, which could enable it
          to start removing traditional delivery vans from the road network.
        </Normal>
        <Normal>Elaine Kerr, DPD UK CEO, comments:</Normal>
        <Normal>
          <em>
            “This is a really important trial for us. We want to understand the
            role that delivery robots could play in certain locations in the UK.
            They are a sustainable solution, and we genuinely want to find out
            if they could help us take vans off the road in future. The
            technology is proven and with the DPD branding, they look fantastic.
            The public’s response to our EV fleet has been amazing, so I am
            confident these smart robots will be embraced by customers as part
            of the way forward.”
          </em>
        </Normal>
        <Normal>
          <em>
            “Realistically, we aren’t going to be completely replacing our
            delivery drivers anytime soon. And we wouldn’t want to. The service
            they provide is fantastic. But at DPD we’ve always led the way on
            innovation and investment in our network and this is the next
            logical step in terms of evolving delivery solutions and working
            towards our aim of being the UK’s most sustainable delivery
            company.”
          </em>
        </Normal>
        <Normal>
          Cartken Co-Founder and COO, Anjali Jindal Naik, said of the project,
        </Normal>
        <Normal>
          <em>
            “We are excited to collaborate with DPD for parcel delivery in the
            UK market. Our robots are designed to provide sustainable solutions
            that integrate into existing city infrastructure. We are thrilled to
            be a part of DPD&apos;s sustainability plan as they adopt more
            environmentally conscious solutions through the use of our delivery
            robots.”
          </em>
        </Normal>
        <Normal>
          The trial is part of a series of innovative green initiatives from DPD
          UK. The firm has already announced plans to have over 3,000 electric
          vehicles on the road in the UK this year and 5,000 by 2023, when it
          will be delivering to 30 towns and cities on all-electric vehicles.
        </Normal>
        <Normal>
          Details on all of DPD UK’s sustainability initiatives can be found on
          its dedicated green website:{" "}
          <AnchorLink
            to="https://green.dpd.co.uk/"
            externalLink={true}
            linkColor={variables.linkColor}
          >
            https://green.dpd.co.uk/
          </AnchorLink>
        </Normal>
        <Normal>
          <strong>About DPD UK</strong>
        </Normal>
        <Normal>
          DPD UK was awarded the Queen’s Award for Enterprise 2015 in the
          Innovation category, for its unique one-hour delivery service,
          Predict.
        </Normal>
        <Normal>
          In 2018, DPD opened the UK’s first all-electric parcel delivery depot
          in Westminster. DPD currently has over 1,700 EVs on the road in the UK
          and in October 2020, announced plans to deliver to 25 of the largest
          towns and cities in the UK with zero and low-emission delivery means
          by 2023. In July 2021, Oxford became DPD’s first all-electric city in
          the UK. Details of all of DPD’s sustainability initiatives can be
          found on its dedicated green website:{" "}
          <AnchorLink
            to="https://green.dpd.co.uk/"
            externalLink={true}
            linkColor={variables.linkColor}
          >
            https://green.dpd.co.uk/
          </AnchorLink>
        </Normal>
        <Normal>
          DPD UK is a member of DPDgroup, the largest parcel delivery network in
          Europe. DPDgroup is the parcel delivery network of GeoPost, a holding
          company owned by France’s La Poste.
        </Normal>
        <Normal>
          The company operates more than 10,000 vehicles from 84 locations and
          delivers over 400 million parcels a year.
        </Normal>
        <Normal>
          <strong>About Cartken</strong>
        </Normal>
        <Normal>
          Cartken makes communities more connected by bridging the distance
          between customers and the local businesses they love Our autonomous
          robot platform is designed to transport goods over short distances in
          malls, business parks and local neighbourhoods. We use advanced
          autonomous technology, making our robots the safest and most reliable
          in the market today. Cartken&apos;s team of engineers and operators
          combine deep expertise in self-driving cars, AI-powered robotics and
          delivery operations.
        </Normal>
        <Normal>
          For Cartken Press Inquiries, Please Contact: Teal Minisofer
          teal@zerotrillion.com +31 06 163 82 684
        </Normal>
      </>
    ),
  },
  {
    id: 15,
    date: "July 2022",
    dateTitle: "5 July 2022",
    country: "Ireland",
    category: "Sustainability",
    title: "DPD Ireland hits 1 million sustainable deliveries",
    cardTitle: "DPD Ireland hits 1 million sustainable deliveries",
    imgs: [img14],
    body: (
      <>
        <Header3>
          DPD Ireland has reached the milestone of 1 million sustainable
          deliveries, with the millionth parcel delivered to An Tánaiste and
          Minister for Enterprise, Trade and Employment, Leo Varadkar.
        </Header3>
        <Normal>
          The parcel-delivery company is currently delivering more than 4,000
          parcels per day by electric vehicle and estimates to have saved 72.65
          tons of CO2 emissions in 2021.
        </Normal>
        <Normal>
          The company has also begun testing the use of hydrotreated vegetable
          oil (HVO) instead of diesel on its line-haul fleet, in a bid to
          further reduce emissions. The use of HVO fuel, which is a completely
          renewable diesel alternative based on waste grease and vegetable oils,
          offers a 90% reduction in greenhouse gas emissions and particulate
          matter pollution per trip.
        </Normal>
        <Normal>
          DPD Ireland last year announced it was investing a further €2 million
          decarbonising its Irish fleet, with the addition of 100 new electric
          vehicles by the end of 2022. The company has already invested €3.2
          million since 2019 in electric vans and infrastructure.
        </Normal>
        <Normal>
          The company aims to electrify 25% of its total fleet by 2025, which
          means having 400 electric vehicles on the road in just 3 years.
        </Normal>
        <Normal>
          It currently has 51 electric vehicles on the road in Ireland, and has
          ordered a further 110 electric vans, the largest order reported by
          FORD Ireland this year. The first batch are due to arrive in the
          Autumn, bringing the total number of electric vehicles used by DPD
          Ireland to over 100 by the end of this year.
        </Normal>
        <Normal>
          The new electric vehicles are Ford E-Transits which will provide more
          range and cubic capacity than any electric van to date.
        </Normal>
        <Normal>
          An Tánaiste and Minister for Enterprise, Trade and Employment Mr.
          Varadkar said,
        </Normal>
        <Normal>
          <em>
            “We must do what we can to leave our planet in a better condition
            than we inherited it. Enterprise has a really important role to play
            and it’s great to see DPD Ireland take the lead, investing over €5m
            to make the business more sustainable. Congratulations to the team
            on reaching 1 million deliveries by electric vehicle. It’s is a
            really significant milestone and it was great to be the lucky
            recipient of the 1 millionth parcel! I know the company is only
            getting started and is doing a lot more to reduce its reliance on
            fossil fuels. The very best of luck to the team with their plans.”
          </em>
        </Normal>
        <Normal>
          DPD Ireland sustainability manager James Atkinson said the delivery
          firm places huge emphasis on sustainability and is determined to
          decarbonise its fleet by 2030.
        </Normal>
        <Normal>
          <em>
            “As well as lowering emissions on journeys, we are heavily focussed
            on reducing journeys in the first place. Our flagship route
            optimisation project will enable depots to deliver more parcels with
            the same number of vans, meaning no additional emissions, while
            growing the business,” he said.
          </em>
        </Normal>
        <Normal>
          Mr. Atkinson said the challenge with electric vans is its range. The
          company expects the Ford E Transit to do 250km on a single charge,
          adding that 20-25% of DPD Ireland delivery routes are over 200km.
        </Normal>
        <Normal>
          <em>
            “These hard-to-electrify routes will be supplied with a biofuel,
            HVO, which offers up to 90% emissions reduction versus diesel. We
            have already purchased our first tank of HVO and are currently
            trialing it on line haul trucks between Dublin and our central
            sortation facility in Athlone.”
          </em>
        </Normal>
        <Normal>
          DPD Ireland is part of DPDgroup, the largest parcel delivery network
          in Europe. The group delivers 8.4 million parcels each day – 2.1
          billion parcels per year.
        </Normal>
        <Normal>
          <strong>About DPDgroup</strong>
        </Normal>
        <Normal>
          DPDgroup is the largest parcel delivery network in Europe. The company
          combines innovative technology and local knowledge to provide a
          flexible and user-friendly service for both shippers and shoppers.
          With our industry-leading Predict service, we are setting a new
          standard for convenience by keeping our customers in close contact
          with their delivery.
        </Normal>
        <Normal>
          With 120,000 delivery experts and a network of more than 70,000 Pickup
          points, we deliver 8.4 million parcels each day – 2.1 billion parcels
          per year – through the brands DPD, Chronopost, SEUR, BRT and Jadlog.
        </Normal>
        <Normal>
          At DPDgroup we aim to be a reference in sustainable delivery and
          become a leading enabler of e-commerce acceleration.
        </Normal>
        <Normal>
          DPDgroup is the parcel delivery network of GeoPost, which posted sales
          of €14,7 billion in 2021. GeoPost is a holding company owned by Le
          Groupe La Poste.
        </Normal>
      </>
    ),
  },
  {
    id: 14,
    date: "July 2022",
    dateTitle: "1 July 2022",
    country: "France",
    category: "Sustainability",
    title:
      "Our People, Our Planet, Our Communities: GeoPost/DPDgroup 2021 Sustainability Report now available",
    cardTitle: "GeoPost/DPDgroup 2021 Sustainability Report",
    imgs: [img13],
    body: (
      <>
        <Normal>
          GeoPost/DPDgroup has just released its sixth Sustainability Report.
          Through its pages, we reaffirm our 2030 ambition to become the
          international reference in sustainable delivery, and a leading enabler
          of the e-commerce acceleration.
        </Normal>
        <Normal>
          As part of our commitment to support the UN Sustainable Development
          Goals (SDGs
          <AnchorLink
            to="https://www.dpd.com/group/en/news/geopost-sustainability-report-2021/#_ftn1"
            externalLink={true}
            linkColor={variables.linkColor}
          >
            [1]
          </AnchorLink>
          ), this report highlights our key achievements and progress towards
          embedding sustainability within our business, guided by our core
          values of transparency, accountability, solidarity, open-mindness and
          respect. Built on three pillars – Our People, Our Planet, Our
          Communities - our sustainability strategy is driving change around the
          group because we believe sustainability is vital for our business to
          ensure relevance, to exceed expectations, and to secure our leadership
          position in the future of parcel delivery.
        </Normal>
        <Normal>
          <em>
            “As we look back at 2021, sustainability has never been more in
            focus”
          </em>
        </Normal>
        <Normal>
          <em>Yves Delmas, CEO of GeoPost/DPDgroup</em>
        </Normal>
        <Normal>
          To learn more about our progress and sustainability achievements
          across our Business Units in Europe and further afield, read the
          report{" "}
          <AnchorLink
            to="https://7efd0784.flowpaper.com/SustainabilityReport2021DPDgroupFLOWPAPER001/#page=1"
            externalLink={true}
            linkColor={variables.linkColor}
          >
            here
          </AnchorLink>
          , and visit our{" "}
          <AnchorLink
            to="https://www.dpd.com/group/en/sustainability/"
            externalLink={true}
            linkColor={variables.linkColor}
          >
            Sustainability page
          </AnchorLink>
          .
        </Normal>
        <Normal>
          <AnchorLink
            to="https://www.dpd.com/group/en/news/geopost-sustainability-report-2021/#_ftn1"
            externalLink={true}
            linkColor={variables.linkColor}
          >
            [1]
          </AnchorLink>{" "}
          The four SDGs that GeoPost/DPDgroup supports: Decent work and economic
          growth (8), Sustainable cities and communities (11), Climate Action
          (13), Partnerships for the Goals (17).
        </Normal>
        <CallToActionBtn
          btnLink="https://7efd0784.flowpaper.com/SustainabilityReport2021DPDgroupFLOWPAPER001/#page=1"
          externalBtnLink={true}
        >
          Read our Sustainability Report
        </CallToActionBtn>
      </>
    ),
  },
  {
    id: 13,
    date: "June 2022",
    dateTitle: "12 June 2022",
    country: "United Kingdom",
    category: "Sustainability",
    title:
      "“DPD UK funding of immense value” – Forestry England on Wareham Forest two years on from devastating fire",
    cardTitle: "“DPD UK funding of immense value” – Forestry England",
    imgs: [img12],
    body: (
      <>
        <Header3>
          Two years on from the devasting fire at Wareham Forest, money raised
          from parcel company DPD’s recycling efforts is helping to regenerate
          vital habitats at the Site of Special Scientific Interest.
        </Header3>
        <Normal>
          Since 2020,{" "}
          <AnchorLink
            to="https://green.dpd.co.uk/"
            externalLink={true}
            linkColor={variables.linkColor}
          >
            DPD
          </AnchorLink>{" "}
          UK has worked with Forestry England to help restore Dorset’s Wareham
          Forest with support from its innovative Eco Fund.
        </Normal>
        <Normal>
          Funded solely by the firm’s circular economy initiatives, such as
          recycling shrink wrap and wooden pallets, the Eco Fund awards grants
          to community groups, educational facilities and start-up companies, to
          fund green, ecological or sustainable projects benefiting the
          environment.
        </Normal>
        <Normal>
          With the support from DPD UK, Forestry England has been able to
          replant with many young trees across the forest, considering carefully
          where these are located. The money also went towards the purchase of a
          specialist heavy-duty mower and collector that has been used to create
          firebreaks and gather up the vegetation to minimise the risk of future
          fires in the forest.
        </Normal>
        <Normal>
          Fire can literally leap from one area to another, jumping across roads
          and paths or using vegetation to creep further along. To help make the
          forest more resilient the team at Wareham have used the new equipment
          to create natural gaps between areas of forest by clearing areas of
          vegetation and planting new trees further back from the edge of the
          road.
        </Normal>
        <Normal>
          Linked to this work, they have also repaired and restored paths and
          tracks in the forest to ensure, if needed again, fire crews can get
          access into affected areas and fight the fire at its source.
        </Normal>
        <Normal>
          Some of the areas most badly affected by the fire have not been
          replanted and are being managed to help them return to heathland. But
          even here, Forestry England is reporting green shoots of new life.
        </Normal>
        <Normal>
          <em>
            Mark Warn, Forestry England’s Wareham Wildlife Ranger said, “The new
            machinery funded by DPD’s Eco Fund has been of immense value, mowing
            17.5 kms of ride edges last winter, which improves biodiversity and
            wildfire management. This is a brilliant habitat for pollinating
            insects and species like the Silver Studded Blue butterfly.
          </em>
        </Normal>
        <Normal>
          <em>
            “Restoring Wareham Forest is a big job and will take many years to
            achieve, we are very grateful for DPD’s support, which allows us to
            continue to work with supporters and volunteers and share with them
            the huge benefits of the restoration of this special place.”
          </em>
        </Normal>
        <Normal>
          Tim Jones, Director of Marketing, Communications & Sustainability, DPD
          UK commented:
        </Normal>
        <Normal>
          <em>
            “We are absolutely delighted to hear the good news from Wareham
            Forest. We have been on-site and know the scale of the challenges
            they are facing, so to hear that our funding is making a real
            difference is fantastic. It is such an important environment, and
            while it may take decades to fully recover, our funds are also
            protecting the area against further harm.
          </em>
        </Normal>
        <Normal>
          <em>
            “Having our own circular economy means we can generate eco funds to
            directly back initiatives like this. It gives us a real focus to
            keep on challenging ourselves to be more sustainable at every level.
            The more we can raise from recycling, the more we can put back into
            ecological or sustainable projects that benefit everyone.”
          </em>
        </Normal>
        <Normal>
          Wareham Forest is a stronghold for wildlife and one of the few places
          where it is still possible to find all six of the UK’s native
          reptiles, including the endangered smooth snake and sand lizard, and
          it is home to many rare species of birds and insects. In May 2020 a
          fire, believed to have been started by disposable BBQs, scorched
          heathland, destroyed woodlands and wiped-out critical habitats for
          wildlife across an area equivalent to the size of 350 football
          pitches.
        </Normal>
        <Normal>
          In February 2022, Forestry England and DPD UK announced a four-year
          partnership to plant and restore woodland across England to tackle
          climate change and create positive benefits for local wildlife and
          communities. The partnership focuses on four separate woodland sites -
          Colliers Wood near Manchester, Pleasant Forest in Kent, Dimmingsdale
          in Staffordshire, and Hamsterley Forest, County Durham - where DPD UK
          support will enable Forestry England to plant trees to create new
          woodland areas and carry out work restoring valuable established
          woodlands.
        </Normal>
        <Normal>
          <strong>About DPD UK</strong>
        </Normal>
        <Normal>
          DPD UK was awarded the Queen’s Award for Enterprise 2015 in the
          Innovation category, for its unique one-hour delivery service,
          Predict.
        </Normal>
        <Normal>
          In 2018, DPD opened the UK’s first all-electric parcel delivery depot
          in Westminster. DPD currently has over 1,700 EVs on the road in the UK
          and in October 2020, announced plans to deliver to 25 of the largest
          towns and cities in the UK with zero and low-emission delivery means
          by 2023. In July 2021, Oxford became DPD’s first all-electric city in
          the UK. Details of all of DPD’s sustainability initiatives can be
          found on its dedicated green website:{" "}
          <AnchorLink
            to="https://green.dpd.co.uk/"
            externalLink={true}
            linkColor={variables.linkColor}
          >
            https://green.dpd.co.uk/
          </AnchorLink>
        </Normal>
        <Normal>
          DPD UK is a member of DPDgroup, the largest parcel delivery network in
          Europe. DPDgroup is the parcel delivery network of GeoPost, a holding
          company owned by France’s La Poste.
        </Normal>
        <Normal>
          The company operates more than 10,000 vehicles from 84 locations and
          delivers over 400 million parcels a year.
        </Normal>
        <Normal>
          <strong>About Forestry England</strong>
        </Normal>
        <Normal>
          Forestry England manages and cares for the nation’s 1,500 woods and
          forests, with over 296 million visits per year.
        </Normal>
        <Normal>
          As England’s largest land manager, we shape landscapes and enhance
          forests for people to enjoy, wildlife to flourish and businesses to
          grow. We are continuing the work we have already started to make the
          nation’s forests resilient to climate change and by 2026 we will:
        </Normal>
        <Normal>
          <ul>
            <li>
              Create at least 6,000 more hectares where we integrate wilding
              activities in our productive forests.
            </li>
            <li>
              Increase the diversity of visitors to the nation’s forests and
              have one million hours of high-quality volunteer time given to the
              nation’s forests
            </li>
            <li>
              Plant at least 2,000 hectares of new, high quality, predominantly
              broadleaf woodlands.
            </li>
          </ul>
        </Normal>
        <Normal>
          For more information visit{" "}
          <AnchorLink
            to="https://www.forestryengland.uk/"
            externalLink={true}
            linkColor={variables.linkColor}
          >
            forestryengland.uk
          </AnchorLink>
          . Forestry England is an agency of the Forestry Commission.
        </Normal>
      </>
    ),
  },
  {
    id: 12,
    date: "June 2022",
    dateTitle: "13 June 2022",
    country: "Italy",
    category: "e-Commerce",
    title:
      "BRT: e-shopper numbers in Italy are growing, with new trends in delivery and purchasing profiles",
    cardTitle: "BRT: e-shopper numbers in Italy are growing",
    imgs: [img11],
    body: (
      <>
        <Header3>
          The leading italian company in express transportation relaunches its
          customer service, with last mile deliveries and city branches covering
          the national territory, starting with Bologna
        </Header3>
        <Normal>
          In line with the new growth dynamics in the e-commerce sector, as
          revealed by the DPDgroup&apos;s “E-shoppers Barometer 2021” research,
          the company is focusing on increasingly personalized, digital and
          widespread delivery, collection and return services. Among the
          novelties are the enhancement of customer care, maximum customization
          of delivery services, including Out-of-Home, and the first Smart Urban
          Depot in Bologna, a city branch model that will be extended to other
          locations in Italy within a year. An Industrial Plan stretching to
          2025, making the statement “BRT-Good at Reaching Everyone in Italy and
          all over the World” become a reality.
        </Normal>
        <Normal>
          The e-commerce phenomenon continues to trend in all European
          countries, with Italy in the lead, marking a + 8% increase in the
          number of e-shoppers who made a purchase for the first time in 2021.
          Italy ranks 7th in Europe for the number of packages received, with
          very high percentages of recurring purchases in 46% of regular
          e-shoppers, which amounts to 14.8% of all purchases made online with
          an average of 42 annual transactions. A characteristic of the
          identikit of Italian e-shoppers - identified in the 3 profiles of
          “Aficionado”, “Epicurean” and “Eco-selective”, and who mainly buy 3
          categories of products, namely books, beauty and clothing - is a
          greater degree of loyalty than the European average and the choice of
          a trusted retailer based on the reputation of the platform and a more
          positive, user-friendly shopping experience. This aspect is
          particularly influenced by the ease of the return process, with which
          75% of Italian e-shoppers appear to be satisfied compared to 55%
          Europeans. However, expectations for improvement of the delivery
          service remain high, with 84% preferring it to be carried out directly
          to their home, but with 3 key options to ensure satisfaction: choice
          of the day and time of delivery, knowledge of the time in advance, and
          real time information on delivery. The percentage of Italians who want
          home delivery is however decreasing, in favour of Out-of-Home
          solutions, a delivery model featuring flexibility in terms of time,
          place and convenience for recipients. BRT adopts this system through
          its over 5,400 BRT-fermopoint collection points. This is the overall
          picture of BRT, the first Italian Express Transportation Company, that
          emerged from the “E-shoppers Barometer 2021” research carried out by
          DPDgroup, the largest European operator in the sector, of which the
          historic Bologna-based group has been an integral part since January
          2020.
        </Normal>
        <Normal>
          In BRT’s view, having now completed the transition from a family
          business (the “Bartolini courier”) to a global industrial enterprise
          within the DPDgroup / GeoPost network, e-commerce is increasingly
          becoming a driving force for economic growth and innovation in terms
          of opportunities for hybridisation of business models and access to
          global markets, with potential for vertical development, at least in
          the medium term.
        </Normal>
        <Normal>
          Provided, however, that the end customer is truly at the centre of the
          system, with increasingly tailor-made services and high added value.
          On the business side, first and foremost this means interactively
          managing and controlling the shipment with a direct and increasingly
          real-time dialogue. On the end user side, the challenge will
          increasingly concentrate on providing so-called “last mile” solutions
          with ever greater options for delivery, collection and returns, even
          outside the home, with maximum flexibility in terms of time, delivery
          options and dropping mode.
        </Normal>
        <Normal>
          In line with these trends, BRT has activated and implemented a wide
          range of ad hoc services and projects. Among the novelties is the new
          myBRT - recipient platform, which allows the user to manage the
          delivery of their shipments in complete autonomy and flexibility, with
          a suite of services designed to ensure maximum flexibility. There is
          also the enhancement of Customer Care following greater technological
          investments, to which can be added the advantage - always a
          distinctive feature of BRT - afforded by management of the service
          being entrusted to its own specialised internal staff, with a
          consequent considerable reduction in times for responding to and
          processing a particular request. The “Plugin-BRT-fermopoint” solution,
          now available on the main platforms used by Italian companies - from
          PrestaShop to WooCommerce - is much appreciated by e-commerce
          customers, and enables online buyers to collect shipments at their own
          leisure, if so desired, at the network of BRT-fermopoint collection
          points, with a display of the location on the map, as well as the
          opening hours and days.
        </Normal>
        <Normal>
          The integrated system of options put in place for recipients is also
          moving in this direction: first of all, advanced messaging with a
          2-hour delivery window, real-time notifications via text message and
          email, myBRT to track the shipment in real time, the option of
          delivery direct to your home or apartment on whatever floor for
          packages of any size, and the so-called “In flight option” which
          allows you to change the date and place of delivery after the shipment
          has departed, including the BRT-fermopoint network among the
          out-of-home delivery options.
        </Normal>
        <Normal>
          This network was created with the intention of enacting a new delivery
          model that allows you to choose between news-stands, tobacconists,
          stationeries, bars and other small businesses as a place for
          collection and delivery. The service is delivered in a more convenient
          way and with far more flexible hours, with the customer being able to
          choose the BRT-fermopoints open even on weekends, late in the evening
          or round the clock. In addition to the advantages in terms of
          flexibility, process rationalization and environmental sustainability,
          due to a reduction in the number of handling sections in the last
          mile, the system has interesting repercussions on traffic to local
          points of sale, as a support to the local economy. The BRT-fermopoint
          network is growing at a very rapid pace: since the beginning of the
          year, about 100 new points have been added every month, for a total of
          5,400 to date throughout Italy.
        </Normal>
        <Normal>
          The widespread nature of the service, encompassing everything from
          large metropolis to small suburbs, has always been the strength of
          BRT, which the company has refined, starting from the initials of its
          name, into a true and proper declaration: “Bravi a Raggiungere Tutti”,
          Italian for “Good at Reaching Everyone”. A commitment also fulfilled
          by ensuring the company’s physical presence right across the country.
        </Normal>
        <Normal>
          With this in mind, the first Smart Urban Depot came into operation in
          Bologna, a centre entirely dedicated to the customer where you can
          send and collect your packages, check the quality of the products
          received on site - or try them on directly, in the case of clothing,
          in comfortable internal spaces - and make any necessary returns. The
          Smart Urban Depot BRT, located in the city centre in a strategic area
          containing offices and companies of various types (Via Irnerio 23a),
          is used for low emission delivery, within the framework of the Green
          Strategy adopted by the company, thanks to the provision of a fully
          green fleet consisting of Cargo Bikes equipped with mini-containers
          for the transport of goods. This is the first example of a system of
          “city branches”, launched in Bologna and to be extended within the
          year to other medium-large cities across Italy.
        </Normal>
        <Normal>
          With 200 branches, 35 parcel hubs, 13 messaging hubs, 10 logistics
          facilities and 5,400 BRT-fermopoint collection and delivery points,
          BRT closed 2021 very positively in terms of key figures and
          operational structure. In 2021 alone, it delivered 227 million
          parcels, with 22,000 people serving over 70,000 customers every day,
          and with a turnover of EUR 1,775 million (+ 14% over 2020).
        </Normal>
        <Normal>
          <em>
            “Our commitment is to follow each customer, taking care of every
            need and at every stage of the process with increasing attention and
            anticipating needs as much as possible,”
          </em>
        </Normal>
        <Normal>
          <em>commented Dalmazio Manti, CEO of BRT.</em>
        </Normal>
        <Normal>
          <em>
            “We are able to transport any type of product, weight or volume to
            any part of Italy and well beyond the European borders, from the
            smallest village to the biggest cities, with a totally personalized
            service offered to all major business segments. The Industrial Plan
            stretching to 2025 will enrich this trajectory with new assets,
            further enhancing the widespread nature of our network, the
            digitization of processes, and the improvement of our customer
            experience, which will mean consolidating our B2B position and
            growing ever more strongly in B2C e-commerce.”
          </em>
        </Normal>
        <Normal>
          <strong>About BRT</strong>
        </Normal>
        <Normal>
          BRT is a DPDgroup company providing wide-ranging and reliable express
          courier services, inclusive of outsourced logistics solutions, for the
          management of different types of products in Italy, Europe and the
          world. The company employs more than 22,000 people. Our extensive
          presence in Italy takes the form of 200 branches, 35 parcel hubs, 13
          freight hubs and 10 logistics facilities, and over 5400 BRT-fermopoint
          pick-up and drop-off points. In financial year 2021, BRT SpA generated
          a turnover of over EUR 1,775 million (1,557 million in 2020).
        </Normal>
        <Normal>
          DPDgroup is the largest network of express courier services in Europe:
          a business that combines technology, innovation and knowledge of the
          local markets to provide its customers and recipients with a flexible
          and tailored service. With more than 120,000 delivery experts, and a
          network of more than 70,000 collection Pickup points, it delivers 8.4
          million parcels every day (2.1 billion per year), with record economic
          results in 2021 of EUR 14.7 billion in revenues and a growth of 14%
          over 2020.
        </Normal>
      </>
    ),
  },
  {
    id: 11,
    date: "June 2022",
    dateTitle: "13 June 2022",
    country: "France",
    category: "Technology",
    title:
      "GeoPost/DPDgroup at VivaTech: How does innovation shape the company’s everyday business?",
    cardTitle: "GeoPost/DPDgroup at VivaTech",
    imgs: [img10],
    body: (
      <>
        <Normal>
          For the 5th year in a row, La Poste Groupe takes part in VivaTech, the
          biggest startup and tech event in Europe that brings together business
          leaders, startups, investors and innovators. From June 15th to June
          18th, VivaTech will be a showcase for innovation, inspiration and
          research to initiate positive transformation and new partnerships.
        </Normal>
        <Normal>
          As part of La Poste Groupe, GeoPost/DPDgroup will have the opportunity
          to present some of its most innovative and ambitious projects at the
          La Poste Groupe’s stand. This stand includes several thematic areas.
        </Normal>
        <Normal>
          <ul>
            <li>
              An area for startups which are supported by GeoPost/DPDgroup.
            </li>
            <ul>
              <li>
                <AnchorLink
                  to="https://www.shiptify.com/fr/"
                  externalLink={true}
                  linkColor={variables.linkColor}
                >
                  Shiptify
                </AnchorLink>
                , whose mission is to democratize access to B2B transport
                management systems, and build interconnected and globally
                efficient supply chains.
              </li>
              <li>
                <AnchorLink
                  to="https://www.epicery.com/?utm_source=bing&utm_medium=cpc&utm_campaign=search_brand&utm_content=marque&msclkid=1fed3ae22e2c1eec9e1b5844d2614741"
                  externalLink={true}
                  linkColor={variables.linkColor}
                >
                  Epicery
                </AnchorLink>
                , which aims at making foodtech available to local shops by
                helping with their digitization or by bringing live commerce to
                the forefront.
              </li>
              <li>
                <AnchorLink
                  to="https://wayve.ai/about/"
                  externalLink={true}
                  linkColor={variables.linkColor}
                >
                  Wayve
                </AnchorLink>
                , which contributes to rethinking autonomous mobility through
                deep learning AI technology.
              </li>
            </ul>
            <li>
              An area hosting innovative offers, where GeoPost/DPDgroup will
              showcase some of its latest developments.
            </li>
            <ul>
              <li>
                The Carbon Calculator, which provides shippers with dynamic
                information about the CO2 emissions of their overall parcel
                delivery operated by DPDgroup.
              </li>
              <li>
                The myDPD app, which allows consumers to decide when and where
                they want to retrieve their parcels.
              </li>
              <li>
                DrivR, a gamification app which enables drivers to directly see
                their individual performance on a daily basis, and particularly
                focuses on delivery performance and customer reviews.
              </li>
            </ul>
            <li>
              A demonstration area where an exoskeleton conceived by German
              Bionic and which has been deployed in DPD Germany and DPD
              Switzerland will be showcased during the whole event.
            </li>
          </ul>
        </Normal>
        <Normal>
          GeoPost/DPDgroup will also share insights on how new technologies will
          transform the company more broadly. Olivier Storch - Executive Vice
          President, Asia-Pacific, Americas & Urban Services, Innovation - will
          take the floor on June 16th at the CEO stories Keynote, where he will
          deep-dive into e-shoppers behaviors and new e-commerce trends which
          directly impact urban logistics.
        </Normal>
        <Normal>
          <strong>
            Innovation as a key player in our “Together & Beyond” ambition
          </strong>
        </Normal>
        <Normal>
          As one of the world’s leading parcel delivery network,
          GeoPost/DPDgroup has set a strong ambition for 2030: to become the
          international reference player in sustainable delivery, and a leading
          enabler of e-commerce acceleration. To achieve this, innovation and
          new technologies are key in many areas of the logistics industry:
          algorithms for routing optimizations, alternative energies for
          delivery fleets, chatbots for a better customer experience, devices to
          support and empower warehouses staff, etc.
        </Normal>
        <Normal>
          All these initiatives are being developed by the group and its
          business units around the world to help improve both customer
          experience and quality of service, to attract and retain the best
          talents, and to significantly act for a better quality of life.
        </Normal>
        <Normal>
          <strong>About GeoPost/DPDgroup</strong>
        </Normal>
        <Normal>
          DPDgroup is one of the world leading parcel delivery networks, aiming
          to be a reference in sustainable delivery and become a leading enabler
          of e-commerce acceleration.
        </Normal>
        <Normal>
          We combine innovative technology and local knowledge to provide a
          flexible and user-friendly service for both shippers and shoppers.
        </Normal>
        <Normal>
          With 120,000 delivery experts operating in nearly 50 countries, and a
          network of 70,000 Pickup points, we deliver 8.4 million parcels
          worldwide each day – 2.1 billion parcels per year – through the brands
          DPD, Chronopost, SEUR, BRT and Jadlog.
        </Normal>
        <Normal>
          DPDgroup is the parcel delivery network of GeoPost. GeoPost posted
          sales of €14.7 billion in 2021. GeoPost is a holding company owned by
          La Poste Groupe.
        </Normal>
      </>
    ),
  },
  {
    id: 10,
    date: "June 2022",
    dateTitle: "07 June 2022",
    country: "Spain",
    category: "Sustainability",
    title:
      "SEUR expands its sustainable strategy and will carry out low-emission deliveries in 64 Spanish cities in 2025",
    cardTitle:
      "SEUR expands its sustainable strategy and will carry out low-emission deliveries",
    imgs: [img4],
    body: (
      <>
        <Normal>
          Two years ago SEUR announced a new commitment to the environment under
          its sustainability strategy to reduce the impact of its activity on
          the environment by offsetting its CO2 emissions, a task that it has
          already been developing since 2013. Given the climatic urgency, SEUR
          has decided to make this strategy more ambitious by expanding from 20
          to 64 Spanish cities with more than 50,000 inhabitants in which it
          will carry out distribution with low-emission vehicles.
        </Normal>

        <Normal>
          To achieve these objectives, the execution of this plan will mean an
          investment of more than 4 million euros for the company in 2022 alone,
          as well as the incorporation of 1,500 low-emission vehicles by 2025.
          All this without forgetting the commitment of the company for the
          installation of recharging points, which by the end of 2022 will reach
          200. The implementation of this program will reduce CO2 emissions in
          these cities by around 85% and will have a direct impact on 17 million
          inhabitants throughout Spain.
        </Normal>

        <Normal>
          The company has also spent years betting on services that offer the
          greatest possible flexibility and personalization to its consumers,
          something that has led to the growth of out-of-home solutions, which
          also benefit sustainability, such as the SEUR Pickup network, which
          already exceeds the 3,000 convenience stores and lockers, 30% more
          than in 2020. A solution that allows the online buyer to choose where
          to receive their shipments with a wide freedom of schedules that
          includes, in most cases, weekends and where Five million deliveries
          were made in 2021. The commitment to the Pickup network is part of the
          Group&apos;s sustainability strategy, as it reduces CO2 emissions
          associated with the last mile by 63%.
        </Normal>
      </>
    ),
  },
  {
    id: 9,
    date: "May 2022",
    dateTitle: "31 May 2022",
    country: "Germany",
    category: "Sustainability",
    title:
      "DPD Germany launches rail pilot project: transport by train for a better carbon footprint",
    cardTitle: "DPD Germany launches rail pilot project",
    subtitle:
      "DPD Germany launches rail pilot project: transport by train for a better carbon footprint",
    imgs: [img9],
    body: (
      <>
        <Normal>
          <ul>
            <li>Goods trains carry DPD parcels between Hamburg and Duisburg</li>
            <li>
              Rail is to become an integral part of DPD’s long-distance
              operations
            </li>
            <li>
              Additional needs-based services and rail connections required
            </li>
          </ul>
        </Normal>

        <Normal>
          In future DPD parcels will also be arriving by rail: from 30 May 2022
          goods trains transporting DPD shipments will run daily between Hamburg
          and Duisburg. As part of a carefully prepared pilot project, the
          company will gain valuable experience and gradually shift more of its
          long-distance parcel transport to rail. As the carbon footprint of the
          parcels transported will be significantly improved, this will make an
          important contribution to greater sustainability. The first interim
          goal is for around 5 per cent of DPD&apos;s domestic freight transport
          to be shipped by rail as early as next year.
        </Normal>

        <Normal>
          <i>“Our long-term goal is climate-neutral logistics,“</i>
        </Normal>

        <Normal>
          says Anke Förster, Chief Network Planning and Optimisation Officer at
          DPD Germany
        </Normal>

        <Normal>
          <i>
            “In addition to a range of measures that we are already implementing
            in local transport and along the last mile, our focus is very much
            on long-distance transport. This is where alternative drive
            technologies on the road play just as important a role as rail.“
          </i>
        </Normal>

        <Normal>
          DPD’s partner in the implementation of rail transport is the service
          provider Kombiverkehr. The Frankfurt-based company is backed by more
          than 230 freight forwarders and DB Cargo AG as limited partners.
        </Normal>

        <Normal>Anke Förster states:</Normal>

        <Normal>
          <i>
            “Thanks to Kombiverkehr we don&apos;t need to fill a complete train,
            but can book individual swap bodies onto existing routes.“
          </i>
        </Normal>

        <Normal>
          <i>
            “In the round trip between Hamburg and Duisburg, each swap body
            loaded with DPD shipments will emit around one tonne less CO2
            compared to pure road transport. This corresponds to a saving of
            more than 80 per cent,“
          </i>
        </Normal>

        <Normal>
          emphasises Björn Saschenbrecker from the Sales department at
          Kombiverkehr, adding:
        </Normal>

        <Normal>
          <i>
            “Intermodal transport will therefore bring DPD a good deal closer to
            achieving its own climate targets.“
          </i>
        </Normal>

        <Normal>
          Every evening goods trains depart almost simultaneously in opposite
          directions from Hamburg and Duisburg, loaded with DPD swap bodies.
        </Normal>

        <Normal>
          <i>
            “The route is ideal for us, as our depots in both places are in the
            immediate vicinity of the rail cargo terminals,“
          </i>{" "}
          explains Anke Förster
        </Normal>

        <Normal>
          Nevertheless, because the switch means extensive changes in the
          company&apos;s internal processes, she adds:
        </Normal>

        <Normal>
          <i>
            “It&apos;s precisely in this area that we want to gain experience.
            An additional means of transport means more complexity, and at the
            same time we want to make sure we keep our service promises.“
          </i>
        </Normal>

        <Normal>
          It is helpful here that DPD Germany has already dealt intensively with
          the area of rail transport in the past and can now put this knowledge
          to use. According to Anke Förster, it is also an ideal opportunity to
          integrate the specific characteristics of rail as a means of transport
          into customer-oriented services:
        </Normal>

        <Normal>
          <i>
            “In the long term our customers will certainly have the choice of
            receiving their goods by the fastest possible means of transport by
            road, or with the greatest possible sustainability by rail.“
          </i>
        </Normal>

        <Normal>
          With the project that has now been launched DPD Germany is also
          responding to the desire of the government to shift freight transport
          from road to rail wherever possible. Anke Förster comments:
        </Normal>

        <Normal>
          <i>
            We&apos;ll be happy to offer our experience as a partner in the
            discussion, and we can already say this much: it is advisable to
            specifically consult the business community on its wishes and
            interests, and to base customer-oriented services on this. If
            possible, we would very much like to quickly shift a much larger
            share of our freight transport to rail.“
          </i>
        </Normal>

        <Normal>
          <strong>About DPD Germany</strong>
        </Normal>

        <Normal>
          DPD Germany is part of DPDgroup, the largest international parcel
          delivery network in Europe. Throughout Germany DPD has 79 depots and
          7,500 Pickup parcelshops. A workforce of 9,500 and 13,000 delivery
          drivers are in daily operation on behalf of the company’s customers.
          Every year the No. 2 on the German parcels market ships more than 400
          million parcels – providing carbon-neutral transport operations at no
          additional cost to the customer. With a number of initiatives for
          sustainable parcel delivery DPD is demonstrating, particularly in city
          centres, how parcel shipping can be made environmentally friendly and
          emission-free at the local level.
        </Normal>

        <Normal>
          Through innovative technology, local knowledge and dedicated customer
          care, DPD provides the best possible experience for both shippers and
          shoppers. DPD’s industry-leading Predict service is setting a new
          standard for keeping customers closely in touch with their delivery,
          with real time tracking of their delivery, a one-hour delivery window
          and a range of options for redirecting parcels. In recognition of this
          innovation DPD Germany received several awards like the Digital
          Transformation Award or the UX Design Award.
        </Normal>

        <Normal>
          As part of DPDgroup, DPD Germany has access to over 70,000 Pickup
          points worldwide, and delivers to 230 countries and territories across
          the globe. DPDgroup&apos;s 120,000 delivery experts work together to
          deliver more than 8.4 million parcels each day. The sole shareholder
          in DPD is GeoPost, a wholly-owned subsidiary of Le Groupe La Poste.
          GeoPost posted sales of €14,7 billion in the year 2021.
        </Normal>
      </>
    ),
  },
  {
    id: 8,
    date: "May 2022",
    dateTitle: "25 May 2022",
    country: "United Kingdom",
    category: "Sustainability",
    title:
      "DPD UK confirms all-electric delivery status for 10 towns and cities and announces plans for five additional ‘green’ towns",
    cardTitle: "DPD UK confirms all-electric delivery status",
    subtitle:
      "DPD UK confirms all-electric delivery status for 10 towns and cities and announces plans for five additional ‘green’ towns",
    imgs: [img8],
    body: (
      <>
        <Header3>
          All-electric target updated to 30 locations, two years earlier than
          planned
        </Header3>
        <Normal>
          In October 2020,{" "}
          <AnchorLink
            to="https://green.dpd.co.uk/"
            externalLink={true}
            linkColor={variables.linkColor}
          >
            DPD
          </AnchorLink>{" "}
          UK announced plans to create 25 all-electric towns and cities in the
          UK by 2025. In July 2021, Oxford became DPD’s first &apos;green&apos;
          city, with a fleet of 40 electric vehicles based at the new DPD
          Bicester eco-depot, delivering over 15,000 parcels a week across the
          city.
        </Normal>
        <Normal>
          Today, DPD has confirmed that nine additional UK locations have
          achieved the same all-electric delivery status. The ten ‘green’ towns
          and cities are, Bradford, Bristol, Cardiff, Hull, Newcastle,
          Nottingham, Oxford, Reading, Southampton and Stoke.
        </Normal>
        <Normal>
          Progress is well underway at the remaining 15 original green delivery
          locations – Birmingham, Brighton and Hove, Cambridge, Coventry, Derby,
          Edinburgh, Glasgow, Leeds, Leicester, Liverpool, London, Manchester,
          Plymouth, Portsmouth and Sheffield.
        </Normal>
        <Normal>
          DPD UK now expects these locations to achieve all-electric status two
          years earlier than planned, by the end of next year. In addition, the
          firm is now planning for five more towns, Birkenhead, Gateshead,
          Newcastle-under-Lyme, Rotherham and Shipley to become all-electric by
          the end of 2023.
        </Normal>
        <Normal>
          To-date, DPD has invested over £90m on all-electric vehicles in the
          UK, including the most recent order of 1,000 Ford E-Transit vans in
          May 2022, which will take DPD’s electric vehicle fleet to over 2,500
          in total.
        </Normal>
        <Normal>
          The company, which hasn&apos;t bought a diesel van since summer 2020,
          expects to deliver 26 million parcels this year on electric vehicles,
          a saving of 7,200,000kg of CO2.
        </Normal>
        <Normal>Elaine Kerr, DPD UK CEO, commented,</Normal>
        <Normal>
          <i>
            “I&apos;m absolutely delighted to confirm that we now have 10 UK
            towns and cities being delivered by an all-electric DPD fleet. One
            of our big aims is to be the UK’s most sustainable delivery company
            and by the end of 2023, we will have over 5,000 EVs on the road and
            30 urban centres delivered on all-electric vehicles.“
          </i>
        </Normal>
        <Normal>
          <i>
            “Since opening the UK&apos;s first all-electric parcel depot in
            Westminster in 2018, we have grown our electric fleet year on year,
            despite some of the issues that have held us back, such as the lack
            of righthand drive EVs for the UK market. We are now seeing improved
            availability of larger electric vans, which is what we need. As a
            result, it is fantastic to be able to extend our original pledge to
            30 locations and two years earlier than planned.“
          </i>
        </Normal>
        <Normal>
          <i>
            “By targeting major town and city centres with our electric
            vehicles, we can make a significant difference to emissions in those
            locations and help improve air quality where it is most needed. In
            addition to buying the vehicles, and investing in charging
            infrastructure, we continue to optimise our routing and operation,
            to create the smartest possible delivery system and reduce our
            overall miles per parcel.“
          </i>
        </Normal>
        <Normal>
          Details of all of DPD’s sustainability initiatives can be found on its
          dedicated green website:{" "}
          <AnchorLink
            to="https://green.dpd.co.uk/"
            externalLink={true}
            linkColor={variables.linkColor}
          >
            https://green.dpd.co.uk/
          </AnchorLink>
        </Normal>
        <Normal>
          <strong>About DPD UK</strong>
        </Normal>
        <Normal>
          DPD UK was awarded the Queen&apos;s Award for Enterprise 2015 in the
          Innovation category, for its unique one-hour delivery service,
          Predict.
        </Normal>

        <Normal>
          In 2018, DPD opened the UK&apos;s first all-electric parcel delivery
          depot in Westminster. DPD currently has over 1,500 EVs on the road in
          the UK and in October 2020, announced plans to deliver to 25 of the
          largest towns and cities in the UK with zero and low-emission delivery
          means by 2023. In July 2021, Oxford became DPD’s first all-electric
          city in the UK. Details of all of DPD’s sustainability initiatives can
          be found on its dedicated green website:{" "}
          <AnchorLink
            to="https://green.dpd.co.uk/"
            externalLink={true}
            linkColor={variables.linkColor}
          >
            https://green.dpd.co.uk/
          </AnchorLink>
        </Normal>

        <Normal>
          DPD UK is a member of DPDgroup, the largest parcel delivery network in
          Europe. DPDgroup is the parcel delivery network of GeoPost, a holding
          company owned by France’s La Poste.
        </Normal>

        <Normal>
          The company operates more than 10,000 vehicles from 84 locations and
          delivers over 400 million parcels a year.
        </Normal>
      </>
    ),
  },
  {
    id: 7,
    date: "May 2022",
    dateTitle: "24 May 2022",
    country: "Singapore",
    category: "Fulfilment",
    title:
      "Ninja Direct: One Stop Procurement Solution to Help SMEs Provide Imported Raw Materials Easily from Ninja Xpress",
    cardTitle: "Ninja Direct: One Stop Procurement Solution to Help SMEs",

    imgs: [img7],
    body: (
      <>
        <Header3>
          Understanding that there are still many SMEs/shippers who have
          difficulty in providing raw materials, especially imported ones, Ninja
          Xpress presents a one-stop procurement solution that is easy,
          profitable, and safe through Ninja Direct.
        </Header3>

        <Normal>
          Based on the results of a report by the DPD group with Ninja Xpress
          concerning E-Commerce Southeast Asia (SEA) Barometer Report 2021 -
          Uncovering SEA Online Shoppers and Delivery Preferences, it is known
          that more than half of respondents in Indonesia (52%) made purchases
          from foreign websites, most of whom stated to have done so several
          times. In addition to buying from within the SEA region, Asian
          countries such as China, Hong Kong, Taiwan, Japan and South Korea are
          becoming the most sought-after e-commerce.
        </Normal>

        <Normal>
          However, in reality, online buyers, especially shippers who buy raw
          materials for their stores from abroad, experience several
          difficulties in making transactions ranging from language constraints,
          difficulty finding trusted suppliers with quality raw materials,
          complicated cross-border trade regulations, to the difficulty of
          tracking shipping packages in real-time. This is supported by the data
          that show that only 28% of respondents from Indonesia argued that
          shipping/returning imported goods is easy and transparent.
        </Normal>

        <Normal>In response, Andi Djoewarsa, CMO of Ninja Xpress said,</Normal>

        <Normal>
          <i>
            “As a manifestation of Ninja Xpress&apos; commitment to understand
            the needs of shippers and to help shippers Deliver to the Doorstep,
            we present Ninja Direct which provides imported raw material
            procurement services with easier and hassle-free experience. Not
            only can they buy raw materials from abroad, but shippers or SMEs
            have the opportunity to sell their goods abroad through Ninja Direct
            as well.“
          </i>
        </Normal>

        <Normal>
          Shippers can easily buy imported raw materials with user-friendly
          applications and Ninja Direct customer service which helps the
          end-to-end purchase process as needed. Ninja Direct also provides
          dedicated account managers who always accompany shippers in every
          process, from searching to shipping goods.
        </Normal>

        <Normal>
          A large network of Ninja Direct suppliers in several countries can
          assist shippers in searching, purchasing, and delivering quality raw
          materials at competitive prices. The choice of payment schemes and
          systems is also flexible and can be adjusted to the wishes of the
          shipper.
        </Normal>

        <Normal>
          Reflecting on the same report that 56% of Indonesian respondents
          wanted to choose a shipping company that could guarantee security and
          51% wanted a company that could provide insurance, Ninja Direct
          guarantees goods that are shipped as ordered and safely arrive at the
          destination.
        </Normal>

        <Normal>
          Furthermore, Ninja Direct provides three types of services, namely Buy
          for You, Find for You, and Ship for You. Buy for You is a special
          service for shippers who already know what to buy and want Ninja
          Direct to process the purchase and delivery of the goods. Find for You
          is for shippers who do not know which supplier to buy from, so Ninja
          Direct will provide quality supplier recommendations at competitive
          prices. Ship for You is for shippers who have ordered items but have
          difficulty finding a reliable delivery service. Shippers can get Ninja
          Direct service by joining at{" "}
          <AnchorLink
            to="https://ninjadirectpromo.com/"
            externalLink={true}
            linkColor={variables.linkColor}
          >
            https://ninjadirectpromo.com/
          </AnchorLink>
          .
        </Normal>
      </>
    ),
  },
  {
    id: 6,
    date: "May 2022",
    dateTitle: "16 May 2022",
    country: "Poland",
    category: "Sustainability",
    title: "DPD Poland measures air quality in Warsaw",
    cardTitle: "DPD Poland measures air quality in Warsaw",
    imgs: [img6],
    body: (
      <>
        <Header3>
          DPDgroup has joined the efforts to improve the living conditions and
          has already installed air quality monitoring sensors in several
          European cities such as London, Prague, Lisbon, Paris, Bologna,
          Rotterdam, Madrid, Dublin and Hamburg. Warsaw is the first city in
          Poland with Pollutrack sensors, which have been installed on vehicles
          and in fixed locations.
        </Header3>

        <Normal>
          Sustainable development is the response to the expectations of
          customers across Europe, who are increasingly aware of the impact of
          consumption on the environment and living conditions in developed
          economies. According to the findings of the{" "}
          <AnchorLink
            to="https://www.dpd.com/wp-content/uploads/sites/77/2022/02/European-E-Shoppers-in-2021.pdf"
            externalLink={true}
            linkColor={variables.linkColor}
          >
            E-shopper Barometer 2021 report
          </AnchorLink>{" "}
          , buyers who select environmentally friendly solutions account for 14%
          of all e-consumers in Europe. As many as 65% of them choose online
          shops which guarantee sustainable delivery options. These challenges
          have resulted in DPD Poland’s activities, such as creation of low- and
          zero-emission fleet, deliveries on foot, development of an extensive
          DPD Pickup network, purchases of certified “green“ energy, own
          photovoltaic installations, as well as investments in sustainable
          infrastructure.
        </Normal>

        <Normal>
          A few years ago DPD Poland focused on the development of
          environmentally friendly urban logistics. The next step is the Breathe
          project: it provides access to information relating to levels of
          pollution and PM 2.5 concentrations, and allows consumers, businesses
          and authorities to be more aware of the air quality in specific
          locations. The sensors were installed on one hundred cars (for example
          on all electric delivery vans in Warsaw) and in fixed locations: in
          the Warsaw depots, DPD Pickup points, as well as in several public
          facilities made available by the Śródmieście District and the
          Białołęka District in Warsaw (such as parks and schools). Three Warsaw
          universities have also offered their locations. Information is
          collected by almost 120 sensors.
        </Normal>

        <Normal>
          The sensors take measurements every few seconds. An up-to-date air
          quality map around the delivery address can be viewed{" "}
          <AnchorLink
            to="https://eur04.safelinks.protection.outlook.com/?url=https%3A%2F%2Fwww.dpd.com%2Fpl%2Fpl%2Fzrownowazony-rozwoj%2Fbadania-jakosci-powietrza%2F&data=05%7C01%7C%7C03175dc2c8924444e79508da34cc7897%7C0469bc310999431b936b1299302124b7%7C1%7C0%7C637880350037509893%7CUnknown%7CTWFpbGZsb3d8eyJWIjoiMC4wLjAwMDAiLCJQIjoiV2luMzIiLCJBTiI6Ik1haWwiLCJXVCI6Mn0%3D%7C3000%7C%7C%7C&sdata=41lMdASNaBQX8yZy74eXMCqV00JBTMhEKbRqy6aJcOE%3D&reserved=0"
            externalLink={true}
            linkColor={variables.linkColor}
          >
            on the dpd.com.pl&apos;s website
          </AnchorLink>
        </Normal>

        <Normal>
          <strong>About DPD Poland</strong>
        </Normal>

        <Normal>
          <AnchorLink
            to="http://dpd.com.pl/"
            externalLink={true}
            linkColor={variables.linkColor}
          >
            DPD Poland
          </AnchorLink>{" "}
          is part of GeoPost/DPDgroup, one of the largest international courier
          networks in Europe. The company provides the highest quality services
          to senders and recipients of parcels and strives for neutrality of
          deliveries. It cooperates with over 9000 couriers. The company has a
          network of over 9200 DPD Pickup points. In 2021, it expanded the fleet
          of low-emission and zero-emission vehicles to include electric courier
          vans.
        </Normal>

        <Normal>
          DPDgroup delivers 8.4 million shipments worldwide every day. It has a
          team of 120 000 delivery experts operating under the brands DPD,
          Chronopost, SEUR, BRT and Jadlog and a network of 70 000 Pickup
          points. In 2020, DPDgroup strengthened its commitment to reducing its
          carbon footprint. As part of the corporate social responsibility
          strategy, the company will deliver parcels in many European cities in
          an environmentally friendly manner with the aim of improving the
          quality of life of their inhabitants.
        </Normal>
      </>
    ),
  },
  {
    id: 5,
    date: "May 2022",
    dateTitle: "09 May 2022",
    country: "Switzerland",
    category: "Sustainability",
    title:
      "Thanks to the Green CityHub, DPD Switzerland delivers all of Basel green over the last mile",
    cardTitle:
      "Thanks to the Green CityHub, DPD Switzerland delivers all of Basel",
    imgs: [img5],
    body: (
      <>
        <Header3>
          On Tuesday DPD Switzerland opened the first Green CityHub at the Smart
          City Lab Basel on the Wolf site. This gives the Basel region a parcel
          service provider that relies 100 per cent on e-vehicles for the last
          mile and reduces emissions. At the Basel Wolf site DPD Switzerland
          benefits from synergies with partners in the Smart City Lab Basel.
        </Header3>

        <Normal>
          The continuously increasing volume of parcels, especially in
          conurbations, poses an ever greater problem for the environment and
          the urban population. The burden of traffic and emissions on city
          centres on the one hand and the management of the increasing volume on
          the other poses a major challenge for logistics companies. The Green
          CityHub Basel not only makes delivery over the last mile more
          flexible, but also creates added value for people and the environment.
        </Normal>

        <Normal>
          Thanks to the new Green CityHub in the Smart City Lab Basel, DPD
          Switzerland is now supplying another city with seamless green
          transport over the last mile, in addition to the cities of
          Schaffhausen and Geneva. DPD Switzerland is thus one step closer to
          its goal of offering green transport throughout 10 Swiss cities by
          2025.
        </Normal>

        <Normal>
          At the opening ceremony on 3 May 2022, the Green CityHub was
          officially put into operation. The partners of the Smart City Lab
          Basel and other partners were invited. After a short opening speech,
          the opening ribbon was symbolically cut. A representative of the DPD
          partner company Manor AG thinks
        </Normal>

        <Normal>
          <i>
            “A CO2-neutral delivery of parcels should be promoted in every
            city... Keep it up!“
          </i>
        </Normal>

        <Normal>An employee of the Basel city administration adds:</Normal>

        <Normal>
          <i>
            “The location at Wolf is ideal for green delivery in the city of
            Basel. The location is centrally important for the future.“
          </i>
        </Normal>

        <Normal>
          The Smart City Lab Basel promotes innovations in the areas of
          buildings, mobility, city logistics and beyond for a liveable,
          sustainable and competitive Switzerland of tomorrow. It offers space
          and formats to network stakeholders, develop approaches, test ideas
          and experience smart solutions.
        </Normal>

        <Normal>
          DPD Switzerland works locally with various partners of the Smart City
          Lab Basel.
        </Normal>

        <Normal>
          In addition to its own e-vans for delivery directly to the
          recipient&apos;s door, parcels are also delivered to Parcel Lockers in
          Basel&apos;s city centre, which are operated by the parcel machine
          operator “SchlaueBox“. This allows recipients to pick up their parcel
          when it is convenient for them. Thanks to the cooperation with the
          company UpVolt, the E-Vans are charged with environmentally friendly
          electricity.
        </Normal>

        <Normal>
          The electrified delivery reduces CO2 emissions by 70 percent. The
          Green CityHub saves about 11,000 km of travel each month. The parcels
          are pre-sorted at night in the depot in Möhlin and transported by
          truck to the Green CityHub Wolf. There, the e-vans are loaded and the
          consignments delivered.
        </Normal>

        <Normal>
          <strong>Facts & Figures Green CityHub Basel Wolf:</strong>
        </Normal>

        <Normal>
          <ul>
            <li>Expected number of parcels per month for delivery: 26,000</li>
            <li>
              Size of the Green CityHub: 300 m2 interior area plus 290 m2 front
              area
            </li>
            <li>10 e-vans</li>
            <li>8 tours and 2 spring tours</li>
          </ul>
        </Normal>

        <Normal>
          For DPD Switzerland, responsibility towards the environment is a duty.
          Since 2012, the parcel service provider has delivered every parcel in
          a CO2-neutral manner - at no additional cost to customers. It does
          this by measuring its CO2 emissions, steadily reducing them and
          offsetting any remaining CO2 emissions
        </Normal>

        <Normal>
          <strong>About DPD Switzerland</strong>
        </Normal>

        <Normal>
          DPD Switzerland is one of the leading private express and parcel
          service providers in Switzerland and handles over 24 million parcels a
          year for companies and private individuals with 1,200 employees and
          drivers. The company has its headquarters in Buchs ZH and is
          represented at eleven other locations in Switzerland and in
          neighbouring countries. DPD Switzerland is part of DPDgroup.
        </Normal>
      </>
    ),
  },
  {
    id: 4,
    date: "April 2022",
    dateTitle: "08 April 2022",
    country: "Philippines",
    title:
      "Ninja Van Philippines automates largest hub in Cabuyao with fully integrated, measurement and sortation systems",
    cardTitle: "Ninja Van Philippines automates largest hub in Cabuyao",
    subtitle:
      "To keep up with growing customer demand, the company is ramping up its sort automation efforts for faster, more efficient deliveries.",
    imgs: [img1],
    navImg: navBg,
    body: (
      <>
        <Normal>
          Tech-enabled logistics company Ninja Van Philippines continues to
          strive for operational excellence in order to better meet the needs of
          its customers nationwide. Along with strengthening its core services
          and improving delivery speed, the company has installed new sortation
          systems in its new automated hub in Cabuyao, Laguna. Spanning
          21,000-sqm, this is the largest automated hub and sortation system in
          the whole Ninja Van Group.
        </Normal>
        <Normal>
          With integrated parcel measurement and real-time parcel status
          connectivity, these latest sortation systems will ensure that Ninja
          Van Philippines has the capacity to fulfill the growing delivery
          demand in the country.
        </Normal>
        <Normal>
          One of the key highlights is our double deck cross belt sorter that
          simultaneously measures, weighs, photographs and sorts parcels at an
          estimated throughput rate of 20,000 units per hour. Not only that, but
          the new belt also provides seamless real-time parcel data, sharing
          instant feedback and updates to shippers and shoppers on their parcel
          status.
        </Normal>
        <Normal>
          Faster deliveries can be expected as the cross belt sorter boosts
          Ninja Van Philippines’s receiving capacity by 300% with a 400%
          increase in outbound speed. In terms of speed, it has increased the
          number of parcels processed per hour by 400% and spatial capacity by
          500%.
        </Normal>
        <Normal>
          “The addition of new sortation systems in our Cabuyao hub is part of
          our continued efforts for operational excellence With this, we can
          ensure on-time deliveries and better customer service that are in line
          with the customer-first agenda that we are pushing at Ninja Van
          Philippines,” said Vin Perez, Ninja Van Philippines Chief Operating
          Officer. “The cross belt sorter is ideal for a company like Ninja Van
          Philippines given our high throughput needs. This will increase the
          accuracy and efficiency of our sorting process so that we can fulfill
          more deliveries in the least amount of time.”
        </Normal>
        <Normal>
          With this operational innovation, shippers, and shoppers can look
          forward to a fast, reliable and hassle-free delivery experience as
          well as excellent customer service for logistic needs and parcel
          concerns with Ninja Van Philippines.
        </Normal>
        <Normal>About Ninja Van Philippines</Normal>
        <Normal>
          Ninja Van is a tech-enabled express logistics company providing
          hassle-free delivery solutions for businesses of all sizes across
          Southeast Asia with strong presence in six countries: Singapore,
          Malaysia, Philippines, Indonesia, Vietnam, and Thailand.
        </Normal>
        <Normal>
          Launched in the Philippines in 2016, Ninja Van Philippines now covers
          100% of the Philippine population, and is among the fastest growing
          tech logistics companies in the country. Today, Ninja Van continues
          empowering businesses with fast deliveries, excellent service, and
          innovative logistics solutions.
        </Normal>
        <Normal>
          For more information, visit{" "}
          <AnchorLink
            to="https://www.ninjavan.co/en-ph"
            externalLink={true}
            linkColor={variables.linkColor}
          >
            www.ninjavan.co/en-ph
          </AnchorLink>
        </Normal>
      </>
    ),
  },
  {
    id: 3,
    date: "April 2022",
    dateTitle: "06 April 2022",
    country: "Spain",
    title:
      "SEUR launches Shop2Shop, the new solution for shop to shop delivery between private customers",
    cardTitle: "SEUR launches Shop2Shop",

    imgs: [img2],
    body: (
      <Normal>
        Recent years have seen the emergence of new consumer profiles, mostly
        millennials, who value the convenience of sending items from one point
        to another quickly and without fuss, but who are also more conscious of
        their impact on the environment.
      </Normal>
    ),
  },
  {
    id: 2,
    date: "April 2022",
    dateTitle: "06 April 2022",
    country: "United Kingdom",
    title: "DPD UK teams up with Rock Fall for new sustainable footwear range",
    cardTitle: "DPD UK teams up with Rock Fall",
    subtitle:
      "52,000 plastic recycled bottles go into safety boots for drivers and warehouse staff",
    imgs: [img3],
    body: (
      <Normal>
        Leading parcel delivery firm DPD UK has partnered with innovative
        footwear brand Rock Fall to create a new range of sustainable safety
        boots for drivers and other workers that require protective footwear.
      </Normal>
    ),
  },
  {
    id: 1,
    date: "April 2022",
    dateTitle: "05 April 2022",
    country: "France",
    title: "epicery : the food business enhancement stakes are urgent",
    cardTitle: "epicery : the food business enhancement stakes are urgent",
    subtitle:
      "epicery, a fresh goods local retailer delivery application interviewed French customers with Institut Odoxa* about how they see their relationships with local retailers. 62% of French people don’t think that local retailers are really suited to digital techniques and need to innovate. The enhancement stakes are urgent.",
    imgs: [img4],
    body: (
      <Normal>
        Although the survey confirms that French customers have an excellent
        image of local food retailers and are strongly attached to them,
        consumers believe that they are lagging behind in terms of modernity and
        innovation. The primary shortcoming: adaptation to the digital world.
        epicery intends to participate actively to help them in their digital
        enhancement.
      </Normal>
    ),
  },
];

// Insight & info / Newsroom / International Updates
export const internationalNewsArticles = [
  {
    id: 44,
    title: "Cyclone Gabrielle - New Zealand",
    country: "New Zealand",
    date: "February 2023",
    dateTitle: "13 February 2023",
    appliesTo: ["Air Express", "Air Classic", "DPD Direct", "DPD Classic"],
    body: (
      <>
        <Body>
          Please be advised that due to the impact of a category 3 tropical
          cyclone, we are experiencing delivery delays across New Zealand. It is
          expected that the cyclone may impact the length of the country.
        </Body>
        <Body>
          We are continuing to monitor this situation and will update you as
          soon as we have further information.
        </Body>
      </>
    ),
  },
  {
    id: 43,
    title: "Turkey Earthquake",
    country: "Turkey",
    date: "February 2023",
    dateTitle: "8 February 2023",
    appliesTo: ["Air Express", "Air Classic", "DPD Direct", "DPD Classic"],
    body: (
      <>
        <Body>
          Please be advised that as a result of the earthquake in Turkey,
          delivery delays are to be expected to the following cities:
        </Body>
        <Body>
          Kahramanmaras, Gaziantep, Hatay, Osmaniye, Adana, Malayca, Sanliurfa,
          Diyarbakir and Adiyaman.
        </Body>
        <Body>
          We are continuing to monitor the situation and we&apos;ll update you
          as soon as we have further information.
        </Body>
      </>
    ),
  },
  {
    id: 42,
    title: "Storm Diaz - USA",
    country: "USA",
    date: "December 2022",
    dateTitle: "20 December 2022",
    appliesTo: ["Air Express", "Air Classic", "DPD Direct"],
    body: (
      <>
        <Body>
          Please be advised that winter Storm Diaz is causing hazardous
          conditions in Colorado, the Plains, upper Midwest and the Northeast.
          As a result, delivery delays may be expected.
        </Body>
        <Body>
          We are monitoring the situation and will provide further updates.
        </Body>
      </>
    ),
  },
  {
    id: 41,
    title: "Adverse Weather Conditions - Finland",
    country: "Finland",
    date: "December 2022",
    dateTitle: "14 December 2022",
    appliesTo: ["Air Express", "Air Classic", "DPD Classic", "DPD Direct"],
    body: (
      <>
        <Body>
          Due to severe snowfall in Finland, they are experiencing difficulties
          with clearing roads for travel. This will impact traffic, increasing
          overall transit time for deliveries.
        </Body>
        <Body>
          We are expecting to see a 48 hour delay in service due to these
          conditions.
        </Body>
      </>
    ),
  },
  {
    id: 40,
    title: "Adverse Weather Conditions in France",
    country: "France",
    date: "December 2022",
    dateTitle: "14 December 2022",
    appliesTo: ["Air Express", "Air Classic", "DPD Classic", "DPD Direct"],
    body: (
      <>
        <Body>
          Due to severe weather conditions in France, deliveries to North West
          and South East regions will potentially be delayed over the next 24/48
          hours.
        </Body>
        <Body>
          In addition, there is a driving ban in Paris from 10pm tonight due to
          freezing conditions. However, DPD has authorisation for trucks to
          still be on the road, so we will endeavour to deliver without any
          delay where possible.
        </Body>
      </>
    ),
  },
  {
    id: 39,
    title: "Australia Delays",
    country: "Australia",
    date: "December 2022",
    dateTitle: "13 December 2022",
    appliesTo: ["DPD Direct"],
    body: (
      <>
        <Body>
          Please be advised that due to increased volumes, there are potential
          delays of up to 48 hours to Australia.
        </Body>
        <Body>
          We are monitoring the situation and will provide further updates.
        </Body>
      </>
    ),
  },
  {
    id: 38,
    title: "Italy Delays",
    country: "Italy",
    date: "December 2022",
    dateTitle: "13 December 2022",
    appliesTo: ["DPD Direct"],
    body: (
      <>
        <Body>
          Please be advised that due to increased volumes, there are potential
          delays of up to 48 hours to Italy.
        </Body>
        <Body>
          We are monitoring the situation and will provide further updates.
        </Body>
      </>
    ),
  },
  {
    id: 37,
    title: "Industrial Action - Belgium",
    country: "Belgium",
    date: "December 2022",
    dateTitle: "13 December 2022",
    appliesTo: ["Air Express", "Air Classic", "DPD Classic", "DPD Direct"],
    body: (
      <>
        <Body>
          Please be aware that general strike action will commence across
          Belgium on 16 December, with a march taking place in Brussels.
        </Body>
        <Body>This will likely affect Hubs and Depots in Belgium.</Body>
        <Body>
          The delivery network will still be operational to ensure deliveries
          are being met, however please expect delays of up to 48 hours.
        </Body>
        <Body>
          The situation will continue to be monitored and updates will be
          provided when required.
        </Body>
      </>
    ),
  },
  {
    id: 36,
    title: "USA Service Delays",
    country: "USA",
    date: "December 2022",
    dateTitle: "6 December 2022",
    appliesTo: ["DPD Direct"],
    body: (
      <>
        <Body>
          Please be advised that due to increased volumes, there are potential
          delays of up to 48 hours in the Mid-West Region impacting the
          following states:
        </Body>
        <Body>
          <ul>
            <li>Illinois</li>
            <li>Indiana</li>
            <li>Iowa</li>
            <li>Kansas</li>
            <li>Michigan</li>
            <li>Minnesota</li>
            <li>Missouri</li>
            <li>Nebraska</li>
            <li>North Dakota</li>
            <li>Ohio</li>
            <li>South Dakota</li>
            <li>Wisconsin</li>
          </ul>
        </Body>
        <Body>
          We are continuing to monitor the situation and will update as soon as
          we have further information.
        </Body>
      </>
    ),
  },
  {
    id: 35,
    title: "Industrial Action in Italy",
    country: "Italy",
    date: "December 2022",
    dateTitle: "6 December 2022",
    appliesTo: ["Air Express", "Air Classic", "DPD Classic", "DPD Direct"],
    body: (
      <>
        <Body>
          Following the previous notice regarding the industrial action that
          took place in Italy on 2 December, the network has confirmed the
          industrial action has now concluded.
        </Body>
        <Body>
          However, as a result, there are significant delays that will affect
          parcels due for delivery this week by up to 48 hours.
        </Body>
        <Body>
          Delivery depots are working through the backlog and will endeavour to
          minimise the effects of the delays.
        </Body>
      </>
    ),
  },
  {
    id: 34,
    title: "Singapore GST Changes",
    country: "Singapore",
    date: "December 2022",
    dateTitle: "2 December 2022",
    appliesTo: ["Air Express", "Air Classic"],
    body: (
      <>
        <Body>
          With effect from 1st January 2023 the Singapore Government has
          increased the National Tax Rate (GST) from 7% to 8% in 2023.
        </Body>
        <Body>
          The duty threshold will remain at SG$400 where shipments below SG$400
          will not require GST payment, except for controlled items.
        </Body>
        <Body>
          Also take note that the Inland Revenue Authority for Singapore (IRAS)
          will start a new regime, which is called the OVR (Overseas Vendor
          Registration). With this registration, shippers can collect GST at
          checkout and remit the monies to IRAS in regular GST returns. We will
          provide further details on how shippers can register and the data they
          should provide to us for this in the next fortnight.
        </Body>
        <Body>
          For high value shipments (over SG$400) without OVR numbers, GST will
          be collected during clearance along with duty. If the customer has
          chosen to use DDP, GST will be billed back to them along with duty.
        </Body>
        <Body>
          More information can be found on :
          <AnchorLink
            to="https://www.iras.gov.sg/taxes/goods-services-tax-(gst)/gst-and-digital-economy/gst-on-imported-services"
            externalLink={true}
          >
            https://www.iras.gov.sg/taxes/goods-services-tax-(gst)/gst-and-digital-economy/gst-on-imported-services
          </AnchorLink>
        </Body>
      </>
    ),
  },
  {
    id: 33,
    title: "UAE Customs Change",
    country: "UAE",
    date: "December 2022",
    dateTitle: "2 December 2022",
    appliesTo: ["Air Express", "Air Classic"],
    body: (
      <>
        <Body>
          Please be advised that effective from 1st January 2023, the high value
          threshold for all shipments arriving into the United Arab Emirates has
          been changed. This will be reduced from 1000 AED (approx £222) to 300
          AED (approx £66).
        </Body>
        <Body>
          This means, any shipments above 300 AED will not attract duties,
          whereas previously, it was anything over 1000 AED. This affects B2C
          and B2B customers.
        </Body>
        <Body>
          Reminder: there are no changes to the VAT rates, which remains the
          same at 5%.
        </Body>
        <Body>
          Please ensure you are providing clear consignee details, valid
          contact/email address, descriptions, HS codes, quantities, unit and
          total price with currencies.
        </Body>
      </>
    ),
  },
  {
    id: 32,
    title: "Italy - National Strike Action",
    country: "Italy",
    date: "December 2022",
    dateTitle: "1 December 2022",
    appliesTo: ["Air Express", "Air Classic", "DPD Classic", "DPD Direct"],
    body: (
      <>
        <Body>
          Please be aware there is industrial action due to commence on 2nd
          December.
        </Body>
        <Body>
          The Italian delivery network will still be operational to ensure
          deliveries are being met, however please expect delays of up to 48
          hours.
        </Body>
        <Body>
          Though we currently have no timeframe for the duration of the strike,
          we will continue to monitor this situation and further updates will be
          issued as required.
        </Body>
      </>
    ),
  },
  {
    id: 31,
    title: "France - Service Delays due to higher volumes",
    country: "France",
    date: "November 2022",
    dateTitle: "30 November 2022",
    appliesTo: ["DPD Direct"],
    body: (
      <>
        <Body>
          Please be advised due to higher than forecasted volumes, our partner
          in France has advised there are potential delays in the South of
          France.
        </Body>
        <Body>
          This is impacting the following postcode areas beginning with: 09, 11,
          12, 31, 65, 66 & 81.
        </Body>
        <Body>
          We are monitoring the situation closely and will provide further
          updates.
        </Body>
      </>
    ),
  },
  {
    id: 30,
    title: "Saudi Arabia - Service delays due to flooding",
    country: "Saudi Arabia",
    date: "November 2022",
    dateTitle: "29 November 2022",
    appliesTo: ["Air Express", "Air Classic", "DPD Direct"],
    body: (
      <>
        <Body>
          Please be advised that the below cities in Saudi Arabia have been
          impacted by road closures caused by flooding. The closures are
          expected to be in place for 2 weeks, please expect delays in
          deliveries and collections.
        </Body>
        <Body>
          <ul>
            <li>Sanabil</li>
            <li>Ajawed</li>
            <li>Khomrah</li>
            <li>Prince Fawaz</li>
            <li>Mahjr</li>
            <li>Ghuil</li>
            <li>Industrial area</li>
            <li>Harzat</li>
            <li>Kilo 14</li>
            <li>Barra</li>
          </ul>
        </Body>
        <Body>
          We are monitoring the situation closely and will provide further
          updates.
        </Body>
      </>
    ),
  },
  {
    id: 29,
    title: "IT issues at Clearing Cluster",
    country: "Germany",
    date: "October 2022",
    dateTitle: "5 October 2022",
    appliesTo: ["DPD Classic"],
    body: (
      <>
        <Body>
          Due to a change being implemented within the national German customs
          system, parcels being imported into Germany may encounter delays.
        </Body>
        <Body>
          DPD Germany are working on fixing the issue, and Body service should
          resume soon. Many parcels entering Germany between the 3rd and 4th
          October may be delayed by up to 48 hours.
        </Body>
      </>
    ),
  },
  {
    id: 28,
    title: "USA Hurricane Ian",
    country: "USA",
    date: "September 2022",
    dateTitle: "28 September 2022",
    appliesTo: ["Air Express", "Air Classic"],
    body: (
      <>
        <Body>
          Please be advised that there are ongoing adverse weather conditions
          caused by Hurricane Ian passing through Florida Keys, Tampa and Fort
          Myers. As a result, delays in deliveries are expected across the
          impacted areas.
        </Body>
        <Body>Service to the following ZIP codes is impacted:</Body>
        <Body>59020</Body>
        <Body>82190</Body>
        <Body>
          We are continuing to monitor the situation and will update as soon as
          we have further information.
        </Body>
      </>
    ),
  },
  {
    id: 27,
    title: "Italy Industrial Action",
    country: "Italy",
    date: "September 2022",
    dateTitle: "7 September 2022",
    appliesTo: ["DPD Classic"],
    body: (
      <>
        <Body>
          Please be advised that unexpected industrial action organised by local
          trade unions is taking place in Northern Italy today. Whilst the
          Italian network and depots will endeavour to deliver all parcels
          promptly, there may be a delay of 24-48 hours.
        </Body>
        <Body>
          The depot affected by this is 0230827 in Settimo Torinese (Region of
          Piedmont).
        </Body>
        <Body>
          Body operation will resume tomorrow and the backlog will be cleared by
          the end of the week.
        </Body>
      </>
    ),
  },
  {
    id: 26,
    title: "United Arab Emirates - Customs Changes Update",
    country: "UAE",
    date: "July 2022",
    dateTitle: "12 July 2022",
    appliesTo: ["Air Express", "Air Classic", "DPD Direct", "Tracked Mail"],
    body: (
      <>
        <Body>
          Please be advised that the new De Minimis threshold in UAE will now be
          effective from 1 January 2023.
        </Body>
        <Body>
          This means the new threshold for all B2B and B2C shipments in UAE will
          be reduced from 1000 AED (approx 229 GBP) to 300 AED (approx 69 GBP).
        </Body>
        <Body>
          Reminder: There are no changes to the VAT rate, which stays the same
          at 5%.
        </Body>
      </>
    ),
  },
  {
    id: 25,
    title: "Plastic Products Prohibited into India",
    country: "India",
    date: "July 2022",
    dateTitle: "11 July 2022",
    appliesTo: ["Air Express", "Air Classic", "DPD Direct"],
    body: (
      <>
        <Body>
          Please be advised that with immediate effect the Indian government has
          banned the single use of plastic products. This means that items
          intended to be used only once before being disposed or recycled are
          prohibited.
        </Body>
        <Body>The prohibited items are:</Body>
        <Body>
          <ul>
            <li>Earbuds with plastic sticks</li>
            <li>Balloons and flags with plastic sticks</li>
            <li>Candy and icecream sticks</li>
            <li>Thermocol for decoration</li>
            <li>Plastic plates, cups, glasses, stirrers and cutlery</li>
            <li>Plastic straws</li>
            <li>Plastic trays</li>
            <li>Wrapping or packaging films around sweet boxes</li>
            <li>Plastic / PVC banners not less than 100 microns</li>
            <li>Wrapping or packaging film around invitation cards</li>
            <li>Wrapping or packaging film around cigarettes</li>
            <li>Plastic carry bags less than 75 microns</li>
          </ul>
        </Body>
      </>
    ),
  },
  {
    id: 24,
    title: "France Drivers Strike",
    country: "France",
    date: "June 2022",
    dateTitle: "27 June 2022",
    appliesTo: ["DPD Direct", "Tracked Mail"],
    body: (
      <>
        <Body>
          Please be advised that driver’s strikes across France are planned from
          27 June onwards.
        </Body>
        <Body>
          The French drivers are intending to block industrial estates and
          logistic points to prevent any operational activity. As a result,
          please expect delays affecting our services.
        </Body>
        <Body>
          We are continuing to monitor the situation and will update as soon as
          we have further information.
        </Body>
      </>
    ),
  },
  {
    id: 23,
    title: "France Storm",
    country: "France",
    date: "June 2022",
    dateTitle: "8 June 2022",
    appliesTo: ["DPD Direct", "Tracked Mail"],
    body: (
      <>
        <Body>
          Please be advised that strong rain and hail storms have hit France
          which has led to severe damages to one of the regional hubs of our
          Partner. Parcels are currently being routed to alternative hubs
          however please expect a 48 hour delay in the meantime.
        </Body>
        <Body>
          Parcels affected are destined to the following postcodes beginning
          with; 18, 41, 72, 28, 44, 79, 36, 45, 85, 37,49, 86
        </Body>
        <Body>
          We are continuing to monitor the situation and will update as soon as
          we have further information.
        </Body>
      </>
    ),
  },
  {
    id: 22,
    title: "Canada Storms",
    country: "Canada",
    date: "May 2022",
    dateTitle: "23 May 2022",
    appliesTo: ["Air Express", "Air Classic", "DPD Direct"],
    body: (
      <>
        <Body>
          Please be advised that strong wind storms have hit the Ontario and
          Quebec provinces of Canada, which has led to power outages and
          disruptions around these areas.
        </Body>
        <Body>
          As a result of this, please expect delivery delays to the above
          regions.
        </Body>
        <Body>
          We are continuing to monitor the situation and will update as soon as
          we have further information.
        </Body>
      </>
    ),
  },
  {
    id: 21,
    title: "Curfew - Sri Lanka",
    country: "Sri Lanka",
    date: "May 2022",
    dateTitle: "12 May 2022",
    appliesTo: ["Air Express", "Air Classic", "DPD Direct"],
    body: (
      <>
        <Body>
          Please be advised that due to the civil disturbances in Sri Lanka, the
          government has imposed a curfew to ensure the safety of citizens. As a
          result of this, please expect delivery delays.
        </Body>
        <Body>
          We are monitoring the situation closely and will provide an update as
          soon as we have further information.
        </Body>
      </>
    ),
  },
  {
    id: 20,
    title: "Customs Clearance - Turkey",
    country: "Turkey",
    date: "May 2022",
    dateTitle: "6 May 2022",
    appliesTo: ["Air Express", "Air Classic", "DPD Direct"],
    body: (
      <>
        <Body>
          Please note effective from 1st May 2022, there have been some customs
          changes enforced by the local authorities in Turkey.
        </Body>
        <Body>
          For B2C shipments, previously, parcels valued above 1500 EUR would
          need to go through a formal clearance process. This hasnow been
          reduced to 150 EUR, so parcels above this will go through a formal
          clearance. This is based on the CIF of the parcel.
        </Body>
        <Body>
          Previously, goods valued up to 200 EUR were processed as DDP, this has
          now been reduced to 150 EUR.
        </Body>
        <Body>
          The tax rate for products outside EU countries will be 30% (previously
          this was 20%), and will remain at 18% for those coming within the EU.
          The consignee will have the option to choose their own Broker for
          clearance, or use the default option.The below charges wiI1 be
          applicable for parcels above 150 EUR:
        </Body>
        <Body>
          <ul>
            <li>Import declaration service charge - 35 USD +VAT</li>
            <li>Physical checking fee - 15 USD +VAT</li>
            <li>
              Application fee (any special approval or certification) - 20 USD
              +VAT
            </li>
          </ul>
        </Body>
        <Body>
          If the shipper is DDP, the duty charges will be billed back, along
          with the above charges listed if the parcel undergoes formal
          clearance.
        </Body>
        <Body>
          If the shipper is DAP, the above charges will be paid by the
          consignee, however if this is refused by the recipient, these charges
          will be billed back to the shipper.
        </Body>
        <Body>
          As this is a new process, please expect clearance delays in the
          initial period. It remains imperative that the shipper is providing
          clear descriptions, HS Codes, Quantities and total prices with
          currencies.
        </Body>
      </>
    ),
  },
  {
    id: 19,
    title: "Duty/Tax Changes - Returns Parcels",
    country: "",
    date: "April 2022",
    dateTitle: "27 April 2022",
    appliesTo: ["Air Express", "Air Classic"],
    body: (
      <>
        <Body>
          Please be advised for DAP customers only and where duties/taxes are
          applicable, the following change in process now applies.
        </Body>
        <Body>
          Duties/Taxes will now be billed back to the shipper in the 2 scenarios
          below as the parcel will be precleared:
        </Body>
        <Body>
          <ol>
            <li>
              Where the receivers refuse to pay the duties/taxes, these charges
              will be billed back and the parcels returned.
            </li>
            <li>
              Where our supplier is unable to make contact with the receiver for
              the required information, again, the charges will be billed back
              and parcels returned.
            </li>
          </ol>
        </Body>
        <Body>
          Note that these parcels will initially be reported on the exception
          report to our Customer Services team, which will give the option to
          destroy (at lesser charge) and will also request an alternative
          contact number if required.
        </Body>
        <Body>
          If there is no response from the shipper within 9 days, the parcel
          will be force returned and then these charges will apply
        </Body>
        <Body>
          For DDP customers, there are no changes and terms stay the same.
        </Body>
      </>
    ),
  },
  {
    id: 18,
    title: "Russia - Suspended Service",
    country: "Russia",
    date: "February 2022",
    dateTitle: "25 February 2022",
    appliesTo: [
      "Air Express",
      "Air Classic",
      "DPD Direct",
      "International Mail",
    ],
    body: (
      <>
        <Body>
          Please be advised due to the ongoing current events, effective
          immediately, services to Russia will be suspended until further
          notice.
        </Body>
        <Body>
          Any parcels received destined to Russia will be returned back to the
          sender.
        </Body>
        <Body>
          We are monitoring the situation closely and will update if any further
          changes are made.
        </Body>
      </>
    ),
  },
  {
    id: 17,
    title: "Ukraine - Suspended Service",
    country: "Ukraine",
    date: "February 2022",
    dateTitle: "24 February 2022",
    appliesTo: [
      "Air Express",
      "Air Classic",
      "DPD Direct",
      "International Mail",
    ],
    body: (
      <>
        <Body>
          Please be advised due to the ongoing current events, Ukraine has
          closed their airspace for commercial flights. As a result, effective
          immediately, services to Ukraine will be suspended until further
          notice.
        </Body>
        <Body>
          Any parcels received destined to Ukraine will be returned back to the
          sender.
        </Body>
        <Body>
          There are currently no changes to Russia, this is still operational as
          Body. We are monitoring the situation closely and will update as soon
          as we have further information.
        </Body>
      </>
    ),
  },
  {
    id: 16,
    title: "Russia - Customs Requirements",
    country: "Russia",
    date: "February 2022",
    dateTitle: "15 February 2022",
    appliesTo: ["Air Express", "Air Classic", "DPD Direct"],
    body: (
      <>
        <Body>
          Please be advised for all B2C parcels being sent to Russia, the below
          information is mandatory by Russian Customs and must be provided:
        </Body>
        <Body>
          <strong>Recipient Information</strong> - The receiver will be
          contacted via email to provide the following:
        </Body>
        <Body>
          <ol>
            <li>Consignee&apos;s full name (First, Middle, Last)</li>
            <li>Consignee&apos;s passport number</li>
            <li>Passport serial number</li>
            <li>Issue date of passport</li>
            <li>
              Individuals VAT/TIN number - The TIN number consists of 12 digits
              and is required for Russian citizens only. If the receiver is not
              aware of their VAT number, it can be easily obtained here:{" "}
              <AnchorLink
                to="https://service.nalog.ru/inn.do"
                externalLink={true}
              >
                https://service.nalog.ru/inn.do
              </AnchorLink>
            </li>
          </ol>
        </Body>
        <Body>
          <strong>Sender Information</strong> - The following must be provided
          by the sender in the data they send to DPD:
        </Body>
        <Body>
          <ol>
            <li>
              Product URL&apos;s - Russia Customs requires the website
              URL&apos;s for each individual item being sent within the
              shipment. The value declared for each item must match the website
              URL link provided.
            </li>
          </ol>
        </Body>
        <Body>
          Parcels will be held in the UK for 9 Days pending the ID information
          from the recipient. Failure to provide this will result in parcels
          being returned to the sender and could result in potential fines
          issued by Russian Customs.
        </Body>
        <Body>
          In addition to this, as per information shared by Russian Customs,
          they now have checks in place where they will visit the
          retailer&apos;s site to see if information is visible about delivery
          through DPD in Russia.
        </Body>
        <Body>
          Therefore, it is recommended that on the retailer&apos;s website in
          the &lsquo;About Delivery&lsquo; section, to include the following
          information about delivery:
        </Body>
        <Body>
          Customs clearance and delivery in Russia carried out by DPD Russia.
          You can read offer here -{" "}
          <AnchorLink
            to="http://www.dpd.ru/dpd/otpravka/mezhdunarodnaja-otpravka/public-agreement.do2"
            externalLink={true}
          >
            http://www.dpd.ru/dpd/otpravka/mezhdunarodnaja-otpravka/public-agreement.do2
          </AnchorLink>
        </Body>
        <Body>
          Failure to present this information on the retailer website may result
          in refusal of goods at customs clearance, and may lead to Customs
          seizures/returns of goods.
        </Body>
      </>
    ),
  },
  {
    id: 15,
    title: "Bahrain - Customs Changes",
    country: "Bahrain",
    date: "December 2021",
    dateTitle: "16 December 2021",
    appliesTo: [
      "Air Express",
      "Air Classic",
      "DPD Direct",
      "International Mail",
    ],
    body: (
      <>
        <Body>
          Please be advised that effective from 1st January 2022, the Government
          of Bahrain has announced the increase in VAT rate from 5% to 10%.
        </Body>
        <Body>
          For B2C and B2B shipments, this now means any shipment regardless of
          value, will now attract VAT at a rate of 10% where as previously, it
          was at 5%.
        </Body>
      </>
    ),
  },
  {
    id: 14,
    title: "UAE - Customs Changes",
    country: "UAE",
    date: "December 2021",
    dateTitle: "10 December 2021",
    appliesTo: [
      "Air Express",
      "Air Classic",
      "DPD Direct",
      "International Mail",
    ],
    body: (
      <>
        <Body>
          Please be advised that effective from 1st January 2022, the duty
          threshold for all shipments arriving into United Arab Emirates will be
          reduced for 1,000 AED (approx £206) to 300 AED (approx £62).
        </Body>
        <Body>
          This means, any shipments above 300 AED, will now attract duties,
          where as previously, it was anything over 1000 AED. This will impact
          B2C and B2B customers.
        </Body>
        <Body>
          However, it&apos;s worth noting, effective May 2022 Dubai Customs will
          remove the duty threshold for B2B shipments, meaning all B2B shipments
          to United Arab Emirates will be dutiable regardless of value. A notice
          will be issued early next year to confirm.
        </Body>
        <Body>
          Reminder: There are no changes to the VAT rate, which stays the same
          at 5%.
        </Body>
      </>
    ),
  },
  {
    id: 13,
    title: "Jordan - Customs Changes",
    country: "Jordan",
    date: "October 2021",
    dateTitle: "27 October 2021",
    appliesTo: ["Air Express", "Air Classic", "DPD Direct"],
    body: (
      <>
        <Body>
          Please be advised that following recent changes made by Jordanian
          Customs, all non-document shipments will now be X-Rayed upon
          inspection.
        </Body>
        <Body>
          As a result, please expect delays in clearance for all non-document
          shipments into Jordan.
        </Body>
      </>
    ),
  },
  {
    id: 12,
    title: "Egypt - Customs Changes",
    country: "Egypt",
    date: "September 2021",
    dateTitle: "28 September 2021",
    appliesTo: [
      "Air Express",
      "Air Classic",
      "DPD Direct",
      "International Mail",
    ],
    body: (
      <>
        <Body>
          Please be advised that in line with Egyptian customs regulations, with
          immediate effect an official attorney letter (power of attorney) must
          be provided by the consignee in order to clear consignments that
          require formal clearance. This applies to both B2B and B2C shipments.
        </Body>
        <Body>This will apply for consignments of the following criteria:</Body>
        <Body>
          <ul>
            <li>Consignments with a value of more than 2000 USD or</li>
            <li>Consignments with a weight over 50 kgs or</li>
            <li>Consignments that need approval from any authority or</li>
            <li>
              Re-estimated consignments by customs where they can be considered
              as commercial, or the value exceeded 2000 USD
            </li>
          </ul>
        </Body>
      </>
    ),
  },
  {
    id: 11,
    title: "Philippines - Customs Charges",
    country: "Philippines",
    date: "September 2021",
    dateTitle: "23 September 2021",
    appliesTo: ["Air Express", "Air Classic"],
    body: (
      <>
        <Body>
          Please be advised that in line with the Philippines customs
          administration order, fines and surcharges may be issued if goods are
          not declared correctly.
        </Body>
        <Body>These include:</Body>
        <Body>
          <ul>
            <li>Mis-declaration as to the quantity of goods</li>
            <li>Mis-declaration as to the quality of the goods</li>
            <li>Mis-declaration as to the description of goods</li>
            <li>Mis-declaration as to the weight and measurement of goods</li>
            <li>Undervaluation and miss classification</li>
          </ul>
        </Body>
        <Body>
          It is essential that you continue to declare the correct value and
          type of goods for customs clearance to avoid government imposed
          penalties or costs with mis-declaration of imported goods to
          Philippines.
        </Body>
      </>
    ),
  },
  {
    id: 10,
    title: "Oman - Customs Changes",
    country: "Oman",
    date: "September 2021",
    dateTitle: "25 September 2021",
    appliesTo: ["Air Express", "Air Classic", "DPD Direct"],
    body: (
      <>
        <Body>
          Further to International Notice 1786, please note effective
          immediately as per the new regulations applied by Omani customs, all
          personal shipments, regardless of value are now subject to important
          VAT at 5%.
        </Body>
        <Body>
          Previously goods valued up to the duty threshold of 259 USD did not
          incur any import VAT.
        </Body>
        <Body>
          Please note duty will be applicable should the value of the goods
          exceed 259 USD, this has not changed.
        </Body>
        <Body>
          It remains essential that you continue to correctly declare the value
          and type of goods for customs clearance to avoid government imposed
          penalties or costs associated with mis-declaration of imported goods
          to Oman.
        </Body>
      </>
    ),
  },
  {
    id: 9,
    title: "Taiwan Customs Requirement Changes",
    country: "Taiwan",
    date: "September 2021",
    dateTitle: "18 September 2021",
    appliesTo: ["Air Express", "Air Classic", "International Mail"],
    body: (
      <>
        <Body>
          Please be aware that due to a customs regulations change in Taiwan,
          B2C consignee&apos;s must be pre-registered on the ezWay customs app.
        </Body>
        <Body>
          Before the parcels can be exported, checks will be made to ensure that
          the consignee&apos;s are registered and have been authenticated. To
          facilitate the checks, please ensure that the consignee details
          provided on the shipment (name/ address / mobile number) are
          consistent with the consignee&apos;s registration details.
        </Body>
        <Body>
          It is vital that the mobile phone number of the receiver is provided
          to aid the customs clearance.
        </Body>
        <Body>
          The receiver&apos;s telephone number needs to contain a valid area
          code + phone no (sample: “02“- 26271808). The valid area codes in
          Taiwan are only 02, 003, 04, 004, 005, 006, 007, 008, 09 and then
          followed by the telephone number. Total number of digits should be 10.
        </Body>
        <Body>
          If the consignee is not registered, the team in Taiwan will contact
          the consignee and show them how to register, but this will of course
          incur delays.
        </Body>
        <Body>
          <strong>
            See below links on where the apps can be downloaded for Android and
            Apple users:
          </strong>
        </Body>
        <Body>
          <AnchorLink
            to="https://play.google.com/store/apps/details?id=com.tradevan.android.forms"
            externalLink={true}
          >
            https://play.google.com/store/apps/details?id=com.tradevan.android.forms
          </AnchorLink>
          <AnchorLink
            to="https://apps.apple.com/tw/app/ez-way-%E6%98%93%E5%88%A9%E5%A7%94/id1127781971"
            externalLink={true}
          >
            https://apps.apple.com/tw/app/ez-way-%E6%98%93%E5%88%A9%E5%A7%94/id1127781971
          </AnchorLink>
        </Body>
      </>
    ),
  },
  {
    id: 8,
    title: "Oman - Customs Change",
    country: "Oman",
    date: "September 2021",
    dateTitle: "1 September 2021",
    appliesTo: ["Air Express", "Air Classic", "DPD Direct"],
    body: (
      <>
        <Body>
          Please note effective immediately as per the new regulation applied by
          Omani customs, for personal and individual customers there will be a
          duty free limit of up to 259 USD (Approx. 182 GBP).
        </Body>
        <Body>
          Previously VAT at the rate of 5% was charged on all imports of goods
          into Oman. This change now means goods valued under the threshold will
          not incur duty and VAT.
        </Body>
        <Body>
          It remains essential that you continue to correctly declare the value
          and type of goods for customs clearance to avoid government imposed
          penalties or costs associated with mis-declaration of imported goods
          to Oman.
        </Body>
      </>
    ),
  },
  {
    id: 7,
    title: "Qatar - Customs Requirements Change",
    country: "Qatar",
    date: "July 2021",
    dateTitle: "12 July 2021",
    appliesTo: ["Air Express", "Air Classic"],
    body: (
      <>
        <Body>
          Please note effective immediately, the duty threshold for shipments
          imported into Qatar has changed from QAR 3,000 (Approx. 583 GBP) to
          QAR 1,000 (Approx. 194 GBP).
        </Body>
        <Body>
          As a result of this, any shipment with a value of higher than QAR
          1,000 will incur 5% customs duties.
        </Body>
        <Body>
          Additionally, for any e-Commerce shipments, please note the following:
        </Body>
        <Body>
          <ul>
            <li>
              Valid consignee Qatari ID, this consists of 11 digits and must be
              provided by the receiver. This information will be requested from
              the consignee if not initially provided from the sender.{" "}
            </li>
            <li>Complete consignee address.</li>
            <li>
              Correct consignee phone number, excluding the country code, the
              mobile and landline number consists of 8 digits.
            </li>
          </ul>
        </Body>
      </>
    ),
  },
  {
    id: 6,
    title: "Jordan - Customs Changes",
    country: "Jordan",
    date: "June 2021",
    dateTitle: "10 June 2021",
    appliesTo: ["Air Express", "Air Classic", "International Mail"],
    body: (
      <>
        <Body>
          Please be advised effective immediately as per the Jordanian customs
          authority, all inbound low value shipments (up to 200 JOD), consigned
          to individuals are now subject to the below:
        </Body>
        <Body>
          <ul>
            <li>
              Customs fees: 10% of the shipments value with a minimum amount of
              5 JOD, charged to the Consignee
            </li>
            <li>
              In the event the consignee refuses to pay these charges, this will
              be billed back to the sender of the shipment.
            </li>
          </ul>
        </Body>
        <Body>
          It is very important that the true value and description of the goods
          is declared on all inbound shipments to Jordan. Penalties may be
          charged in any case of misdeclaration or sending prohibited items.
        </Body>
      </>
    ),
  },
  {
    id: 5,
    title: "Turkey - Custom Regulations Change",
    country: "Turkey",
    date: "May 2021",
    dateTitle: "25 May 2021",
    appliesTo: ["Air Express", "Air Classic"],
    body: (
      <>
        <Body>
          Please be advised that Turkish customs have sent a reminder to stress
          the importance of providing accurate information to cater for a smooth
          clearance process and avoid delays.
        </Body>
        <Body>Customers must ensure the following:</Body>
        <Body>
          <ul>
            <li>
              Accurate description of goods (sample/gifts etc is not accepted)
            </li>
            <li>
              For personal effects, the description must state 2x trousers,
              books, watch etc.
            </li>
            <li>Accurate value of goods and HS codes are provided</li>
            <li>
              Consignees are required to provide ID upon arrival in country
            </li>
            <li>Valid in country contact number</li>
          </ul>
        </Body>
        <Body>
          Failure to comply will result in delays and may incur storage charges.
        </Body>
        <Body>
          Furthermore, for prohibited items, Turkish customs will seize and
          destroy these items. This will result in a 255 USD penalty fee. See
          list of prohibited items below:
        </Body>
        <table>
          <thead>
            <tr>
              <td>
                <strong>Product Category</strong>
              </td>
              <td>
                <strong>Example Items</strong>
              </td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Animal and Animal Products</td>
              <td>Live or dead animals</td>
            </tr>
            <tr>
              <td>Documents</td>
              <td>Anti-government propoganda documents, passports</td>
            </tr>
            <tr>
              <td>Food Products/perishable items</td>
              <td> - </td>
            </tr>
            <tr>
              <td>Gambling Related Games</td>
              <td> - </td>
            </tr>
            <tr>
              <td>Monetary Items</td>
              <td>Fake money, coins, cash</td>
            </tr>
            <tr>
              <td>Personal products</td>
              <td>Perfumes, cosmetics</td>
            </tr>
            <tr>
              <td>Pharmaceutical items</td>
              <td>Vitamins, drugs</td>
            </tr>
            <tr>
              <td>Plants</td>
              <td>Herb/ Natural Products</td>
            </tr>
            <tr>
              <td>Precious Goods</td>
              <td>Gold, precious metals/ stones, ivory, jewellery, silver</td>
            </tr>
            <tr>
              <td>Spirit Drinks and Products</td>
              <td>Alcohol, Wine, etc</td>
            </tr>
            <tr>
              <td>Tobacco Products or related</td>
              <td>
                Cigarette, cigars, electronic cigarettes, tobacco, advertising
                material, rollers
              </td>
            </tr>
            <tr>
              <td>Weapon related</td>
              <td>Ammunitions, bullets, firearms, knives</td>
            </tr>
            <tr>
              <td>Electronics and Technology Products</td>
              <td>Drones, remote controlled machinery</td>
            </tr>
          </tbody>
        </table>
      </>
    ),
  },
  {
    id: 4,
    title: "South Korea - Customs Charge Change",
    country: "South Korea",
    date: "May 2021",
    dateTitle: "23 May 2021",
    appliesTo: ["Air Express", "Air Classic", "International Mail"],
    body: (
      <>
        <Body>
          Please be advised that low value shipments into South Korea up to the
          value of 150 USD (Approximately 114.10 GBP) can be selected for
          customs inspection and will require a formal entry fee of 5 USD.
        </Body>
        <Body>
          Please note all parcels selected for customs inspection will incur
          this fee, this charge will be collected from the receivers before
          clearance is completed.
        </Body>
      </>
    ),
  },
  {
    id: 3,
    title: "Egypt - Customs Charge",
    country: "Egypt",
    date: "April 2021",
    dateTitle: "20 April 2021",
    appliesTo: [
      "Air Express",
      "Air Classic",
      "DPD Direct",
      "International Mail",
    ],
    body: (
      <>
        <Body>
          Please be advised that all inbound volume into Egypt will now be
          operated through MTS (Misr Technology Services) which is implementing
          a one window system for clearance. This means all parcels imported
          into Egypt will now be subject to a service fee of 256 EGP or 12 GBP,
          irrespective of the value.
        </Body>
        <Body>
          This change has been enforced on direction from the Egyptian
          authorities and will impact all Carriers, please note:
        </Body>
        <Body>
          DDP customers - The charges will be billed back to the sender
        </Body>
        <Body>
          DAP customers - The charges will be obtained from the recipient in the
          country
        </Body>
      </>
    ),
  },
  {
    id: 2,
    title: "Kuwait - Customs Duty Charge",
    country: "Kuwait",
    date: "April 2021",
    dateTitle: "11 April 2021",
    appliesTo: [
      "Air Express",
      "Air Classic",
      "DPD Direct",
      "International Mail",
    ],
    body: (
      <>
        <Body>
          Please note that effective immediately, Kuwaiti Customs have
          introduced a new low-value limit for B2C shipments, exempting
          shipments with a value of 100 KWT (approx. £249) or less than Customs
          duty.
        </Body>
        <Body>
          Previously, any shipments with a value of under 100 KWT (approx. £249)
          would have incurred duty charges of 5% of the value of goods. Now,
          they will not and only shipments above 100 KWT will incur duty charges
          of 5% of the value of the goods.
        </Body>
      </>
    ),
  },
  {
    id: 1,
    title: "Russia Customs Clearance Fee Increase",
    country: "Russia",
    date: "August 2020",
    dateTitle: "1 August 2020",
    appliesTo: [
      "Air Express",
      "Air Classic",
      "DPD Direct",
      "International Mail",
    ],
    body: (
      <>
        <Body>
          Please be advised that effective 1st August 2020, Russian Customs will
          increase the Clearance Fee per parcel charged to the consignee from
          250 Rubles (approx. £2.68) to 500 Rubles (approx. £5.36).
        </Body>
        <Body>
          The current duty threshold is €200 and/or 31 kg per parcel. This
          clearance fee will impact dutiable shipments imported into Russia,
          this will be charged to the consignee.
        </Body>
      </>
    ),
  },
];
